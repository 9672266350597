import {CONFIG} from 'src/config';
import { store } from '../store'
//import Swal from "sweetalert2";

const showGenericAlert = (msg, detail, typeClass) => {

    if (window.globalAlert) {
        window.globalAlert.remove();
    }

    if (!detail) detail = "";
    let iconClass = "";

    if (typeClass === 'success') {
        iconClass = "fa fa-check-circle";
    }
    else if (typeClass === 'error') {
        iconClass = "fa fa-times-circle";
    }
    else if (typeClass === 'info') {
        iconClass = "fa fa-info-circle";
    }
    else if (typeClass === 'warning') {
        iconClass = "fa fa-warning";
    }

    const title = document.createElement('div');
    title.innerHTML = "<i class='"+iconClass+" mr-2'></i>" + msg;
    title.className = 'titleA';
    const msgDiv = document.createElement('div');
    msgDiv.innerHTML = detail;
    msgDiv.className = 'msgA';

    const globalAlert = document.createElement('div');
    globalAlert.className = "globalAlert " + typeClass;
    globalAlert.appendChild(title);
    globalAlert.appendChild(msgDiv);
    document.body.appendChild(globalAlert);
    window.globalAlert = globalAlert;

    setTimeout(function () {
        globalAlert.style.opacity = '100';
    }, 50);

    const deleteEvent = setTimeout(function () {
        globalAlert.style.opacity = '0';
        setTimeout(function () {
            globalAlert.remove();
        }, 5500);
    }, 5000);

    globalAlert.addEventListener('click',function () {
        globalAlert.remove();
        clearTimeout(deleteEvent);
    })
}

const showGenericNotify = (msg, detail, typeClass, isConfirm, callbackConfirm, callbackCancel, btnConfirmText, btnCancelText) => {

    if (!isConfirm) isConfirm = false;
    if (!detail) detail = "";
    if (!callbackConfirm) callbackConfirm = false;
    if (!callbackCancel) callbackCancel = false;
    if (!btnConfirmText) btnConfirmText = "Continuar";
    if (!btnCancelText) btnCancelText = "Cancelar";

    let iconClass = "";

    if (typeClass === 'success') {
        iconClass = "fa fa-check-circle";
    }
    else if (typeClass === 'error') {
        iconClass = "fa fa-times-circle";
    }
    else if (typeClass === 'info') {
        iconClass = "fa fa-info-circle";
    }
    else if (typeClass === 'warning') {
        iconClass = "fa fa-warning";
    }

    const title = document.createElement('div');
    title.innerHTML = "<i class='"+iconClass+" mr-2'></i>" + msg;
    title.className = 'titleA';
    const msgDiv = document.createElement('div');
    msgDiv.innerHTML = detail;
    msgDiv.className = 'msgA';

    const continueBtn = document.createElement('button');
    continueBtn.className = "continueBtn";
    continueBtn.innerHTML = btnConfirmText;
    continueBtn.addEventListener("click", function () {
        if (isConfirm) {
            if (typeof callbackConfirm === 'function') {
                callbackConfirm();
            }
            globalAlert.remove();
        }
        else {
            globalAlert.remove();
        }
    })

    const cancelBtn = document.createElement('button');
    cancelBtn.className = "cancelBtn";
    cancelBtn.innerHTML = btnCancelText;
    cancelBtn.addEventListener("click", function () {
        if (isConfirm) {
            if (typeof callbackCancel === 'function') {
                callbackCancel();
                globalAlert.remove();
            }
            else {
                globalAlert.remove();
            }
        }
        else {
            globalAlert.remove();
        }
    })

    const globalAlertContainer = document.createElement('div');
    globalAlertContainer.appendChild(title);
    globalAlertContainer.appendChild(msgDiv);
    globalAlertContainer.appendChild(continueBtn);

    if (isConfirm) {
        globalAlertContainer.appendChild(cancelBtn);
    }

    const globalAlert = document.createElement('div');
    globalAlert.className = "globalNotify " + typeClass;
    globalAlert.appendChild(globalAlertContainer);
    document.body.appendChild(globalAlert);

    setTimeout(function () {
        globalAlert.style.opacity = '100';
    }, 50);
}

export default {
    send: (method, url, data, onSuccess, onError, options) => {

        if (!method) method = 'GET';
        method = method.toUpperCase();

        if (!options) options = {};

        if (!options.disableLoading) {
            // voy a validar si el token esta ok
            store.dispatch('coreShowLoading');
        }

        const config = {
            method: method,
        };

        if (method === 'FILE') {
            config['headers'] = new Headers({
                'Authorization': 'Bearer ' + store.getters.AuthGetToken,
                "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
            });
            config.method = 'POST';
            config['body'] = data;
        }
        else {
            config['headers'] = new Headers({
                'Authorization': 'Bearer ' + store.getters.AuthGetToken,
                "Content-Type": "application/json",
                "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
            });

            if (method !== 'GET') {
                config['body'] = JSON.stringify(data);
            }
        }

        fetch(CONFIG.getWsUrl(url), config)
            .then(response => response.json())
            .then(data => {
                if (typeof data.status !== 'undefined') {
                    if (data.status) {
                        onSuccess(data);
                        if (!options.disableLoading && !store.getters.coreIsLockedLoading) {
                            store.dispatch('coreHideLoading');
                        }
                    }
                    else {
                        if (!options.disableLoading && !store.getters.coreIsLockedLoading) {
                            store.dispatch('coreHideLoading');
                        }

                        if (data['error-code'] === 'RT-NO-ACCESS') {
                            showGenericAlert("El usuario requiere acceso al módulo: " + data['data']['m'], '', 'error');
                        }
                        else {
                            onError(data);
                        }
                    }
                }
            })
            .catch((e) => {
                console.log('API-RequestError:'+ e);
                if (!options.disableLoading) {
                    store.dispatch('coreHideLoading');
                }
                showGenericAlert("Ha ocurrido un error, por favor intente de nuevo", '', 'error');
            });
    },
    showSuccessAlert : (msg, detail) => {
        if (!detail) detail = "";
        showGenericAlert(msg, detail, 'success');
    },
    showErrorAlert : (msg, detail) => {
        if (!detail) detail = "";
        showGenericAlert(msg, detail, 'error');
    },
    showInfoAlert : (msg, detail) => {
        if (!detail) detail = "";
        showGenericAlert(msg, detail, 'info');
    },
    showWarningAlert : (msg, detail) => {
        if (!detail) detail = "";
        showGenericAlert(msg, detail, 'warning');
    },
    showFormValidationErrors : (errors) => {
        let strError = '';
        for (let error of errors) {
            strError += error + ', ';
        }
        strError = strError.replace(/,\s*$/, "");
        showGenericAlert("Error al llenar campos", strError, 'error');
    },
    showSuccessNotify: (title, msg) => {
        showGenericNotify(title, msg, 'success');
    },
    showErrorNotify: (title, msg) => {
        showGenericNotify(title, msg, 'error');
    },
    showConfirm: (title, msg, callbackOnSuccess, callbackOnCancel, btnContinueText, btnCancelText) => {
        showGenericNotify(title, msg, 'info', true, callbackOnSuccess, callbackOnCancel, btnContinueText, btnCancelText);
    }
}
