<template>
    <div>
        <ficha-layout title="Veterinaria" color="#1e9a7c" :tabs="tabs" tab-default="preclinica" enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #grooming>
                <grooming></grooming>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #consulta>
                <box title="Observación de mascota" icon="fas fa-notes-medical">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in veterinaria_observacion" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveObservacion"></Field>
                        </div>
                    </div>
                </box>
                <box title="Alimentación" icon="fas fa-utensils">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in veterinaria_alimentacion" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveAlimentacion"></Field>
                        </div>
                    </div>
                </box>
                <box title="Otros" icon="fas fa-file-alt">
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in veterinaria_otros" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveOtros"></Field>
                        </div>
                    </div>
                </box>

                <box title="Grooming" icon="fas fa-dog" >
                    <div class="row">
                        <div class="col-12 col-sm-3 mb-3" v-for="item in veterinaria_grooming" :key="item.name">
                            <Field :data="item" v-model="item.value" @change="saveGrooming"></Field>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in grooming_comportamiento" :key="'grooming_comportamiento_' + key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveGroomingComportamiento"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                        </div>
                    </div>
                </box>
                <box title="Vacunas" icon="fas fa-syringe">
                    <table class="table">
                        <tr>
                            <td class="font-weight-bold">
                                Vacuna
                            </td>
                            <td class="font-weight-bold">Fecha #1</td>
                            <td class="font-weight-bold">Fecha #2</td>
                            <td class="font-weight-bold">Fecha #3</td>
                            <td class="font-weight-bold">Fecha #4</td>
                            <td class="font-weight-bold">Fecha #5</td>
                        </tr>
                        <tr v-for="item in veterinaria_vacunas.vacunas" :key="item" class="mb-3">
                            <td>
                                <div v-if="!item.editable">
                                    {{item.nombre}}
                                </div>
                                <div v-else>
                                    <input type="text" class="form-control" v-model="item.nombre" @change="saveVacunas"/>
                                </div>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f1" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f2" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f3" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f4" @change="saveVacunas"/>
                            </td>
                            <td>
                                <input type="date" class="form-control" v-model="item.f5" @change="saveVacunas"/>
                            </td>
                        </tr>
                    </table>
                    <div class="mt-3">
                        <div class="form-group">
                            <label>Observaciones sobre vacunas</label>
                            <textarea class="form-control w-100" v-model="veterinaria_vacunas.observaciones"  @change="saveVacunas"></textarea>
                        </div>
                    </div>
                </box>
            </template>
        </ficha-layout>
    </div>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


import FichaLayout from './components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';
import Field from '/src/modules/medico/views/fichas/Field.vue';

// componentes medicos
import Tratamiento from './components/Tratamiento.vue';
import PreClinica from './components/PreClinica.vue';
import ImpresionClinica from './components/ImpresionClinica.vue';
import Grooming from './components/Grooming.vue';
import Resumen from './components/Resumen.vue';
import Adjuntos from './components/Adjuntos.vue';

export default {
    components: {
        Box,
        Field,
        FichaLayout,
        Tratamiento,
        PreClinica,
        Grooming,
        ImpresionClinica,
        Resumen,
        Adjuntos,
    },
    props: [],
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                consulta: {
                    icon: 'fas fa-paw',
                    name: 'Veterinaria',
                },
                grooming: {
                    icon: 'fas fa-hands-wash',
                    name: 'Grooming',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
            veterinaria_observacion: {
                dias_enfermo: {
                    name: 'Días de enfermedad',
                    type: 'text',
                    value: '',
                },
                dificultad_respiratoria: {
                    name: 'Dificultad respiratoria',
                    type: 'text',
                    value: '',
                },
                congestion: {
                    name: 'Congestión',
                    type: 'text',
                    value: '',
                },
                tos: {
                    name: 'Tos',
                    type: 'text',
                    value: '',
                },
                secreciones: {
                    name: 'Secreciones',
                    type: 'text',
                    value: '',
                },
                estornudos: {
                    name: 'Estornudos o agita la cabeza',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                se_alimenta: {
                    name: 'Se alimenta',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                        'Muy poco',
                    ]
                },
                vomitos: {
                    name: 'Vómitos o náusea',
                    type: 'text',
                    value: '',
                },
                gases: {
                    name: 'Gases o inflamación abdominal',
                    type: 'text',
                    value: '',
                },
                diarrea: {
                    name: 'Diarrea',
                    type: 'text',
                    value: '',
                },
                deshidratado: {
                    name: 'Deshidratación',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                temblores: {
                    name: 'Temblores musculares',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                incoordinacion: {
                    name: 'Incoordinación para caminar',
                    type: 'select',
                    value: '',
                    options: [
                        'Si',
                        'No',
                    ]
                },
                ceguera: {
                    name: 'Ceguera o problemas oculares',
                    type: 'text',
                    value: '',
                },
                salivacion: {
                    name: 'Salivación',
                    type: 'text',
                    value: '',
                },
                afonico: {
                    name: 'Afónico',
                    type: 'text',
                    value: '',
                },
                envenenamiento: {
                    name: 'Sospecha de envenenamiento',
                    type: 'text',
                    value: '',
                },
                dolor: {
                    name: 'Dolor',
                    type: 'text',
                    value: '',
                },
                articulaciones: {
                    name: 'Problema articular',
                    type: 'text',
                    value: '',
                },
                movilidad: {
                    name: 'Movilidad',
                    type: 'text',
                    value: '',
                },
            },
            veterinaria_vacunas: {
                "vacunas": {
                    "parvovirus": {
                        "nombre": "Parvovirus",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "moquillo": {
                        "nombre": "Moquillo",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "rabia": {
                        "nombre": "Rabia",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "leucemiaFelina": {
                        "nombre": "Leucemia Felina",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "panleucopenia": {
                        "nombre": "Panleucopenia",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "calicivirus": {
                        "nombre": "Calicivirus",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "rinotraqueitis": {
                        "nombre": "Rinotraqueitis",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "peritonitisInfecciosaFelina": {
                        "nombre": "Peritonitis Infecciosa Felina",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "bordetella": {
                        "nombre": "Bordetella",
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "otraUno": {
                        "nombre": "",
                        "editable": true,
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    },
                    "otraDos": {
                        "nombre": "",
                        "editable": true,
                        "f1": "",
                        "f2": "",
                        "f3": "",
                        "f4": "",
                        "f5": ""
                    }
                },
                "observaciones": ""
            },
            veterinaria_alimentacion: {
                alimentacion: {
                    name: 'Detalle la alimentación',
                    type: 'textarea',
                    value: '',
                },
            },
            veterinaria_grooming: {
                detalles: {
                    name: 'Detalles específicos del grooming',
                    type: 'textarea',
                    value: '',
                },
                hora: {
                    name: 'Hora de recogida',
                    type: 'time',
                    value: '',
                },
                groomer: {
                    name: 'Groomer o encargados',
                    type: 'text',
                    value: '',
                }
            },
            grooming_comportamiento: {
                amigable: {
                    name: 'Amigable',
                    complemento: {},
                    value: false,
                },
                energica: {
                    name: 'Enérgico',
                    complemento: {},
                    value: false,
                },
                pesado: {
                    name: 'Pesado',
                    complemento: {},
                    value: false,
                },
                viejo: {
                    name: 'Viejo',
                    complemento: {},
                    value: false,
                },
                ruidoso: {
                    name: 'Ruidoso',
                    complemento: {},
                    value: false,
                },
                mordidas: {
                    name: 'Mordidas',
                    complemento: {},
                    value: false,
                },
                banos: {
                    name: 'Baños',
                    complemento: {},
                    value: false,
                },
                Rechazado: {
                    name: 'rechazado',
                    complemento: {},
                    value: false,
                }
            },
            veterinaria_otros: {
                habitos: {
                    name: 'Hábitos o comportamientos',
                    type: 'textarea',
                    value: '',
                },
                observaciones: {
                    name: 'Observaciones',
                    type: 'textarea',
                    value: '',
                },
            },

            // graficos
            weightForAge: false,
            weightForLengthHeight: false,
            lengthHeightForAge: false,
            BMIForAge: false,
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {
        'GetMedicoFichaActive.datos_paciente.peso': function (value) {
            this.drawChart();
        },
    },
    mounted() {
        const self = this;

        // Cargo la info de prenatales
        Object.keys(self.veterinaria_observacion).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_observacion) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_observacion[value]) {
                    self.veterinaria_observacion[value].value = self.GetMedicoFichaActive.metadata.veterinaria_observacion[value];
                }
            }
        });

        // Cargo la info de pediatria
        Object.keys(self.veterinaria_alimentacion).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_alimentacion) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_alimentacion[value]) {
                    self.veterinaria_alimentacion[value].value = self.GetMedicoFichaActive.metadata.veterinaria_alimentacion[value];
                }
            }
        });

        // Cargo la info de pediatria
        Object.keys(self.veterinaria_otros).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_otros) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_otros[value]) {
                    self.veterinaria_otros[value].value = self.GetMedicoFichaActive.metadata.veterinaria_otros[value];
                }
            }
        });
        // Cargo la info de grooming
        Object.keys(self.veterinaria_grooming).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_grooming) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_grooming[value]) {
                    self.veterinaria_grooming[value].value = self.GetMedicoFichaActive.metadata.veterinaria_grooming[value];
                }
            }
        });
        // Cargo la info de comportamiento
        Object.keys(self.grooming_comportamiento).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.grooming_comportamiento) {
                if (self.GetMedicoFichaActive.metadata.grooming_comportamiento[value]) {
                    self.grooming_comportamiento[value].value = self.GetMedicoFichaActive.metadata.grooming_comportamiento[value];
                }
            }
        });

        // Cargo la info de vacunas
        Object.keys(self.veterinaria_vacunas.vacunas).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas) {
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f1']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f1'];
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f2']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f2'];
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f3']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f3'];
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f4']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f4'];
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f5']) self.veterinaria_vacunas.vacunas[value].f1 = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_f5'];
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_n']) self.veterinaria_vacunas.vacunas[value].nombre = self.GetMedicoFichaActive.metadata.veterinaria_vacunas[value + '_n'];
                if (self.GetMedicoFichaActive.metadata.veterinaria_vacunas['observaciones']) self.veterinaria_vacunas['observaciones'] = self.GetMedicoFichaActive.metadata.veterinaria_vacunas['observaciones'];
            }
        });
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        saveObservacion() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_observacion).map(function (value) {
                if (self.veterinaria_observacion[value].value !== '') arrToSave[value] = self.veterinaria_observacion[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_observacion',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveAlimentacion() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_alimentacion).map(function (value) {
                if (self.veterinaria_alimentacion[value].value !== '') arrToSave[value] = self.veterinaria_alimentacion[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_alimentacion',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveGrooming() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_grooming).map(function (value) {
                if (self.veterinaria_grooming[value].value !== '') arrToSave[value] = self.veterinaria_grooming[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_grooming',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveGroomingComportamiento() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.grooming_comportamiento).map(function (value) {
                arrToSave[value] = self.grooming_comportamiento[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'grooming_comportamiento',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_otros).map(function (value) {
                if (self.veterinaria_otros[value].value !== '') arrToSave[value] = self.veterinaria_otros[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_otros',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveVacunas() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.veterinaria_vacunas.vacunas).map(function (value) {
                if (self.veterinaria_vacunas.vacunas[value].f1 !== '') arrToSave[value + '_f1'] = self.veterinaria_vacunas.vacunas[value].f1;
                if (self.veterinaria_vacunas.vacunas[value].f2 !== '') arrToSave[value + '_f2'] = self.veterinaria_vacunas.vacunas[value].f2;
                if (self.veterinaria_vacunas.vacunas[value].f3 !== '') arrToSave[value + '_f3'] = self.veterinaria_vacunas.vacunas[value].f3;
                if (self.veterinaria_vacunas.vacunas[value].f4 !== '') arrToSave[value + '_f4'] = self.veterinaria_vacunas.vacunas[value].f4;
                if (self.veterinaria_vacunas.vacunas[value].f5 !== '') arrToSave[value + '_f5'] = self.veterinaria_vacunas.vacunas[value].f5;

                if (self.veterinaria_vacunas.vacunas[value].editable) {
                    if (self.veterinaria_vacunas.vacunas[value].nombre !== '') arrToSave[value + '_n'] = self.veterinaria_vacunas.vacunas[value].nombre;
                }
            });
            arrToSave['observaciones'] = self.veterinaria_vacunas['observaciones'];

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'veterinaria_vacunas',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },

        // Gráficos
        drawChart() {

            const self = this;
            const peso = (typeof self.GetMedicoFichaActive.cliente.peso !== 'undefined') ? self.GetMedicoFichaActive.cliente.peso : false;
            const talla = (typeof self.GetMedicoFichaActive.metadata !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof self.GetMedicoFichaActive.metadata.datos_paciente.talla !== 'undefined') ? self.GetMedicoFichaActive.metadata.datos_paciente.talla : false;
            const genero = (typeof self.GetMedicoFichaActive.cliente.genero !== 'undefined') ? self.GetMedicoFichaActive.cliente.genero : false;
            const fechaNacimiento = (typeof self.GetMedicoFichaActive.cliente.fechaNacimiento !== 'undefined') ? self.GetMedicoFichaActive.cliente.fechaNacimiento : false;

            if (!genero || !talla || !peso || !fechaNacimiento) {
                API.showErrorAlert('Para calcular los gráficos es necesario que el paciente posea peso, talla, género y fecha de nacimiento');
            }
            else {
                API.send('POST', 'medico/utilities/oms-anthropometric-indicators', {
                        'fechaNacimiento': fechaNacimiento,
                        'genero': genero,
                        'peso': peso,
                        'talla': talla,
                        'displayWeightIn': 'lb',
                    }, function (response) {
                        self.BMIForAge = response.data.BMIForAge;
                        self.weightForAge = response.data.weightForAge;
                        self.weightForLengthHeight = response.data.weightForLengthHeight;
                        self.lengthHeightForAge = response.data.lengthHeightForAge;
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            }
        },
    },
};

</script>
