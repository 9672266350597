<template>
    <div id="preloader" v-show="coreGetPreloaderStatus">
        <div class="sk-three-bounce">
            <div class="sk-child sk-bounce1"></div>
            <div class="sk-child sk-bounce2"></div>
            <div class="sk-child sk-bounce3"></div>
        </div>
    </div>
    <!-- <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link> -->

    <router-view/>
</template>

<script>

import {mapGetters, mapMutations} from "vuex";
import Numbro from 'numbro';
import CurrencyLanguajes from 'src/core/CurrencyLanguajes';
import {store} from "src/store";
import { setOptions, bootstrap } from 'vue-gtag'

export default {
    name: "App",
    data() {
        return {
            time: 100,
            show: 1,
        };
    },
    watch: {
        whiteLabelInfo() {
            this.loadProps();
        }
    },
    mounted() {
        this.loading(true);
        setTimeout(() => {
            this.loading(false);
        }, 500);

        // Number config
        let tagLanguage = 'es-GT';
        Numbro.registerLanguage(CurrencyLanguajes.getDefaultLang(tagLanguage));
        Numbro.setLanguage(tagLanguage);

        this.loadProps();

        // Analytics
        if (typeof this.StoreInfo.gtag !== 'undefined' && this.StoreInfo.gtag !== '') {
            setOptions({
                config: { id: this.StoreInfo.gtag }
            })

            bootstrap().then((gtag) => {
                // all done!
                console.log('Loaded Gtag');
            });
        }
    },
    computed: {
        ...mapGetters({
            coreGetPreloaderStatus: 'coreGetPreloaderStatus',
            whiteLabelInfo: 'whiteLabelInfo',
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapMutations(["loading"]),
        loadProps() {
            let root = document.documentElement;
            root.style.setProperty('--primary-color', store.getters.whiteLabelInfo.primaryColor);
            root.style.setProperty('--secondary-color', store.getters.whiteLabelInfo.secondaryColor);
            root.style.setProperty('--third-color', store.getters.whiteLabelInfo.thirdColor);

            // change favicon
            let link = document.querySelector("link[rel~='icon']");
            if (!link) {
                link = document.createElement('link');
                link.rel = 'icon';
                document.getElementsByTagName('head')[0].appendChild(link);
            }
            link.href = store.getters.whiteLabelInfo.faviconUrl;

            // change title
            document.title = store.getters.whiteLabelInfo.nombre;
        }
    }
};
</script>
