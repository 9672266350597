<template>
    <layout>
        <div class="content-body">
            <div class="verification section-padding mb-80">
                <div class="container h-100">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-xl-8 col-md-6">
                            <div class="auth-form card">
                                <div class="card-header">
                                    <h4 class="card-title">
                                        <router-link to="/account-affiliations">
                                            <i class="back fa fa-arrow-circle-left"></i>
                                        </router-link>
                                        Solicitudes
                                    </h4>
                                </div>
                                <div class="card-body">
                                    Coming soon!
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
//import {store} from "store";

export default {
    components: {
        Layout,
    },
    data() {
        return {

        };
    },
    mounted: function () {
    },
    methods: {

    },
};
</script>
