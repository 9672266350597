<template>
    <SettingLayout :active="26">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Localidades, bodegas y envíos</h4>
                        <button class="btn btn-primary" @click="resetItemEdit(); newItem = 1">Agregar nueva</button>
                    </div>
                    <div class="card-body">
                        <div class="mb-4">
                            Una localidad se refiere una "ubicación", en el cual es posible atender al cliente. En caso el centro de atención sea físico, es posible que funcione como bodega, que a su vez, será el lugar en el cual el proveedor de envíos recogerá los paquetes para su despacho.
                        </div>
                        <div class="mb-4" v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                            <a :href="whiteLabelInfo.enlaces.envio" class="text-primary text-decoration-underline" target="_blank">Ver política de envíos y empaque</a>
                        </div>
                        <div v-if="itemEdit.id > 0 || newItem">
                            <hr>
                            <div class="form-row mb-2">
                                <div class="col-12">
                                    <h5 class="text-primary">Información general</h5>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Nombre *</label>
                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.nombre" name="nombre"/>
                                    <span class="validationError">{{ Valid.nombre }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Latitud GPS</label>
                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.latitud" name="latitud"/>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Longitud GPS</label>
                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.longitud" name="longitud"/>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Teléfono de contacto (para envíos u otros) *</label>
                                    <input type="tel" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.telefono" name="telefono"/>
                                    <span class="validationError">{{ Valid.telefono }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Teléfono adicional</label>
                                    <input type="tel" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.telefonoAdicional" name="telefono"/>
                                    <span class="validationError">{{ Valid.telefonoAdicional }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Email de contacto (para envíos u otros) *</label>
                                    <input type="email" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.email" name="email"/>
                                    <span class="validationError">{{ Valid.email }}</span>
                                </div>
                                <div class="form-group col-xl-6">
                                    <div>
                                        <label class="mr-sm-2">Dirección completa (para recolección de envíos) *</label>
                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.direccion" name="direccion"/>
                                        <span class="validationError">{{ Valid.direccion }}</span>
                                    </div>
                                </div>
                                <div class="col-12 mb-2">
                                    <hr>
                                    <h5 class="text-primary">Ubicación de centro de atención o bodega *</h5>
                                </div>
                                <div class="form-group col-xl-6 editPage-piker">
                                    <label class="mr-sm-2">País *</label>
                                    <select class="form-control" v-model="itemEdit.paisId">
                                        <option v-for="value in paises" :key="value.id" :value="value.id">{{ value.nombre }}</option>
                                    </select>
                                    <span class="validationError">{{ Valid.paisId }}</span>
                                </div>
                                <div class="form-group col-xl-6 editPage-piker">
                                    <label class="mr-sm-2">Departamento *</label>
                                    <select class="form-control" v-model="itemEdit.departamentoId">
                                        <option v-for="value in departamentos" :key="value.id" :value="value.id">{{ value.nombre }}</option>
                                    </select>
                                    <span class="validationError">{{ Valid.departamentoId }}</span>
                                </div>
                                <div class="form-group col-xl-6 editPage-piker">
                                    <label class="mr-sm-2">Municipio *</label>
                                    <select class="form-control" v-model="itemEdit.municipioId">
                                        <option v-for="value in municipios" :key="value.id" :value="value.id">{{ value.nombre }}</option>
                                    </select>
                                    <span class="validationError">{{ Valid.municipioId }}</span>
                                </div>
                                <div class="form-group col-xl-6 text-center pt-4">
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox"
                                            v-model="itemEdit.estado"
                                            :checked="itemEdit.estado"/>
                                        <div class="toggle-switch"></div>
                                        <span class="toggle-label"> Activo </span>
                                    </label>
                                </div>
                                <div class="col-12 mb-2">
                                    <hr>
                                    <h5 class="text-primary">Cobertura para citas a domicilio</h5>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mt-3 mb-5">
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox"
                                                v-model="itemEdit.aDomicilio"
                                                :checked="itemEdit.aDomicilio"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label"> Brinda citas a domicilio</span>
                                        </label>
                                    </div>
                                    <div v-if="itemEdit.aDomicilio">
                                        <div class="mb-2 text-success text-center">
                                            Ingresa las áreas en las cuales este centro de atención brindará servicio de citas a domicilio
                                        </div>
                                        <div class="form-group">
                                            <label class="mr-sm-2">País</label>
                                            <div v-if="paises.length > 0">
                                                <multiselect v-model="coberturaPaisesList" :options="paises" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona los departamentos" :multiple="true"></multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="mr-sm-2">Departamentos</label>
                                            <div v-if="departamentosFiltered.length > 0">
                                                <multiselect v-model="coberturaDepartamentosList" :options="departamentosFiltered" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona los departamentos" :multiple="true"></multiselect>
                                            </div>
                                        </div>
                                        <div class="form-group">
                                            <label class="mr-sm-2">Municipios</label>
                                            <div v-if="municipiosFiltered.length > 0">
                                                <multiselect v-model="coberturaMunicipiosList" :options="municipiosFiltered" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona los municipios" :multiple="true"></multiselect>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mb-2">
                                    <hr>
                                    <h5 class="text-primary">Envíos a domicilio</h5>

                                    <div class="mb-4" v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                                        <a :href="whiteLabelInfo.enlaces.envio" class="text-primary text-decoration-underline" target="_blank">Ver política de envíos y empaque</a>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="form-group mt-3 mb-5">
                                        <label class="toggle">
                                            <input class="toggle-checkbox" type="checkbox"
                                                v-model="itemEdit.warehouse"
                                                :checked="itemEdit.warehouse"/>
                                            <div class="toggle-switch"></div>
                                            <span class="toggle-label"> Habilitar bodega para envíos</span>
                                        </label>
                                    </div>
                                    <div v-if="itemEdit.warehouse">
                                        <hr class="my-5">
                                        <div>
                                            <div class="my-3">
                                                <img src="staticAssets/global/brands/forzaDeliveryExpress.png" width="150">
                                            </div>
                                            <div>
                                                Proveedor de envíos Forza Delivery Express, posee cobertura en Guatemala.
                                            </div>
                                            <div class="mt-3">
                                                <label class="toggle">
                                                    <input class="toggle-checkbox" type="checkbox"
                                                        v-model="itemEdit.delivery.ForzaExpressDeliveryEnabled"
                                                        :checked="itemEdit.delivery.ForzaExpressDeliveryEnabled"/>
                                                    <div class="toggle-switch"></div>
                                                    <span class="toggle-label"> Habilitar envíos con Forza Delivery Express</span>
                                                </label>
                                            </div>
                                            <div class="mt-3" v-if="itemEdit.delivery.ForzaExpressDeliveryEnabled">
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Código exclusivo de bodega Forza</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.delivery.ForzaExpressDeliveryCode" name="ForzaExpressDeliveryCode" />
                                                    <div v-if="itemEdit.delivery.ForzaExpressDeliveryCode === ''">
                                                        <div v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                                                            <div>
                                                                El codigo exclusivo de bodega Forza será necesario para el envío correcto de tus paquetes. Este será enviado por Forza al correo electrónico de contacto de tu bodega (ingresado arriba) en un máximo de 24 horas. Solicita tu código para esta bodega, haciendo clic en el botón "Solicitar mi código".
                                                            </div>
                                                        </div>
                                                        <div class="my-3">
                                                            <button class="btn btn-primary" @click="requestWarehouse('forzaDeliveryExpress')">Solicitar mi código</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <hr class="my-5">
                                        <div>
                                            <div class="my-3">
                                                <img src="staticAssets/global/brands/estandarShipping.png" width="150">
                                                <span class="ml-2">(Envío propio)</span>
                                            </div>
                                            <div>
                                                Proveedor de envíos genérico, debe utilizar este proveedor si usted un proveedor de envíos propio o que no se encuentre integrado en la plataforma.
                                            </div>
                                            <div class="mt-3">
                                                <label class="toggle">
                                                    <input class="toggle-checkbox" type="checkbox"
                                                        v-model="itemEdit.delivery.EstandarShippingEnabled"
                                                        :checked="itemEdit.delivery.EstandarShippingEnabled"/>
                                                    <div class="toggle-switch"></div>
                                                    <span class="toggle-label"> Habilitar envíos con proveedor propio</span>
                                                </label>
                                            </div>
                                            <div class="mt-3" v-if="itemEdit.delivery.EstandarShippingEnabled">
                                                <div class="form-group col-xl-6">
                                                    <label class="mr-sm-2">Precio de envío</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="itemEdit.delivery.EstandarShippingPrice" name="EstandarShippingPrice" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group col-12 text-right">
                                    <hr>
                                    <button @click="saveItem" class="btn btn-primary mr-2">Guardar</button>
                                    <button @click="resetItemEdit" class="btn btn-danger">Cancelar</button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <ul v-if="Object.keys(locaciones).length > 0" class="linked_account">
                                <li v-for="item in locaciones" :key="item.id">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="media"><span class="mr-3"><i class="fa fa-bank"></i></span>
                                                <div class="media-body">
                                                    <h5 class="mt-0 mb-1">{{ item.nombre }}</h5>
                                                    <p>
                                                        Código de bodega: <b> &nbsp; {{ item.codigo }}</b>
                                                    </p>
                                                    <p>
                                                        Dirección: {{ item.direccion }}
                                                    </p>
                                                </div>
                                                <div class="edit-option">
                                                    <a @click="editItem(item)"><i class="fa fa-pencil"></i></a>
                                                    <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                            <div v-else class="text-center">
                                Aún no posee locaciones o establecimientos configurados
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </SettingLayout>
</template>
<script>
import SettingLayout from "../components/settings/settingLayout";

import {mapGetters} from "vuex";
import API from "src/core/Api";
import DataValidation from "src/core/DataValidation";
import Multiselect from "vue-multiselect";

export default {
    components: {
        SettingLayout,
        Multiselect
    },
    data() {
        return {
            Valid: {},
            locaciones: {},
            newItem: false,
            itemEdit: {
                id: 0,
                nombre: "",
                codigo: "",
                latitud: "",
                longitud: "",
                direccion: "",
                email: "",
                telefono: "",
                telefonoAdicional: "",
                estado: 0,
                aDomicilio: 0,
                municipioId: 0,
                departamentoId: 0,
                paisId: 0,
                warehouse: 0,
                forzaSourceId: 0,
                coberturasPaises: [],
                coberturasDepartamentos: [],
                coberturasMunicipios: [],

                // envios
                delivery: {
                    ForzaExpressDeliveryCode: '',
                    ForzaExpressDeliveryEnabled: 0,
                    EstandarShippingEnabled: 0,
                    EstandarShippingPrice: 0,
                },
            },

            paises: [],
            departamentos: [],
            municipios: [],

            // coberturas
            departamentosFiltered: [],
            municipiosFiltered: [],

            coberturaPaisesList: [],
            coberturaDepartamentosList: [],
            coberturaMunicipiosList: [],
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    watch: {
        'itemEdit.paisId': function () {
            this.getDepartamentos();
        },
        'itemEdit.departamentoId': function () {
            this.getMunicipios();
        },
        coberturaPaisesList: function () {
            this.getDepartamentosFiltered();
        },
        coberturaDepartamentosList: function () {
            this.getMunicipiosFiltered();
        },
    },
    mounted() {
        this.loadItems();
        this.getPaises();
    },
    methods: {
        resetItemEdit() {
            this.newItem = 0;
            this.itemEdit = {
                id: 0,
                nombre: "",
                codigo: "",
                latitud: "",
                longitud: "",
                direccion: "",
                email: "",
                telefono: "",
                telefonoAdicional: "",
                estado: 0,
                aDomicilio: 0,
                municipioId: 0,
                departamentoId: 0,
                paisId: 0,
                warehouse: 0,
                coberturasPaises: [],
                coberturasDepartamentos: [],
                coberturasMunicipios: [],

                // envios
                delivery: {
                    ForzaExpressDeliveryCode: '',
                    ForzaExpressDeliveryEnabled: 0,
                    EstandarShippingEnabled: 0,
                    EstandarShippingPrice: 0,
                },
            };
        },
        loadItems() {
            const self = this;
            this.resetItemEdit();
            API.send('GET', 'locaciones/all/', {},
                function (response) {
                    if (response.status) {
                        self.locaciones = response.data;
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },
        editItem(item) {
            this.itemEdit.id = item.id;
            this.itemEdit.codigo = item.codigo;
            this.itemEdit.nombre = item.nombre;
            this.itemEdit.telefono = item.telefono;
            this.itemEdit.telefonoAdicional = item.telefonoAdicional;
            this.itemEdit.email = item.email;
            this.itemEdit.latitud = item.latitud;
            this.itemEdit.longitud = item.longitud;
            this.itemEdit.direccion = item.direccion;
            this.itemEdit.municipioId = item.municipioId;
            this.itemEdit.departamentoId = item.departamentoId;
            this.itemEdit.paisId = item.paisId;
            this.itemEdit.estado = !!(item.estado);
            this.itemEdit.aDomicilio = !!(item.aDomicilio);
            this.itemEdit.warehouse = !!(item.warehouse);
            this.itemEdit.coberturasPaises = item.coberturasPaises;
            this.itemEdit.coberturasDepartamentos = item.coberturasDepartamentos;
            this.itemEdit.coberturasMunicipios = item.coberturasMunicipios;

            // Shipping
            this.itemEdit.delivery.ForzaExpressDeliveryEnabled = (typeof item.delivery.ForzaExpressDeliveryEnabled !== 'undefined') ? (!!parseInt(item.delivery.ForzaExpressDeliveryEnabled)) : 0
            this.itemEdit.delivery.ForzaExpressDeliveryCode = (typeof item.delivery.ForzaExpressDeliveryCode !== 'undefined') ? item.delivery.ForzaExpressDeliveryCode : '';
            this.itemEdit.delivery.EstandarShippingEnabled = (typeof item.delivery.EstandarShippingEnabled !== 'undefined') ? (!!parseInt(item.delivery.EstandarShippingEnabled)) : 0
            this.itemEdit.delivery.EstandarShippingPrice = (typeof item.delivery.EstandarShippingPrice !== 'undefined') ? item.delivery.EstandarShippingPrice : 0;

            // coloco los paises de cobertura que traigo
            if (typeof item.coberturasPaises !== 'undefined') {
                let counter = 0;
                for (const cobertura in item.coberturasPaises) {
                    for (const itemKey in this.paises) {
                        if (parseInt(this.paises[itemKey].id) === parseInt(item.coberturasPaises[cobertura])) {
                            this.coberturaPaisesList[counter] = this.paises[itemKey];
                        }
                    }
                    counter++;
                }
                this.getDepartamentosFiltered();
            }
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Si elimina el establecimiento, dejarán de funcionar los horarios y citas asignadas a dicho establecimiento, esta acción no puede revertirse', function () {
                API.send('delete', 'locaciones/delete', {
                        id: id
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.loadItems();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }

                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    });
            })
        },
        requestWarehouse(shippingProvider) {
            const self = this;

            API.showConfirm('Se enviará una solicitud de código hacia Forza', 'Su código será enviado en un máximo de 24 horas', function () {

                self.saveItem(function () {
                    API.send('POST', 'delivery/bodegas/'+shippingProvider+'/request', {
                            'data': self.itemEdit
                        },
                        function (response) {
                            if (response.status) {
                                API.showSuccessNotify(response.msg);
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }

                        },
                        function (response) {
                            API.showErrorNotify(response.msg);
                        });
                });
            })
        },
        saveItem(callback) {

            if (!callback) callback = false;

            if (this.itemEdit.id > 0 || this.newItem) {

                const self = this;
                const validation = new DataValidation();
                validation.required('nombre', this.itemEdit.nombre, 'Ingrese un nombre válido');
                validation.required('direccion', this.itemEdit.direccion, 'Ingrese la dirección del establecimiento');
                validation.required('email', this.itemEdit.email, 'Ingrese un correo electrónico de contacto');
                validation.required('telefono', this.itemEdit.telefono, 'Ingrese un teléfono de contacto');
                validation.required('municipioId', this.itemEdit.municipioId, 'Ingrese el municipio donde se encuentra el establecimiento');
                validation.required('departamentoId', this.itemEdit.departamentoId, 'Ingrese el departamento donde se encuentra el establecimiento');
                validation.required('paisId', this.itemEdit.paisId, 'Ingrese el pais donde se encuentra el establecimiento');

                this.Valid = validation.validate(function () {

                    self.itemEdit.coberturasPaises = self.coberturaPaisesList;
                    self.itemEdit.coberturasDepartamentos = self.coberturaDepartamentosList;
                    self.itemEdit.coberturasMunicipios = self.coberturaMunicipiosList;

                    API.send('POST', 'locaciones/save', self.itemEdit,
                        function (response) {
                            if (response.status) {
                                API.showSuccessAlert(response.msg);

                                if (typeof callback === 'function') {
                                    callback();
                                }
                                //self.loadItems();
                            }
                            else {
                                API.showErrorNotify(response.msg);
                            }

                        },
                        function (response) {
                            API.showErrorNotify(response.msg);
                        });
                });
            }
            else {
                API.showErrorNotify("No ha seleccionado ningún item a editar");
            }
        },
        getPaises() {
            const self = this;
            this.departamentos = {};
            this.municipios = {};
            API.send('GET', 'geolocation/paises', {},
                function (response) {
                    self.paises = response.data;
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        getDepartamentos() {
            const self = this;
            this.municipios = {};
            if (self.itemEdit.paisId) {
                API.send('GET', 'geolocation/departamentos/' + self.itemEdit.paisId, {},
                    function (response) {
                        self.departamentos = response.data;
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }
        },
        getMunicipios() {
            const self = this;
            if (self.itemEdit.departamentoId) {
                API.send('GET', 'geolocation/municipios/' + self.itemEdit.departamentoId, {},
                    function (response) {
                        self.municipios = response.data;
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }
        },

        getDepartamentosFiltered() {
            const self = this;
            this.coberturaDepartamentosList = [];

            const paisesIdArr = {};
            for (const item in this.coberturaPaisesList) {
                paisesIdArr[this.coberturaPaisesList[item].id] = this.coberturaPaisesList[item].id;
            }

            API.send('POST', 'geolocation/departamentos/filter', {
                    paisesId: paisesIdArr
                },
                function (response) {
                    self.departamentosFiltered = response.data;

                    // coloco los paises de cobertura que traigo
                    if (typeof self.itemEdit.coberturasDepartamentos !== 'undefined') {
                        let counter = 0;
                        for (const cobertura in self.itemEdit.coberturasDepartamentos) {
                            for (const itemKey in self.departamentosFiltered) {
                                if (parseInt(self.departamentosFiltered[itemKey].id) === parseInt(self.itemEdit.coberturasDepartamentos[cobertura])) {
                                    self.coberturaDepartamentosList[counter] = self.departamentosFiltered[itemKey];
                                }
                            }
                            counter++
                        }
                        self.getMunicipiosFiltered();
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        getMunicipiosFiltered() {
            const self = this;
            this.coberturaMunicipiosList = [];

            const deptosArr = {};
            for (const item in this.coberturaDepartamentosList) {
                deptosArr[this.coberturaDepartamentosList[item].id] = this.coberturaDepartamentosList[item].id;
            }

            API.send('POST', 'geolocation/municipios/filter', {
                    departamentoId: deptosArr
                },
                function (response) {
                    self.municipiosFiltered = response.data;

                    // coloco los paises de cobertura que traigo
                    if (typeof self.itemEdit.coberturasMunicipios !== 'undefined') {
                        let counter = 0;
                        for (const cobertura in self.itemEdit.coberturasMunicipios) {
                            for (const itemKey in self.municipiosFiltered) {
                                if (parseInt(self.municipiosFiltered[itemKey].id) === parseInt(self.itemEdit.coberturasMunicipios[cobertura])) {
                                    self.coberturaMunicipiosList[counter] = self.municipiosFiltered[itemKey];
                                }
                            }
                            counter++;
                        }
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
    },
};
</script>
