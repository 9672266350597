<template>
    <div class="form-builder">
        <div class="mobileBlocker">
            <div class="mobileBlockerContainer">
                <h4>Dispositivo no compatible</h4>
                <div>
                    El editor de documentos no es compatible en este dispositivo
                </div>
                <div class="mt-4">
                    <i class="fas fa-mobile-retro"></i>
                </div>
                <button class="btn btn-sm btn-primary mt-4" @click="$router.push('/forms-list')">Regresar</button>
            </div>
        </div>
        <div class="fb-header">
            <img src="staticAssets/formBuilder/docsplus.png" style="max-width: 100px">
            <div class="buttons-config">
                <div class="btn btn-outline-primary mr-2" @click="showKeyboardShortcuts">
                    <i class="fas fa-keyboard"></i>
                </div>
                <div class="btn btn-primary mr-2" @click="addObject">
                    <i class="fas fa-plus mr-2"></i> Agregar objeto
                </div>
                <div class="btn btn-dark mr-2" @click="generateDOC">
                    <i class="fas fa-eye mr-2"></i> Generar PDF
                </div>
                <div class="btn btn-dark mr-2" @click="saveForm">
                    <i class="fas fa-save mr-2"></i> Guardar
                </div>
                <div class="btn btn-dark" @click="pageModalShow = true">
                    <i class="fas fa-cog mr-2"></i> Configuración
                </div>
                <div class="btn btn-danger ml-2" @click="$router.push('/forms-list')">
                    <i class="fas fa-door-closed mr-2"></i> Salir del editor
                </div>
            </div>
        </div>
        <div>
            <div class="fb-toolbar">
                <div class="mb-3">
                    <div @click="createPage" class="btn btn-primary btn-sm w-100">
                        <i class="fa fa-plus-circle mr-1"></i> Página
                    </div>
                </div>
                <div id="toolsButtons" class="toolsButtons">
                    <div @click="selectPage(item.number)" :class="(this.pageSelected === item.number) ? 'miniPageItem pageSelected' : 'miniPageItem'" v-for="item in template.pages" :key="'page_' + item.number">
                        <div class="text-center mt-3">
                            Página<br>
                            # {{ item.number + 1 }}
                        </div>
                    </div>
                </div>
                <div class="mt-3">
                    <div @click="createPage" class="btn btn-primary btn-sm w-100">
                        <i class="fa fa-plus-circle mr-1"></i> Página
                    </div>
                    <div class="text-center text-muted mt-2">
                        {{ template.numP }} página(s)
                    </div>
                    <div>
                        <div class="loadFile">
                            <button class="btn btn-success btn-sm w-100 mt-4">
                                <i class="fa fa-file-pdf mr-1"></i> Crear desde PDF
                            </button>
                            <input id="loadPageFromPdf" type="file" accept="application/pdf" class="loadFile" @change="showFromPdf = true"/>
                        </div>
                    </div>
                </div>
            </div>
            <div class="fb-editor">
                <div class="pageContainer">
                    <template v-if="typeof template.pages[pageSelected] !== 'undefined'">
                        <div class="text-small text-muted">
                            {{ template.pages[pageSelected].size.w }}x{{ template.pages[pageSelected].size.h }}mm
                        </div>
                        <div id="page" :class="'page ' + ((typeof template.pages[pageSelected].inserto !== 'undefined' && template.pages[pageSelected].inserto) ? 'pageInserto' : '')" @click="unselectObject" :style="{
                            height: template.pages[pageSelected].size.h+'mm',
                            width: template.pages[pageSelected].size.w+'mm',
                            backgroundImage: ((template.pages[pageSelected].bk !== '') ? 'url('+template.pages[pageSelected].bk+')' : 'inherit'),
                            backgroundSize: ((template.pages[pageSelected].bkw !== '') ? (template.pages[pageSelected].bkw + '% ' + template.pages[pageSelected].bkh + '%') : 'contain'),
                        }">
                            <div class="pageRuler" @click="unselectObject">
                                <ul class="ruler-x">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                                <ul class="ruler-y">
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                    <li></li>
                                </ul>
                            </div>
                            <div :class="(this.objectSelected === parseInt(key)) ? 'object objectSelected' : 'object'" v-for="(item, key) in template.pages[pageSelected].objects" :key="item.id" :id="item.id" :data-key="key" :style="{top: item.y + 'px',left: item.x+'px', height: item.h+'px', width: item.w+'px'}">
                                <!--{{item.xmm}}x{{item.ymm}}-->
                                <div v-if="!item.type">
                                    {{ item.slug }}
                                </div>
                                <div v-if="item.type === 'type_field_text'">
                                    <div class="type_field_text" v-if="(item.value && item.value !== '')" v-html="item.value"></div>
                                    <span v-else>Sin texto</span>
                                </div>
                                <div v-if="item.type === 'type_field_static_image'">
                                    <img class="type_field_static_image" :src="item.value" v-if="(item.value && item.value !== '')" :style="{height: item.h+'px', width: item.w+'px'}"/>
                                    <span v-else>Sin imagen</span>
                                </div>
                            </div>
                            <div class="insertoInPage" v-if="typeof template.pages[pageSelected].inserto !== 'undefined' && template.pages[pageSelected].inserto">
                                <embed :src="template.pages[pageSelected].inserto">
                            </div>
                        </div>
                    </template>
                </div>
            </div>
            <div class="fb-properties">
                <div v-if="this.objectSelected === null">
                    <template v-if="pageSelected !== null">
                        <div class="mb-4">
                            <h6>
                                Propiedades de página
                                <button @click="removePage" class="objectConfig btn btn-danger btn-sm float-right">
                                    <i class="fas fa-trash"></i></button>
                            </h6>
                        </div>
                        <div class="mt-3" v-if="typeof template.pages[pageSelected] !== 'undefined'">
                            <div class="whiteBox mt-3" v-if="!template.pages[pageSelected].inserto">
                                <div>
                                    <div>
                                        <label>Fondo de página</label>
                                        <div class="loadFile">
                                            <button class="btn btn-primary w-100">
                                                <i class="fas fa-upload mr-2"></i>Seleccionar imagen
                                            </button>
                                            <input id="loadPageBackground" type="file" accept="image/*" class="loadFile" @change="loadPageBackground"/>
                                        </div>
                                        <div class="text-muted text-small mt-2">Puede configurar el fondo de su página desde una imagen</div>
                                    </div>
                                    <div class="mt-3">
                                        <label class="font-weight-bold">Tamaño de fondo</label>
                                        <div class="row">
                                            <div class="col">
                                                <label>Ancho</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control" v-model="template.pages[pageSelected].bkw" @keydown="slugCreate"/>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" id="basic-addon1">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col">
                                                <label>Alto</label>
                                                <div class="input-group mb-3">
                                                    <input type="number" class="form-control" v-model="template.pages[pageSelected].bkh" @keydown="slugCreate"/>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text" id="basic-addon1">%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                                <div>
                                    <label class="font-weight-bold">Tamaño de página</label>
                                    <div class="row">
                                        <div class="col">
                                            <label>Ancho</label>
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" v-model="template.pages[pageSelected].size.w" @keydown="slugCreate"/>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="basic-addon1">mm</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col">
                                            <label>Alto</label>
                                            <div class="input-group mb-3">
                                                <input type="number" class="form-control" v-model="template.pages[pageSelected].size.h" @keydown="slugCreate"/>
                                                <div class="input-group-append">
                                                    <span class="input-group-text" id="basic-addon1">mm</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label>Tamaños de papel estándar</label>
                                    <select class="form-control" @change="changeDefaultSizePage" v-model="changeDefaultSizePageSelected">
                                        <option value=""> - Seleccione una opción -</option>
                                        <option value="letter">Carta (Letter)</option>
                                        <option value="legal">Legal (Legal)</option>
                                    </select>
                                </div>
                            </div>
                            <div v-else class="my-3">
                                Las páginas de tipo inserto no pueden ser ajustadas en tamaño debido a que respetarán el tamaño del inserto.
                            </div>
                            <div class="whiteBox mt-2">
                                <div>
                                    <label v-if="typeof template.pages[pageSelected].inserto !== 'undefined' && template.pages[pageSelected].inserto">Cambiar inserto</label>
                                    <label v-else>Convertir en inserto</label>
                                    <div class="loadFile">
                                        <button class="btn btn-primary w-100">
                                            <i class="fas fa-upload mr-2"></i>Seleccionar archivo
                                        </button>
                                        <input id="loadInsertFile" type="file" accept="application/pdf" class="loadFile" @change="loadInserto"/>
                                    </div>
                                    <small class="text-muted mt-2">Los insertos permiten agregar documentos PDF adicionales entre páginas</small>
                                </div>
                            </div>
                        </div>
                    </template>
                    <div v-else class="noObjectSelected">Selecciona un objeto para ver sus propiedades</div>
                </div>
                <div v-else>
                    <template v-if="typeof template.pages[pageSelected] !== 'undefined'">
                        <div>
                            <h6>
                                Propiedades de objeto
                                <button @click="removeObject(objectSelected)" class="objectConfig btn btn-danger btn-sm float-right">
                                    <i class="fas fa-trash"></i></button>
                            </h6>
                        </div>
                        <div class="whiteBox mt-4">
                            <div>
                                <label>Tipo de objeto</label>
                                <select class="form-control" v-model="template.pages[pageSelected].objects[objectSelected].type">
                                    <option value="type_field_text">Texto</option>
                                    <option value="type_field_static_image">Imagen</option>
                                </select>
                                <div class="text-muted text-center text-small mt-3" v-if="!template.pages[pageSelected].objects[objectSelected].type || template.pages[pageSelected].objects[objectSelected].type === ''">
                                    Selecciona el tipo de objeto para continuar
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div class="whiteBox mb-4" v-if="template.pages[pageSelected].objects[objectSelected].type">
                                <label>
                                    <span v-if="template.pages[pageSelected].objects[objectSelected].type === 'type_field_static_image'">Reemplazar URL de imagen</span>
                                    <span v-if="template.pages[pageSelected].objects[objectSelected].type === 'type_field_text'">Reemplazar texto</span>
                                    mediante código corto (shortcode)
                                </label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">[[</span>
                                    </div>
                                    <input type="text" class="form-control" v-model="template.pages[pageSelected].objects[objectSelected].shortcode" @keydown="slugCreate">
                                    <div class="input-group-append">
                                        <span class="input-group-text">]]</span>
                                    </div>
                                </div>
                                <div class="text-muted text-small">
                                    Los códigos cortos o shortcodes permiten reemplazar su valor a través de la creación de documento mediante servicios. Ejemplo:
                                    <br>
                                    <div class="text-center mt-2">[[URL_DE_IMAGEN]] &nbsp;&nbsp;&nbsp;ó&nbsp;&nbsp;&nbsp; [[TEXTO_RANDOM]]</div>
                                </div>
                            </div>
                            <div v-if="template.pages[pageSelected].objects[objectSelected].type === 'type_field_text'" class="whiteBox">
                                <h6 class="mb-3">Texto a mostrar</h6>
                                <div class="text-muted my-3 text-small">
                                    Puede formatear el texto a su conveniencia, también puede agregar códigos cortos (shortcodes) dentro del editor.
                                </div>
                                <quill-editor :toolbar="quillToolbar" theme="snow" v-model:content="template.pages[pageSelected].objects[objectSelected].value" contentType="html" @textChange="resizeObject(objectSelected, 'type_field_text')"></quill-editor>
                            </div>
                            <div v-if="template.pages[pageSelected].objects[objectSelected].type === 'type_field_static_image'" class="whiteBox">
                                <h6 class="mb-3">Cargar imagen</h6>
                                <div class="loadFile">
                                    <button class="btn btn-primary w-100">
                                        <i class="fas fa-upload mr-2"></i>Seleccionar archivo
                                    </button>
                                    <input id="type_field_static_image" type="file" accept="image/*" class="loadFile" @change="loadStaticImage"/>
                                </div>
                            </div>
                        </div>
                    </template>
                </div>
            </div>
        </div>
        <!--<div class="form-config-modal" v-show="fieldModalShow">
            <div class="form-config-modal-container">
                <div>
                    <div>
                        <h6>Nombre de campo</h6>
                        <div>
                            <input class="form-control" type="text" v-model="fieldConfig.name" />
                        </div>
                    </div>
                    <div class="row mt-3">
                        <div class="col-12 col-sm-6">
                            <div>
                                <label for="requiredField"><input id="requiredField" type="checkbox" v-model="fieldConfig.required" value="1" /> Requerido</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div>
                                <label for="boldField"><input id="boldField" type="checkbox" v-model="fieldConfig.bold" value="1" /> Resaltar en negrita</label>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div>
                                <label for="italicField"><input id="italicField" type="checkbox" v-model="fieldConfig.italic" value="1" /> Resaltar en cursiva</label>
                            </div>
                        </div>
                    </div>
                    <div class="mt-3" v-if="fieldConfig.type === 'select'">
                        <h6>Opciones a mostrar</h6>
                        <div>
                            <vue3-tags-input :tags="(fieldConfig.tagsTmp) ? fieldConfig.tagsTmp : []" placeholder="Ingresa las opciones a mostrar" @on-tags-changed="handleChangeSelectOptions" :add-tag-on-keys="[13]"/>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h6>Tamaño de letra</h6>
                        <div>
                            <select v-model="fieldConfig.fontSize" class="form-control">
                                <option value="7">7</option>
                                <option value="9">9</option>
                                <option value="12">12</option>
                                <option value="16">16</option>
                                <option value="24">24</option>
                                <option value="32">32</option>
                                <option value="48">48</option>
                                <option value="64">64</option>
                                <option value="84">84</option>
                            </select>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h6>Ancho máximo de casilla</h6>
                        <div>
                            <select v-model="fieldConfig.maxBoxWidth" class="form-control">
                                <option value="10">10% de página</option>
                                <option value="15">15% de página</option>
                                <option value="20">20% de página</option>
                                <option value="25">25% de página</option>
                                <option value="30">30% de página</option>
                                <option value="35">35% de página</option>
                                <option value="40">40% de página</option>
                                <option value="50">50% de página</option>
                                <option value="60">60% de página</option>
                                <option value="70">70% de página</option>
                                <option value="80">80% de página</option>
                                <option value="90">90% de página</option>
                                <option value="100">100% de página</option>
                            </select>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h6>Tipo: <span>{{fieldConfig.type}}</span></h6>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <button class="btn btn-success w-100" @click="saveFieldConfig(true)">Guardar</button>
                    </div>
                    <div class="col-6">
                        <button class="btn btn-danger w-100" @click="fieldModalShow = false">Cancelar</button>
                    </div>
                </div>
            </div>
        </div>-->
        <div class="form-config-modal" v-show="pageModalShow">
            <div class="form-config-modal-container">
                <div>
                    <div class="mb-4">
                        <h6>Token de formulario</h6>
                        <div class="text-center">
                            {{ pageConfig.token }}
                        </div>
                    </div>
                    <div>
                        <h6>Nombre de formulario</h6>
                        <div>
                            <input class="form-control" type="text" v-model="pageConfig.name"/>
                        </div>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-6">
                        <button class="btn btn-success w-100" @click="saveForm()">Guardar</button>
                    </div>
                    <div class="col-6">
                        <button v-if="this.pageConfig.id > 0" class="btn btn-danger w-100" @click="pageModalShow = false">Cancelar</button>
                        <button v-else class="btn btn-danger w-100" onclick="window.history.go(-1); return false;">Regresar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-config-modal" v-show="showCompressDetails">
            <div class="form-config-modal-container">
                <div>
                    <div>
                        <h5 class="mb-3 text-center">Calidad de imagen</h5>
                        <hr>
                        <div>
                            La calidad de imagen determina el nivel de compresión a utilizar.
                        </div>
                        <div class="mt-2">
                            <select class="form-control" v-model="compressLevel">
                                <option value="ultralow">Ultra bajo</option>
                                <option value="low">Bajo</option>
                                <option value="medium">Medio</option>
                                <option value="high">Alto</option>
                                <option value="ultra">Ultra</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 col-sm-6 text-center">
                        <button class="btn btn-success w-100" @click="compressSelected">Continuar</button>
                    </div>
                    <div class="col-12 col-sm-6 text-center">
                        <button class="btn btn-danger w-100" @click="showCompressDetails = false; compressLevel = 'medium'; compressType = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-config-modal" v-show="showFromPdf">
            <div class="form-config-modal-container">
                <div>
                    <div>
                        <h5 class="mb-3 text-center">Nivel de compresión</h5>
                        <hr>
                        <div>
                            La calidad de imagen determina el nivel de compresión a utilizar.
                        </div>
                        <div class="mt-2">
                            <select class="form-control" v-model="fromPdfCompressLevel">
                                <option value="ultralow">Ultra bajo</option>
                                <option value="low">Bajo</option>
                                <option value="medium">Medio</option>
                                <option value="high">Alto</option>
                                <option value="ultra">Ultra</option>
                            </select>
                        </div>
                        <div class="mt-2">
                            <label>Rango de páginas</label>
                            <input type="text" class="form-control" v-model="fromPdfRange"/>
                            <div class="text-small text-muted mt-3">
                                 (*) todas, ejemplos: *, 1, 3, 2-6, 12-16, 6-*
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 col-sm-6 text-center">
                        <button class="btn btn-success w-100" @click="createPageFromPdf">Continuar</button>
                    </div>
                    <div class="col-12 col-sm-6 text-center">
                        <button class="btn btn-danger w-100" @click="showFromPdf = false; fromPdfCompressLevel = 'medium';">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-config-modal" v-show="showKeyboardShortcutsModal">
            <div class="form-config-modal-container">
                <div>
                    <div>
                        <h5 class="mb-3 text-center">Listado de atajos de teclado</h5>
                        <hr>
                        <div class="row mb-2">
                            <div class="col-2">
                                <kbd class="keyboard-shortcut">Shift</kbd>
                            </div>
                            <div class="col-10">
                                Desactivar bloquedo de relación de aspecto al cambiar tamaño de objetos.
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-5">
                    <div class="col-12 text-center">
                        <button class="btn btn-success w-100" @click="showKeyboardShortcutsModal = false">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import "/src/layout/admin/assets/css/form-builder.css";
import {mapGetters} from "vuex";

import Sortable from 'sortablejs';
import API from "src/core/Api";
import Vue3TagsInput from 'vue3-tags-input';
import interact from 'interactjs'

import {QuillEditor} from '@vueup/vue-quill'
import Quill from 'quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

// json forms
//import {JsonForms} from '@jsonforms/vue';
//import {vanillaRenderers} from '@jsonforms/vue-vanilla';
import Tools from "src/core/Tools";

/*const renderers = [
    ...vanillaRenderers,
    // here you can add custom renderers
];*/

export default {
    components: {
        QuillEditor,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    data() {
        return {
            // Json forms
            /*renderers: Object.freeze(renderers),
            dataSchema: {},
            schema: {},
            uiSchema: {},*/

            // editor de campos
            //sortableList: false,
            //selectedItem: false,
            //formCounter: 0,

            // configuración de página
            showKeyboardShortcutsModal: false,
            pageModalShow: false,
            pageConfig: {
                id: 0,
                name: '',
                token: '',
                type: 'informationSend',
                orientation: 'vertical',
                enablePdf: false,
                backgroundUrl: '',
                extraData: {
                    formId: 0,
                },
            },

            // crear desde pdf
            fromPdfCompressLevel: 'medium',
            fromPdfRange: '*',
            showFromPdf: false,

            // compresión
            compressType: false,
            compressLevel: 'medium',
            showCompressDetails: false,

            // PDF
            /*pdfDraggerContainer: false,
            pdfDraggers: {},*/

            // pagina
            changeDefaultSizePageSelected: '',

            bAR: true,
            screenDPI: 96,
            objectSelected: null,
            pageSelected: 0,
            template: {
                numP: 0,
                pages: [],
            },

            // Quill
            quillToolbar: [
                ['bold', 'italic', 'underline', 'strike'],
                [{'list': 'ordered'}, {'list': 'bullet'}],
                [{'header': [1, 2, 3, 4, 5, 6, false]}],
                [{'color': []}],
                [{'font': ['Helvetica', 'CutiveMono']}],
                [{'align': []}],
                [{'size': ['2mm', '3mm', '4mm', '5mm', '6mm', '8mm', '10mm', '12mm']}],
            ],
        };
    },
    mounted() {

        const self = this;
        this.screenDPI = this.calculateDPI();
        this.createPage();

        // cargo el id del form
        if (parseInt(this.$route.params.id) > 0) {

            API.send('GET', '/formularios/form-builder-v2/by/id/' + this.$route.params.id, {},
                function (response) {
                    console.log(response.data.template);
                    if (response.status) {
                        self.pageConfig.id = response.data.id;
                        self.pageConfig.name = response.data.descripcion;
                        self.pageConfig.token = response.data.token;
                        self.pageConfig.type = response.data.typeForm;
                        self.pageConfig.orientation = response.data.orientation;
                        self.pageConfig.extraData.formId = response.data.id;
                        self.pageConfig.backgroundUrl = response.data.backgroundUrl;
                        self.pageConfig.enablePdf = !!(response.data.enablePdfRender);
                        self.template = response.data.template;

                        if (typeof self.template.pages !== 'undefined' && typeof self.template.pages.length !== 'undefined') {
                            self.template.numP = self.template.pages.length;
                        }

                        self.refreshDrag();
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        } else {
            this.pageModalShow = true;
        }

        const font = Quill.import('formats/font')
        // We do not add Aref Ruqaa since it is the default
        font.whitelist = ['Helvetica', 'CutiveMono']
        Quill.register(font, true)

        const Size = Quill.import('attributors/style/size');
        Size.whitelist = ['2mm', '3mm', '4mm', '5mm', '6mm', '8mm', '10mm', '12mm'];
        Quill.register(Size, true);

        // Aspect ratio
        window.addEventListener('keydown', function (e) {
            if (e.key === 'Shift') {
                self.bAR = false;
            }
        })
        window.addEventListener('keyup', function (e) {
            if (e.key === 'Shift') {
                self.bAR = true;
            }
        });
    },
    methods: {
        // genericos
        showKeyboardShortcuts() {
            this.showKeyboardShortcutsModal = true;
        },
        calculateDPI() {
            const el = document.createElement('div');
            el.style = 'width: 1in;'
            document.body.appendChild(el);
            const dpi = el.offsetWidth;
            document.body.removeChild(el);
            return dpi;
        },
        saveForm(handler) {
            const self = this;
            if (!handler) handler = false;

            const data = {
                idForm: self.pageConfig.id,
                descripcion: self.pageConfig.name,
                /*type: self.pageConfig.type,*/
                /*orientation: self.pageConfig.orientation,
                enablePdf: (self.pageConfig.enablePdf) ? 1 : 0,*/
                template: self.template,
            };

            API.send('POST', 'formularios/form-builder-v2/save/', data, function (response) {
                    if (data.idForm === 0) {
                        if (typeof response.data.id !== 'undefined' && parseInt(response.data.id) > 0) {
                            window.location.href = '/form-builder/' + response.data.id
                        }
                    } else {
                        API.showSuccessAlert(response.msg);
                        self.pageModalShow = false;

                        if (typeof handler === 'function') {
                            handler();
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        generateDOC() {
            const self = this;

            const data = {
                token: self.pageConfig.token,
                operation: 'preview',
            };

            /*this.saveForm(function () {


                API.send('POST', 'formularios/docs-plus/generate', data, function (response) {

                        if (typeof response.data.fileData !== 'undefined') {
                            const binary = atob(response.data.fileData.replace(/\s/g, ''));
                            const len = binary.length;
                            const buffer = new ArrayBuffer(len);
                            let view = new Uint8Array(buffer);
                            for (let i = 0; i < len; i++) {
                                view[i] = binary.charCodeAt(i);
                            }
                            const blob = new Blob([view], {type: 'application/pdf'});
                            const blobURL = URL.createObjectURL(blob);
                            window.open(blobURL);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })*/

            API.send('POST', 'formularios/docs-plus/generate', data, function (response) {

                    if (typeof response.data.fileData !== 'undefined') {
                        const binary = atob(response.data.fileData.replace(/\s/g, ''));
                        const len = binary.length;
                        const buffer = new ArrayBuffer(len);
                        let view = new Uint8Array(buffer);
                        for (let i = 0; i < len; i++) {
                            view[i] = binary.charCodeAt(i);
                        }
                        const blob = new Blob([view], {type: 'application/pdf'});
                        const blobURL = URL.createObjectURL(blob);
                        window.open(blobURL);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },

        // Builder (drag and drop y objetos)

        // páginas
        countPages(reindex) {
            if (!reindex) reindex = false;

            // reindexo páginas
            const self = this;
            this.template.pages.forEach(function (a, b) {
                self.template.pages[b].number = b;
            })

            return this.template.pages.length;
        },
        createPage() {

            const pageNum = this.countPages();

            // cuento las páginas
            this.template.pages.push({
                id: 'pg_' + this.createId(),
                number: pageNum,
                size: {
                    w: 215.9,
                    h: 279.4,
                },
                inserto: '',
                bk: '',
                bkw: 100,
                bkh: 100,
                objects: [],
            });
            this.pageSelected = pageNum;
            this.template.numP = pageNum;
        },
        removePage() {
            const self = this;
            //console.log(self.pageSelected);
            API.showConfirm('¿Está seguro que desea eliminar la página?', 'Esta acción no se puede revertir', function () {
                if (typeof self.template.pages[self.pageSelected] !== 'undefined') {
                    const deletedPage = self.pageSelected;
                    self.template.pages.splice(self.pageSelected, 1);
                    self.pageSelected = null;
                    self.objectSelected = null;
                    self.template.numP = self.countPages(true);

                    if (deletedPage > 0) {
                        self.selectPage(deletedPage - 1);
                    } else {
                        self.createPage();
                    }
                }
            })

            //this.refreshDrag();
        },
        changeDefaultSizePage() {
            const self = this;
            API.showConfirm('Se reemplazará el tamaño del documento', '', function () {
                if (self.changeDefaultSizePageSelected === 'letter') {
                    self.template.pages[self.pageSelected].size.w = 215.9;
                    self.template.pages[self.pageSelected].size.h = 279.4;
                } else if (self.changeDefaultSizePageSelected === 'legal') {
                    self.template.pages[self.pageSelected].size.w = 215.9;
                    self.template.pages[self.pageSelected].size.h = 355.6;
                }
                self.changeDefaultSizePageSelected = '';
            })
        },
        createPageFromPdf() {

            const self = this;
            const file = document.getElementById('loadPageFromPdf').files[0];

            const fd = new FormData();
            fd.append('file', file);
            fd.append('level', this.fromPdfCompressLevel);
            fd.append('range',  this.fromPdfRange);

            API.send('FILE', 'formularios/docs-plus/load-from-pdf', fd, function (response) {
                    API.showSuccessAlert(response.msg);
                    if (typeof response.data !== 'undefined') {
                        response.data.forEach(function (a) {
                            self.createPage();
                            self.template.pages[self.pageSelected].bk = a;
                        })
                        self.fromPdfRange = '*';
                        self.fromPdfCompressLevel = 'medium';
                        self.showFromPdf = false;
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        loadPageBackground() {
            this.compressType = 'pageBackground';
            this.showCompressDetails = true;
        },

        // objetos
        createId() {
            let result = '';
            let timeId = new Date().valueOf();
            const length = 5;
            const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
            for (let i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
            return result + '_' + timeId;
        },
        createObject() {
            return {
                id: this.createId(),
                x: 30,
                y: 30,
                w: 200,
                h: 35,
                ow: 0, // original width
                oh: 0, // original height
                xmm: 0, // milímetros
                ymm: 0, // milímetros
                wmm: 0, // milímetros
                hmm: 0, // milímetros
                /*slug: 'CAMPO',*/
                shortcode: '',
                fontS: 20, // Font size
                fontF: 'sans-serif', // Font family
            }
        },
        addObject() {
            const object = this.createObject();
            this.template.pages[this.pageSelected].objects.push(object);
            this.refreshDrag();
        },
        removeObject(objectSelected) {
            if (typeof this.template.pages[this.pageSelected].objects[objectSelected] !== 'undefined') {
                this.template.pages[this.pageSelected].objects.splice(objectSelected, 1)
                this.objectSelected = null;
            }
            this.refreshDrag();
        },
        selectPage(pageNumber) {
            this.objectSelected = null;
            this.pageSelected = pageNumber;
        },
        selectObject(objectNumber) {
            this.objectSelected = parseInt(objectNumber);
        },
        unselectObject(e) {
            if (e.target === e.currentTarget) {
                this.objectSelected = null;
            }
        },

        // Move
        refreshDrag() {
            // target elements with the "draggable" class
            const self = this;

            const findUpTag = function (el, className) {
                if (el.classList.contains(className)) {
                    return el;
                } else {
                    while (el.parentNode) {
                        el = el.parentNode;
                        if (el.classList.contains(className)) return el;
                    }
                }
                return null;
            }

            interact('.object')
                .draggable({
                    // enable inertial throwing
                    inertia: true,
                    // keep the element within the area of it's parent
                    modifiers: [
                        /*interact.modifiers.restrictRect({
                            restriction: 'parent',
                            endOnly: true
                        }),*/
                    ],
                    // enable autoScroll
                    autoScroll: true,

                    listeners: {
                        move: function (event) {
                            const target = event.target
                            const objectSelectedKey = parseInt(event.target.getAttribute('data-key'));

                            const x = parseFloat(self.template.pages[self.pageSelected].objects[objectSelectedKey].x) + event.dx;
                            const y = parseFloat(self.template.pages[self.pageSelected].objects[objectSelectedKey].y) + event.dy;

                            // translate the element
                            target.style.left = x + 'px';
                            target.style.top = y + 'px';

                            // update the posiion attributes
                            self.template.pages[self.pageSelected].objects[objectSelectedKey].x = x;
                            self.template.pages[self.pageSelected].objects[objectSelectedKey].y = y;
                            self.template.pages[self.pageSelected].objects[objectSelectedKey].xmm = Math.floor((x * 25.4) / self.screenDPI);
                            self.template.pages[self.pageSelected].objects[objectSelectedKey].ymm = Math.floor((y * 25.4) / self.screenDPI);
                        },
                    }
                }).on('tap', function (event) {

                const object = findUpTag(event.target, 'object');
                if (object) self.objectSelected = parseInt(object.getAttribute('data-key'));

            }).resizable({
                edges: {top: false, left: false, bottom: true, right: true},
                margin: 5,
                listeners: {
                    move: function (event) {

                        // selección de objeto
                        const objectSelectedKey = parseInt(event.target.getAttribute('data-key'));
                        console.log(objectSelectedKey);

                        // Si el objeto seleccionado no tiene tamaño original, lo guardo
                        if (!self.template.pages[self.pageSelected].objects[objectSelectedKey].ow) self.template.pages[self.pageSelected].objects[objectSelectedKey].ow = event.rect.width;
                        if (!self.template.pages[self.pageSelected].objects[objectSelectedKey].oh) self.template.pages[self.pageSelected].objects[objectSelectedKey].oh = event.rect.height;

                        // calculo tamaño
                        let width = (event.rect.width);
                        let height = (event.rect.height);
                        let ratio = (self.template.pages[self.pageSelected].objects[objectSelectedKey].oh / self.template.pages[self.pageSelected].objects[objectSelectedKey].ow);

                        if (self.bAR) {
                            height = width * ratio;
                        }

                        Object.assign(event.target.style, {
                            width: `${width}px`,
                            height: `${height}px`,
                        })

                        self.template.pages[self.pageSelected].objects[objectSelectedKey].w = width;
                        self.template.pages[self.pageSelected].objects[objectSelectedKey].h = height;

                        self.template.pages[self.pageSelected].objects[objectSelectedKey].wmm = Math.floor((width * 25.4) / self.screenDPI);
                        self.template.pages[self.pageSelected].objects[objectSelectedKey].hmm = Math.floor((height * 25.4) / self.screenDPI);

                        // Cambio de tamaño de imagen interna
                        if (self.template.pages[self.pageSelected].objects[objectSelectedKey].type === 'type_field_static_image') {
                            const image = document.getElementById(self.template.pages[self.pageSelected].objects[objectSelectedKey].id).getElementsByClassName('type_field_static_image');
                            Object.assign(image[0].style, {
                                width: `${width}px`,
                                height: `${height}px`,
                            })
                        }
                    }
                }
            });
        },
        resizeObject(objectSelected, className) {
            const self = this;
            setTimeout(function () {
                const object = document.getElementById(self.template.pages[self.pageSelected].objects[objectSelected].id);
                const objectForSize = object.getElementsByClassName(className);
                if (objectForSize) {
                    const offsetHeight = objectForSize[0].offsetHeight + 10;
                    self.template.pages[self.pageSelected].objects[objectSelected].h = offsetHeight;
                    Object.assign(object.style, {
                        height: `${offsetHeight}px`,
                    })
                }
            }, 100);
        },

        // otros
        slugCreate(e) {
            // allowedKeys Safari issues allowing keyCodes through regex - 8 = backspace, 46 = delete, 38 ArrowUp, 40 = ArrowDown, 37 = ArrowLeft, 39 = ArrowRight
            let allowedKeys = [8, 46, 38, 40, 37, 39];
            var keypressed = e.which || e.keyCode;
            if (!/[0-9A-Za-z_]+/.test(e.key)) {
                if (allowedKeys.every(e => e !== keypressed)) {
                    e.preventDefault();
                }
            }
        },
        loadStaticImage() {
            this.compressType = 'imageStatic';
            this.showCompressDetails = true;
        },
        loadInserto() {
            const self = this;
            const file = document.getElementById('loadInsertFile').files[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                API.showConfirm('Antes de continuar', 'Si convierte esta página en inserto, se borrarán todos sus objetos', function () {
                    self.template.pages[self.pageSelected].size.w = 215.9;
                    self.template.pages[self.pageSelected].size.h = 355.6;
                    self.template.pages[self.pageSelected].inserto = reader.result;
                    self.template.pages[self.pageSelected].objects = [];
                })
            }
            reader.readAsDataURL(file);
        },
        compressSelected() {

            const self = this;

            const compressImage = function (filesObject, callback) {

                const fd = new FormData();
                fd.append('file', filesObject);
                fd.append('level', self.compressLevel);
                fd.append('response', 'base64');

                API.send('FILE', 'formularios/docs-plus/image-compress', fd, function (response) {
                        if (typeof callback === 'function') {
                            callback(response);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }

            if (this.compressType === 'pageBackground') {

                const file = document.getElementById('loadPageBackground').files[0];
                const reader = new FileReader();

                reader.onloadend = async () => {
                    //const rawImg = reader.result;

                    compressImage(file, function (response) {
                        if (response.data) {
                            API.showSuccessAlert('Compresión realizada con éxito', 'Comprimido de ' + response.data.original + ' a ' + response.data.compressed + ', ahorrado: ' + response.data.loss);
                            self.template.pages[self.pageSelected].bk = response.data.result;
                            self.compressType = false;
                            self.compressLevel = 'medium';
                            self.showCompressDetails = false;
                        } else {
                            API.showErrorAlert('Error al comprimir imagen, por favor intente de nuevo');
                        }
                    })
                }
                reader.readAsDataURL(file);
            }
            // Image static
            else {
                const objectSelected = self.objectSelected;

                const getDimensions = function (image) {
                    return new Promise((resolve) => {
                        const img = new Image();
                        img.src = image;
                        img.onload = () => {
                            resolve({width: img.width, height: img.height})
                        }
                    })
                }
                const file = document.getElementById('type_field_static_image').files[0];
                const reader = new FileReader();

                reader.onloadend = async () => {

                    compressImage(file, async function (response) {
                        if (response.data) {
                            API.showSuccessAlert('Compresión realizada con éxito', 'Comprimido de ' + response.data.original + ' a ' + response.data.compressed + ', ahorrado: ' + response.data.loss);

                            const rawImg = response.data.result;
                            let dimensions = await getDimensions(rawImg);
                            self.template.pages[self.pageSelected].objects[objectSelected].value = rawImg;
                            self.template.pages[self.pageSelected].objects[objectSelected].w = dimensions.width;
                            self.template.pages[self.pageSelected].objects[objectSelected].h = dimensions.height;
                            self.template.pages[self.pageSelected].objects[objectSelected].ow = dimensions.width;
                            self.template.pages[self.pageSelected].objects[objectSelected].oh = dimensions.height;
                            self.resizeObject(objectSelected, 'type_field_static_image');

                            self.compressType = false;
                            self.compressLevel = 'medium';
                            self.showCompressDetails = false;
                        } else {
                            API.showErrorAlert('Error al comprimir imagen, por favor intente de nuevo');
                        }
                    })

                }
                reader.readAsDataURL(file);
            }
        }
    }
};
</script>
