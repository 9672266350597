// Core state
import  { createStore } from 'vuex'
import localDisk from 'src/localDisk';


import {authState} from "src/modules/auth/state";
import {orderState} from "src/modules/order/state";
import {medicoState} from "src/modules/medico/state";

export const store = createStore({
    state: {
        appconfig: localDisk.read('appconfig', 'appPosConfig'),
        preloader: false,
        lockLoading: false,
    },
    mutations: {
        loading(state, data) {
            state.preloader = data;
        },
        lockLoading(state, data) {
            state.lockLoading = data;
        },
        SetAppConfig(state, data) {
            if ( data ) {
                state.token = data
                localDisk.write('appconfig', data, 'appPosConfig')
            }
            else {
                localDisk.write('appconfig', null, 'appPosConfig')
            }
        },
    },
    actions: {
        coreLockLoading: ({commit}) => {
            commit('lockLoading', true);
        },
        coreUnlockLoading: ({commit}) => {
            commit('lockLoading', false);
        },
        coreShowLoading: ({commit}) => {
            commit('loading', true);
        },
        coreHideLoading: ({commit}) => {

            // 300 ms para esperar cualquier otro loading
            setTimeout(function (){
                commit('loading', false);
            }, 300);
        },
        SetAppConfig: ({commit}, payload) => {
            commit('SetAppConfig', payload);
        },
    },
    getters: {
        coreGetPreloaderStatus: state => {
            return state.preloader;
        },
        coreIsLockedLoading: state => {
            return state.lockLoading;
        },
        AppConfig: state => {
            return state.appconfig;
        }
    },
    // cargo los modulos
    modules: {
        auth: authState,
        order: orderState,
        medico: medicoState,
    },
})
