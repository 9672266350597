<script>
 import FichaLayout from './components/FichaLayout.vue';


export default { 
 data() {
        return {
        //data
 }},
 components:{
  FichaLayout
 }
 }
</script>
 
 
<template>
  <div>
    <ficha-layout title="Urologia" color="#e89b00" :tabs="tabs" tab-default="preclinica" enable-timeline="true">
        
    </ficha-layout>
  </div>
</template>
 
 
<style scoped>
 
</style>