<template>
    <div class="page_title">
        <div class="container">
            <div class="row mt-0 pt-0">
                <div class="col-xl-12">
                    <div class="page_title-content">
                        <p>
                            Bienvenido,
                            <span>{{AuthGetUserInfo.nombres}}</span>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "PageTitle",
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
};
</script>
