<template>
    <setting-layout :active="25">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Usuarios adicionales</h4>
                <div class="float-right">
                    <a @click="$router.push('/account-personal/role')" role="button" tabindex="0" class="btn btn-primary px-4 mr-3">
                        Editar roles
                    </a>
                    <a @click="$router.push('/account-third-party-apps')" role="button" tabindex="0" class="btn btn-primary px-4 mr-3">
                        Apps de terceros
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="card-subtitle">
                    Puedes administrar a los usuarios adicionales que ingresan a la plataforma, estos también pueden ser considerados como personal.
                </div>
                <div class="mt-2 mb-3">
                    <a @click="$router.push('/account-personal/edit/0')" role="button" tabindex="0"
                        class="btn btn-primary px-4 mr-3">
                        Agregar nuevo usuario
                    </a>
                </div>
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in personal" :key="item.id">
                            <div class="media d-block">
                                <div class="row">
                                    <div class="col-9">
                                        <h5 class="mt-0 mb-1">{{ item.nombres }} {{ item.apellidos }}</h5>
                                        <div class="row">
                                            <div class="col-12 col-sm-6">
                                                <div><b>Email:</b> {{item.email}}</div>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <div><b>Nombre de usuario:</b> {{item.nombreUsuario || 'N/D'}}</div>
                                            </div>
                                        </div>
                                        <div>
                                            <b>Roles asignados:</b> &nbsp; <span v-for="rol in item.roles" :key="rol.id + '_rol'" class="mr-2 badge badge-primary">{{rol.nombre}}</span>
                                        </div>
                                        <div class="text-small">
                                            <span v-if="item.habilitarInicioDeSesionSso" class="text-success mr-4"><i class="fa fa-lock mr-1"></i>SSO activo</span>
                                            <span v-if="item.habilitarInicioDeSesion" class="text-success mr-4"><i class="fa fa-user-lock mr-1"></i>Acceso a plataforma</span>
                                        </div>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div class="edit-option text-right">
                                            <a @click="$router.push('/account-personal/edit/' + item.id)">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                            <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";


export default {
    components: {
        settingLayout,
        FileUploader,
        Multiselect,
    },
    data() {
        return {
            personal: {},
            editar: false,
            nuevo: false,
            descansoActivo: false,
            tab: false,
            horarioDescanso: 0,
            horario: {},
            persona: {},
            /*paises: {},
            deptos: {},
            municipios: {},*/
            locaciones: [],
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('GET', 'personal/all/', {},
                function (data) {
                    if (data.status) {
                        self.personal = data.data;
                    }
                    else {
                        self.msg = data.msg;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
            //this.getPaises();
            this.getLocaciones();
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'personal/borrar/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
        getLocaciones() {
            const self = this;
            API.send('GET', 'personal/locaciones/', {},
                function (response) {
                    self.locaciones = response.data;
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        loadPersona(item) {
            const self = this;
            this.editar = item.id;
            /*this.getPaises();
            this.getMunicipios();
            this.getDepartamentos();*/

            // recorro para las locaciones
            for (const [keyP, value] of Object.entries(self.personal)) {
                //const locacionesTmp = [];
                if (typeof value.locaciones !== 'undefined' && item.id === value.id) {
                    item.locacionesList = [];
                    Object.keys(item.locaciones).map(function (key) {
                        item.locacionesList.push({nombre: item.locaciones[key]['locacion'].nombre, id: key});
                    });
                    self.personal[keyP] = item;
                }
            }
            this.persona = item;
        },
        savePersona() {
            const self = this;

            //console.log(self.persona);
            if (self.persona.id > 0) {
                API.send('PUT', 'personal/edit/' + self.persona.id, self.persona, function (response) {
                        API.showSuccessNotify(response.msg);
                        //self.loadItems();
                        self.loadPersona(response.data);
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            }
            else {
                const self = this;
                API.send('POST', 'personal/new/', self.persona, function (data) {
                        API.showSuccessNotify(data.msg);
                        self.personal = data.data;
                        self.nuevo = false;
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            }
        },
        setHorarios() {
            this.persona.horarios = this.horarios;
            //this.horario = this.horarios;
        },
        fileSuccess(data) {
            const self = this;
            self.campos = data.data;
            self.camposFinales = self.campos[self.tipo].campos[self.itemPadre];
        },

    },
};
</script>
