<template>
    <setting-layout :active="25">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Edición - Aplicación de terceros</h4>
            </div>
            <div class="card-body">
                <div class="form">
                    <div class="media d-block">
                        <div class="row">
                            <div class="form-group col-sm-12 col-xl-6">
                                <label class="mr-sm-2">Nombre de aplicación</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="nombre" name="nombres"/>
                            </div>
                            <div class="form-group col-sm-12 col-xl-6">
                                <label class="mr-sm-2">Descripción</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="descripcion">
                            </div>
                            <div class="form-group col-sm-12 col-xl-12">
                                <label class="mr-sm-2">URL para redirección</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="urlRedirect">
                            </div>
                            <div class="form-group col-sm-12 col-xl-6">
                                <label class="toggle">
                                    <input type="checkbox"
                                        v-model="activa" :checked="activa"
                                        value="1"
                                        class="toggle-checkbox">
                                    <div class="toggle-switch"></div>
                                    &nbsp; Habilitar aplicación
                                </label>
                            </div>
                            <div class="col-sm-12" v-if="token !== ''">
                                <b>Token de aplicación:</b>
                                <div class="codeBox mt-1 mb-2">
                                    {{ token }}
                                </div>
                                <div class="text-muted mb-3">
                                    Este token permite integrar tu aplicación de terceros al SSO (Single Sign On o Inicio de sesión único) de esta plataforma.
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="mt-3">
                                    <h5>Accesos de aplicación:</h5>
                                </div>
                                <div>
                                    <div v-if="Object.keys(accesos).length > 0 ">
                                        <div class="row mt-2">
                                            <div class="col-6">
                                                <div class="text-muted">Descripción</div>
                                            </div>
                                            <div class="col-6">
                                                <div class="text-muted">Slug</div>
                                            </div>
                                        </div>
                                        <div v-for="item in accesos" :key="item.slug">
                                            <div class="row">
                                                <div class="col-6">
                                                    <div>
                                                        {{ item.descripcion }}
                                                    </div>
                                                </div>
                                                <div class="col-5">
                                                    <div>
                                                        {{ item.slug }}
                                                    </div>
                                                </div>
                                                <div class="col-1">
                                                    <i class="fa fa-times-circle text-danger cursor-pointer" @click="eliminarAcceso(item.slug)"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div v-else class="text-center">
                                        Aún no ha configurado accesos
                                    </div>
                                    <hr>
                                    <div class="row mt-2 mb-5">
                                        <div class="col-5">
                                            Descripción
                                            <input class="form-control" type="text" v-model="addNewDescription">
                                        </div>
                                        <div class="col-5">
                                            Slug
                                            <input class="form-control" type="text" v-model="addNewSlug">
                                        </div>
                                        <div class="col-2">
                                            &nbsp;<br>
                                            <button class="btn btn-primary" @click="addAccess">Agregar</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-2">
                                <button @click="save" class="btn btn-primary mx-1 mb-2"><i
                                    class="mdi mdi-content-save"></i> Guardar
                                </button>
                                <button @click="$router.back()" class="btn btn-danger mx-1 mb-2"><i
                                    class="mdi mdi-cancel"></i> Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import {store} from "src/store";
import Tools from "src/core/Tools";


export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            id: 0,
            nombre: '',
            descripcion: '',
            urlRedirect: '',
            token: '',
            activa: 0,
            accesos: [],

            addNewSlug: '',
            addNewDescription: '',
        }
    },
    watch: {
        addNewSlug: function (val) {
            this.addNewSlug = val.replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
            if (this.addNewSlug !== '') {
                this.addNewSlug = this.addNewSlug.toLowerCase();
            }
        },
        addNewDescription: function (val) {
            this.addNewSlug = val.replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
            if (this.addNewSlug !== '') {
                this.addNewSlug = this.addNewSlug.toLowerCase();
            }
        },
    },
    mounted() {

        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;

            this.id = parseInt(this.$route.params.id);

            if (this.id > 0) {
                API.send('GET', 'personal/third-party-apps/' + this.id, {},
                    function (response) {
                        if (response.status) {
                            self.id = response.data.id;
                            self.nombre = response.data.nombre;
                            self.descripcion = response.data.descripcion;
                            self.token = response.data.token;
                            self.activa = response.data.activa;
                            self.accesos = response.data.accesos;
                            self.urlRedirect = response.data.urlRedirect;
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            }
            //this.getPaises();
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'personal/third-party-apps/borrar/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
        save() {
            const self = this;

            if (Tools.isEmptyString(this.nombre)) {
                API.showErrorAlert('Debe ingresar un nombre');
                return false;
            }
            if (Tools.isEmptyString(this.descripcion)) {
                API.showErrorAlert('Debe ingresar una descripción');
                return false;
            }

            API.send('POST', 'personal/third-party-apps/save', {
                    id: self.id,
                    nombre: self.nombre,
                    descripcion: self.descripcion,
                    accesos: self.accesos,
                    activa: self.activa,
                    urlRedirect: self.urlRedirect,
                }, function (response) {
                    API.showSuccessAlert(response.msg);

                    if (parseInt(self.id) === 0) {
                        window.location.href = '/account-third-party-apps/edit/' + response.data.id;
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                })
        },
        addAccess() {
            const self = this;
            self.accesos.push({
                slug: self.addNewSlug,
                descripcion: self.addNewDescription,
            });

            self.addNewSlug = '';
            self.addNewDescription = '';
        },
        eliminarAcceso(slug) {
            const self = this;

            API.showConfirm('Importante', 'Si elimina este acceso, todas las aplicaciones que estén relacionadas dejarán de funcionar, ¿desea continuar?', function () {
                self.accesos.forEach(function (item, index, object) {
                    if (item.slug === slug) {
                        object.splice(index, 1);
                    }
                })
            })
        }
    },
};
</script>
