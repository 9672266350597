<template>
    <div class="sidebar">
        <router-link class="brand-logo text-center" to="/dashboard">
            <img :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre" style="margin: auto" class="mb-3"/>
        </router-link>
        <div class="menu">
            <ul>
                <li :class="active === 1 && 'active'" v-if="Access('reportes-dashboard')">
                    <router-link to="/dashboard" :class="active === 1 && 'active'">
                        <span><i class="mdi mdi-view-dashboard"></i></span>
                        <span class="nav-text">Inicio</span>
                    </router-link>
                </li>
                <li :class="active === 2 && 'active'" v-if="Access('orders-pos')">
                    <router-link to="/order/creation" :class="active === 2 && 'active'">
                        <span><i class="la la-cash-register"></i></span>
                        <span class="nav-text">Caja</span>
                    </router-link>
                </li>
                <li :class="active === 90 && 'active'" v-if="Access('orders-agenda-ver')">
                    <router-link to="/orders/reservas" :class="active === 90 && 'active'">
                        <span><i class="mdi mdi-calendar"></i></span>
                        <span class="nav-text">Agenda</span>
                    </router-link>
                </li>
                <li :class="active === 40 && 'active'" v-if="Access('clients-administrar-crear_editar')">
                    <router-link to="/clients" :class="active === 90 && 'active'">
                        <span><i class="mdi mdi-account"></i></span>
                        <span class="nav-text">Pacientes</span>
                    </router-link>
                </li>
                <li :class="active === 31 && 'active'" v-if="Access('orders-listado')">
                    <router-link to="/orders" :class="active === 31 && 'active'">
                        <span><i class="la la-file-invoice-dollar"></i></span>
                        <span class="nav-text">Órdenes</span>
                    </router-link>
                </li>
                <li :class="active === 80 && 'active'" v-if="Access('products-productos-listado')">
                    <router-link to="/products" :class="active === 30 && 'active'">
                        <span><i class="mdi mdi-archive"></i></span>
                        <span class="nav-text">Productos y servicios</span>
                    </router-link>
                </li>
                <li :class="active === 70 && 'active'" v-if="Access('reportes-reportes')">
                    <router-link to="/reports" :class="active === 70 && 'active'">
                        <span><i class="mdi mdi-chart-bar"></i></span>
                        <span class="nav-text">Reportes</span>
                    </router-link>
                </li>
                <li :class="active === 50 && 'active'" v-if="Access('users-config-pagina_web')">
                    <router-link to="/my-website" :class="active === 50 && 'active'">
                        <span><i class="mdi mdi-laptop-mac"></i></span>
                        <span class="nav-text">Página web</span>
                    </router-link>
                </li>
                <li :class="active === 20 && 'active'" v-if="Access('users-config')">
                    <router-link to="/account" :class="active === 20 && 'active'">
                        <span><i class="mdi mdi-cogs"></i></span>
                        <span class="nav-text">Configuración</span>
                    </router-link>
                </li>
                <li class="closeSession">
                    <a @click="logout()" class="text-danger">
                        <span><i class="mdi mdi-logout"></i></span>
                        <span class="nav-text">Cerrar sesión</span>
                    </a>
                </li>
            </ul>
        </div>

        <!--<div class="sidebar-footer">
            <div class="copy_right">© {{ new Date().getFullYear() }} - by Abstrakt</div>
        </div>-->
    </div>
</template>

<script>

import API from "/src/core/Api.js";
import {mapGetters} from "vuex";

export default {
    name: "Sidebar",
    props: {
        active: Number,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    mounted() {
    },
    methods: {
        logout() {
            const self = this;
            API.showConfirm('¿Desea cerrar sesión?', '', function () {
                self.$store.dispatch('AuthLogout');
                window.location.href = '/signin';
            }, false, 'Cerrar sesión', 'Seguir conectado');
        }
    }
};
</script>
