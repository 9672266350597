import OrderForm from "./views/OrderForm";
import Order from "./views/Order/Order";
import ListadoSuscripciones from "./views/Subscription/ListadoSuscripciones";
import NuevaSubscription from "./views/Subscription/NewSubscription.vue";
import OrderDetail from "./views/Order/OrderDetailForm";
//import Medico from "../medico/views/Record/OrderDetailForm";
import ReservaForm from "./views/Reservas/OrderDetailForm";
import ReservaList from "./views/Reservas/Reserva";
import ReservasForms from "./views/Reservas/ReservasForms";
export default [
    {
        path: "/order/creation",
        component: OrderForm,
    },
    {
        path: "/order/edit/:id",
        component: OrderForm,
    },
    {
        path: "/orders",
        component: Order,
    },
    {
        path: "/orders/generated",
        component: Order,
    },
    {
        path: "/orders/subscriptions",
        component: ListadoSuscripciones,
    },
    {
        path: "/orders/subscriptions/new/:id",
        component: NuevaSubscription,
    },
    {
        path: "/order/view/:id",
        component: OrderDetail,
    },
    {
        path: "/reserva/view/:id",
        component: ReservaForm,
    },
    {
        path: "/orders/reservas",
        component: ReservaList,
    },
    {
        path: "/orders/reservas/forms/:id",
        component: ReservasForms,
    }
];
