<template>
    <div>
        <div class="fichaHeader">
            <div>
                <div>
                    <h3 :style="'color: ' + color">
                        {{title}}:
                        <span class="ml-3 text-dark small" v-if="typeof GetMedicoFichaActive.cliente !== 'undefined'">
                            {{GetMedicoFichaActive.cliente.nombre_mostrar}}
                        </span>
                        <span class="ml-2"  v-if="typeof GetMedicoFichaActive.cliente !== 'undefined'">
                            <router-link :to="'/client/' + GetMedicoFichaActive.cliente.id" class="text-small text-muted">
                                <i class="fa fa-pencil"></i>
                            </router-link>
                        </span>

                        <div class="tipoSangreAlert">
                            <div v-if="typeof GetMedicoFichaActive.metadata !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre !== 'undefined' && GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre">
                                <span><i class="fas fa-tint mr-1"></i> Sangre:</span> {{GetMedicoFichaActive.metadata.datos_paciente.tipo_sangre}}
                            </div>
                            <div v-else>
                                <span class="text-muted"><i class="fas fa-tint-slash mr-1"></i>Sin tipo sangre</span>
                            </div>
                        </div>
                        <div class="alergiasAlert">
                            <div v-if="typeof GetMedicoFichaActive.metadata !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente !== 'undefined' && typeof GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones !== 'undefined' && GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones">
                                <span><i class="fas fa-allergies mr-1"></i> Alergias:</span> {{GetMedicoFichaActive.metadata.datos_paciente.alergias_observaciones}}
                            </div>
                            <div v-else>
                                <span class="text-muted"><i class="fas fa-allergies mr-1"></i> Sin alergias</span>
                            </div>
                        </div>
                    </h3>
                </div>
                <div v-if="enableTimeline" class="showTimeline" @click="showTimeline = true"><i class="fa fa-calendar-day"></i></div>
            </div>
            <div class="fichaTabBar m-0">
                <div v-for="(tab, slug) in tabs" :key="'tab_' + slug" @click="changeTab(slug)" class="fichaTab" :style="(tabActive === slug) ? 'color: ' + color : ''">
                    <i :class="tab.icon"></i> {{tab.name}}
                </div>
            </div>
            <timeline v-if="enableTimeline" :show="showTimeline" @close="showTimeline = false"></timeline>
        </div>
        <div class="fichaContentTab">
            <div v-for="(tab, slug) in tabs" :key="'tabcontent_' + slug" v-show="tabActive === slug">
                <slot :name="slug"></slot>
            </div>
        </div>
    </div>
</template>
<script>
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


// componentes
import Timeline from './Timeline.vue';
import API from "src/core/Api";

export default {
    components: {
        Timeline
    },
    props: [
        'title',
        'color',
        'tabs',
        'tabDefault',
        'enableTimeline',
    ],
    data() {
        return {
            tabActive: '',
            showTimeline: false,
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
        this.tabActive = this.tabDefault;
    },
    methods: {
        ...mapActions({
        }),
        changeTab(slug) {
            this.tabActive = slug
            this.emitter.emit("medico-ficha-changed-tab", slug);
        },
    },
};

</script>
