<template>
    <div>
        <ficha-layout title="Traumatologia" color="#e89b00" :tabs="tabs" tab-default="preclinica"
                      enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <template #adjuntos>
                <adjuntos></adjuntos>
            </template>
            <template #consulta>

                <box title="Observaciones" icon="fas fa-radiation">
                    <div class="row">
                        <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in podologia_observaciones" :key="'grooming_comportamiento_' + key">
                            <label class="toggle">
                                <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveTomografia"/>
                                <div class="toggle-switch"></div>
                                <span class="toggle-label">
                                    {{ item.name }}
                                </span>
                            </label>
                        </div>
                    </div>
                </box>
            </template>
        </ficha-layout>
    </div>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import {Chart} from 'highcharts-vue';


import FichaLayout from './components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';
import Field from '/src/modules/medico/views/fichas/Field.vue';

// componentes medicos
import Antecedentes from './components/Antecedentes.vue';
import ExploracionFisica from './components/ExploracionFisica.vue';
import Tratamiento from './components/Tratamiento.vue';
import LaboratorioRadiologia from './components/LaboratorioRadiologia.vue';
import PreClinica from './components/PreClinica.vue';
import ImpresionClinica from './components/ImpresionClinica.vue';
import Resumen from './components/Resumen.vue';
import Adjuntos from './components/Adjuntos.vue';

export default {
    components: {
        Box,
        Field,
        FichaLayout,
        Antecedentes,
        ExploracionFisica,
        Tratamiento,
        PreClinica,
        ImpresionClinica,
        Resumen,
        Adjuntos,

        // graficos
        highcharts: Chart
    },
    props: [],
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                consulta: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Podología',
                },
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                adjuntos: {
                    icon: 'fas fa-paperclip',
                    name: 'Adjuntos', // agregar estado de cuenta
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
            podologia_observaciones: {
                ortesis: {
                    name: 'ÓRTESIS',
                    complemento: {},
                    value: false,
                },
                pseca: {
                    name: 'PIEL SECA',
                    complemento: {},
                    value: false,
                },
                phumeda: {
                    name: 'PIEL HÚMEDA',
                    complemento: {},
                    value: false,
                },
                pdelgada: {
                    name: 'PIEL DELGADA',
                    complemento: {},
                    value: false,
                },
                pEscamosa: {
                    name: 'PIEL Escamosa',
                    complemento: {},
                    value: false,
                },
                pBrillante: {
                    name: 'PIEL Brillante',
                    complemento: {},
                    value: false,
                },
                pTransparente: {
                    name: 'PIEL Transparente',
                    complemento: {},
                    value: false,
                },
                pSincabello: {
                    name: 'PIEL Sin cabello',
                    complemento: {},
                    value: false,
                }
            }

        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    watch: {},
    mounted() {
        const self = this;

        // Cargo la info de prenatales
        Object.keys(self.podologia_observaciones).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.podologia_observaciones) {
                if (self.GetMedicoFichaActive.metadata.podologia_observaciones[value]) {
                    self.podologia_observaciones[value].value = self.GetMedicoFichaActive.metadata.podologia_observaciones[value];
                }
            }
        });
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        saveRayosX() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.podologia_observaciones).map(function (value) {
                if (self.podologia_observaciones[value].value !== '') arrToSave[value] = self.podologia_observaciones[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'podologia_observaciones',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveRMN() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.podologia_observaciones).map(function (value) {
                if (self.podologia_observaciones[value].value !== '') arrToSave[value] = self.podologia_observaciones[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'traumatologia_rmn',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveDensitometria() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.traumatologia_densitometria).map(function (value) {
                if (self.traumatologia_densitometria[value].value !== '') arrToSave[value] = self.traumatologia_densitometria[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'traumatologia_densitometria',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveTomografia() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.traumatologia_tomografia).map(function (value) {
                if (self.traumatologia_tomografia[value].value !== '') arrToSave[value] = self.traumatologia_tomografia[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'traumatologia_tomografia',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        }
    },
};

</script>
