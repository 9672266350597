<template>
    <div class="AuthContainer">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="authWidthContainer">
                    <div class="mini-logo text-center my-5">
                        <router-link to="landing">
                            <img v-if="!StoreInfo.uriTienda" class="logoBrand" :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre"/>
                            <img v-if="StoreInfo.uriTienda" class="logoBrand" :src="StoreInfo.logoTienda" :alt="StoreInfo.logoTienda"/>
                        </router-link>
                    </div>
                    <div v-if="passwordChangedOk" class="text-center pb-3">
                        <div>
                            Contraseña actualizada con éxito
                        </div>
                        <div class="mt-3">
                            <router-link class="btn btn-primary" to="/signin">
                                Continuar
                            </router-link>
                        </div>
                    </div>
                    <div v-if="!passwordChangedOk">
                        <form v-if="this.token" method="post" @submit.prevent="formChangePassword">
                            <div>
                                <h6>Ingresa tu contraseña nueva <i class="fa fa-lock ml-2"></i></h6>
                                <hr>
                            </div>
                            <div class="form-group">
                                <label>Contraseña</label>
                                <i class="fa fa-question-circle ml-2" v-tooltip="'Una contraseña fuerte debe contener al menos 8 caracteres, mayúsculas, mínusculas, números y algún caracter especial como (@,*,#,$,!,=,+,-)'"></i>
                                <input type="password" class="form-control" placeholder="Contraseña" name="password" id="password" v-model="password"/>
                                <span class="validationError" v-if="passwordStrengthMsg" :style="'color:' + passwordStrengthColor">{{ passwordStrengthMsg }}</span>
                            </div>
                            <div class="form-group">
                                <label>Confirmar contraseña</label>
                                <input type="password" class="form-control" placeholder="Contraseña" name="passwordConfirm" id="passwordConfirm" v-model="confirmPassword"/>
                                <span class="validationError">{{ Valid.passwordConfirm }}</span>
                            </div>
                            <div class="text-center">
                                <div class="loginAdvice text-danger mb-3">
                                    <small>{{ msg }}</small>
                                </div>
                                <button type="submit" class="btn btn-primary btn-block">
                                    Cambiar contraseña
                                </button>
                            </div>
                        </form>
                        <form v-else method="post" @submit.prevent="formSubmit">
                            <div class="form-group">
                                <label>Correo electrónico</label>
                                <input type="email" class="form-control error" placeholder="hola@ejemplo.com" name="email" id="email" v-model="email"/>
                                <span class="validationError">{{ Valid.email }}</span>
                            </div>

                            <div class="text-center">
                                <div class="loginAdvice text-danger mb-3">
                                    <small>{{ msg }}</small>
                                </div>
                                <button v-if="msg === ''" type="submit" class="btn btn-primary btn-block">
                                    Enviar enlace de recuperación
                                </button>
                                <button v-if="fastLogin" type="button" class="btn btn-dark btn-block" @click="$emit('close')">
                                    Cancelar
                                </button>
                            </div>
                        </form>
                        <div class="new-account mt-3 text-center">
                            <p>
                                ¿Aún no tienes cuenta?
                                <router-link v-if="!fastLogin" class="text-primary" to="/signup">
                                    Registrarme
                                </router-link>
                                <a v-if="fastLogin" @click="$emit('update:showing', 'signup')" class="text-primary">
                                    Registrarme
                                </a>
                            </p>
                            <p>
                                <router-link v-if="!fastLogin" to="/signin">Ya tengo cuenta, iniciar sesión</router-link>
                                <a v-if="fastLogin" @click="$emit('update:showing', 'signing')" class="text-primary">
                                    Ya tengo cuenta, iniciar sesión
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from '../../../store';
// import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "/src/layout/admin/assets/css/Components.lazy.css";

import DataValidation from "src/core/DataValidation";
import Tools from "src/core/Tools";
import API from "src/core/Api";

export default {
    name: "Signin",
    components: {},
    props: {
        fastLogin: {
            default: false
        },
        showing: {
            default: 'signing'
        }
    },
    data() {
        return {
            Valid: {},
            email: "",
            msg: "",
            token: false,
            password: "",
            confirmPassword: "",
            passwordStrength: 0,
            passwordStrengthMsg: false,
            passwordStrengthColor: false,
            passwordChangedOk: false
        };
    },
    beforeCreate() {
        //controlPanelStyle.use();
        //controlPanelPopupStyle.use();
        // publicStore.use();
        componentsStyleGlobal.use();
    },
    unmounted() {
        //controlPanelStyle.unuse();
        //controlPanelPopupStyle.unuse();
        // publicStore.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        this.token =  this.$route.params.token;
    },
    watch: {
        password: function (value) {
            //console.log(value);
            this.CheckPasswordStrength(value);
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged"]),
        ...mapMutations(["AuthSetToken", "SetUserInfo"]),
        formSubmit() {

            const self = this;

            const validation = new DataValidation()
            validation.email('email', this.email, 'Ingrese un email válido');

            this.Valid = validation.validate(function () {

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('users/reset/'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
                    },
                    body: JSON.stringify({
                        email: self.email,
                        wl: store.getters.whiteLabelInfo.id,
                        store: (store.getters.StoreInfo) ? store.getters.StoreInfo.uriTienda : 0,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                self.msg = data.msg;
                            }
                            store.dispatch('coreHideLoading');
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error de conexión, por favor intente de nuevo';
                    });
            });
        },
        formChangePassword() {

            const self = this;

            const validation = new DataValidation()
            validation.required('passwordConfirm', this.confirmPassword, 'Por favor confirma la contraseña');

            this.Valid = validation.validate(function () {

                if (self.confirmPassword !== self.password) {
                    API.showErrorNotify("Las contraseñas no coinciden");
                    return false;
                }

                if (self.passwordStrength < 4) {
                    API.showErrorNotify("Ingresa una contraseña más fuerte");
                    return false;
                }

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('users/reset/'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
                    },
                    body: JSON.stringify({
                        token: self.token,
                        password: self.password,
                        wl: store.getters.whiteLabelInfo.id,
                        store: (store.getters.StoreInfo) ? store.getters.StoreInfo.uriTienda : 0,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {

                            if (data.status) {
                                self.passwordChangedOk = true;
                            }
                            else {
                                self.msg = data.msg;
                            }
                            store.dispatch('coreHideLoading');
                        }
                    })
                    .catch(() => {
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error de conexión, por favor intente de nuevo';
                    });
            });
        },
        CheckPasswordStrength(password) {
            const passwordSt = Tools.checkPasswordStrength(password);

            this.passwordStrength = passwordSt.passwordStrength;
            this.passwordStrengthMsg = passwordSt.passwordStrengthMsg;
            this.passwordStrengthColor = passwordSt.passwordStrengthColor;

        }
    },
};
</script>
