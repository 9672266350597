<template>
    <control-comercios-layout :active="60">
        <div class="card ordenesListado">
            <div class="card-header">
                <h4 class="card-title">Listado de comercios</h4>
                <div>
                    <router-link to="/order/creation" class="btn btn-primary px-4 mr-3">
                        <i class="fa fa-plus-circle"></i> Nueva
                    </router-link>
                </div>
            </div>
            <div class="card-body controlComercios">



                <div class="orderItem" v-for="(item, key) in usersList" :key="item.id">
                    <!--
                    <span class="buy-thumb"><i class="la la-arrow-up"></i></span>
                    -->
                    <div class="row">
                        <div class="col-12 col-sm-2 text-center pt-4">
                            <img v-if="item.tienda.logo" :src="item.tienda.logo" style="max-width: 100%">
                            <i v-else class="fas fa-store" style="font-size: 3em; color: #e0e0e0;"/>
                        </div>
                        <div class="col-12 col-sm-10">
                            <div>
                                <div>
                                    <div>
                                        <div>
                                            <h6 v-if="item.tienda.nombre">({{ item.tienda.nombre }}) {{ item.tienda.dominio }}</h6>
                                            <h6 v-else>{{ item.tienda.dominio }}</h6>
                                        </div>
                                        <div>
                                            <i class="fas fa-user mr-2"></i>
                                            <span>
                                                <b>Nombre:</b> {{ item.usuario.nombre }}, <b>usuario:</b> {{ item.usuario.email }}
                                            </span>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 col-sm-3">
                                                <i class="fas fa-phone mr-2"></i>
                                                <span class="ml-2">
                                                    {{ (item.usuario.telefono) ? item.usuario.telefono : 'Sin teléfono' }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-6">
                                                <i class="fas fa-location mr-2"></i>
                                                <span>
                                                    {{ (item.usuario.direccion) ? item.usuario.direccion : 'Sin dirección' }}
                                                </span>
                                            </div>
                                            <div class="col-12 col-sm-3">
                                                <i class="fas fa-check-circle mr-2"></i>
                                                <span>
                                                    <span class="text-success">Activo</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div class="mt-2">
                                            <a @click="$router.push('/sub-commerce/control/' + key)" class="text-info text-hover-underline"><i class="fa fa-eye mr-1"></i> Detalles</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>

<script>
import controlComerciosLayout from "../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import router from "src/router";

export default {
    components: {
        controlComerciosLayout,
    },
    data() {
        return {
            usersList: {},
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        router() {
            return router
        },
        Numbro,
        dayjs,
        loadItems() {
            const self = this;
            API.send('GET', 'stores/sub/commerce-list', {},
                function (response) {
                    if (response.status) {
                        self.usersList = response.data.usuarios;
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
    },
};
</script>
