<template>
    <div class="paymentContainer wrapper">
        <div class="container fullHeight">
            <div class="row align-items-center fullHeight mt-5">
                <div class="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mx-auto text-center mt-5">
                    <div class="logoBrandContainer mx-auto">
                        <!--<img src="../../assets/controlPanel/images/anysubscriptions.png" class="logoBrand" alt="Any Subscriptions" style="max-width: 200px"/>-->
                        <div class="brandSeparator mt-4 mx-auto"></div>
                    </div>
                    <div class="mt-5">
                        <div>
                            <h1>Error de conexión</h1>
                        </div>
                        <div class="mt-4">
                            El sitio parece no responder a la solicitud
                        </div>
                        <div class="mt-5">
                            <a class="btn btn-primary" onclick="history.back()">Volver a intentar</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: "404",
}
</script>
