<template>
    <setting-layout :active="25">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de aplicaciones de terceros</h4>
            </div>
            <div class="card-body">
                <div class="card-subtitle">
                    Administra los las aplicaciones de terceros con las cuales quieres utilizar el SSO (Single Sign On)
                </div>
                <div class="mt-2 mb-3">
                    <a @click="$router.push('/account-third-party-apps/edit/0')" role="button" tabindex="0"
                        class="btn btn-primary px-4 mr-3">
                        Agregar nueva a aplicación
                    </a>
                </div>
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in apps" :key="item.id">
                            <div class="media d-block">
                                <div class="row">
                                    <div class="col-9">
                                        <h4 class="mt-0 mb-1">{{ item.nombre }}</h4>
                                        <div>
                                            <small><b>Token:</b> {{ item.token }}</small>
                                        </div>
                                    </div>
                                    <div class="col-3 text-right">
                                        <div class="edit-option text-right">
                                            <a @click="$router.push('/account-third-party-apps/edit/' + item.id)">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                            <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";


export default {
    components: {
        settingLayout,
        FileUploader,
        Multiselect,
    },
    data() {
        return {
            apps: {},
            editar: false,
            nuevo: false,
            descansoActivo: false,
            tab: false,
            horarioDescanso: 0,
            horario: {},
            persona: {},
            /*paises: {},
            deptos: {},
            municipios: {},*/
            locaciones: [],
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('GET', 'personal/third-party-apps/0', {},
                function (data) {
                    if (data.status) {
                        self.apps = data.data;
                    }
                    else {
                        self.msg = data.msg;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                });
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Si elimina un rol, todos los usuarios que posean ese rol quedarán sin acceso, incluyendo las aplicaciones de terceros vinculadas mediante SSO (Single Sign-On). Esta acción no se puede deshacer.', function () {
                API.send('POST', 'personal/third-party-apps/delete', {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
    },
};
</script>
