<template>
    <layout>
        <div class="content-body fichaMedicaForm">
            <ficha-ginecologia v-if="tipoFicha === 'ginecologia'"></ficha-ginecologia>
            <ficha-medicina-general v-if="tipoFicha === 'medicina_general'"></ficha-medicina-general>
            <ficha-pediatria v-if="tipoFicha === 'pediatria'"></ficha-pediatria>
            <ficha-urgencia v-if="tipoFicha === 'urgencia'"></ficha-urgencia>
            <ficha-odontologia v-if="tipoFicha === 'odontologia'"></ficha-odontologia>
            <ficha-veterinaria v-if="tipoFicha === 'veterinaria'"></ficha-veterinaria>
            <ficha-podologia v-if="tipoFicha === 'podologia'"></ficha-podologia>
            <ficha-traumatologia v-if="tipoFicha === 'traumatologia'"></ficha-traumatologia>
            <ficha-urologia v-if="tipoFicha === 'urologia'"></ficha-urologia>
            <div v-if="tipoFicha === '' || !tipoFicha" class="my-5 p-4 text-danger text-center">
                El producto de esta orden, no posee ninguna ficha configurada
            </div>
        </div>
    </layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";

// FICHAS MÉDICAS DISPONIBLES
import FichaGinecologia from './fichas/medico/Ginecologia.vue';
import FichaMedicinaGeneral from './fichas/medico/MedicinaGeneral.vue';
import FichaPediatria from './fichas/medico/Pediatria.vue';
import FichaUrgencia from './fichas/medico/Urgencia.vue';
import FichaOdontologia from './fichas/medico/Odontologia.vue';
import FichaPodologia from './fichas/medico/Podologia.vue';
import FichaVeterinaria from './fichas/medico/Veterinaria.vue';
import FichaTraumatologia from './fichas/medico/Traumatologia.vue';
import FichaUrologia from './fichas/medico/Urologia.vue';

export default {
    components: {
        Layout,

        // fichas médicas
        FichaUrologia,
        FichaGinecologia,
        FichaMedicinaGeneral,
        FichaPediatria,
        FichaUrgencia,
        FichaOdontologia,
        FichaVeterinaria,
        FichaTraumatologia,
        FichaPodologia
    },
    data() {
        return {
            // Ficha médica
            idReserva: 0,
            tipoFicha: '',
        };
    },
    computed: {
        ...mapGetters({
        })
    },
    watch: {

    },
    mounted() {
        this.$store.dispatch('SetMedicoFichaActive', {}); // limpio todo antes de iniciar
        this.idReserva = this.$route.params.reservaId;
        this.loadReserva();

        const self = this;
        this.emitter.on("medico-ficha-refresh", function () {
            self.loadReserva();
        });
    },
    methods: {
        ...mapActions({
            SetMedicoFichaActive: 'SetMedicoFichaActive',
        }),
        dayjs,
        Numbro,
        loadReserva() {
            const self = this;
            self.personal = [];
            self.asignaciones = [];
            API.send('GET', 'orders/metadata/by-reserva/' + self.idReserva, {}, function (response) {
                self.tipoFicha = response.data.producto.fichaMedica;
                self.emitter.emit("medico-ficha-load");
                self.$store.dispatch('SetMedicoFichaActive', response.data);
            },
            function (response) {
                    API.showErrorNotify(response.msg);
                });
            },
        },
    };

</script>