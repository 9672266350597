<template>
    <control-comercios-layout :active="61">
        <div class="card ordenesListado">
            <div class="card-header">
                <h4 class="card-title">Detalle de comercio</h4>
            </div>
            <div class="card-body controlComercios">
                <div>
                    <h5 v-if="comercio.tienda.nombre">({{ comercio.tienda.nombre }}) {{ comercio.tienda.dominio }}</h5>
                    <h5 v-else>{{ comercio.tienda.dominio }}</h5>
                </div>
                <div>
                    <i class="fas fa-user mr-2"></i>
                    <span>
                        <b>Nombre:</b> {{ comercio.usuario.nombre }}
                    </span>
                </div>
                <div>
                    <i class="fas fa-user mr-2"></i>
                    <span>
                        <b>Usuario:</b> {{ comercio.usuario.email }}
                    </span>
                </div>
                <div>
                    <i class="fas fa-phone mr-2"></i>
                    <span class="ml-2">
                        {{ (comercio.usuario.telefono) ? comercio.usuario.telefono : 'Sin teléfono' }}
                    </span>
                </div>
                <div>
                    <i class="fas fa-location mr-2"></i>
                    <span>
                        {{ (comercio.usuario.direccion) ? comercio.usuario.direccion : 'Sin dirección' }}
                    </span>
                </div>
                <div>
                    <i class="fas fa-calendar mr-2"></i>
                    <span>
                        Creado: {{ dayjs(comercio.usuario.creado).format('DD-MM-YYYY H:mm') }}
                    </span>
                </div>
                <div>
                    <i class="fas fa-check-circle mr-2"></i>
                    <span>
                        Estado: <span class="text-success">Activo</span>
                    </span>
                </div>
            </div>
        </div>
        <div class="card ordenesListado">
            <div class="card-header">
                <h4 class="card-title">Concilia por comercio</h4>
            </div>
            <div class="card-body controlComercios">
                <concilia-generar-pago :usuario-id="userId"></concilia-generar-pago>
            </div>
        </div>
    </control-comercios-layout>
</template>

<script>
import controlComerciosLayout from "../components/settings/controlComerciosLayout.vue";
import ConciliaGenerarPago from "../components/ConciliaGenerarPago.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";

export default {
    components: {
        controlComerciosLayout,
        ConciliaGenerarPago,
    },
    data() {
        return {
            userId: 0,
            comercio: {
                usuario: {},
                tienda: {},
            },

        };
    },
    mounted() {
        this.userId = this.$route.params.id
        this.loadItems();
    },
    methods: {
        Numbro,
        dayjs,
        loadItems() {
            const self = this;
            API.send('GET', 'stores/sub/commerce/' + self.userId, {},
                function (response) {
                    if (response.status) {
                        self.comercio = response.data;
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
    },
};
</script>
