<template>
    <setting-layout :active="32">
        <button class="btn btn-outline-primary w-25" onclick="window.history.go(-1); return false;">Regresar</button>
        <button class="btn btn-outline-success w-25" @click="isActive = 'info';">Información</button>
        <button class="btn btn-outline-success w-25" @click="isActive = 'coti';">Cotizar</button>
        <div class="card card-body">
            <div v-html="product.descripcion" v-if="isActive === 'info'" class="mt-100 mixitUp-container"/>
            <div v-if="isActive === 'coti'">
                <flow-form ref="flowform"
                           v-if="questionsLoaded && !cartNotice.status"
                           :force-update="forceUpdate"
                           v-bind:standalone="false"
                           v-bind:questions="questions"
                           v-bind:language="language"
                           v-bind:progressbar="true"
                           v-on:submit="onSubmit"
                           v-on:answer="onAnswer"
                           v-on:step="onStep"
                >
                </flow-form>
                <div v-if="cartNotice.status" class="bg-light shadow-lg rounded-3 cartProducts">
                    <div class="cartNotify">
                        <div>
                            <h3 class="text-primary">Suscripción Creada
                                <i class="fa fa-check-circle ml-2"></i>
                            </h3>
                        </div>
                        <hr class="my-4">
                        <div class="card">
                            <div class="card-body">
                                <h4 class="card-title"><i class="fas fa-file-invoice mr-2"></i> Detalles de la suscripción:</h4>
                                <div>
                                    <p>El código que debes copiar y pegar en SGC es el siguiente: <b>{{ cartNotice.codigoOrden }}</b> y debes cobrar en caja un total de (<b>{{formularioData['ordenMonto']}}</b>)</p>
                                </div>
                                <div class="mt-3">
                                    <div v-if="cartNotice.metodoPago === 'card'">
                                        <p>Los datos de cobro son:</p>
                                        <ul>
                                            <li><span class="font-weight-bold">Autorización:</span> {{ cartNotice.auth }}</li>
                                            <li><span class="font-weight-bold">Referencia No.:</span> {{ cartNotice.reference }}</li>
                                            <li><span class="font-weight-bold">Procesado por:</span> {{ cartNotice.processor }}</li>
                                        </ul>
                                    </div>
                                    <div v-else-if="cartNotice.metodoPago === 'later_on_delivery'" class="my-3">
                                        <p><span class="font-weight-bold">Pagarás contra entrega</span></p>
                                    </div>
                                    <div v-else-if="cartNotice.metodoPago === 'later_on_store'" class="my-3">
                                        <p><span class="font-weight-bold">Pagarás en tienda tu pedido</span></p>
                                    </div>
                                </div>
                                <div class="my-3 text-primary">
                                    Recibirás un correo electrónico con la confirmación de tu compra.
                                </div>
                                <div class="d-flex justify-content-between">
                                    <button type="button" class="btn btn-primary" @click="continueAfterCart">Hacer otra venta</button>
                                    <button type="button" class="btn btn-primary" @click="printDocumento"><i class="fa fa-print"></i></button>
                                    <button type="button" class="btn btn-primary" @click="consultaPDF"><i class="fa fa-download"></i></button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            <div class="gap"></div>
            <div class="gap"></div>
            <div class="gap"></div>
        </div> <!-- /.mixitUp-container -->


        <div v-show="showPrintDialog">

            <div class="voucher" id="divImprimir">

                <div class="voucher-body">
                    <div class="row">
                        <div class="col-12">
                            <p style="text-align: justify; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 9.0pt; font-family: 'Times New Roman', serif; color: black;">Bienvenido a la cobertura VIAL GUATESEGURO. Tu número de afiliación es<u>&nbsp; {{cartNotice.codigoOrden}}</u> y el cual has adquirido bajo el plan de pago {{ formularioData['Plan'] ? formularioData['Plan'] : '' }} {{formularioData['ordenMonto']}}&nbsp;con vigencia hasta el {{cartNotice.fechaPago}}, registrando los siguientes datos para tu veh&iacute;culo:</span></p>
                            <ul style="margin-top: 0cm; margin-bottom: 0cm;" type="disc">
                                <li style="color: black; margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 11.0pt; font-family: 'Times New Roman', serif;">Tipo: {{ formularioData['Tipo'] ? formularioData['Tipo'] : '' }}</span></li>
                                <li style="color: black; margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 11.0pt; font-family: 'Times New Roman', serif;">Placa: {{ formularioData['placa_a'] ? formularioData['placa_a'] : formularioData['placa_b'] }}</span></li>
                                <li style="color: black; margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 11.0pt; font-family: 'Times New Roman', serif;">Marca: {{ formularioData['lineaM'] ? formularioData['lineaM'] : formularioData['linea'] }}</span></li>
                                <li style="color: black; margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 11.0pt; font-family: 'Times New Roman', serif;">Color: {{ formularioData['color'] ? formularioData['color'] : '' }}</span></li>
                                <li style="color: black; margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 11.0pt; font-family: 'Times New Roman', serif;">A&ntilde;o: {{ formularioData['year'] ? formularioData['year'] : '' }}</span></li>
                            </ul>
                            <p style="text-align: justify; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 9.0pt; font-family: 'Times New Roman', serif; color: black;">El que aparece registrado a nombre de {{ formularioData['clienteNombre'] ? formularioData['clienteNombre'] : '' }} con No. de DPI {{ formularioData['DPI'] ? formularioData['DPI'] : '' }} y NIT {{ formularioData['clienteTaxId'] ? formularioData['clienteTaxId'] : '' }}.</span></p>
                            <p style="text-align: justify; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 10.5pt; font-family: 'Times New Roman', serif; color: black;">&nbsp;</span></strong></p>
                            <p style="text-align: justify; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 7.5pt; font-family: 'Times New Roman', serif;">&iquest;Que incluye tu plan de asistencias VIAL GUATESEGURO?</span></strong></p>
                            <p style="text-align: justify; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 10.0pt; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
                            <table class="MsoNormalTable" style="width: 100.0%; margin-left: 4.8pt; border-collapse: collapse;" border="0" cellspacing="0" cellpadding="0">
                                <tbody>
                                <tr style="height: 13.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 6.5pt; color: black;">ASISTENCIA</span></strong></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 6.5pt; color: black;">L&Iacute;MITE DE LA ASISTENCIA</span></strong></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 6.5pt; color: black;">EVENTOS ANUALES</span></strong></p>
                                    </td>
                                </tr>
                                <tr style="height: 13.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de gr&uacute;a por aver&iacute;a o accidente (hasta 20km)</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Q480.00</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">1</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de cambio de llanta</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Q200.00</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">1<br>&nbsp;(en conjunto)</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 10.05pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de paso de corriente</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Sin l&iacute;mite</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;" rowspan="3">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">1<br>&nbsp;(en conjunto)</span></p>
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">1</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 13.4pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de env&iacute;o de combustible.</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Sin l&iacute;mite</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de cerrajer&iacute;a vial</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Q250.00</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de mec&aacute;nica ligera en caso de aver&iacute;a para garantizar la continuidad del viaje (reparaci&oacute;n menor)</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Q100.00</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">1</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de referencia de talleres mec&aacute;nicos</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de orientaci&oacute;n telef&oacute;nica para primera licencia o renovaci&oacute;n</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Orientaci&oacute;n legal telef&oacute;nica en caso de accidente vial</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid #BFBFBF 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Orientaci&oacute;n legal telef&oacute;nica en materia penal y civil</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Sin l&iacute;mite</span></p>
                                    </td>
                                </tr>
                                <tr style="height: 6.7pt;">
                                    <td style="width: 244.8pt; border: solid windowtext 1.0pt; border-top: none; background: white; padding: 0cm 5.4pt 0cm 5.4pt;" valign="top">
                                        <p style="text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt; color: black;">Servicio de traslado m&eacute;dico en ambulancia por accidente vial o emergencia hasta 15km. (no enfermedad)&nbsp;</span></p>
                                    </td>
                                    <td style="width: 105.0pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">Q600.00</span></p>
                                    </td>
                                    <td style="width: 91.1pt; border-top: none; border-left: none; border-bottom: solid #BFBFBF 1.0pt; border-right: solid #BFBFBF 1.0pt; padding: 0cm 5.4pt 0cm 5.4pt;">
                                        <p style="text-align: center; text-indent: -0.1pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 6.5pt;">1</span></p>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 10.0pt; font-family: 'Times New Roman', serif;">&nbsp;</span></strong></p>
                            <p style="text-indent: 35.4pt; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Todos los servicios que se detallan en la tabla anterior ser&aacute;n prestados exclusivamente por la empresa de asistencia American Assist Guatemala, S.A. (Addiuva) y llamando al n&uacute;mero 2306 1238, los cuales se describen a continuaci&oacute;n:</span></strong></p>
                            <p style="text-align: justify; margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">&nbsp;</span></strong></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Coberturas y exclusiones de cada asistencia:</span></strong></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE GR&Uacute;A POR AVER&Iacute;A O ACCIDENTE (HASTA 20 KM)</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de aver&iacute;a o accidente que no permita la circulaci&oacute;n aut&oacute;noma del Veh&iacute;culo o motocicleta, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva para el traslado del Veh&iacute;culo en gr&uacute;a/remolque hasta el taller mec&aacute;nico m&aacute;s cercano al lugar de la aver&iacute;a o al lugar indicado por el Afiliado hasta por una distancia m&aacute;xima de 20 Km por evento, de acuerdo con los l&iacute;mites y eventos anuales descritos en la tabla de asistencias. El costo por Km. Adicional recorrido es de quince quetzales (Q15.00) y ser&aacute; cancelado por el Afiliado directamente al proveedor al momento del servicio.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Antes de enviar el servicio, en caso de haber un excedente al monto de la cobertura, se le comunicar&aacute; al AFILIADO el valor de este para su autorizaci&oacute;n, el excedente ser&aacute; pagado en forma inmediata por el AFILIADO al proveedor del servicio con sus propios recursos.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El Afiliado o un designado de este, deber&aacute; acompa&ntilde;ar a la gr&uacute;a/el remolque durante todo el trayecto de traslado del Veh&iacute;culo hasta su destino. El tiempo de respuesta del servicio depender&aacute; de la ubicaci&oacute;n del Veh&iacute;culo.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">EXCLUSIONES DEL SERVICIO DE GR&Uacute;A/REMOLQUE POR AVER&Iacute;A.</span></strong></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Quedan excluidos del presente Servicio:</span></p>
                            <ul style="margin-top: 0cm; margin-bottom: 0cm;" type="disc">
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El remolque de Veh&iacute;culos que se encuentren en subterr&aacute;neos privados, de centros comerciales o de supermercados.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El remolque de Veh&iacute;culos volcados, atrapados o enterrados.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El remolque de Veh&iacute;culos comerciales, es decir, aqu&eacute;llos destinados a transporte p&uacute;blico, de carga o de personas, tales como taxis, microbuses, camiones, autobuses de turismo y Veh&iacute;culos rentados a una empresa de alquiler de autos establecida como tal, entre otros.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El remolque de Veh&iacute;culos con carga y/o heridos.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El rescate o remoci&oacute;n de Veh&iacute;culos atascados o atorados en huecos, barrancos, lagos, lagunas, pantanos, etc.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El remolque de Veh&iacute;culos que requiera de equipamiento adicional al simple remolque en gr&uacute;a.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El remolque de Veh&iacute;culos a estaciones de servicio de expendiendo de combustible por falta de estos.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Segundos traslados o traslados derivados de un Servicio anterior.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La prestaci&oacute;n del servicio a cualquier veh&iacute;culo NO reportado en la compra del producto de asistencia o que no se encuentre al d&iacute;a en sus pagos mensuales.</span></li>
                            </ul>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="2">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE CAMBIO DE LLANTA</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que se pinche una llanta del Veh&iacute;culo, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y enviar&aacute; a un t&eacute;cnico especializado para asistir al Afiliado en el cambio de la llanta de repuesto.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El Afiliado deber&aacute; proveer la llanta de repuesto a la hora de prestar el Servicio en correctas condiciones para hacer el cambio (inflado) y deber&aacute; contar con las herramientas especiales y de seguridad que amerite la situaci&oacute;n, lugar o modelo del Veh&iacute;culo.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El tiempo de respuesta del servicio depender&aacute; de la ubicaci&oacute;n del Veh&iacute;culo.</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="3">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE PASO DE CORRIENTE</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que el Veh&iacute;culo tenga la bater&iacute;a descargada, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y enviar&aacute; una unidad de auxilio vial para realizar un puente el&eacute;ctrico con el Veh&iacute;culo inmovilizado con el fin de activar su bater&iacute;a.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La entrega de este servicio es a responsabilidad del Afiliado, dado los posibles da&ntilde;os que pudiesen ocurrirle al computador del Veh&iacute;culo.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El tiempo de respuesta del servicio depender&aacute; de la ubicaci&oacute;n del Veh&iacute;culo.</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="4">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE ENV&Iacute;O DE COMBUSTIBLE</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que el Veh&iacute;culo se quede sin combustible, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y enviar&aacute; hasta dos (2) galones de combustible, el cual le permitir&aacute; al Afiliado desplazarse hasta una bomba o destino cercano.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El valor del combustible deber&aacute; ser pagado por el Afiliado al momento de recibir el Servicio</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="5">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE CERRAJER&Iacute;A VIAL</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de presentarse un hecho accidental como p&eacute;rdida, extrav&iacute;o o robo de las llaves o a la inutilizaci&oacute;n de la cerradura por intento de hurto u otra causa, que impida la apertura del Veh&iacute;culo, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y enviar&aacute; a un t&eacute;cnico especializado que realizar&aacute; la apertura del Veh&iacute;culo.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Queda excluido del presente Servicio la reparaci&oacute;n o reposici&oacute;n de la cerradura del Veh&iacute;culo o de la llave, &uacute;nicamente se realizar&aacute; la apertura de este. Se apertura &uacute;nicamente la puerta principal del veh&iacute;culo por protocolos de seguridad y de requerirse la apertura de una segunda cerradura, el costo ser&aacute; asumido por parte del Afiliado y cancelado al momento de la asistencia directamente al proveedor.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El tiempo de respuesta del servicio depender&aacute; de la ubicaci&oacute;n del Veh&iacute;culo.</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="6">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE MEC&Aacute;NICA LIGERA EN CASO DE AVER&Iacute;A PARA GARANTIZAR LA CONTINUIDAD DEL VIAJE (REPARACI&Oacute;N MENOR)</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que el Veh&iacute;culo tenga fallas menores y sea posible su reparaci&oacute;n en el lugar tales como limpieza de bornes, revisi&oacute;n de fajas, revisi&oacute;n de distribuidores, falta de corriente, entre otros, que impidan el arranque o funcionamiento del Veh&iacute;culo, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y enviar&aacute; una unidad de auxilio vial que realizar&aacute; las reparaciones necesarias para que el Veh&iacute;culo contin&uacute;e su marcha, en caso de ser posible.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El costo por suministrar repuestos y la mano de obra derivada de dichos cambios va por cuenta del Afiliado y deber&aacute; cancelarse directamente al proveedor al momento del servicio.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El tiempo de respuesta del servicio depender&aacute; de la ubicaci&oacute;n del Afiliado.</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="7">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE REFERENCIA DE TALLERES MEC&Aacute;NICOS</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que el Afiliado lo requiera, ADDIUVA le proporcionar&aacute; informaci&oacute;n sobre talleres mec&aacute;nicos cercanos al Domicilio del Afiliado o al lugar donde &eacute;ste se encuentre.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">ADDIUVA presta este servicio como informaci&oacute;n o mera referencia. Los gastos en que se incurran por los trabajos de reparaci&oacute;n, repuestos y mano de obra, deber&aacute;n ser pagados por el Afiliado.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La garant&iacute;a de los trabajos realizados por el proveedor del servicio, autorizado previamente y por escrito por el Afiliado, tiene relaci&oacute;n directa entre estas partes.</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="8">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE INFORMACI&Oacute;N PARA PROCESOS DE OBTENCI&Oacute;N O RENOVACI&Oacute;N DE LICENCIA </span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que el Afiliado lo requiera, ADDIUVA le proporcionar&aacute; informaci&oacute;n general sobre el proceso de obtenci&oacute;n por primera vez o renovaci&oacute;n de la licencia de conducci&oacute;n en el lugar de inter&eacute;s del Afiliado.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">ADDIUVA presta este servicio como informaci&oacute;n o mera referencia. Los gastos en que se incurran por la renovaci&oacute;n, el traspaso o la liberaci&oacute;n deber&aacute;n ser pagados por el Afiliado.</span></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="9">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE ORIENTACI&Oacute;N LEGAL TELEF&Oacute;NICA POR ACCIDENTE VIAL</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Por solicitud del Afiliado, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y lo contactar&aacute; telef&oacute;nicamente con un Abogado para que sea consultado sobre cualquier asunto relacionado con accidentes viales, pago y reclamaci&oacute;n de da&ntilde;os y perjuicios.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">ADDIUVA queda excluido de responsabilidad por cualquier determinaci&oacute;n que adopte el Afiliado por la consulta jur&iacute;dica.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">EXCLUSIONES AL SERVICIO DE ORIENTACI&Oacute;N LEGAL TELEF&Oacute;NICA POR ACCIDENTE VIAL</span></strong></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Quedan excluidos del presente Servicio:</span></p>
                            <ul style="margin-top: 0cm; margin-bottom: 0cm;" type="disc">
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El pago de honorarios legales, fianzas y/o cauciones.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La elaboraci&oacute;n o redacci&oacute;n de cualquier tipo de documento legal.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La representaci&oacute;n directa del Afiliado ante cualquier autoridad judicial o administrativa.</span></li>
                            </ul>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="10">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE ORIENTACI&Oacute;N LEGAL TELEF&Oacute;NICA EN MATERIA PENAL Y CIVIL</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Por solicitud del Afiliado, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva y lo contactar&aacute; telef&oacute;nicamente con un Abogado para que sea consultado sobre cualquier asunto relacionado con el derecho penal o civil para la presentaci&oacute;n de denuncias ante las autoridades competentes o para la defensa de los intereses del Afiliado en caso de que este siendo investigado.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">No se prestar&aacute; asesor&iacute;a sobre delitos de delincuencia organizada (terrorismo, delitos contra la salud, tr&aacute;fico de estupefacientes), falsificaci&oacute;n o alteraci&oacute;n de moneda, operaciones con recursos de procedencia il&iacute;cita, acopio y tr&aacute;fico de armas, tr&aacute;fico de indocumentados, tr&aacute;fico de &oacute;rganos; corrupci&oacute;n, pornograf&iacute;a, turismo sexual, tr&aacute;fico de menores, lenocinio, en contra de personas menores de 18 a&ntilde;os de edad o de personas que no tienen capacidad para comprender el significado del hecho o de personas que no tienen capacidad para resistirlo, secuestro y trata de personas).</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">ADDIUVA queda excluido de responsabilidad por cualquier determinaci&oacute;n que adopte el Afiliado por la consulta jur&iacute;dica.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">EXCLUSIONES AL SERVICIO DE ORIENTACI&Oacute;N LEGAL TELEF&Oacute;NICA EN MATERIA PENAL Y CIVIL</span></strong></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Quedan excluidos del presente Servicio:</span></p>
                            <ul style="margin-top: 0cm; margin-bottom: 0cm;" type="disc">
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El pago de honorarios legales, fianzas y/o cauciones.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La elaboraci&oacute;n o redacci&oacute;n de cualquier tipo de documento legal.</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">La representaci&oacute;n directa del Afiliado ante cualquier autoridad judicial o administrativa.</span></li>
                            </ul>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;" start="11">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">SERVICIO DE TRASLADO M&Eacute;DICO TERRESTRE EN AMBULANCIA POR ACCIDENTE VIAL O EMERGENCIA (NO ENFERMEDAD)</span></strong></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que el Afiliado sufra un accidente de tr&aacute;nsito que requiera manejo en un centro hospitalario, por solicitud del Afiliado, ADDIUVA har&aacute; la coordinaci&oacute;n efectiva para el traslado del Afiliado hacia el centro hospitalario m&aacute;s cercano al sitio del accidente, o hacia el centro m&eacute;dico que el Afiliado decida, si la emergencia as&iacute; lo permite y se encuentra dentro de misma la ciudad, para que contin&uacute;e la atenci&oacute;n m&eacute;dica a cargo de su empresa prestadora de salud, medicina pre pagada seguro de salud o el servicio de salud que el Afiliado tenga contratado.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El valor de los costos y gastos derivados de la atenci&oacute;n m&eacute;dica deber&aacute;n ser pagados por Afiliado.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El Equipo M&eacute;dico coordinado por ADDIUVA orientar&aacute; a quien solicita el servicio respecto de las conductas provisionales que se deben asumir, mientras se produce el contacto profesional m&eacute;dico paciente.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El servicio incluye un desplazamiento de hasta 15Km. El costo por Km. Adicional promedio corresponde a Q15.00, el cual deber&aacute; ser cubierto por el Afiliado al momento del servicio.</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En los sitios en donde no se cuente con un proveedor de ambulancia, se coordinar&aacute; con Bomberos Voluntarios o Municipales</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">EXCLUSIONES AL SERVICIO DE TRASLADO M&Eacute;DICO TERRESTRE EN AMBULANCIA POR ACCIDENTE VIAL O EMERGENCIA (NO ENFERMEDAD)</span></strong></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Quedan excluidos del presente Servicio:</span></p>
                            <ul style="margin-top: 0cm; margin-bottom: 0cm;" type="disc">
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El traslado desde zonas donde no exista la infraestructura p&uacute;blica o privada necesaria para el traslado del paciente</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Las ambulancias programadas</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Los atentados voluntarios contra la vida del Afiliado</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El retiro de pacientes en hospitales o cl&iacute;nicas</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Cuando la Situaci&oacute;n de Asistencia es causada por la participaci&oacute;n en pr&aacute;cticas deportivas, competencias, pruebas preparatorias o entrenamientos</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Cuando la Situaci&oacute;n de Asistencia es causada por la participaci&oacute;n en carreras o pruebas de seguridad, resistencia o velocidad</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Cuando la Situaci&oacute;n de Asistencia es causada por la destinaci&oacute;n del Veh&iacute;culo para fines de ense&ntilde;anza o de instrucci&oacute;n de su manejo o funcionamiento</span></li>
                                <li style="margin-top: 0cm; margin-right: 0cm; margin-bottom: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">En caso de que no exista ambulancia disponible, se coordinar&aacute; con Bomberos Voluntarios o Municipales.</span></strong></li>
                            </ul>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><strong><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Condiciones:</span></strong></p>
                            <ol style="margin-bottom: 0cm; margin-top: 0px;">
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Para gozar de los servicios de asistencia, deber&aacute;s contar con tu programa de asistencias activo seg&uacute;n el plazo del servicio contratado, para lo cual deber&aacute; estar al d&iacute;a en pago de los servicios de asistencia. Por lo que a su vencimiento deber&aacute;s contar con la renovaci&oacute;n para continuar gozando de tus beneficios.</span></li>
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Todo excedente por evento ser&aacute; pagado por el AFILIADO con sus propios recursos.</span></li>
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Este plan estar&aacute; activo luego de 48h, luego de la afiliaci&oacute;n en la caja de ENERGUATE.</span></li>
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Los servicios VIALES GUATESEGURO, se prestar&aacute;n &uacute;nica y exclusivamente en el territorio de la rep&uacute;blica de Guatemala, siempre y cuando exista la infraestructura p&uacute;blica o privada para la prestaci&oacute;n del servicio y donde no se denomine zona roja o de conflicto, ubicaciones que por su alta peligrosidad pongan en riesgo al proveedor en su vida, bienes o la prestaci&oacute;n del servicio.</span></li>
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Todos los servicios anteriormente mencionados ser&aacute;n prestados por la empresa de asistencia American Assist Guatemala, S.A. (Addiuva); por lo cual, con la firma del presente documento el AFILIADO acepta y autoriza que los datos proporcionados (Nombre completo, DPI, NIT, Correo electr&oacute;nico en caso de que tenga, Tel&eacute;fono, Tipo de veh&iacute;culo, Placa de veh&iacute;culo, Marca del veh&iacute;culo, Color del veh&iacute;culo, A&ntilde;o del veh&iacute;culo), ser&aacute;n tratados por esta empresa y sus proveedores, para la correcta prestaci&oacute;n del servicio</span></li>
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Bajo estos t&eacute;rminos y datos proporcionados en las oficinas comerciales de Energuate, acepto el cargo de las cuotas futuras en mi factura de energ&iacute;a el&eacute;ctrica, y estoy entendido de las asistencias contratadas.</span></li>
                                <li style="margin: 0cm 0cm 0cm 0.4px; font-size: 12pt; font-family: Calibri, sans-serif; text-indent: 0.4px;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">El AFILIADO entiende que el contrato puede finalizarlo en cualquier momento y no podr&aacute; pedir la devoluci&oacute;n de dineros que ya se hayan pagado en el respectivo plan.</span></li>
                            </ol>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 12pt; font-family: 'Times New Roman', serif;">Nombre del cliente: {{ formularioData['clienteNombre'] ? formularioData['clienteNombre'] : '' }}</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 12pt; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 12pt; font-family: 'Times New Roman', serif;">Firma: ____________________</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 12pt; font-family: 'Times New Roman', serif;">&nbsp;</span></p>
                            <p style="margin: 0cm; font-size: 12pt; font-family: Calibri, sans-serif;"><span style="font-size: 8.0pt; font-family: 'Times New Roman', serif;">Todos los servicios que se detallan en la tabla anterior ser&aacute;n prestados exclusivamente por la empresa de asistencia American Assist Guatemala, S.A. (Addiuva) y llamando al n&uacute;mero 2306 1238.</span></p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="voucher voucher-footer">
                <button type="button" class="btn btn-primary" @click="printDocumento">Imprimir</button>
                <button type="button" class="btn btn-secondary" @click="showPrintDialog = false">Cerrar</button>
            </div>
        </div>
    </setting-layout>



</template>

<script>

import {mapActions, mapGetters} from "vuex";
import API from "/src/core/Api";
import {store} from "/src/store";
import Numbro from 'numbro';
import dayjs from "dayjs";

import settingLayout from "../../components/settings/settingLayout.vue";
import {
    ChoiceOption,
    FlowForm,
    LanguageModel,
    Question,
    QuestionModel,
    QuestionType,
    MatrixRow,
    MatrixColumn,
    LinkOption
} from "@ditdot-dev/vue-flow-form";

const automoviles = [
    new ChoiceOption({label: 'Automóvil',  value: 'Automóvil'}),
    new ChoiceOption({label: 'Motocicleta',  value: 'Motocicleta'}),
];
const NITs = [
    new ChoiceOption({label: 'CF'}),
];
const marcaM = [
    new ChoiceOption({label: 'Honda'}),
    new ChoiceOption({label: 'Yamaha'}),
    new ChoiceOption({label: 'Suzuki'}),
    new ChoiceOption({label: 'Kawasaki'}),
    new ChoiceOption({label: 'Bajaj'}),
    new ChoiceOption({label: 'TVS'}),
    new ChoiceOption({label: 'Hero'}),
    new ChoiceOption({label: 'Royal Enfield'}),
    new ChoiceOption({label: 'KTM'}),
    new ChoiceOption({label: 'Harley-Davidson'}),
    new ChoiceOption({label: 'Ducati'}),
    new ChoiceOption({label: 'BMW Motorrad'}),
    new ChoiceOption({label: 'Triumph'}),
    new ChoiceOption({label: 'Benelli'}),
    new ChoiceOption({label: 'Kymco'}),
    new ChoiceOption({label: 'Piaggio'}),
    new ChoiceOption({label: 'Vespa'}),
    new ChoiceOption({label: 'Aprilia'}),
    new ChoiceOption({label: 'Husqvarna'}),
    new ChoiceOption({label: 'Indian'}),
    new ChoiceOption({label: 'Moto Guzzi'}),
    new ChoiceOption({label: 'Zero Motorcycles'}),
    new ChoiceOption({label: 'Can-Am'}),
    new ChoiceOption({label: 'CFMoto'}),
    new ChoiceOption({label: 'SYM'}),
    new ChoiceOption({label: 'KSR Moto'}),
    new ChoiceOption({label: 'Gas Gas'}),
    new ChoiceOption({label: 'MV Agusta'}),
    new ChoiceOption({label: 'Husaberg'}),
    new ChoiceOption({label: 'Daelim'}),
    new ChoiceOption({label: 'Keeway'}),
    new ChoiceOption({label: 'AJP'}),
    new ChoiceOption({label: 'Lifan'}),
    new ChoiceOption({label: 'Mash'}),
    new ChoiceOption({label: 'Zontes'}),
    new ChoiceOption({label: 'Cagiva'}),
    new ChoiceOption({label: 'Norton'}),
    new ChoiceOption({label: 'CPI'}),
    new ChoiceOption({label: 'Rieju'}),
    new ChoiceOption({label: 'UM Motorcycles'}),
    new ChoiceOption({label: 'SWM'}),
    new ChoiceOption({label: 'SYM'}),
    new ChoiceOption({label: 'Oset'}),
    new ChoiceOption({label: 'Vyrus'}),
    new ChoiceOption({label: 'Sherco'}),
    new ChoiceOption({label: 'AJP'}),
    new ChoiceOption({label: 'Skyteam'}),
    new ChoiceOption({label: 'Aeon'}),
    new ChoiceOption({label: 'Bimota'}),
    new ChoiceOption({label: 'Brixton'}),
    new ChoiceOption({label: 'Cleveland CycleWerks'}),
    new ChoiceOption({label: 'FB Mondial'}),
    new ChoiceOption({label: 'Hyosung'}),
    new ChoiceOption({label: 'Keeway'}),
    new ChoiceOption({label: 'Motoguzzi'}),
    new ChoiceOption({label: 'Scomadi'}),
    new ChoiceOption({label: 'Tauris'}),
    new ChoiceOption({label: 'TGB'}),
    new ChoiceOption({label: 'Vectrix'}),
    new ChoiceOption({label: 'Zontes'}),
    new ChoiceOption({label: 'Otras'})

];


const marca = [
    new ChoiceOption({label: 'Toyota'}),
    new ChoiceOption({label: 'Honda'}),
    new ChoiceOption({label: 'Nissan'}),
    new ChoiceOption({label: 'Hyundai'}),
    new ChoiceOption({label: 'Kia'}),
    new ChoiceOption({label: 'Mitsubishi'}),
    new ChoiceOption({label: 'Mazda'}),
    new ChoiceOption({label: 'Volkswagen'}),
    new ChoiceOption({label: 'Chevrolet'}),
    new ChoiceOption({label: 'Ford'}),
    new ChoiceOption({label: 'Mercedes-Benz'}),
    new ChoiceOption({label: 'BMW'}),
    new ChoiceOption({label: 'Audi'}),
    new ChoiceOption({label: 'Subaru'}),
    new ChoiceOption({label: 'Lexus'}),
    new ChoiceOption({label: 'Land Rover'}),
    new ChoiceOption({label: 'Jeep'}),
    new ChoiceOption({label: 'Volvo'}),
    new ChoiceOption({label: 'Peugeot'}),
    new ChoiceOption({label: 'Suzuki'}),
    new ChoiceOption({label: 'Fiat'}),
    new ChoiceOption({label: 'Renault'}),
    new ChoiceOption({label: 'Chrysler'}),
    new ChoiceOption({label: 'Jaguar'}),
    new ChoiceOption({label: 'Maserati'}),
    new ChoiceOption({label: 'Porsche'}),
    new ChoiceOption({label: 'Isuzu'}),
    new ChoiceOption({label: 'Tesla'}),
    new ChoiceOption({label: 'Acura'}),
    new ChoiceOption({label: 'Alfa Romeo'}),
    new ChoiceOption({label: 'Bentley'}),
    new ChoiceOption({label: 'Bugatti'}),
    new ChoiceOption({label: 'Cadillac'}),
    new ChoiceOption({label: 'Dodge'}),
    new ChoiceOption({label: 'Ferrari'}),
    new ChoiceOption({label: 'Genesis'}),
    new ChoiceOption({label: 'Infiniti'}),
    new ChoiceOption({label: 'Lamborghini'}),
    new ChoiceOption({label: 'Lincoln'}),
    new ChoiceOption({label: 'Lotus'}),
    new ChoiceOption({label: 'McLaren'}),
    new ChoiceOption({label: 'Mini'}),
    new ChoiceOption({label: 'Pagani'}),
    new ChoiceOption({label: 'Rolls-Royce'}),
    new ChoiceOption({label: 'Smart'}),
    new ChoiceOption({label: 'Tata Motors'}),
    new ChoiceOption({label: 'Zenvo'})
];

const year = [];
for (let i = 2024; i >= 1990; i--) {
    year.push(new ChoiceOption({label: ''+i+''}));
}

const ciudades = [
    new ChoiceOption({label: 'CHAMPERICO'}),
    new ChoiceOption({label: 'CHIMALTENANGO'}),
    new ChoiceOption({label: 'CHIQUIMULA'}),
    new ChoiceOption({label: 'CHIQUIMULILLA'}),
    new ChoiceOption({label: 'CHISEC'}),
    new ChoiceOption({label: 'COATEPEQUE'}),
    new ChoiceOption({label: 'COBAN'}),
    new ChoiceOption({label: 'CUILAPA'}),
    new ChoiceOption({label: 'CUILCO'}),
    new ChoiceOption({label: 'ESTANZUELA'}),
    new ChoiceOption({label: 'FRAY BARTOLOME'}),
    new ChoiceOption({label: 'GUATEMALA ZONA 10'}),
    new ChoiceOption({label: 'HUEHUETENANGO'}),
    new ChoiceOption({label: 'IXCAN'}),
    new ChoiceOption({label: 'IXCHIGUAN'}),
    new ChoiceOption({label: 'JACALTENANGO'}),
    new ChoiceOption({label: 'JALAPA'}),
    new ChoiceOption({label: 'JALPATAGUA'}),
    new ChoiceOption({label: 'JUTIAPA'}),
    new ChoiceOption({label: 'LA DEMOCRACIA'}),
    new ChoiceOption({label: 'LA REFORMA'}),
    new ChoiceOption({label: 'LA TINTA'}),
    new ChoiceOption({label: 'LIVINGSTON'}),
    new ChoiceOption({label: 'MALACATAN'}),
    new ChoiceOption({label: 'MAZATENANGO'}),
    new ChoiceOption({label: 'MELCHOR DE MENCOS'}),
    new ChoiceOption({label: 'MONTERrico'}),
    new ChoiceOption({label: 'MORALES'}),
    new ChoiceOption({label: 'MORAZAN'}),
    new ChoiceOption({label: 'NUEVA CONCEPCION'}),
    new ChoiceOption({label: 'NUEVA SANTA ROSA'}),
    new ChoiceOption({label: 'PATULUL'}),
    new ChoiceOption({label: 'POPTUN'}),
    new ChoiceOption({label: 'QUETZALTENANGO'}),
    new ChoiceOption({label: 'RETALHULEU'}),
    new ChoiceOption({label: 'SAN AGUSTIN ACASAGUASTLAN'}),
    new ChoiceOption({label: 'SAN ANTONIO SUCHITEPEQUEZ'}),
    new ChoiceOption({label: 'SAN CRISTOBAL ACASAGUASTLAN'}),
    new ChoiceOption({label: 'SAN MARCOS'}),
    new ChoiceOption({label: 'SAN MIGUEL IXTAHUACAN'}),
    new ChoiceOption({label: 'SANARATE'}),
    new ChoiceOption({label: 'SANTA CRUZ BARILLAS'}),
    new ChoiceOption({label: 'SANTA CRUZ DEL QUICHE'}),
    new ChoiceOption({label: 'SANTA ELENA, PETEN'}),
    new ChoiceOption({label: 'SAYAXCHE'}),
    new ChoiceOption({label: 'SIBILIA'}),
    new ChoiceOption({label: 'SOLOLA'}),
    new ChoiceOption({label: 'SOLOMA'}),
    new ChoiceOption({label: 'TECULUTAN'}),
    new ChoiceOption({label: 'TECUN UMAN'})
];

const agentes = [
    new ChoiceOption({ label: 'ABNER SAMUEL PUAC' }),
    new ChoiceOption({ label: 'ALEJANDRA MARIBEL COMPA ORELLANA' }),
    new ChoiceOption({ label: 'ALEX EMILIO GARCIA HUINAC' }),
    new ChoiceOption({ label: 'ALEX VIRGILIO BARRILLAS FAJARDO' }),
    new ChoiceOption({ label: 'ALLISON SOFIA ESQUIVEL MENENDEZ' }),
    new ChoiceOption({ label: 'ANA BEATRIZ ALDANA VASQUEZ' }),
    new ChoiceOption({ label: 'ANA GABRIELA LOPEZ GOMEZ' }),
    new ChoiceOption({ label: 'ANA PATRICIA MERIDA VELIZ' }),
    new ChoiceOption({ label: 'ANA SARAI FONG FUENTES ' }),
    new ChoiceOption({ label: 'ANDREA CATARINA CRUZ MONTEJO' }),
    new ChoiceOption({ label: 'ANDREINA ADELIA CALVAC GARCIA' }),
    new ChoiceOption({ label: 'AXEL EFREN FLORES LOPEZ' }),
    new ChoiceOption({ label: 'BERNARDO NICOLAS VASQUEZ PUAC' }),
    new ChoiceOption({ label: 'BIANDY MELISSA RUIZ DE LEON' }),
    new ChoiceOption({ label: 'BIRMAN RAFAEL RUIZ AGUILAR' }),
    new ChoiceOption({ label: 'BORIS NOLBERTO TRUJILLO' }),
    new ChoiceOption({ label: 'BRANDON ALEXANDER ALVARADO VILLATORO' }),
    new ChoiceOption({ label: 'BRENDA LISBETH PEREZ GOMEZ' }),
    new ChoiceOption({ label: 'CARLOS FERNANDO FLORES LEMUS' }),
    new ChoiceOption({ label: 'CARMEN CECILIA CRUZ CETINO' }),
    new ChoiceOption({ label: 'CARMEN DINORA ESCOBAR VASQUEZ' }),
    new ChoiceOption({ label: 'CARMEN GABRIELA HERRERA MOYA' }),
    new ChoiceOption({ label: 'CARMEN PETRONILA ANTONIO SAMINEZ' }),
    new ChoiceOption({ label: 'CATY NOEMI TOT VELASQUEZ' }),
    new ChoiceOption({ label: 'CESAR AGUSTO BATEN PEREZ' }),
    new ChoiceOption({ label: 'CLAUDIA JUDITH SANTIZO MALDONADO' }),
    new ChoiceOption({ label: 'CRISMY GRICELDA OCHAETA' }),
    new ChoiceOption({ label: 'CRISTIAN JOSE FERNANDO DEL VALLE DE LEON' }),
    new ChoiceOption({ label: 'CRISTINA LIDIA JULAJ' }),
    new ChoiceOption({ label: 'DARLY MERARI SALAZAR TEJADA' }),
    new ChoiceOption({ label: 'DAYANA MICHELL ARITA GONZALEZ' }),
    new ChoiceOption({ label: 'DEVORA IDIDA CATUN LAINES' }),
    new ChoiceOption({ label: 'EDGAR IVAN CORDON RAMIREZ' }),
    new ChoiceOption({ label: 'EDGAR PAUL RABANALES' }),
    new ChoiceOption({ label: 'EDILSAR ONELIO ESCALANTE PEREZ' }),
    new ChoiceOption({ label: 'EDINSON LEONEL HERNANDEZ MOLINA' }),
    new ChoiceOption({ label: 'EDVIN MARDOQUEO PEREZ JUC' }),
    new ChoiceOption({ label: 'EDWIN AUGUSTO MENDEZ FALLAS' }),
    new ChoiceOption({ label: 'ELDER ESTUARDO DUQUE ALARCON' }),
    new ChoiceOption({ label: 'ELMER LUIS ALEXANDER PANA JUC' }),
    new ChoiceOption({ label: 'EDITH YESENIA MORALES ALVARADO' }),
    new ChoiceOption({ label: 'EVELYN EDITH MANSILLA QUEZADA' }),
    new ChoiceOption({ label: 'FLOR DE MARIA GARCÍA JORDÁN' }),
    new ChoiceOption({ label: 'ENNA GABRIELA MELGAR DONIS' }),
    new ChoiceOption({ label: 'ERICK ANTONIO QUEVEDO MARTINEZ' }),
    new ChoiceOption({ label: 'ESLIE VANELY PADILLA SAGASTUME' }),
    new ChoiceOption({ label: 'ESTUARDO ROCAEL CHIVALAN CAAL' }),
    new ChoiceOption({ label: 'ESVIN ALBERTO CAYAX TEZO' }),
    new ChoiceOption({ label: 'EVELYN CLEOTILDE HERNANDEZ FLORIAN' }),
    new ChoiceOption({ label: 'EVELYN JULISSA VELASQUEZ CARDONA' }),
    new ChoiceOption({ label: 'EYBORY CELESTE VASQUEZ LOPEZ' }),
    new ChoiceOption({ label: 'EZEQUIEL TAY TZOY ' }),
    new ChoiceOption({ label: 'FLOR DE MARIA POCASANGRE DEL CID' }),
    new ChoiceOption({ label: 'GERBER JOSSUE REYNOSO GAMBOA' }),
    new ChoiceOption({ label: 'GERSON JOSUE OSORIO JUAREZ' }),
    new ChoiceOption({ label: 'GIANINA LILETH GARCIA MERIDA' }),
    new ChoiceOption({ label: 'GLADYS BERANIS DE LEON SIL' }),
    new ChoiceOption({ label: 'GREYSI VANESSA LOPEZ LOPEZ' }),
    new ChoiceOption({ label: 'HEBER ISMAEL OROZCO FUENTES' }),
    new ChoiceOption({ label: 'HEBER MARDOQUEO SON PAZ' }),
    new ChoiceOption({ label: 'HEIDY CHITIC MORALES' }),
    new ChoiceOption({ label: 'HELEN VANESA ARANA DE GOMEZ' }),
    new ChoiceOption({ label: 'HENRY ALEXANDER CALI CHALI' }),
    new ChoiceOption({ label: 'HENRY FERNANDO MITUN SUNUN' }),
    new ChoiceOption({ label: 'HERLINDA REYES HERNANDEZ' }),
    new ChoiceOption({ label: 'HUGO DANIEL COLORADO HERNANDEZ' }),
    new ChoiceOption({ label: 'INGRID NOHEMI SONTAY CUYUCH' }),
    new ChoiceOption({ label: 'ISIS MARIA DE LOS ANGELES RIVERA BARILLAS' }),
    new ChoiceOption({ label: 'JANSY GABRIELA TUNCHEZ MUÑOZ' }),
    new ChoiceOption({ label: 'JAVIER FRANCISCO MORALES RODRIGUEZ' }),
    new ChoiceOption({ label: 'JEORGINA JUDITH PIRIR BLANCO' }),
    new ChoiceOption({ label: 'JONATHAN GAMALIEL CUC BOL' }),
    new ChoiceOption({ label: 'JORGE ORLANDO OROZCO BARRIOS' }),
    new ChoiceOption({ label: 'JOSE ALBERTO RIVERA AJ' }),
    new ChoiceOption({ label: 'JOSE LUIS ROMERO CORONADO' }),
    new ChoiceOption({ label: 'JOSE MIGUEL TORRES RODRIGUEZ' }),
    new ChoiceOption({ label: 'JOSE MIGUEL YES FLORIAN' }),
    new ChoiceOption({ label: 'JOSELINE ITZEL FLORES ELIAS' }),
    new ChoiceOption({ label: 'JOSELINE RECINOS FRANCO' }),
    new ChoiceOption({ label: 'JOSSELYN FERNANDA MORALES LARIOS' }),
    new ChoiceOption({ label: 'JUAN CARLOS GARCIA HUINAC' }),
    new ChoiceOption({ label: 'JUAN JOSE BAUTISTA MACZ' }),
    new ChoiceOption({ label: 'JUAN NOLBERTO MEJIA REYNOSO' }),
    new ChoiceOption({ label: 'KARLA EDITH SALAZAR PEREZ' }),
    new ChoiceOption({ label: 'KARLA NEREIRA HERNANDEZ MARTINEZ' }),
    new ChoiceOption({ label: 'KARLA VIVIANA PADILLA VIELMAN' }),
    new ChoiceOption({ label: 'KATHERINE NINETH FRANCO GAMEZ' }),
    new ChoiceOption({ label: 'KATIRES GRISELDA SIS JUAREZ' }),
    new ChoiceOption({ label: 'KEMBERLY ALICIA CARDONA MENDEZ' }),
    new ChoiceOption({ label: 'KEVIN FABRICIO LOPEZ GONZALEZ' }),
    new ChoiceOption({ label: 'KIMBERLY ALEJANDRA ORTEGA GUTIERREZ' }),
    new ChoiceOption({ label: 'KIMBERLY JULISSA GALVEZ GALVEZ' }),
    new ChoiceOption({ label: 'KINVERLIN MEDARIS MENDEZ SALGUERO' }),
    new ChoiceOption({ label: 'LEIDI ABIGAIL RODRIGUEZ' }),
    new ChoiceOption({ label: 'LEONARDO MARCO CORDOVA GARCIA' }),
    new ChoiceOption({ label: 'LESLY MARIANE MEZA CASTELLANOS' }),
    new ChoiceOption({ label: 'LESLY ORALIA MOYA LEAL' }),
    new ChoiceOption({ label: 'LESLY SORAYA LEMUS CHINCHILLA' }),
    new ChoiceOption({ label: 'LETICIA MARULANDA SANCHEZ OROZCO' }),
    new ChoiceOption({ label: 'LEYDIN ILIANA JOR FIGUEROA' }),
    new ChoiceOption({ label: 'LISBET CELESTE BARRIOS RUANO' }),
    new ChoiceOption({ label: 'LIZBETH ALEJANDRA ORTEGA COMAPA' }),
    new ChoiceOption({ label: 'LUDVIN ANTONIO CAPIR TUBAC' }),
    new ChoiceOption({ label: 'LUIS EDILZAR JOSE FERNANDEZ' }),
    new ChoiceOption({ label: 'LUIS FERNANDO MEJIA NAZARIO' }),
    new ChoiceOption({ label: 'MADARY CRISTINA GONZALEZ SANTIAGO' }),
    new ChoiceOption({ label: 'MADELIN VERONICA CRUZ CASTAÑEDA' }),
    new ChoiceOption({ label: 'MARCO ARNOLDO SOSA DE LEON' }),
    new ChoiceOption({ label: 'MARIA IZABEL ALDANA RODRIGUEZ' }),
    new ChoiceOption({ label: 'MARIELA TERESA GATICA VALIENTE' }),
    new ChoiceOption({ label: 'MARIO OMAR JUAREZ MERLO' }),
    new ChoiceOption({ label: 'MARLEN ADALI GARCIA ESCOBAR' }),
    new ChoiceOption({ label: 'MARLON ANDRE CASTRO LORENZO' }),
    new ChoiceOption({ label: 'MAYBELLIN ROXIBEL ALVAREZ MUÑOZ' }),
    new ChoiceOption({ label: 'MAYELI IBETH BELTETON HERNANDEZ' }),
    new ChoiceOption({ label: 'MELQUISEDEC GARCIA RECINOS' }),
    new ChoiceOption({ label: 'MERLIN ROSALBA PEREZ JUAREZ' }),
    new ChoiceOption({ label: 'MEYLIN DANIELA CASTILLO GONZALEZ' }),
    new ChoiceOption({ label: 'MILDRED YANET MAZARIEGOS DIAZ' }),
    new ChoiceOption({ label: 'MILENY YANIRA QUIÑONEZ YANES' }),
    new ChoiceOption({ label: 'MIRIAN DALILA ALBEÑO SALAZAR' }),
    new ChoiceOption({ label: 'MIRLA ARACELY RODRIGUEZ LOPEZ' }),
    new ChoiceOption({ label: 'NAYDELIN GUADALUPE VICENTE BARRIOS' }),
    new ChoiceOption({ label: 'NEIDY MICHEL FLORES CORDOVA DE REYES' }),
    new ChoiceOption({ label: 'NELSON DAVID HERNANDEZ LOPEZ' }),
    new ChoiceOption({ label: 'NERY ALEJANDRO ICAL MAQUIN' }),
    new ChoiceOption({ label: 'NERY ARISTIDES VILLATORO BAMACA' }),
    new ChoiceOption({ label: 'NIDIA NOHEMI PINEDA GUTIERREZ' }),
    new ChoiceOption({ label: 'NIDIA YAMILETH CORTEZ LOPEZ' }),
    new ChoiceOption({ label: 'NOELIA PETRONILA PEREZ GUTIERREZ' }),
    new ChoiceOption({ label: 'NORMA ELIZABETH SAC ALVARADO' }),
    new ChoiceOption({ label: 'ODILIA BERTA ALICIA HERRERA CASTAÑEDA' }),
    new ChoiceOption({ label: 'OLGA EDILMA ESPERANZA PECH GARCIA' }),
    new ChoiceOption({ label: 'OLGA NOHEMI GALLARDO MERLOS' }),
    new ChoiceOption({ label: 'OLMA JANETH RIVERA LOPEZ' }),
    new ChoiceOption({ label: 'OLVI MIRSA FUENTES GUZMAN' }),
    new ChoiceOption({ label: 'OSCAR ANTONIO REQUENA DE PAZ' }),
    new ChoiceOption({ label: 'OSCAR OMAR VASQUEZ RODRIGUEZ' }),
    new ChoiceOption({ label: 'OTTO FERNANDO TUX CHE' }),
    new ChoiceOption({ label: 'PABLO ELIAS LAYNEZ JAX' }),
    new ChoiceOption({ label: 'PAULA BEATRIZ XOYON XICAY' }),
    new ChoiceOption({ label: 'PRISCILA VASQUEZ GONZALEZ' }),
    new ChoiceOption({ label: 'PRUDENCIO RUIZ CETINO' }),
    new ChoiceOption({ label: 'RAUL ALEJANDRO CHAVEZ HERNANDEZ' }),
    new ChoiceOption({ label: 'ROBERTO GENARO DIAZ ZEISSSIG' }),
    new ChoiceOption({ label: 'ROCIO IVONNE DIAZ DIAZ' }),
    new ChoiceOption({ label: 'RONALDO ALEJANDRO GRAJEDA CANA' }),
    new ChoiceOption({ label: 'ROSARIO EUGENIA JACOME FELIPE' }),
    new ChoiceOption({ label: 'RUBI JUDITH GOMEZ CINTO' }),
    new ChoiceOption({ label: 'SAMMY KENNEDY MAXIA RODRIGUEZ' }),
    new ChoiceOption({ label: 'SANDRA FELICIANA GONZALEZ GOMEZ' }),
    new ChoiceOption({ label: 'SEBASTIAN MORALES MEJIA' }),
    new ChoiceOption({ label: 'SINAKAN BOL MACZ' }),
    new ChoiceOption({ label: 'SULMY ESCOBAR CRUZ' }),
    new ChoiceOption({ label: 'TELMA MALDONADO MENDEZ' }),
    new ChoiceOption({ label: 'VILMA ALICIA HERNANDEZ ISCAYA' }),
    new ChoiceOption({ label: 'WENDY CAROLINA MOLINA ALVAREZ' }),
    new ChoiceOption({ label: 'YEISI MARIA VASQUEZ GOMEZ' }),
    new ChoiceOption({ label: 'YESENIA SUMAYA ZAMORA ZACARIAS' }),
    new ChoiceOption({ label: 'YESICA ROXANA BARRIOS HERNANDEZ' }),
    new ChoiceOption({ label: 'YOSELIN MAIBELY NAJERA PERALTA' }),
    new ChoiceOption({ label: 'YOSSELYN YAMILETH ZUNUN URIZAR' }),
    new ChoiceOption({ label: 'YUNIOR ARMANDO PABLO LOPEZ' }),
    new ChoiceOption({ label: 'Bernardo Nicolás Vásquez Puac' }),
    new ChoiceOption({ label: 'Claudia Judith Santizo Maldonado' }),
    new ChoiceOption({ label: 'Olga Edilma Esperanza Pech García' })

];

const colores = [
    new ChoiceOption({label: 'Blanco'}),
    new ChoiceOption({label: 'Negro'}),
    new ChoiceOption({label: 'Gris'}),
    new ChoiceOption({label: 'Plateado'}),
    new ChoiceOption({label: 'Azul'}),
    new ChoiceOption({label: 'Rojo'}),
    new ChoiceOption({label: 'Verde'}),
    new ChoiceOption({label: 'Beige'}),
    new ChoiceOption({label: 'Marrón'}),
    new ChoiceOption({label: 'Amarillo'}),
    new ChoiceOption({label: 'Naranja'}),
    new ChoiceOption({label: 'Morado'}),
    new ChoiceOption({label: 'Rosa'}),
    new ChoiceOption({label: 'Turquesa'}),
    new ChoiceOption({label: 'Bronce'}),
    new ChoiceOption({label: 'Plata'}),
    new ChoiceOption({label: 'Oro'}),
    new ChoiceOption({label: 'Granate'}),
    new ChoiceOption({label: 'Cobre'}),
    new ChoiceOption({label: 'Champán'}),
    new ChoiceOption({label: 'Azul marino'}),
    new ChoiceOption({label: 'Verde oscuro'}),
    new ChoiceOption({label: 'Gris oscuro'}),
    new ChoiceOption({label: 'Rojo cereza'}),
    new ChoiceOption({label: 'Violeta'}),
    new ChoiceOption({label: 'Turquesa'}),
    new ChoiceOption({label: 'Marfil'}),
    new ChoiceOption({label: 'Carmesí'}),
    new ChoiceOption({label: 'Salmon'}),
    new ChoiceOption({label: 'Púrpura'}),
    new ChoiceOption({label: 'Gris claro'}),
    new ChoiceOption({label: 'Gris plateado'}),
    new ChoiceOption({label: 'Azul cielo'}),
    new ChoiceOption({label: 'Azul eléctrico'}),
    new ChoiceOption({label: 'Azul zafiro'}),
    new ChoiceOption({label: 'Rojo rubí'}),
    new ChoiceOption({label: 'Rojo vino'}),
    new ChoiceOption({label: 'Verde esmeralda'}),
    new ChoiceOption({label: 'Verde lima'}),
    new ChoiceOption({label: 'Verde oliva'}),
    new ChoiceOption({label: 'Marrón claro'}),
    new ChoiceOption({label: 'Marrón oscuro'}),
    new ChoiceOption({label: 'Amarillo mostaza'}),
    new ChoiceOption({label: 'Amarillo dorado'}),
    new ChoiceOption({label: 'Naranja quemado'}),
    new ChoiceOption({label: 'Naranja brillante'}),
    new ChoiceOption({label: 'Rosado claro'}),
    new ChoiceOption({label: 'Rosado oscuro'}),
    new ChoiceOption({label: 'Gris pardo'}),
    new ChoiceOption({label: 'Gris acero'}),
    new ChoiceOption({label: 'Gris pizarra'}),
];




export default {
    name: 'ServiceDetails',
    components: {
        settingLayout,
        FlowForm,
        Question,
        QuestionType,
        LanguageModel
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
            Access: 'Access',
        })
    },
    async created(){
        const self = this;
        try {

            API.send('POST', 'products/public/search/'+self.AuthGetUserInfo.s+'/'+ self.id, {
                    filterTermBy: 'id',
                },
                function (response) {
                    if (response.status && typeof response.data.products[0] !== 'undefined'&& typeof response.data.products[0].variaciones !== 'undefined') {
                        const variaciones = response.data.products[0].variaciones;
                        console.log(variaciones);

                        const planes = [];
                        if (variaciones) {
                            variaciones.forEach(variacion => {
                                const label = `${variacion.descripcion} (Q ${variacion.precio})`;
                                const value = variacion.descripcion.toLowerCase();
                                const id = variacion.id;
                                const precio = variacion.precio;
                                const diasRecurrencia = variacion.diasRecurrencia;

                                const choiceOption = new ChoiceOption({label, value, id, precio, diasRecurrencia});
                                planes.push(choiceOption);
                            });
                        }
                        self.questions = [
                            new QuestionModel({
                                id: 'Plan',
                                answer: '',
                                tagline: '',
                                title: 'Seleccione el plan',
                                type: QuestionType.MultipleChoice,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: planes,
                            }),
                            new QuestionModel({
                                id: 'clienteNombre',
                                tagline: '',
                                title: 'Nombre completo',
                                type: QuestionType.Text,
                                required: true,
                                answer: self.formularioData.clienteNombre,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                id: 'expediente',
                                tagline: "",
                                title: 'NIS/Exp',
                                type: QuestionType.Text,
                                required: true,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                tagline: '',
                                id: 'DPI',
                                title: 'Ingresa DPI',
                                mask: '#### ##### ####',
                                type: QuestionType.Text,
                                required: true,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                tagline: 'Ingresa NIT sin guión...',
                                id: 'clienteTaxId',
                                title: '¿A quién emitimos la factura?',
                                type: QuestionType.MultipleChoice,
                                options: NITs,
                                required: true,
                                mask: '#######XX',
                                allowOther: true
                            }),
                            new QuestionModel({
                                id: 'clienteEmail',
                                tagline: "",
                                title: '¿Correo electrónico?',
                                type: QuestionType.Email,
                                required: false,
                                placeholder: 'Escribe aquí...'
                            }),
                            new QuestionModel({
                                id: 'clienteTelefono',
                                tagline: "",
                                title: 'Teléfono',
                                type: QuestionType.Phone,
                                answer: self.formularioData.clienteTelefono,
                                required: true,
                                mask: '####-####'
                            }),
                            new QuestionModel({
                                id: 'Tipo',
                                answer: self.formularioData.Tipo,
                                tagline: '',
                                title: '¿Tipo de vehículo?',
                                type: QuestionType.MultipleChoice,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: true,
                                required: true,
                                allowOther: false,
                                options: automoviles,
                                jump: {
                                    Motocicleta: 'placa_b',
                                }
                            }),
                            new QuestionModel({
                                id: 'placa_a',
                                tagline: '',
                                title: 'Placas del vehículo',
                                type: QuestionType.Text,
                                required: true,
                                answer:self.formularioData.placa_a || 'P-',
                                mask: 'X-##XXXX',
                                jump: {
                                    _other: 'linea',
                                }
                            }),
                            new QuestionModel({
                                id: 'placa_b',
                                tagline: '',
                                title: 'Placas del vehículo',
                                type: QuestionType.Text,
                                mask: 'X-##XXXX',
                                required: true,
                                answer: self.formularioData.placa_b || 'M-',
                            }),
                            new QuestionModel({
                                id: 'lineaM',
                                answer: self.formularioData.lineaM,
                                tagline: '',
                                title: '¿Marca de la Motocicleta?',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: marcaM,
                                jump: {
                                    _other: 'color',
                                }
                            }),
                            new QuestionModel({
                                id: 'linea',
                                answer: self.formularioData.linea,
                                tagline: '',
                                title: '¿Marca del vehículo?',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: marca,
                            }),
                            new QuestionModel({
                                id: 'color',
                                answer: self.formularioData.color,
                                tagline: '',
                                title: 'Color del vehículo',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: colores

                            }),
                            new QuestionModel({
                                id: 'year',
                                answer: self.formularioData.year,
                                tagline: '',
                                title: '¿Año del vehículo?',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: year,
                            }),
                            new QuestionModel({
                                id: 'agencia',
                                answer: '',
                                tagline: '',
                                title: 'Agencia',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: ciudades,
                            }),
                            new QuestionModel({
                                id: 'agentes',
                                answer: '',
                                tagline: '',
                                title: 'Agente',
                                type: QuestionType.Dropdown,
                                multiple: false,
                                placeholder: 'selecciona uno',
                                inline: false,
                                required: true,
                                allowOther: false,
                                options: agentes
                            }),
                        ];
                        self.questionsLoaded = true;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        } catch (error) {
            API.showErrorNotify(error.msg);
        }
    },
    watch: {
        date: function (value) {
            if (value) {
                this.getDisponibilidad();
                this.hideDateSelector = true;
            }
        },
        municipioLocationCobertura: function (value) {
            if (value) {
                this.getDisponibilidad();
            }
        },
        locacion: function () {
            this.horarioSelected = '';
            this.selectPersonal();
        },
        personal: function () {
            this.horarioSelected = '';
            this.selectPersonal();
        },
    },
    data() {
        return {
            suscriptionList: {},
            searchTerm: '',
            itemFacturar: '',
            voucherPago: '',
            invoiceBlob: '',
            questionsLoaded: false,
            logoTienda: '',
            step: 'agendamiento',
            id: this.$route.params.id,
            categories: {},
            isActive: 'coti',
            fechaFutura:'',
            forceUpdate: false,
            active: false,
            show: false,
            showPrintDialog: false,
            showFacturaDialog: false,
            product: {},
            category: 'all',
            center: {lat: 15.783471, lng: -90.23075899999999},
            zoomData: 4,
            showModal: false,
            theme: 'a257512d80524267',
            type: 'JavaScript – Vector',
            locations: [],
            locacionId: 0,
            filterLocations: [],
            formularioData: {
                clienteDireccion: "Ciudad",
                clienteEmail: "",
                clienteNombre: "",
                ordenMonto: "",
                facturaEmitir: 0,
                clienteTaxId: "",
                clienteTelefono: "",
                ordenDetalle: {
                    0: {
                        productoCantidad: 1,
                        productoId: 0,
                        productoPrecio: 0.5,
                        productoDescMontoDirecto: "",
                        productoVId: 0
                    }
                },
                ordenId: 0,
                ordenTiendaDomain: '',
                pagoMetodo: "cotizacion",
                pagoTcCvv: "",
                pagoTcExp: "",
                pagoTcNombre: "",
                pagoTcNumero: "",
                pagoCuotas: 0,
                tipoVehiculo: '',
            },
            placas: '',
            productoRecurrente: false,
            modalidadCompra: 'recurrente',
            maxToBuy: 0,
            quantity: 1,
            totalAmount: 0,
            galleryItemActive: 0,
            language: new LanguageModel({
                ok: 'Siguiente',
                percentCompleted: ':percent% completado',
                ariaTypeAnswer: ':Escribe aquí',
                invalidPrompt: 'Por favor complete el campo correctamente',
                ariaMultipleChoice: 'Presione :letter para seleccionar',
                ariaSubmitText: 'Presione para enviar',
                longTextHelpText: ':shiftKey + :enterKey Si quieres escribir más de una línea',
                continue: 'Continuar',
                multipleChoiceHelpTextSingle: 'Seleccione una respuesta',
                pressEnter: 'presione Enter',
                otherPrompt: 'Ingresar NIT',
                submitText: 'Enviar',
                thankYouText: 'Listo, hemos terminado',
                skip: 'No tiene',
                successText: 'Tu formulario ha sido enviado'
            }),
            questions: [],
            showFormLead: false,
            dataExtra: [],
            cartNotice: {
                status: false,
                msg: '',
                codigoOrden: '',
                metodosPago: '',
                auth: '',
                reference: '',
                processor: '',
                fechaPago: '',
            },
            // envio
            paisEnvio: 0,
            deptoEnvio: 0,
            municipioEnvio: 0,
            direccionCompletaEnvio: "",
            comentarioEnvio: "",
            direccionEnvioText: "",
            direccionEnvioSelected: false,
            envioShowPicker: false,
            bodegasEnvio: {},
            shippingRates: {},
            selectedShippingRates: {},
            disponibilidad: {},
            horarios: {},
            date: new Date(),
            time: '',

            orden: {},
            isFastPayProduct: false,
            isPayLink: false,
            token: false,
            globalError: '',
            //step: 'agenda',
            productsInOrderCustomAmount: {},
            productsInCart: {},

            // tipos de producto para cambiar asistente
            productsType: {
                agendable: false,
                agendableDomicilio: false,
                agendablePresencial: false,
                normal: false,
                virtual: false,
                envio: false,
            },

            // montos
            subtotal: 0,
            totalShipping: 0,
            total: 0,

            // paso carrito

            // paso ubicacion
            autoLocationUsed: false,
            enterLocation: false,
            geolocationData: {},
            paises: {},
            deptos: {},
            municipios: {},
            locacionSelected: {},

            // Validación de coberturas
            paisLocationCobertura: 0,
            deptoLocationCobertura: 0,
            municipioLocationCobertura: 0,
            ubicacionSelectedCobertura: false,
            locationTextCobertura: "",


            // facturación
            direccionFacturaSelected: false,
            direccionFacturaText: "",

            // disponibilidad, horarios y locaciones
            horariosCitas: {},

            // form de pago
            formDataPay: {
                cardName: '',
                cardNumber: '',
                cardMonth: '',
                cardYear: '',
                cardCvv: '',
                cardCuotas: 0,
            },

            // Pago y proceso
            ordenCreadaId: 0,
            payRedirectionData: "none",

            //Calendario
            hideDateSelector: false,
            showDataTimePicker: false,
            datePickerParams: [
                {
                    dot: true,
                    key: 'today',
                    highlight: false,

                },
            ],
            locacion: 0,
            personal: 0,
            personalSelected: 0,
            locacionName: '',
            horarioSelected: ''
        }
    },
    methods: {
        Numbro,
        dayjs,

        continueAfterCart() {
            location.reload();
        },
        calcularFechas(dias) {
            const fechaActual = dayjs();
            const fechaFutura = fechaActual.add(dias, 'day');
            this.fechaFutura = fechaFutura.format('YYYY-MM-DD');
            //console.log(this.fechaFutura);
        },
        getProduct(idProducto) {

            const self = this;

            // obtengo lsa categorías
            API.send('POST', 'products/public/search/'+self.AuthGetUserInfo.s+'/' + idProducto, {
                    filterTermBy: 'id',
                },
                function (response) {
                    if (response.status && typeof response.data.products[0] !== 'undefined') {
                        self.product = response.data.products[0];
                        self.product = response.data.products[0];
                        self.formularioData.ordenTiendaDomain = self.AuthGetUserInfo.s;
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        getCategories(category) {
            const self = this;
            let endpoint;
            endpoint = 'products/public/search/'+self.AuthGetUserInfo.s+'/category/' + category;

            // obtengo las categorías
            //API.send('GET', 'products/public/brand-by-slug/'+store.getters.StoreInfo.uriTienda+'/category/all', {},
            API.send('GET', endpoint, {},
                function (response) {
                    if (response.status) {
                        self.categories = response.data.filter(
                            (item) => item.conteoProductos === 1
                        );
                        //self.$store.dispatch('CategoryListSave', response.data)
                        //self.getProducts();
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        onSubmit() {
            const self = this;
            API.send('POST', 'payments/create-pay-order', self.formularioData,
                function (response) {
                    if (typeof response.data !== 'undefined') {
                        self.cartNotice.status = 1;
                        self.cartNotice.msg = response.msg;
                        self.cartNotice.codigoOrden = response.data.subs[0].codigo || '';
                        self.cartNotice.fechaPago = response.data.subs[0].siguienteFechaPago || '';
                        self.cartNotice.metodoPago = 'Cotización';
                        self.ordenCreadaId = response.data.ordenId;
                        self.language.successText = 'Tu subscripción creada es ' + self.cartNotice.codigoOrden + ' Se está generando la factura...';
                        API.showSuccessNotify(response.msg);

                        // Asegura que self.cartNotice.codigoOrden esté completo antes de llamar a printDocumento
                        if (self.cartNotice.codigoOrden) {
                            // Usa $nextTick para asegurarte de que la interfaz se haya actualizado antes de llamar a printDocumento
                            self.$nextTick(function() {
                                self.printDocumento();
                                self.createInvoice();
                            });
                        } else {
                            console.error('codigoOrden no está completo, no se puede llamar a printDocumento');
                        }
                    }
                    else {
                        API.showErrorAlert('Error al crear cotización, por favor, intente de nuevo');
                    }
                },
                function (response) {
                    if (typeof response.data.ordenId !== 'undefined') {
                        self.ordenCreadaId = response.data.ordenId;
                    }

                    if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                        API.showErrorAlert(response.data.extra.error);
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                });
            //this.formularioData.ordenDetalle[0].productoId = this.product.id;
            //console.log(questionList);
            //this.$router.push('/cart');
        },
        onStep(step) {

        },
        printDocumento() {
            const elementoImprimir = document.getElementById('divImprimir');

            if (!elementoImprimir) {
                console.error("Elemento para imprimir no encontrado.");
                return;
            }

            // Guardar el estado actual de v-show y ocultar el elemento
            const vshowState = elementoImprimir.style.display;
            elementoImprimir.style.display = 'block';

            // Extraer el HTML del elemento
            const contenidoHTML = elementoImprimir.innerHTML;

            // Restaurar el estado original de v-show
            elementoImprimir.style.display = vshowState;

            // Intentar crear un nuevo documento para imprimir
            try {
                const ventanaImpresion = window.open('', '_blank');
                if (!ventanaImpresion) {
                    throw new Error("No se pudo crear la ventana de impresión.");
                }

                ventanaImpresion.document.open();
                ventanaImpresion.document.write(`
                    <!DOCTYPE html>
                    <html>
                        <head>
                            <title>Impresión</title>
                        </head>
                        <body>
                            ${contenidoHTML}
                        </body>
                    </html>
                `);
                ventanaImpresion.document.close();

                // Esperar a que el nuevo documento se cargue antes de imprimir
                ventanaImpresion.onload = function() {
                    ventanaImpresion.print();
                };
            } catch (error) {
                console.error("Error al imprimir:", error);
            }
        },

        onAnswer(questionAnswered) {
            const self = this;
            if(questionAnswered.options){
                const filteredOptions = questionAnswered.options.filter(option => option.selected === true);
                const hasIdAndPrecio = filteredOptions.some(option => option.hasOwnProperty('id') && option.hasOwnProperty('precio'));

                //console.log(hasIdAndPrecio);
                if(hasIdAndPrecio){
                    filteredOptions.forEach(option => {
                        if (option.hasOwnProperty('precio')) {
                            self.formularioData['precio'] = option.precio;
                            self.formularioData.ordenDetalle[0].productoPrecio = option.precio;
                            self.formularioData.ordenMonto =  option.precio;
                            self.formularioData.ordenDetalle[0].productoVId = option.id;
                        }
                        if (option.hasOwnProperty('diasRecurrencia')) {
                            self.formularioData['diasRecurrencia'] = option.diasRecurrencia;
                            self.calcularFechas(option.diasRecurrencia);
                        }
                    });
                }
            }

            if (typeof this.formularioData[questionAnswered.id] !== 'undefined') {

                this.formularioData[questionAnswered.id] = questionAnswered.answer;
                this.formularioData.ordenDetalle[0].productoId = this.product.id;
                this.formularioData.ordenDetalle[0].productoDescMontoDirecto = 'Cotización de' + this.product.nombre;
            }
            else {
                this.formularioData[questionAnswered.id] = '';
                this.formularioData[questionAnswered.id] = questionAnswered.answer;
                this.dataExtra[questionAnswered.id] = questionAnswered.answer;
            }
        },
        getDisponibilidad() {
            const self = this;
            API.send('POST', 'products/public/disponibilidad/'+self.AuthGetUserInfo.s+'/producto/' + self.product.id + '/' + dayjs(self.date).format('YYYY-MM-DD'), {
                    idPais: self.paisLocationCobertura,
                    idDepartamento: self.deptoLocationCobertura,
                    idMunicipio: self.municipioLocationCobertura,
                },
                function (response) {
                    if (response.status && typeof response.data !== 'undefined') {
                        self.disponibilidad = response.data;
                        self.locations = [];
                        for (let locacion in self.disponibilidad.locaciones) {
                            //console.log(locacion);
                            const positions = {
                                position: {
                                    lat: Number(self.disponibilidad.locaciones[locacion].latitud),
                                    lng: Number(self.disponibilidad.locaciones[locacion].longitud)
                                },
                                label: self.disponibilidad.locaciones[locacion].nombre,
                                title: self.disponibilidad.locaciones[locacion].nombre,
                                moneda: self.disponibilidad.locaciones[locacion].moneda,
                                id: self.disponibilidad.locaciones[locacion].id
                            }
                            //console.log(position);
                            self.locations.push(positions);

                            //console.log(self.locations)
                        }
                    }
                },
                function (response) {
                    self.horarios = {};
                    API.showErrorNotify(response.msg);
                });
        },
        handleItem(category) {
            this.isActive = category
        },
        updateLocaciones(event, cluster, map) {
            //console.log(cluster);
            //console.log(map);
            map.fitBounds(cluster.bounds);
        },
        filterLocation() {
            const self = this;
            return (this.locacionSelected = this.locations.filter(
                (item) => item.id === self.locacionId
            ));
        },
        someEventw(event) {
            this.center = event.position;
            this.zoomData = 15;
            //this.$refs.mapRef.map.panTo({ lat: 0, lng: 0 });
            //this.$refs.mapRef.map.setCenter(this.$refs.mapRef.map.marker.getPosition());

            this.locacionId = event.id;
            this.filterLocation();
        },
        finishSelection() {
            if (this.horarioSelected && this.horarioSelected !== '') {
                this.$router.push('/cart');
                this.hidePicker();
            }
        },
        selectPersonal() {
            if (parseInt(this.personal) === 0) {
                if (typeof this.disponibilidad.locaciones !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion] !== 'undefined' && typeof this.disponibilidad.locaciones[this.locacion].personal !== 'undefined') {
                    this.personalSelected = Object.keys(this.disponibilidad.locaciones[this.locacion].personal)[0];
                }
            }
            else {
                this.personalSelected = this.personal;
            }
        },
        selectHorario(data) {
            const dataToPushDate = {
                'citaFecha': data.fecha,
                'citaHora': data.horario,
                'locacionId': data.locacionId,
                'locacionName': data.locacion,
                'personal': data.personal,
                'pais': this.paisLocationCobertura,
                'depto': this.deptoLocationCobertura,
                'dire': this.locationTextCobertura,
                'moneda': this.disponibilidad.locaciones[data.locacionId].moneda,
                'muni': this.municipioLocationCobertura
            }
            this.horarioSelected = dataToPushDate;
            this.$store.dispatch('CartAddDate', {
                id: this.product.id,
                product: this.product,
                dateSelected: this.horarioSelected,
                quantity: 1,
            });
            if (this.locationTextCobertura === '') {
                window.location.href = '/cart'
            }

        },
        addToLead() {
            this.$store.dispatch('CartAddProduct', this.product);
            this.$store.dispatch('CartChangeProductQuantity', {
                id: this.product.id,
                quantity: this.quantity,
            });
            this.quantity = 1;
        },
        consultaPDF() {
            const self = this;
            // para al final es el mismo pdf solo consulto este servicio si no tengo el blob del pdf
            if (self.invoiceBlob.length === 0) {

                API.send('GET', 'orders/factura/pdf/' + self.ordenCreadaId + '/fcUID', {},
                    function (response) {
                        if (response.status) {
                            self.downloadPDF(response.data, 'fcUID');
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
            else {
                self.downloadPDF(self.invoiceBlob, 'fcUID');

            }

        },
        downloadPDF(file, tipo) {
            const linkSource = `${file}`;
            const downloadLink = document.createElement("a");
            const fileName =  this.ordenCreadaId + tipo + ".pdf";

            downloadLink.href = linkSource;
            downloadLink.download = fileName;
            downloadLink.click();
        },
        createInvoice() {
            const self = this;
            const dataSend = {


                id: self.ordenCreadaId,
                taxCode:  (self.formularioData['clienteTaxId']) ? self.formularioData['clienteTaxId']: 'CF',
                clientName:(self.formularioData['clienteNombre']) ? self.formularioData['clienteNombre']: 'Consumidor Final',
                correoContacto: (self.formularioData['clienteEmail']) ? self.formularioData['clienteEmail']: '',
                idSubscription: (self.cartNotice.codigoOrden) ? self.cartNotice.codigoOrden: '',
                voucher: 'pos-subscripciones'
            }
            //store.dispatch('coreShowLoading');
            // obtengo lsa categorías
            API.send('POST', 'orders/factura/crear', dataSend,
            function (response) {
                if (response.status) {
                    API.showSuccessNotify(response.msg);
                    //self.printDocumento();
                    self.consultaPDF()
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
        },

    },
    mounted() {

        this.getProduct(this.id);
        this.getCategories(this.category);
    }
}
</script>
<style lang="css">

@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.css';
@import '~@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css';



@media (prefers-color-scheme: dark) {
    :root {
        --vff-bg-color: #FFFFFF; /* Fondo principal en blanco */
        --vff-main-text-color: #0000FF; /* Texto principal en azul oscuro */
        --vff-secondary-text-color: #333333; /* Texto secundario en gris oscuro */
        --vff-tertiary-text-color: #0000FF; /* Texto terciario en azul oscuro */
        --vff-main-accent-color: #3399FF; /* Acento principal en azul claro */
        --vff-secondary-accent-color: #FFFF00; /* Acento secundario en amarillo */
    }

    .vff,
    .vff textarea,
    .vff input,
    .vff select,
    .vff select option,
    .vff kbd,
    .vff-header,
    .vff-footer {
        font-family: 'gordita';
    }

    .vff-footer .f-progress-bar {
        display: inline-block;
        height: 4px;
        margin: 0 6px 0 0;
        position: static;
        vertical-align: middle;
        width: 64px;
    }

    .vff-footer .f-progress-bar-inner {
        height: 4px;
        -webkit-transition: width .3s ease;
        transition: width .3s ease;
    }

    .vff-footer .footer-inner-wrap {
        background-color: var(--vff-bg-color);

    }



}
.voucher {
    width: 100%;
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    margin: 0 auto; /* Agrega esta línea */
}

.voucher-title {
    font-size: 18px;
    margin-bottom: 10px;
}

.voucher-body {
    margin-bottom: 20px;
}

.voucher-footer {
    text-align: right;
}


</style>
