<template>
    <box title="Laboratorios" icon="fas fa-flask">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Laboratorio</label>
                    <multiselect v-model="recetaMedicamento"
                        :options="recetaSearchMedicamentos"
                        track-by="id" label="nombre"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder=""
                        :multiple="false"
                        :internal-search="false"
                        @search-change="getData">
                    </multiselect>
                </div>
            </div>
            <div class="col-6 text-center">
                <div class="form-group">
                    <label>
                        Observaciones
                    </label>
                    <textarea class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="text-right mt-4">
            <button class="btn btn-success btn-sm">
                <i class="fa fa-plus"></i> Agregar laboratorio
            </button>
        </div>
        <!--<table class="table table-striped w-100 mt-3">
            <tr v-for="item in rowDataReceta" :key="item.medicamento.nombre+'_'+item.cantidad">
                <th scope="row">
                    <b>{{ item.medicamento.nombre }}</b>
                    <div class="small">{{ stripTags(item.medicamento.descripcion) }}</div>
                    <b>Dósis:</b> {{ item.cantidad }} {{ item.unidad }}, <b>cada:</b> {{ item.cada }} {{ item.cadaTiempo }}, <b>durante:</b> {{ item.durante }} {{ item.duranteTiempo }}
                </th>
                <td>
                    <b>Administración:</b>
                    {{ item.administracion }}
                </td>
                <td>
                    <textarea class="form-control" v-model="item.recomendaciones"></textarea>
                </td>
            </tr>
        </table>-->
    </box>
    <box title="Radiología" icon="fas fa-x-ray">
        <div class="row">
            <div class="col-6">
                <div class="form-group">
                    <label>Procedimiento radiológico</label>
                    <multiselect v-model="recetaMedicamento"
                        :options="recetaSearchMedicamentos"
                        track-by="id" label="nombre"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder=""
                        :multiple="false"
                        :internal-search="false"
                        @search-change="getData">
                    </multiselect>
                </div>
            </div>
            <div class="col-6 text-center">
                <div class="form-group">
                    <label>
                        Observaciones
                    </label>
                    <textarea class="form-control"></textarea>
                </div>
            </div>
        </div>
        <div class="text-right mt-4">
            <button class="btn btn-success btn-sm">
                <i class="fa fa-plus"></i> Agregar procedimiento radiológico
            </button>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from "src/modules/medico/views/fichas/Box.vue";
import Multiselect from 'vue-multiselect';

// Componentes

export default {
    components: {
        Box,
        Multiselect
    },
    props: [
        'type',
    ],
    data() {
        return {
            recetaSearchMedicamentos: [],


            recomendaciones: '',
            recetaMedicamento: '',
            recetaAdministracion: '',
            recetaDosis: '',
            recetaCada: '',
            cadaOptions: [
                {
                    "name": "Diario",
                    "id": 0
                },
                {
                    "name": "Antes de cada comida",
                    "id": 1
                },
                {
                    "name": "Después de cada comida",
                    "id": 2
                },
                {
                    "name": "q1h",
                    "id": 3
                },
                {
                    "name": "q2h",
                    "id": 4
                },
                {
                    "name": "q3h",
                    "id": 5
                },
                {
                    "name": "q4h",
                    "id": 6
                },
                {
                    "name": "q6h",
                    "id": 7
                },
                {
                    "name": "q8h",
                    "id": 8
                },
                {
                    "name": "q12h",
                    "id": 9
                },
                {
                    "name": "HS",
                    "id": 10
                }
            ],


            recetaDurante: '',
            recetaDuranteTiempo: '',
            duranteOptions: [
                {
                    'name': 'minuto(s)',
                    'id': 0,
                },
                {
                    'name': 'hora(s)',
                    'id': 1,
                },
                {
                    'name': 'dia(s)',
                    'id': 2,
                },
                {
                    'name': 'semana(s)',
                    'id': 3,
                },
                {
                    'name': 'mes(es)',
                    'id': 4,
                },
                {
                    'name': 'año(s)',
                    'id': 5,
                }
            ],

            rowDataReceta: [],

            administracionOptions: [
                {
                    'name': 'Vía oral',
                },
                {
                    'name': 'Subcutánea',
                },
                {
                    'name': 'Intramuscular',
                },
                {
                    'name': 'Intravenosa',
                },
                {
                    'name': 'Intratecal',
                },
                {
                    'name': 'vía sublingual',
                },
                {
                    'name': 'Vía rectal',
                },
                {
                    'name': 'Vía vaginal',
                },
                {
                    'name': 'Vía ocular',
                },
                {
                    'name': 'Vía ótica',
                },
                {
                    'name': 'Vía nasal',
                },
                {
                    'name': 'Vía inhalatoria',
                },
                {
                    'name': 'Nebulizaciones',
                },
                {
                    'name': 'Vía cutánea',
                },
                {
                    'name': 'Vía transdérmica',
                }
            ],


            // procedimientos
            data_clicked_id: ''

        };
    },
    computed: {
        ...mapGetters({
            //AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    mounted() {
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        stripTags: function (text) {
            return Tools.stripTags(text);
        },
        getData(query) {
            const self = this;
            self.isLoading = true;
            if (query.length > 0) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/products/search/' + query, {},
                    function (res) {
                        self.recetaSearchMedicamentos = res.data;
                        self.isLoading = false;

                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.isLoading = false;
                    });
            }
        },
        addItemReceta() {
            let receta = {
                medicamento: this.recetaMedicamento,
                cantidad: this.recetaDosis,
                cada: this.recetaCada.name,
                cadaTiempo: this.recetaCadaTiempo,
                durante: this.recetaDurante,
                duranteTiempo: this.recetaDuranteTiempo.name,

                unidad: this.recetaUnidadMedida,
                recomendaciones: '',
                administracion: this.recetaAdministracion.name,
            };
            this.rowDataReceta.push(receta);

            this.recetaMedicamento = '';
            this.recetaCantidad = '';
            this.recetaCada = '';
            this.recetaCadaTiempo = '';
            this.recetaDurante = '';
            this.recetaDuranteTiempo = '';
            this.recetaAdministracion = '';
        },

        // Procedimientos
        part_clicked(val) {
            this.data_clicked_id = val.target.getAttribute('id')
            //console.log(val.target.getAttribute('id'))
        },
    },
};

</script>
