<template>
    <div class="AuthContainer">
        <div class="container h-100">
            <div class="row justify-content-center h-100 align-items-center">
                <div class="authWidthContainer">
                    <div class="mini-logo text-center appConfigMargin">
                        <router-link to="/">
                            <img class="logoBrand" src="staticAssets/logoapp.png" alt="POS"/>
                        </router-link>
                    </div>
                    <div>
                        <h5 class="font-weight-bold">
                            Configuración inicial
                        </h5>
                    </div>
                    <div class="mt-2 mb-5 text-justify">
                        Para utilizar esta aplicación, debes poseer las credenciales brindadas por tu proveedor.
                    </div>
                    <form method="post" @submit.prevent="formSubmit">
                        <div class="form-group">
                            <label class="font-weight-bold">Ingresa el dominio de tu proveedor</label>
                            <input type="text" class="form-control error" placeholder="ejemplo.com.gt" name="proveedor" id="proveedor" v-model="proveedor"/>
                            <span class="validationError">{{ Valid.proveedor }}</span>
                        </div>
                        <div class="text-center">
                            <div class="loginAdvice text-danger mb-3">
                                <small>{{ msg }}</small>
                            </div>
                            <button type="submit" class="btn btn-dark btn-block">
                                Continuar
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <div class="appVersion">
        V.{{appVersion}}
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from 'src/store';
//import controlPanelPopupStyle from "../../../assets/controlPanel/css/popup.lazy.css";
//import controlPanelStyle from "../../../assets/controlPanel/css/style.lazy.css";
// import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "/src/layout/admin/assets/css/Components.lazy.css";

import DataValidation from "src/core/DataValidation";
import AndroidBridge from "src/core/AndroidBridge";

export default {
    name: "AppConfig",
    components: {},
    props: {
        fastLogin: {
            default: false
        },
        showing: {
            default: 'signing'
        }
    },
    data() {
        return {
            Valid: {},
            proveedor: "",
            msg: "",
            appVersion: ''
        };
    },
    beforeCreate() {
        //controlPanelStyle.use();
        //controlPanelPopupStyle.use();
        // publicStore.use();
        componentsStyleGlobal.use();
    },
    unmounted() {
        //controlPanelStyle.unuse();
        //controlPanelPopupStyle.unuse();
        // publicStore.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        this.appVersion = CONFIG.appVersion;

        this.androidBridge = new AndroidBridge();

        // Salida en POS TOP WISE
        if (this.androidBridge.isOnTopWise()) {
            this.androidBridge.TopWiseCloseApp('http://localhost/signin');
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged"]),
        ...mapMutations(["AuthSetToken", "SetUserInfo"]),
        formSubmit() {

            const self = this;

            const validation = new DataValidation()
            validation.required('proveedor', this.proveedor, 'Ingrese dominio de proveedor válido');

            this.Valid = validation.validate(function () {
                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('users/check-whitelabel-domain'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({
                        domain: self.proveedor,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        store.dispatch('coreHideLoading');
                        if (typeof data.status !== 'undefined') {
                            if (data.status && data.data.config) {
                                self.msg = '';
                                store.dispatch('SetAppConfig', data.data.config);
                                window.location.href = '/signin';
                            }
                            else {
                                store.dispatch('coreHideLoading');
                                self.msg = data.msg;
                            }
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error de conexión, por favor intente de nuevo';
                    });
            });
        },
    },
};
</script>
