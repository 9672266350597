<template>
    <div class="fichaMedicaComponent">
        <h5 class="fichaMedicaComponentTitle" v-if="title !== ''">
            <i :class="icon"></i> {{title}}
        </h5>
        <div>
            <slot></slot>
        </div>
    </div>
</template>
<script>

export default {
    components: {
    },
    props: [
        'title',
        'icon',
    ],
    data() {
        return {

        };
    },
    computed: {
    },
    mounted() {

    },
    methods: {
    },
};

</script>
