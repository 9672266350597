<template>
    <div class="AuthContainer AuthContainerShow">
        <div class="container h-100 pt-5">
            <div class="mini-logo text-center mb-4">
                <router-link to="/">
                    <img class="logoBrand" :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre"/>
                </router-link>
            </div>
            <div class="p-3">
                <div class="row h-100">
                    <div class="col-12 col-sm-6" v-if="!StoreInfo.uriTienda">
                        <div class="mb-3">
                            <h6>Configura tu comercio <i class="fa fa-store ml-2"></i></h6>
                            <hr>
                            <div>
                                Configura tu propia tienda en línea, en ella podrás mostrar tus productos o servicios de forma pública
                            </div>
                        </div>
                        <div class="form-group">
                            <label>Nombre de comercio</label>
                            <input type="text" class="form-control" placeholder="Escribe aquí" name="nombreTienda" id="nombreTienda" v-model="nombreTienda"/>
                            <span class="validationError">{{ Valid.nombreTienda }}</span>
                        </div>
                        <div class="form-group">
                            <label>Subdominio de comercio</label>
                            <input type="text" class="form-control" placeholder="Escribe aquí" name="subdominio" id="subdominio" v-model="subdominio"/>
                            <span class="validationError">{{ Valid.subdominio }}</span>
                        </div>
                        <div class="mb-3 text-right">
                            <button class="btn btn-primary w-100" @click="validateDomain">Validar disponibilidad</button>
                        </div>
                        <div>
                            <div>
                                <div v-if="subdomainValidMsg !== ''" :class="{'text-success': subdomainValid,  'text-danger': !subdomainValid}">
                                    {{subdomainValidMsg}}
                                </div>
                                <div class="mt-3 text-center">
                                    <span class="text-primary">{{ urlTienda }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3 mb-2">
                            <small class="text-muted">El subdominio identifica a tu comercio y es la puerta de acceso a tu tienda en línea. Puedes seleccionarlo según tu preferencia siempre y cuando se encuentre disponible.</small>
                        </div>
                    </div>
                    <div :class="{'col-12': StoreInfo.uriTienda, 'col-12 col-sm-6': !StoreInfo.uriTienda }">
                        <div>
                            <h6>Crea tu usuario <i class="fa fa-user ml-2"></i></h6>
                            <hr>
                        </div>
                        <form method="post" @submit.prevent="formSubmit">
                            <div class="form-group">
                                <label>Nombres</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="nombres" id="nombres" v-model="nombres"/>
                                <span class="validationError">{{ Valid.nombres }}</span>
                            </div>
                            <div class="form-group">
                                <label>Apellidos</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="apellidos" id="apellidos" v-model="apellidos"/>
                                <span class="validationError">{{ Valid.apellidos }}</span>
                            </div>
                            <div class="form-group">
                                <label>Email</label>
                                <input type="email" class="form-control" placeholder="hello@example.com" name="email" id="email" v-model="email"/>
                                <span class="validationError">{{ Valid.email }}</span>
                            </div>
                            <div class="form-group">
                                <label>Contraseña</label>
                                <i class="fa fa-question-circle ml-2" v-tooltip="'Una contraseña fuerte debe contener al menos 8 caracteres, mayúsculas, mínusculas, números y algún caracter especial como (@,*,#,$,!,=,+,-)'"></i>
                                <div class="passwordField">
                                    <input :type="typePasswordInput" class="form-control" placeholder="Contraseña" name="password" id="password" v-model="password"/>
                                    <span class="validationError" v-if="passwordStrengthMsg" :style="'color:' + passwordStrengthColor">{{ passwordStrengthMsg }}</span>
                                    <i @click="typePasswordInput = (typePasswordInput === 'password') ? 'text' : 'password'" :class="{'passwordFieldToogle fa fa-eye': typePasswordInput === 'password', 'passwordFieldToogle fa fa-eye-slash': typePasswordInput === 'text', }"></i>
                                </div>
                            </div>
                            <div class="text-center mt-4">
                                <div class="loginAdvice text-danger mb-3">
                                    <small>{{ msg }}</small>
                                </div>
                                <div class="text-small mb-2 cursor-pointer">
                                    <input id="terminos" type="checkbox" v-model="terminosAceptados" class="mr-2" />
                                    <label for="terminos">
                                        Acepto los <a class="text-primary text-decoration-underline" :href="(typeof whiteLabelInfo.enlaces !== 'undefined' && typeof whiteLabelInfo.enlaces.terminos !== 'undefined') ? whiteLabelInfo.enlaces.terminos : '#'" target="_blank">términos y condiciones</a> de {{whiteLabelInfo.nombre}}
                                    </label>
                                </div>
                                <button type="submit" class="btn btn-primary btn-block">
                                    Registrarme
                                </button>
                                <button v-if="fastLogin" type="button" class="btn btn-dark btn-block" @click="$emit('close')">
                                    Cancelar
                                </button>
                            </div>
                        </form>
                        <div class="new-account mt-3 text-center">
                            <p>
                                ¿Ya tienes cuenta?
                                <router-link v-if="!fastLogin" class="text-primary" to="/signin">
                                    Iniciar sesión
                                </router-link>
                                <a v-if="fastLogin" @click="$emit('update:showing', 'signing')" class="text-primary">
                                    Iniciar sesión
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="appVersion">
        V.{{appVersion}}
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from 'src/store';

import DataValidation from "src/core/DataValidation";
//import controlPanelStyle from "src/assets/controlPanel/css/style.lazy.css";
//import controlPanelPopupStyle from "src/assets/controlPanel/css/popup.lazy.css";
// import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "src/layout/admin/assets/css/Components.lazy.css";
import Tools from "src/core/Tools";
import API from "src/core/Api";

export default {
    name: "Signup",
    components: {},
    props: {
        fastLogin: {
            default: false
        },
        showing: {
            default: 'signing'
        }
    },
    data() {
        return {
            Valid: {},
            terminosAceptados: false,
            email: "",
            password: "",
            nombres: "",
            apellidos: "",
            msg: "",
            nombreTienda: "",
            subdominio: "",
            urlTienda: "",
            subdomainValidMsg: "",
            subdomainValid: "",
            passwordStrength: 0,
            passwordStrengthMsg: false,
            passwordStrengthColor: false,
            typePasswordInput: 'password',
            appVersion: '',
        };
    },
    beforeCreate() {
        //controlPanelStyle.use();
        //controlPanelPopupStyle.use();
        // publicStore.use();
        componentsStyleGlobal.use();
    },
    unmounted() {
        //controlPanelStyle.unuse();
        //controlPanelPopupStyle.unuse();
        // publicStore.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        this.appVersion = CONFIG.appVersion;
    },
    watch: {
        nombreTienda: function (val) {
            this.subdominio = Tools.createSlug(val);
        },
        subdominio: function (val) {
            this.subdominio = val.replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
            this.urlTienda = "https://" + this.subdominio + "." + store.getters.whiteLabelInfo.domain;
            this.subdomainValid = false;
            this.subdomainValidMsg = '';
        },
        password: function (value) {
            //console.log(value);
            this.CheckPasswordStrength(value);
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            StoreInfo: 'StoreInfo'
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged"]),
        ...mapMutations(["AuthSetToken"]),
        formSubmit() {

            const self = this;

            const storeDomain = (store.getters.StoreInfo) ? store.getters.StoreInfo.uriTienda : 0;

            if (!this.terminosAceptados) {
                API.showErrorAlert('Atención', 'Debes aceptar los términos y condiciones para poder hacer uso de la plataforma');
                return false;
            }

            const validation = new DataValidation()
            validation.required('nombres', this.nombres, 'Debe ingresar un nombre');
            validation.required('apellidos', this.apellidos, 'Debe ingresar un apellido');
            validation.email('email', this.email, 'Correo electrónico inválido');
            validation.required('password', this.password, 'Debe ingresar una contraseña');

            if (!storeDomain) {
                validation.required('nombreTienda', this.nombreTienda, 'Debe ingresar un nombre para la tienda');
                validation.required('subdominio', this.subdominio, 'Debe ingresar un subdominio');
            }

            this.Valid = validation.validate(function () {

                if (!self.subdomainValid && !storeDomain) {
                    API.showErrorNotify("Verifica la disponibilidad del subdominio");
                    return false;
                }

                if (self.passwordStrength < 4) {
                    API.showErrorNotify("Ingresa una contraseña más fuerte");
                    return false;
                }

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('users/register'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
                    },
                    body: JSON.stringify({
                        nombres: self.nombres,
                        apellidos: self.apellidos,
                        email: self.email,
                        password: self.password,
                        nombreTienda: self.nombreTienda,
                        subdominio: self.subdominio,
                        whiteLabelId: store.getters.whiteLabelInfo.id,
                        store: storeDomain,
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                self.AuthSetToken(data.data.token);
                                setTimeout(function () {
                                    store.dispatch('coreHideLoading');
                                    if (self.AuthIsLogged()) {
                                        // si es fast login
                                        if (self.fastLogin) {
                                            //store.dispatch('AuthValidateToken', {});
                                            self.$emit('loginSuccess');
                                        }
                                        else {
                                            self.$router.push('/dashboard');
                                        }
                                    }
                                }, 300);
                            }
                            else {
                                self.msg = data.msg;
                                store.dispatch('coreHideLoading');
                            }
                        }
                    })
                    .catch(() => {
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error al registrarse, por favor intente de nuevo';
                    });
            });
        },
        validateDomain() {
            const self = this;

            if (this.subdominio !== '') {
                API.send('GET', 'users/onlineStore/validateSubdomain/' + store.getters.whiteLabelInfo.domain + '/' + self.subdominio, {}, function (response) {

                        if (response.status) {
                            self.subdomainValidMsg = "El subdominio se encuentra disponible, la dirección de tu tienda online será la siguiente:";
                            self.urlTienda = response.data.fullUriTienda;
                            self.subdomainValid = true;
                        }
                    },
                    function () {
                        self.subdomainValidMsg = "El subdominio no se encuentra disponible, intenta modificar el subdominio";
                        self.subdomainValid = false;
                        self.urlTienda = "";
                    })
            }
            else {
                API.showErrorNotify("Debes ingresar un subdominio para tu tienda");
            }
        },
        CheckPasswordStrength(password) {

            //Regular Expressions
            const regex = [];
            regex.push("[A-Z]"); //For Uppercase Alphabet
            regex.push("[a-z]"); //For Lowercase Alphabet
            regex.push("[0-9]"); //For Numeric Digits
            regex.push("[$@$!%*#?&-.;=+]"); //For Special Characters

            let passed = 0;

            //Validation for each Regular Expression
            for (var i = 0; i < regex.length; i++) {
                if ((new RegExp(regex[i])).test(password)) {
                    passed++;
                }
            }

            //Validation for Length of Password
            if (passed > 2 && password.length > 8) {
                passed++;
            }

            //Display of Status
            let color = "";
            let passwordStrength = "";
            switch (passed) {
                case 0:
                    break;
                case 1:
                    passwordStrength = "La contraseña es débil";
                    color = "#d72d2d";
                    break;
                case 2:
                    passwordStrength = "La contraseña es débil";
                    color = "darkorange";
                    break;
                case 3:
                    break;
                case 4:
                    passwordStrength = "La contraseña es fuerte";
                    color = "Green";
                    break;
                case 5:
                    passwordStrength = "La contraseña es muy fuerte";
                    color = "darkgreen";
                    break;
            }
            this.passwordStrength = passed;
            this.passwordStrengthMsg = passwordStrength;
            this.passwordStrengthColor = color;
        }
    },
};
</script>
