<template>
    <div>
        <QuillEditor ref="editor" :options="options" :content="editorContent" @editorChange="onEditorUpdate"/>
    </div>
</template>


<script>

import {QuillEditor} from '@vueup/vue-quill'

export default {
    components: {
        QuillEditor,
    },
    props: [
        'editorValue',
    ],
    mounted() {
        this.$refs.editor.setHTML(this.editorValue);
    },
    watch: {
        editorValue: function (val) {
            console.log(val);
            //this.$refs.editor.setHTML(val);
            /*if (this.editorContent !== val) {
                this.editorContent = val;
                this.$refs.editor.setHTML(val);
            }*/
        },
    },
    data() {
        return {
            editorContent: '',
            options: {
                placeholder: 'Escribe aquí',
                readOnly: false,
                contentType: 'html',
                theme: 'snow',
              modules:{
                toolbar: [
                  ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                  ['blockquote', 'code-block'],

                  //[{ 'header': 1 }, { 'header': 2 }],               // custom button values
                  [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                  [{ 'script': 'sub'}, { 'script': 'super' }],      // superscript/subscript
                  [{ 'indent': '-1'}, { 'indent': '+1' }],          // outdent/indent
                  [{ 'direction': 'rtl' }],                         // text direction
                  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],

                  [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                  [{ 'font': [] }],
                  [{ 'align': [] }],
                  ['video'],

                  ['clean']                                         // remove formatting button
                ]
              },
            }
        };
    },
    methods: {
        onEditorUpdate() {
            this.$emit('update:editorValue', this.$refs.editor.getHTML());
            //console.log(this.$refs.editor.getHTML())
        },
    }
};
</script>
