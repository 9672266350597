
import Dashboard from "src/modules/medico/views/Dashboard.vue";
import Ficha from "src/modules/medico/views/Ficha.vue";
export default [
    {
        path: "/medic/dashboard",
        component: Dashboard,
    },
    {
        path: "/medic/ficha/:reservaId",
        component: Ficha,
    },
    {
        path: "/medico-builder/:id",
        component: Dashboard,
    },
];
