<template>
    <box title="Diagnósticos" icon="fa fa-comment-medical">
        <div class="row">
            <div class="col-12" v-if="tipoFicha !== 'veterinaria'">
                <div class="form-group">
                    <label>Seleccione su diagnóstico CIE10</label>
                    <multiselect v-model="diagnosticos"
                        :options="options"
                        track-by="id" label="nombre"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder=""
                        :multiple="true"
                        :internal-search="false"
                        @search-change="getCie10"
                        @select="saveImpresionClinicaCie10"
                        @remove="saveImpresionClinicaCie10"
                    >
                    </multiselect>
                </div>
            </div>
            <div class="col-12 col-sm-12 mt-3">
                <div class="form-group">
                    <label>Otros diagnósticos y observaciones</label>
                    <textarea v-model="observaciones" class="form-control" placeholder="Escribe aquí" @change="saveImpresionClinica" style="min-height: 250px"></textarea>
                </div>
            </div>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from "src/modules/medico/views/fichas/Box.vue";
import Multiselect from 'vue-multiselect';

// Componentes

export default {
    components: {
        Box,
        Multiselect
    },
    props: [
        'type',
    ],
    data() {
        return {
            tipoFicha: '',
            options: [],
            diagnosticos: [],
            observaciones: '',
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
        const self = this;
        this.tipoFicha = this.GetMedicoFichaActive.producto.fichaMedica;
        this.observaciones = (this.GetMedicoFichaActive.metadata.impresion_clinica) ? this.GetMedicoFichaActive.metadata.impresion_clinica.observaciones : '';

        // Cargo antecedentes
        if (typeof this.GetMedicoFichaActive.metadata.impresion_clinica_cie10 !== 'undefined') {
            Object.keys(this.GetMedicoFichaActive.metadata.impresion_clinica_cie10).map(function (value) {
                self.diagnosticos.push({
                    id: value,
                    nombre: self.GetMedicoFichaActive.metadata.impresion_clinica_cie10[value]
                })
            });
        }
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        getCie10(query) {
            const self = this;
            self.isLoading = true;
            if (query.length > 3) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/orders/catalogos/CIE-10/' + query, {},
                    function (res) {
                        self.options = res.data;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    });
            }
        },
        saveImpresionClinicaCie10() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};

            self.diagnosticos.forEach(function (a) {
                arrToSave[a.id] = a.nombre;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'impresion_clinica_cie10',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        saveImpresionClinica() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            arrToSave['observaciones'] = this.observaciones;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'impresion_clinica',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
    },
};

</script>
