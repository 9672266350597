<template>
    <control-comercios-layout :active="60">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de concilias</h4>
                <div class="float-right">
                    <a @click="$router.push('/sub-commerce/concilia/0')" role="button" tabindex="0" class="btn btn-primary px-4 mr-3">
                        Crear nueva
                    </a>
                </div>
            </div>
            <div class="card-body">
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in concilias" :key="item.id">
                            <div class="media d-block">
                                <div class="row">
                                    <div class="col-9">
                                        <h5 class="mt-0 mb-1">{{ item.nombres }} {{ item.apellidos }}</h5>
                                        <div class="row">
                                            <div class="col-12">
                                                <h5>Identificador de concilia: {{item.id}}</h5>
                                            </div>
                                            <div class="col-12 text-primary">
                                                <span v-if="item.type === 'pago_terceros'">Pago de terceros a clientes</span>
                                                <span v-if="item.type === 'comision_socios'">Pago de comisiones a socios</span>
                                                <span v-if="item.type === 'comision_procesador'">Cobro de comisiones a procesador</span>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div><b>Fecha inicial:</b> {{dayjs(item.fechaInicial).format('DD-MM-YYYY')}}</div>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div><b>Fecha final:</b> {{dayjs(item.fechaFinal).format('DD-MM-YYYY')}}</div>
                                            </div>
                                            <div class="col-12 col-sm-4">
                                                <div><b>Creada:</b> {{dayjs(item.createdAt).format('DD-MM-YYYY')}}</div>
                                            </div>
                                        </div>
                                        <!--<div>
                                            <b>Roles asignados:</b> &nbsp; <span v-for="rol in item.roles" :key="rol.id + '_rol'" class="mr-2 badge badge-primary">{{rol.nombre}}</span>
                                        </div>-->
                                    </div>
                                    <div class="col-3 text-right">
                                        <div class="edit-option text-right">
                                            <a @click="$router.push('/sub-commerce/concilia/' + item.id)">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>
<script>
import controlComerciosLayout from "../../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";
import dayjs from "dayjs";


export default {
    components: {
        controlComerciosLayout,
    },
    data() {
        return {
            concilias: {},
        }
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        dayjs,
        loadItems() {
            const self = this;
            API.send('GET', 'stores/concilia/list', {},
            function (data) {
                self.concilias = data.data;
            },
            function (data) {
                API.showErrorAlert(data.msg);
            });
        },
    },
};
</script>
