<template>
    <layout>
        <div class="content-body">
            <div class="mb-80">
                <div class="container h-100">
                    <form id="formData" @submit="save" method="post">
                        <div class="row justify-content-center h-100 align-items-center">
                            <div v-if="errors.length">
                                <b>Por favor corrija los siguientes errores(s):</b>
                                <ul>
                                    <li v-for="error in errors" :key="error">
                                        {{ error }}
                                    </li>
                                </ul>
                            </div>
                            <div class="col-xl-12 col-md-12">
                                <div class="row">
                                    <div class="col-12 col-md-6">
                                        <div class="card">
                                            <div class="card-header">
                                                <h4 class="card-title">
                                                    <router-link to="/products/category">
                                                        <i class="back fa fa-arrow-circle-left"></i>
                                                    </router-link>
                                                    <span>
                                                        Editar categoría
                                                    </span>
                                                    <a href="/products/category/add" class="btn btn-sm btn-primary ml-3">
                                                        Nueva
                                                    </a>
                                                </h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="form-group col-12">
                                                        <label class="mr-sm-2">Nombre</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="nombre"/>
                                                    </div>
                                                    <div class="form-group col-12">
                                                        <label class="mr-sm-2">Slug</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="slug"/>
                                                    </div>
                                                    <div class="form-group col-12">
                                                        <label class="mr-sm-2">Descripción</label>
                                                        <textarea class="form-control form-control-text-area" placeholder="Escribe aquí" v-model="descripcion"></textarea>
                                                    </div>

                                                    <div class="col-6">
                                                        <label class="toggle">
                                                            <input class="toggle-checkbox" type="checkbox" v-model="status" :checked="status"/>
                                                            <div class="toggle-switch"></div>
                                                            <span class="toggle-label">Habilitar categoría</span>
                                                        </label>
                                                    </div>
                                                </div>
                                                <div class="text-center mt-4">
                                                    <button type="button" class="btn btn-primary mr-3" @click="save">Guardar</button>
                                                    <router-link to="/products/category">
                                                        <button type="button" class="btn btn-dark">Cancelar</button>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6">
                                        <div class="card" v-if="id > 0">
                                            <div class="card-header">
                                                <h4 class="card-title">
                                                    Imagen de categoría
                                                    <i class="fa fa-question-circle" v-tooltip="'Imagen a mostrar referente a categoría'"></i>
                                                </h4>
                                            </div>
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="col-12 text-center mb-4">
                                                        <img v-show="imagenCategoriaUrl !== '' && imagenCategoriaUrl" :src="imagenCategoriaUrl" style="max-height: 200px; max-width: 100%">
                                                    </div>
                                                    <div class="col-12">
                                                        <FileUploader field-name="categoriaImagen" max-files="1" url="products/categoria/img/upload" accepted-mime="image/jpg, image/jpeg, image/png, image/gif" remove-file-on-finish="1" :extra-data-form="{categoriaId: id}" @fileSuccess="UploadSuccess"></FileUploader>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import FileUploader from 'src/components/files/FileUploader';

export default {
    components: {
        Layout,
        FileUploader,
    },
    data() {
        return {
            errors: [],
            id: 0,
            nombre: '',
            slug: '',
            descripcion: '',
            status: true,
            imagenCategoriaUrl: '',
        };
    },
    watch: {
        nombre: function (val) {
            this.slug = Tools.createSlug(val);
        },
    },
    mounted: function () {
        this.load();
    },
    methods: {
        load() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {

                API.send('GET', 'products/idCategoria/' + this.$route.params.id, {},
                    function (response) {
                        if (response.status) {
                            const category = response.data[0];
                            self.id = category.id;
                            self.nombre = category.nombre;
                            self.descripcion = category.descripcion;
                            self.imagenCategoriaUrl = category.imageUrl;
                            self.status = parseInt(category.status);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }
        },
        UploadSuccess() {
            this.load();
        },
        save(e) {

            this.errors = [];
            const self = this;

            if (!this.nombre) this.errors.push('El nombre de la categoría es requerido');
            if (this.errors.length === 0) {
                const data = {
                    id: this.id,
                    nombre: this.nombre,
                    slug: this.slug,
                    descripcion: '',
                    status: this.status | 0,
                }

                if (this.id > 0) {
                    //const self = this;
                    API.send('PUT', 'products/editCategory/' + this.id, data, function (response) {
                        API.showSuccessNotify(response.msg);
                        //self.$router.go(-1);
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
                }
                else {
                    //const self = this;
                    API.send('POST', 'products/newCategory/', data, function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            window.location.href = '/products/category/edit/' + response.data.id;
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
                }
            }
            else {
                API.showFormValidationErrors(this.errors);
            }
            e.preventDefault();
        },
    },
};
</script>
