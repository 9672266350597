<template>
    <div class="AuthContainer">
        <div class="container h-100">
            <div class="justify-content-center h-100 align-items-center">
                <div class="authWidthContainer">
                    <div class="mini-logo text-center my-5">
                        <router-link to="/">
                            <img v-if="!StoreInfo.uriTienda" class="logoBrand" :src="whiteLabelInfo.logoUrl" :alt="whiteLabelInfo.nombre"/>
                            <img v-if="StoreInfo.uriTienda" class="logoBrand" :src="StoreInfo.logoTienda" :alt="StoreInfo.logoTienda"/>
                        </router-link>
                    </div>
                    <form method="post" @submit.prevent="formSubmit">
                        <div class="form-group">
                            <label>Correo electrónico</label>
                            <input type="email" class="form-control error" placeholder="hola@ejemplo.com" name="email" id="email" v-model="email"/>
                            <span class="validationError">{{ Valid.email }}</span>
                        </div>
                        <div class="form-group">
                            <label>Constraseña</label>
                            <div class="passwordField">
                                <input :type="typePasswordInput" class="form-control error" placeholder="Contraseña" name="password" id="password" v-model="password"/>
                                <span class="validationError">{{ Valid.password }}</span>
                                <i @click="typePasswordInput = (typePasswordInput === 'password') ? 'text' : 'password'" :class="{'passwordFieldToogle fa fa-eye': typePasswordInput === 'password', 'passwordFieldToogle fa fa-eye-slash': typePasswordInput === 'text'}"></i>
                            </div>
                        </div>
                        <!--<div class="form-row d-flex justify-content-between mt-4 mb-2">
                            <div class="form-group mb-0">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">Recordarme</span>
                                </label>
                            </div>
                        </div>-->
                        <div class="text-center">
                            <div class="loginAdvice text-danger mb-3">
                                <small>{{ msg }}</small>
                            </div>
                            <button type="submit" class="btn btn-primary btn-block">
                                Continuar
                            </button>
                            <button v-if="fastLogin" type="button" class="btn btn-dark btn-block" @click="$emit('close')">
                                Cancelar
                            </button>
                        </div>
                    </form>
                    <div class="text-center staffNotice">
                        <router-link to="/signin-staff">Soy colaborador</router-link>
                    </div>
                    <div class="new-account mt-3 text-center">
                        <p>
                            ¿Aún no tienes cuenta?
                            <router-link v-if="!fastLogin" class="text-primary" to="signup">
                                Registrarme
                            </router-link>
                            <a v-if="fastLogin" @click="$emit('update:showing', 'signup')" class="text-primary">
                                Registrarme
                            </a>
                        </p>
                        <p>
                            <router-link v-if="!fastLogin" to="/reset/password">Olvidé mi contraseña</router-link>
                            <a v-if="fastLogin" @click="$emit('update:showing', 'password_recover')" class="text-gray">
                                Olvidé mi contraseña
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="appVersion">
        V.{{appVersion}}
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import {mapGetters} from "vuex";
import {CONFIG} from 'src/config';
import {store} from 'src/store';
//import controlPanelPopupStyle from "../../../assets/controlPanel/css/popup.lazy.css";
//import controlPanelStyle from "../../../assets/controlPanel/css/style.lazy.css";
// import publicStore from "src/assets/publicStore/css/theme.lazy.css";
import componentsStyleGlobal from "/src/layout/admin/assets/css/Components.lazy.css";

import DataValidation from "src/core/DataValidation";
import AndroidBridge from "src/core/AndroidBridge";

export default {
    name: "Signin",
    components: {},
    props: {
        fastLogin: {
            default: false
        },
        showing: {
            default: 'signing'
        }
    },
    data() {
        return {
            Valid: {},
            email: "",
            password: "",
            checkbox: "",
            msg: "",
            typePasswordInput: 'password',
            appVersion: ''
        };
    },
    beforeCreate() {
        //controlPanelStyle.use();
        //controlPanelPopupStyle.use();
        // publicStore.use();
        componentsStyleGlobal.use();
    },
    unmounted() {
        //controlPanelStyle.unuse();
        //controlPanelPopupStyle.unuse();
        // publicStore.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        this.appVersion = CONFIG.appVersion;

        this.androidBridge = new AndroidBridge();

        // Salida en POS TOP WISE
        if (this.androidBridge.isOnTopWise()) {
            this.androidBridge.TopWiseCloseApp('http://localhost/signin');
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            StoreInfo: 'StoreInfo',
        })
    },
    methods: {
        ...mapGetters(["AuthIsLogged"]),
        ...mapMutations(["AuthSetToken", "SetUserInfo"]),
        formSubmit() {

            const self = this;

            const validation = new DataValidation()
            validation.email('email', this.email, 'Ingrese un email válido');
            validation.required('password', this.password, 'Debe ingresar una contraseña');

            this.Valid = validation.validate(function () {

                store.dispatch('coreShowLoading');

                fetch(CONFIG.getWsUrl('users/authorize'), {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "X-Any-Wl": (store.getters.whiteLabelInfo) ? store.getters.whiteLabelInfo.id : 0,
                    },
                    body: JSON.stringify({
                        email: self.email,
                        password: self.password,
                        wl: store.getters.whiteLabelInfo.id,
                        store: (store.getters.StoreInfo) ? store.getters.StoreInfo.uriTienda : 0,
                        staff: 0,
                        storeIdentify: '',
                    }),
                })
                    .then(response => response.json())
                    .then(data => {
                        if (typeof data.status !== 'undefined') {
                            if (data.status) {
                                self.AuthSetToken(data.data.token);

                                if (self.AuthIsLogged()) {

                                    store.dispatch('coreHideLoading');

                                    // si es fast login
                                    if (self.fastLogin) {
                                        //store.dispatch('AuthValidateToken', {});
                                        self.$emit('loginSuccess');
                                    }
                                    else {
                                        self.$router.push('/dashboard');
                                    }
                                }
                            }
                            else {
                                store.dispatch('coreHideLoading');
                                self.msg = data.msg;
                            }
                        }
                    })
                    .catch((e) => {
                        console.log(e);
                        store.dispatch('coreHideLoading');
                        self.msg = 'Error de conexión, por favor intente de nuevo';
                    });
            });
        },
    },
};
</script>
