/*import PublicStoreHome from "./publicStore/Home";
import ProductList from "./publicStore/ProductList";
import CategoryList from "./publicStore/CategoryList";
import ProductSingle from "./publicStore/ProductSingle";
import Cart from "./publicStore/Cart";*/
// import PagoOrdenStore from "./publicStore/PagoOrdenStore";
/*import FormFill from "./publicStore/FormFill";
import FormValidate from "./publicStore/FormValidate";
import Profile from "./publicStore/Profile";
import ProfileVirtualCall from "./publicStore/ProfileVirtualCall";*/
import NotFound404 from "./404";
import NotFoundStore404 from "./404Store";
import NetworkError from "./NetworkError";
// import FormPreOrder from "./publicStore/FormPreOrder";

export default [
    /*{
        path: "/",
        component: PublicStoreHome,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/category/:slug",
        component: CategoryList,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/products/:slug",
        component: ProductList,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/products/all/search/:searchTerm",
        component: ProductList,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/brand/:slugBrand",
        component: ProductList,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/product/:slug",
        component: ProductSingle,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/cart",
        component: Cart,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/cart/fast-pay/:productToken",
        component: Cart,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/clients/lead/:leadToken",
        component: FormPreOrder,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/cart/pay-link/:orderToken",
        component: Cart,
        meta: {
            isPublic: true
        }
    },*/
    {
        path: "/404",
        component: NotFound404,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/network-error",
        component: NetworkError,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/store/404",
        component: NotFoundStore404,
        meta: {
            isPublic: true
        }
    },
    /*{
        path: "/pay/:orderToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pr/pay/:productToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pay/validate/:orderToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pr/pay/validate/:orderToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pay/store/:orderToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pr/pay/store/:productToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pay/store/validate/:orderToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/pr/pay/store/validate/:orderToken",
        component: PagoOrdenStore,
        meta: {
            isPublic: true
        }
    },*/
    /*{
        path: "/frm/handle/:formToken",
        component: FormFill,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/frm/validate/:formToken",
        component: FormValidate,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/client/profile",
        component: Profile,
        meta: {
            loginClient: true
        }
    },
    {
        path: "/client/profile/virtualcall/:token",
        component: ProfileVirtualCall,
        meta: {
            isPublic: true
        }
    },*/
];
