<template>
    <setting-layout :active="22">
        <div class="card myPlanAndPackages">
            <div class="card-header">
                <h4 class="card-title">Configuración de plan</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6 text-justify">
                        <h5>¿Qué es un plan?</h5>
                        <div>
                            Para utilizar la plataforma es necesario poseer un plan, este define las funcionalidades que se habilitarán para tu tienda.
                        </div>
                        <br>
                    </div>
                    <div class="col-12 col-sm-6">
                        <h5>¿Qué es un opcional?</h5>
                        <div>
                            El envío de mensajes de texto hacia teléfonos móviles y las facturas electrónicas son elementos opcionales para tu cuenta, por ejemplo: <i>Si realizas una venta y tienes activada la opción de envío de SMS, se agregará a tu cuenta el costo por el número de SMS enviados.</i>
                        </div>
                    </div>
                </div>
                <hr class="mb-4">
                <div v-if="planGratuito" class="freeBanner my-4">
                    Tu cuenta es gratuita, te hemos obsequiado 10 transacciones de venta. Para disfrutar de todos tus beneficios, debes ingresar un método de pago y seleccionar los módulos a utilizar. También podrás seleccionar los consumibles que utilizarás durante el mes.
                </div>
                <!--<h5>Configura tus módulos</h5>-->
                <!--<div>
                    <div class="row">
                        <div class="moduleItem col-6" v-for="item in modulos" :key="item.id">
                            <div>
                                <div class="row">
                                    <div class="col-6">
                                        <h6 class="text-dark-gray">
                                            <i class="mdi mdi-store mr-2"></i>
                                            {{ item.nombre }}
                                        </h6>
                                    </div>
                                    <div class="col-6 text-right">
                                        <h6 class="text-dark-gray">Q.{{ item.precio }}/mes</h6>
                                    </div>
                                </div>
                                <div>
                                    {{ item.descripcion }}
                                </div>
                                <div class="text-center mt-3">
                                    <label class="toggle">
                                        <input class="toggle-checkbox" type="checkbox" v-model="modulosEnabled[item.id]" :checked="modulosEnabled[item.id]"/>
                                        <div class="toggle-switch"></div>
                                        <span class="toggle-label">
                                            <template v-if="modulosEnabled[item.id]">Activo</template>
                                            <template v-if="!modulosEnabled[item.id]">Desactivado</template>
                                        </span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>-->
                <div v-if="planObj.activated">
                    <h3 class="mb-0 text-success">Tu cuenta está activa</h3>
                    <div class="row mt-4">
                        <div class="col-12 licenseResume">
                            <div>
                                <b>Plan actual:</b> <span>{{planObj.desc}}</span>
                            </div>
                            <div>
                                <b>Se renovará automáticamente el:</b> <span>{{ dayjs(planObj.expiryDate).format('DD-MM-YYYY') }}</span>
                            </div>
                        </div>
                        <div class="col-12 mt-3">
                            <a class="text-primary" @click="showActivation = true"><i class="fa fa-exchange mr-3"></i>Hacer cambios en mi plan</a>
                        </div>
                    </div>
                </div>
                <div v-if="showActivation" class="mt-3">
                    <div v-if="planObj.activated" class="mb-5">
                        <div class="resumenPago text-center">
                            <div>
                                Adquiere tu nuevo plan en nuestra tienda digital
                            </div>
                            <div class="mt-3" v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                                <a class="btn btn-success" v-if="whiteLabelInfo.enlaces.plancenter" :href="whiteLabelInfo.enlaces.plancenter" target="_blank">Ir a tienda digital</a>
                            </div>
                            <div class="mt-3">
                                Luego de comprar tu plan, ingresa tu nueva licencia en el campo de abajo
                            </div>
                        </div>
                    </div>
                    <div v-else class="mb-5">
                        <div class="resumenPagoWarning text-center">
                            <div>
                                <h4 class="text-danger">No posees un plan activo</h4>
                            </div>
                            <div>
                                Adquiere tu plan en nuestra tienda digital y disfruta de todos los beneficios
                            </div>
                            <div class="mt-3" v-if="typeof whiteLabelInfo.enlaces !== 'undefined'">
                                <a class="btn btn-success" v-if="whiteLabelInfo.enlaces.plancenter" :href="whiteLabelInfo.enlaces.plancenter" target="_blank">Ir a tienda digital</a>
                            </div>
                            <div class="mt-3">
                                Luego de comprar tu plan, ingresa tu nueva licencia en el campo de abajo
                            </div>
                        </div>
                    </div>
                    <div class="mt-3">
                        <h3 class="mb-0">Ingresar licencia</h3>
                    </div>
                    <div class="row mt-4">
                        <div class="col-12 mb-2">
                            Ingresar licencia de activación correspondiente a tu plan:
                        </div>
                        <div class="col-12">
                            <input class="form-control" v-model="licenseDigital" placeholder="Escribe o pega tu licencia digital">
                        </div>
                        <div class="col-12 mt-3">
                            <button v-show="licenseDigital !== ''" class="btn btn-success" @click="activarMiCuenta">Activar mi cuenta</button>
                        </div>
                    </div>
                </div>
                <!--<div v-if="!paramsHasChanges" class=" mt-5">
                    <div class="resumenMensual mb-3">
                        <div class="row">
                            <div class="col-7">
                                <h5 class="mb-0">Tu cuota a pagar el próximo mes será de:</h5>
                            </div>
                            <div class="col-5 text-right">
                                <h3 class="mb-0 text-success">{{ Numbro(totalModules).formatCurrency() }}</h3>
                            </div>
                            <div class="col-12 text-dark">
                                <small>NO incluye el costo de consumibles como DTE (facturas electrónicas) o envío de mensajes de texto (SMS)</small>
                            </div>
                        </div>
                    </div>
                    <div class="text-gray mb-3">
                        El pago de módulos, comisiones, y costo de consumibles (DTE, SMS, Etc.) se cobrarán de forma recurrente una vez al mes.
                        <span class="text-success">
                                La siguiente fecha de pago es el día {{siguienteFechaPago}}.
                            </span>
                    </div>
                </div>-->
                <!--<div v-if="paramsHasChanges">
                    <div class="resumenPago">
                        <div class="row">
                            <div class="col-12 text-center">
                                <h4 class="mb-4">Resumen</h4>
                            </div>
                            <div class="col-6 text-center">
                                <h5 class="mb-4 mt-4">Pagarás en este momento:</h5>
                                <h2 class="text-success mb-4">{{ Numbro(totalAmount).formatCurrency() }}</h2>
                                <div class="text-center">
                                    <div class="my-4">
                                        <button class="btn btn-success btn-sm" @click="showPaymentForm = true">Continuar</button>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 text-justify">
                                <ul>
                                    <li>
                                        <i class="fa fa-warning"></i>
                                        Se modificará tu fecha de pago hacia el día <span class="text-success">{{upgradeFechaPago}}</span>.
                                        Si tienes módulos activos, se cobrarán únicamente los días adicionales que tu último pago no alcanza a cubrir.
                                    </li>
                                    <li class="font-weight-bold">
                                        Se modificará la cuota de tu suscripción mensual al monto:
                                        <span class="text-success">{{ Numbro(totalModules).formatCurrency() }}.</span>
                                        Este monto se cobrará el siguiente mes.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>-->
                <!--<div>
                    <PaymentForm v-model:show-payment-form="showPaymentForm" @do-pay="doPay" :total-pago="totalAmount"></PaymentForm>
                </div>-->
            </div>
        </div>
        <div class="card myPlanAndPackages">
            <div class="card-header">
                <h4 class="card-title">Configuración de opcionales</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6">
                        <div class="mt-2">
                            <h5 class="text-dark-gray">
                                DTE o Factura electrónica
                                <i class="infoQuestion fa fa-question-circle ml-3"></i>
                            </h5>
                            <div class="text-justify">
                                Puedes activar o desactivar el uso de facturas electrónicas, si desactivas esta opción, tus clientes NO recibirán facturas electrónicas y NO se emitirán facturas para tu negocio.
                            </div>
                            <hr>
                            <div class="text-center">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="enableDTE" :checked="enableDTE" @change="enableDisableConsumibles('dte')"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">
                                        Activar
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6">
                        <div class="mt-2">
                            <h5 class="text-dark-gray">
                                SMS o Mensajes de texto
                                <i class="infoQuestion fa fa-question-circle ml-3"></i>
                            </h5>
                            <div class="text-justify">
                                Cuando esta opción se encuentra activa, tus clientes recibirán un mensaje de texto a su teléfono celular (si está registrado), con la información de facturación, confirmación de compra y algunas otras operaciones.
                            </div>
                            <hr>
                            <div class="text-center">
                                <label class="toggle">
                                    <input class="toggle-checkbox" type="checkbox" v-model="enableSMS" :checked="enableSMS" @change="enableDisableConsumibles('sms')"/>
                                    <div class="toggle-switch"></div>
                                    <span class="toggle-label">
                                        Activar
                                    </span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import PaymentForm from "src/modules/order/components/PaymentForm";
import {mapGetters} from "vuex";
import {store} from "src/store";
import API from "src/core/Api";
import Numbro from 'numbro';
import dayjs from "dayjs";


export default {
    components: {
        settingLayout,
        PaymentForm,
    },
    data() {
        return {
            showPaymentForm: false,
            planGratuito: false,
            SavedTotal: 0,
            enableSMS: false,
            enableDTE: false,
            showComprarSaldo: false,
            rangeSlidersModified: false,
            rangeSliders: {},
            totalAmount: 0,
            totalModules: 0,
            totalConsumibles: 0,
            modulos: {},
            modulosEnabled: {},
            paramsHasChanges: false,
            siguienteFechaPago: '',
            upgradeFechaPago: '',
            opcionales: {},

            // Activación de licencia
            licenseDigital: '',
            planObj: {},
            showActivation: false
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    mounted() {
        this.getLicenceInfo();
    },
    watch: {
        showComprarSaldo: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        totalAmount: function (val) {
            this.paramsHasChanges = this.SavedTotal !== val;
        },
        rangeSliders: {
            deep: true,
            handler() {
                this.calculateTotal();
            }
        },
        modulosEnabled: {
            deep: true,
            handler() {
                this.calculateTotal();
            }
        },
    },
    methods: {
        dayjs,
        Numbro,
        calculateTotal() {

            const self = this;

            /*const transPrice = (store.getters.whiteLabelInfo.tp) ? store.getters.whiteLabelInfo.tp : 0;*/
            /*const smsPrice = (store.getters.whiteLabelInfo.sp) ? store.getters.whiteLabelInfo.sp : 0;*/
            /*const dtePrice = (store.getters.whiteLabelInfo.dp) ? store.getters.whiteLabelInfo.dp : 0;*/

            let Total = 0;
            let TotalReal = 0;
            for (let moduleID in this.modulosEnabled) {
                if (this.modulosEnabled[moduleID]) {
                    if (this.modulos[moduleID].precio) {
                        Total = parseFloat(Total) + parseFloat((typeof (this.modulos[moduleID].precioDeUpgrade) !== 'undefined') ? this.modulos[moduleID].precioDeUpgrade : 0);
                        TotalReal = parseFloat(TotalReal) + parseFloat((typeof (this.modulos[moduleID].precio) !== 'undefined') ? this.modulos[moduleID].precio : 0);
                    }
                }
            }

            self.totalModules = TotalReal;

            // sumo los consumibles
            //self.totalConsumibles = ((parseFloat(this.rangeSliders['range_transacciones']) * parseFloat(transPrice)) + (parseFloat(this.rangeSliders['range_sms']) * parseFloat(smsPrice)) + (parseFloat(this.rangeSliders['range_dte']) * parseFloat(dtePrice)));

            self.totalAmount = Total;

            return self.totalAmount;
        },
        getLicenceInfo() {
            const self = this;
            this.showActivation = false;
            API.send('POST', 'orders/plan/checkPlan', {},
                function (response) {
                    if (response.status) {
                        self.planObj = response.data;

                        if (!self.planObj.activated) {
                            self.showActivation = true;
                        }
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        prepareToChangePreferences() {
            const self = this;
            API.send('POST', 'users/plan-info/', {},
                function (response) {
                    if (response.status) {
                        for (let item in response.data.user) {
                            self.modulosEnabled[item] = (parseInt(response.data.user[item]) === 1);
                        }
                        self.modulos = response.data.global;
                        self.enableRangeConfig();
                        self.SavedTotal = self.calculateTotal();
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        saveConsumiblesSettings() {

            const self = this;

            API.showConfirm('Antes de continuar', 'Esta acción puede modificar el monto que paga de forma mensual, ¿desea continuar?', function (){
                API.send('POST', 'users/consumibles/save-settings', {
                        "consumibles": self.rangeSliders,
                    },
                    function (response) {
                        if (response.status) {
                            self.rangeSlidersModified = false;
                            API.showSuccessNotify(response.msg);
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        },
        disableModules() {

            const self = this;

            API.showConfirm('Antes de continuar', 'Se cancelará la renovación de este módulo, podrás utilizarlo hasta la siguiente fecha de pago.', function (){
                API.send('POST', 'users/modules/disable', {
                        "consumibles": self.rangeSliders,
                    },
                    function (response) {
                        if (response.status) {
                            self.rangeSlidersModified = false;
                            API.showSuccessNotify(response.msg);
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        },
        doPay(data) {

            const self = this;

            const dataSave = {
                "card_name": data.cardName,
                "card_number": data.cardNumber,
                "card_exp_date": data.cardMonth + "/" + data.cardYear.toString().substr(2),
                "card_cvv": data.cardCvv,
                "modules": this.modulosEnabled,
                "consumibles": this.rangeSliders,
            }

            API.send('POST', 'payments/do_pay/plans-and-transactions', dataSave,
                function (response) {
                    if (response.status) {
                        self.showPaymentForm = false;
                        self.paramsHasChanges = false;
                        self.rangeSlidersModified = false;
                        API.showSuccessNotify(response.msg);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        enableDisableConsumibles(type) {

            let alert = "";
            if (type === 'dte') {
                if (!this.enableDTE) {
                    alert = 'Si desactivas esta opción, el sistema dejará de facturar automáticamente. Los DTE o facturas electrónicas dejarán de descontarse de tu saldo';
                }
                else {
                    alert = 'Si activas esta opción, el sistema emitirá DTE o facturas electrónicas automáticamente. Estos iniciarán a descontarse de tu saldo';
                }
            }
            else if (type === 'sms') {

                if (!this.enableSMS) {
                    alert = 'Si desactivas esta opción, el sistema dejará de enviar notificaciones por SMS o mensaje de texto. Los SMS dejarán de descontarse de tu saldo';
                }
                else {
                    alert = 'Si activas esta opción, el sistema enviará automáticamente notificaciones por SMS o mensaje de texto. Los SMS iniciarán a descontarse de tu saldo';
                }
            }

            const self = this;

            API.showConfirm('Antes de continuar', alert, function () {
                API.send('POST', 'users/consumibles/enable-disable', {
                        "dte": self.enableDTE,
                        "sms": self.enableSMS,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }, function () {
                if (type === 'dte') {
                    self.enableDTE = !self.enableDTE;
                }
                else if (type === 'sms') {
                    self.enableSMS = !self.enableSMS;
                }
            })
        },
        activarMiCuenta() {

            const self = this;

            const dataSave = {
                "license": this.licenseDigital,
            }

            API.showConfirm('Antes de continuar', 'Las licencias son únicas por tienda, al activarla no la podrás utilizar en otra tienda, ¿deseas continuar?', function (){
                API.send('POST', 'orders/plan/activatePlan', dataSave,
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.getLicenceInfo();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        }
    },
};
</script>
