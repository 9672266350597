<template>
    <layout>
        <div class="content-body">
            <div class="mb-80">
                <div class="container h-100">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-xl-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-5">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Tienda online
                                                <i class="fa fa-question-circle ml-3" v-tooltip="'Permite configurar propiedades de tu sitio web'"></i>
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <!--<div class="col-12 text-center">
                                                    <p class="mb-4">
                                                        Puedes activar o desactivar la visibilidad de tu tienda en línea. Cuando la visibilidad esté activada, podras acceder a tu propio sitio web donde se mostrarán los productos configurados en tu cuenta. </p>
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="enableWebSite" :checked="enableWebSite"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">Habilitar sitio web</span>
                                                    </label>
                                                    <hr>
                                                </div>-->
                                                <div v-show="enableWebSite" class="form-group col-12">
                                                    <label class="mr-sm-2">
                                                        Dirección de sitio web
                                                        <i class="fa fa-question-circle" v-tooltip="'El subdominio determina la URL o dirección web en la cual se podrá visualizar tu sitio web'"></i>
                                                    </label>
                                                    <div v-if="!showChangeSubdomain && store.uriTienda !== ''" class="text-center mt-3">
                                                        <p class="card-text mt-2">
                                                            Tu tienda online se encuentra activa, la dirección es: </p>
                                                        <p class="text-center">
                                                            <a class="text-primary" :href="'https://' + store.uriTienda" target="_blank">https://{{ store.uriTienda }}</a>
                                                        </p>
                                                        <div>
                                                            <small class="text-danger">
                                                                <a @click="showChangeSubdomain = true"><i class="fa fa-edit cursor-pointer"></i> Cambiar la dirección de mi tienda</a>
                                                            </small>
                                                        </div>
                                                    </div>
                                                    <div v-if="showChangeSubdomain">
                                                        <div class="input-group mb-3">
                                                            <input type="text" class="form-control" placeholder="mi-tienda" aria-label="mi-tienda" v-model="subdomain">
                                                            <div class="input-group-append">
                                                                <span class="input-group-text" id="basic-addon2">.{{ AuthGetUserInfo.whiteLabelDomain }}</span>
                                                            </div>
                                                            <small class="text-center mt-2">
                                                                Puedes seleccionar el subdominio de tu tienda a voluntad si este no ha sido utilizado por algún otro usuario. El subdominio no debe contener letras mayúsculas o caracteres especiales. Utiliza guión medio en lugar de espacio.
                                                            </small>
                                                        </div>
                                                        <div v-if="!subdomainValid">
                                                            <div class="text-center mt-4">
                                                                <div @click="validateDomain" class="btn btn-outline-primary btn-sm">Validar disponibilidad</div>
                                                            </div>
                                                        </div>
                                                        <div v-if="subdomainValid">
                                                            <hr>
                                                            <div class="text-center">
                                                                <h5 class="card-title text-primary">El subdominio se encuentra disponible</h5>
                                                                <p class="card-text mt-2">
                                                                    Felicidades, puedes utilizar el subdominio que seleccionaste, la url de tu tienda será: </p>
                                                                <p class="text-center text-primary">
                                                                    {{ newStoreUri }} </p>
                                                                <div class="btn btn-primary btn-sm" @click="changeSubdomain">
                                                                    Guardar y utilizar subdominio
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="text-center">
                                                            <div class="text-danger mt-3">
                                                                <a @click="cancelDomainChange"> Cancelar</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Whatsapp para la tienda</label>
                                                    <input type="tel" class="form-control" placeholder="Escribe aquí" v-model="whatsapp"/>
                                                    <div>
                                                        <small class="text-success">
                                                            <a href="https://faq.whatsapp.com/general/about-international-phone-number-format/?lang=es_pe"><i class="mdi mdi-whatsapp cursor-pointer"></i>
                                                                502 (Guatemala) Recuerda ingresar los numeros de teléfono en formato internacional. <span class="text-muted">Ejemplo: 50255555555</span>
                                                            </a>
                                                        </small>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <form method="post" @submit.prevent="formSubmit">
                                        <div class="card" v-if="enableWebSite">
                                            <div class="card-body">
                                                <div class="row">
                                                    <div class="form-group col-12">
                                                        <label class="mr-sm-2">Nombre de tienda</label>
                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="nombre"/>
                                                        <span class="validationError">{{ Valid.nombre }}</span>
                                                    </div>
                                                    <div class="form-group col-12">
                                                        <label class="mr-sm-2">
                                                            Descripción de sitio web
                                                            <i class="fa fa-question-circle" v-tooltip="'Una breve descripción sobre tu tienda, puedes colocar el giro de tu negocio, alguna descripción general sobre lo que vendes, etc.'"></i>
                                                        </label>
                                                        <div>
                                                            <TextEditor v-model:editor-value="descripcion"/>
                                                            <span class="validationError">{{ Valid.descripcion }}</span>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="mt-4">
                                                    <input type="submit" value="Guardar" class="btn btn-primary"/>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div class="col-12 col-md-7">
                                    <div class="card" v-if="enableWebSite">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Configuracion del sitio web
                                                <i class="fa fa-question-circle" v-tooltip="'Puedes cargar imágenes para mostrar en el carrusel principal del sitio'"></i>
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="col-sm-12 col-lg-3 col-md-2">
                                                    <div class="btn-group btn-group-sm btn-group-vertical mb-3" role="group" aria-label="Small group">
                                                        <button :class="{'btn btn-primary': tipoImagen === 'colores', 'btn btn-outline-primary': tipoImagen !== 'colores' }" @click="tipoImagen = 'colores'" type="button">Colores</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'logo', 'btn btn-outline-primary': tipoImagen !== 'logo' }" @click="tipoImagen = 'logo'" type="button">Logotipo</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'carroussel', 'btn btn-outline-primary': tipoImagen !== 'carroussel' }" @click="tipoImagen = 'carroussel'" type="button">Banner</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'bloque1', 'btn btn-outline-primary': tipoImagen !== 'bloque1' }" @click="tipoImagen = 'bloque1'; tabCarroussel = 'bloque1';" type="button">Bloque 1</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'bloque2', 'btn btn-outline-primary': tipoImagen !== 'bloque2' }" @click="tipoImagen = 'bloque2'" type="button">Bloque 2</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'bloque3', 'btn btn-outline-primary': tipoImagen !== 'bloque3' }" @click="tipoImagen = 'bloque3'" type="button">Planes</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'bloque4', 'btn btn-outline-primary': tipoImagen !== 'bloque4' }" @click="tipoImagen = 'bloque4'" type="button">Banner corto</button>
                                                        <button :class="{'btn btn-primary': tipoImagen === 'footer', 'btn btn-outline-primary': tipoImagen !== 'footer' }" @click="tipoImagen = 'footer'" type="button">Footer</button>
                                                    </div>
                                                </div>
                                                <div class="col-sm-12 col-lg-9 col-md-10 pl-4">
                                                    <div v-if="enableWebSite && tipoImagen ==='colores'">
                                                        <div>
                                                            <h5>Editando los colores principales del sitio público</h5>
                                                        </div>
                                                        <div class="row">
                                                            <div class="form-group col-sm-6">
                                                                <label class="mr-sm-2" v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                    Color primario <i class="fa fa-circle fa-2x" :style="{'color': typeof storeLayout[tipoImagen].primario === 'object' ? storeLayout[tipoImagen].primario.hex8 : storeLayout[tipoImagen].primario}"></i>
                                                                </label>
                                                                <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                    <Sketch v-model="storeLayout[tipoImagen].primario"></Sketch>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-sm-6">
                                                                <label class="mr-sm-2" v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                    Color secundario <i class="fa fa-circle fa-2x" :style="{'color': typeof storeLayout[tipoImagen].secundario === 'object' ? storeLayout[tipoImagen].secundario.hex8 : storeLayout[tipoImagen].secundario}"></i>

                                                                </label>
                                                                <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                    <Sketch v-model="storeLayout[tipoImagen].secundario"></Sketch>
                                                                </div>
                                                            </div>
                                                            <div class="form-group col-sm-6">
                                                                <label class="mr-sm-2" v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                    Color del texto <i class="fa fa-circle fa-2x" :style="{'color': typeof storeLayout[tipoImagen].texto === 'object' ? storeLayout[tipoImagen].texto.hex8 : storeLayout[tipoImagen].texto}"></i>

                                                                </label>
                                                                <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                    <Twitter v-model="storeLayout[tipoImagen].texto"></Twitter>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='logo'">
                                                        <div class="col-12 text-center mb-4 mt-2">
                                                            <img v-show="logoUrl !== '' && logoUrl" :src="logoUrl" style="max-height: 200px; max-width: 100%">
                                                            <span v-if="logoUrl === ''">Aún no ha configurado el logotipo para la tienda</span>
                                                        </div>
                                                        <div class="col-12">
                                                            <FileUploader field-name="logoFile" max-files="1" url="users/onlineStore/save/logo" accepted-mime="image/jpg, image/jpeg, image/png, image/gif" remove-file-on-finish="1" @fileSuccess="logoUploadSuccess"></FileUploader>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='carroussel'" >
                                                        <div>
                                                            <h5>Versión PC o Desktop</h5>
                                                            <div class="text-muted text-small">
                                                                Para editar títulos o cargar la versión móvil, presione en el ícono editar sobre cada miniatura.
                                                            </div>
                                                        </div>
                                                        <div class="mb-3">
                                                            <div class="galleryItem" v-for="item in carruselPrincipal" :key="item.file">
                                                                <div class="galleryItemImg" :style="'background-image: url('+item.url+')'">
                                                                    <div class="overlay">
                                                                        <!--                                                                        <i class="fa fa-search mr-2"></i>-->
                                                                        <i class="fa fa-trash p-1" @click="deleteImageFromGallery(item.file,0)"></i>
                                                                        <i class="fa fa-sliders p-1" @click="tabCarroussel = item.file; formExtraData.file = item.file;"></i>
                                                                    </div>
                                                                </div>
                                                                <!--<div class="galleryItemImg" :style="'background-image: url('+item.urlMobile+')'" v-if="item.urlMobile">
                                                                    <div class="overlay">
                                                                        <i class="fa fa-trash p-1" @click="deleteImageFromGallery(item.file, item.fileMobile)"></i>
                                                                    </div>
                                                                </div>-->
                                                            </div>
                                                            <div v-if="Object.keys(carruselPrincipal) === 0" class="col-12 text-center mt-4 text-muted">
                                                                Sin imágenes a mostrar
                                                            </div>
                                                        </div>
                                                        <div v-for="item in carruselPrincipal" :key="item.file">
                                                            <div v-if="tabCarroussel === item.file" class="bannerEditionConfig">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <h5>
                                                                            Edición de banner o slide
                                                                        </h5>
                                                                    </div>
                                                                    <div class="col-6 text-right">
                                                                        <span class="text-danger font-weight-light text-decoration-underline ml-3 cursor-pointer" @click="tabCarroussel = false">Cerrar edición</span>
                                                                    </div>
                                                                </div>
                                                                <hr class="my-2">
                                                                <div class="form-group">
                                                                    <div class="mb-3">
                                                                        <label class="mr-sm-2">
                                                                            Versión de escritorio o pantallas grandes
                                                                        </label>
                                                                        <div>
                                                                            <img :src="item.url" class="img-thumbnail w-25" v-if="item.file === tabCarroussel"/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <div>
                                                                        <label class="mr-sm-2">
                                                                            Versión para móvil o pantallas pequeñas
                                                                        </label>
                                                                    </div>
                                                                    <div class="galleryContainer" v-if="item.urlMobile">
                                                                        <div class="galleryItem">
                                                                            <div class="galleryItemImg" :style="'background-image: url('+item.urlMobile+')'">
                                                                                <div class="overlay">
                                                                                    <!--                                                                        <i class="fa fa-search mr-2"></i>-->
                                                                                    <i class="fa fa-trash p-1" @click="deleteImageFromGallery(item.file, item.fileMobile)"></i>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>
                                                                        <div class="col-12 mt-3" v-if="!item.urlMobile">
                                                                            <FileUploader field-name="carruselFileMobile" url="users/onlineStore/save/carrousel" :extra-data-form="formExtraData" accepted-mime="image/jpg, image/jpeg, image/png, image/gif" remove-file-on-finish="1" disable-multiple="true" @fileSuccess="fileSuccessMobile"></FileUploader>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Texto imagen
                                                                        <i class="fa fa-question-circle" v-tooltip="'Texto que quieres que aparezca en tu slider'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <TextEditor v-model:editor-value="item.texto"/>
                                                                        <span class="validationError">{{ Valid.descripcion }}</span>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Texto del botón
                                                                        <i class="fa fa-question-circle" v-tooltip="'Texto que quieres que aparezca en tu botón'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="item.textoBoton"/>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Link del botón
                                                                        <i class="fa fa-question-circle" v-tooltip="'Url del producto o de la pagina que quieres que tenga tu botón'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="item.urlBoton"/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!tabCarroussel">
                                                            <div v-if="Object.keys(carruselPrincipal).length < 5">
                                                                <FileUploader field-name="carruselFile" url="users/onlineStore/save/carrousel" accepted-mime="image/jpg, image/jpeg, image/png, image/gif" remove-file-on-finish="1" @fileSuccess="fileSuccess"></FileUploader>
                                                            </div>
                                                            <div>
                                                                <div class="text-center">
                                                                    Tamaño recomendado para imágenes "1920x760px" para escritorio y "1080x1770" para dispositivos móviles.
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='bloque1'">
                                                        <div>
                                                            <h5>Editando texto de los bloques</h5>
                                                        </div>
                                                        <div class="mb-3">
                                                            <div v-if="typeof (storeLayout) !=='undefined' " class="galleryItem" v-for="(item, key) in storeLayout[tipoImagen]" :key="key">
                                                                {{item.titulo}}
                                                                <div class="galleryItemImg" :style="'background-text:'+key">
                                                                    <div class="overlay">
                                                                        <!--                                                                        <i class="fa fa-search mr-2"></i>-->
                                                                        <i class="fa fa-trash p-1" @click="deleteItemLayout(tipoImagen,key)"></i>
                                                                        <i class="fa fa-sliders p-1" @click="itemKeyLayout = key; formExtraData.bloque = tipoImagen; tabCarroussel = true;"></i>
                                                                    </div>
                                                                </div>
                                                                <!--<div class="galleryItemImg" :style="'background-image: url('+item.urlMobile+')'" v-if="item.urlMobile">
                                                                    <div class="overlay">
                                                                        <i class="fa fa-trash p-1" @click="deleteImageFromGallery(item.file, item.fileMobile)"></i>
                                                                    </div>
                                                                </div>-->
                                                            </div>
                                                            <div v-if="Object.keys(carruselPrincipal) === 0" class="col-12 text-center mt-4 text-muted">
                                                                Sin bloques a mostrar
                                                            </div>
                                                        </div>
                                                        <div v-for="(item, key) in storeLayout[tipoImagen]" :key="key">
                                                            <div v-if="itemKeyLayout === key && tabCarroussel" class="bannerEditionConfig">
                                                                <div class="row">
                                                                    <div class="col-6">
                                                                        <h5>
                                                                            Edición del bloque {{item.titulo}}
                                                                        </h5>
                                                                    </div>
                                                                    <div class="col-6 text-right">
                                                                        <span class="text-danger font-weight-light text-decoration-underline ml-3 cursor-pointer" @click="tabCarroussel = false">Cerrar edición</span>
                                                                    </div>
                                                                </div>
                                                                <hr class="my-2">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Título del bloque
                                                                        <i class="fa fa-question-circle" v-tooltip="'Texto que quieres que aparezca en bloque'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="item.titulo"/>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Descripción
                                                                        <i class="fa fa-question-circle" v-tooltip="'Texto que quieres que aparezca en bloque'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="item.desc"/>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Texto del botón
                                                                        <i class="fa fa-question-circle" v-tooltip="'Url del producto o de la pagina que quieres que tenga tu botón'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="item.textB"/>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Link del botón
                                                                        <i class="fa fa-question-circle" v-tooltip="'Url del producto o de la pagina que quieres que tenga tu botón'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="item.linkB"/>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Color de fondo
                                                                        <i class="fa fa-question-circle" v-tooltip="'color que quieres que tenga tu bloque'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <Twitter v-model="item.bgColor"></Twitter>
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Color del título
                                                                        <i class="fa fa-question-circle" v-tooltip="'Color del titulo'"></i>
                                                                    </label>
                                                                    <div>
                                                                        <Twitter v-model="item.txtColor"></Twitter>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div v-if="!tabCarroussel">
                                                            <div>
                                                                <div class="text-center" v-if="typeof (storeLayout[tipoImagen]) !== 'undefined' && Object.keys(storeLayout[tipoImagen])  <= 4">
                                                                    <button class="btn btn-sm btn-success" @click="addItem(tipoImagen)"><i class="fa fa-plus-circle"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='bloque2'">
                                                        <div>
                                                            <h5>Editando el titulo que aparece en marcas</h5>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Título 1
                                                                        <i class="fa fa-question-circle" v-tooltip="'Primer título'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].titulo1" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Título 2
                                                                        <i class="fa fa-question-circle" v-tooltip="'Título de la sección'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].titulo" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Descripción
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].desc" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='bloque3'">
                                                        <div>
                                                            <h5>Editando el titulo que aparece en planes</h5>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Título
                                                                        <i class="fa fa-question-circle" v-tooltip="'Título de la sección'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].titulo" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Descripción
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].desc" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='bloque4'">
                                                        <div>
                                                            <h5>Editando el banner corto {{tipoImagen}}</h5>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Título
                                                                        <i class="fa fa-question-circle" v-tooltip="'Título de la sección'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].titulo" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Descripción
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].desc" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Titulo del botón
                                                                        <i class="fa fa-question-circle" ></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].textB" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Link del botón
                                                                        <i class="fa fa-question-circle" v-tooltip="'Link del botón'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].linkB" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div v-show="enableWebSite && tipoImagen ==='footer'">
                                                        <div>
                                                        </div>
                                                        <div class="row">
                                                            <div class="col-12">


                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">

                                                                        Correo
                                                                        <i class="fa fa-question-circle" v-tooltip="'correo electrónico'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].correo" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Dirección
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].direc" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Facebook
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].fb" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Instagram
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].ig" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Youtube
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].yt" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Tik Tok
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].tk" />
                                                                    </div>
                                                                </div>
                                                                <div class="form-group">
                                                                    <label class="mr-sm-2">
                                                                        Twitter
                                                                        <i class="fa fa-question-circle" v-tooltip="'Descripción de tus marcas o como quieras llamar a esta categoría'"></i>
                                                                    </label>
                                                                    <div v-if="typeof (storeLayout[tipoImagen]) !== 'undefined'">
                                                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="storeLayout[tipoImagen].tw" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import FileUploader from 'src/components/files/FileUploader';
import { Twitter, Material, Sketch, Grayscale } from '@ckpack/vue-color';
//import GalleryFile from 'src/components/files/GalleryFile';
import TextEditor from 'src/components/TextEditor';
import {mapGetters} from "vuex";
import {store} from 'src/store'
import DataValidation from "src/core/DataValidation";

export default {
    components: {
        Layout,
        FileUploader,
        TextEditor,
        Twitter,
        Material,
        Sketch,
        Grayscale
    },
    data() {
        return {
            Valid: {},
            enableWebSite: false,
            //gallery: {},
            masInformacion: false,
            nombre: "",
            descripcion: "",
            store: {},
            showChangeSubdomain: false,
            subdomain: '',
            subdomainValid: false,
            newStoreUri: '',
            logoUrl: '',
            carruselPrincipal: {},
            itemKeyLayout: {},
            storeLayout: {
                'bloque1': [{
                    'titulo': '',
                    'desc': '',
                    'textB': '',
                    'linkB': '',
                    'bgColor': '',
                    'txtColor': '',
                }],
                'colores': {
                    'primario': (typeof (store.getters.whiteLabelInfo.primaryColor) !=='undefined')? store.getters.whiteLabelInfo.primaryColor:'',
                    'secundario': (typeof (store.getters.whiteLabelInfo.secondaryColor) !=='undefined')? store.getters.whiteLabelInfo.secondaryColor:'',
                    'texto': (typeof (store.getters.whiteLabelInfo.thirdColor) !=='undefined')? store.getters.whiteLabelInfo.thirdColor:'',
                    'fuente': '',
                },
                'bloque2': {
                    'titulo': '',
                    'titulo1': '',
                    'desc': '',
                },
                'bloque3': {
                    'titulo': '',
                    'desc': '',
                },
                'bloque4': {
                    'titulo': '',
                    'desc': '',
                    'textB': '',
                    'linkB': '',
                },
                'footer': {
                    'correo': '',
                    'direc': '',
                    'fb': '',
                    'ig': '',
                    'tk': '',
                    'yt': '',
                    'tw': '',
                }
            },
            tabCarroussel: '',
            tipoImagen: 'carroussel',// logo||carroussel
            whatsapp: '',// formato internacional
            taxCode: '',
            taxAddress: '',
            taxName: '',
            emailTienda: '',
            formExtraData: {
                file: 0,
                bloque: ''
            },
        };
    },
    ready:{
        returnHex(item){
            console.log(item);
            let color = (typeof item === 'object') ? item.hex8 : item;
            return item = color;

        },
    },
    watch: {
        subdomain: function (val) {
            this.subdomain = val.replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
            this.subdomainValid = false;
            this.newStoreUri = '';
        },
        user: {
            handler:function(newVal) {
                console.log("new Value is " + newVal)    },
            deep:true
        },
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
    mounted: function () {
        this.loadStoreSettings();
    },
    methods: {
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue);
        },
        loadStoreSettings() {
            const self = this;
            API.send('GET', 'users/onlineStore/all', {},
                function (response) {
                    if (response.status) {
                        self.store = response.data;

                        // fill form
                        self.nombre = response.data.nombreTienda;
                        self.taxCode = response.data.taxCode;
                        self.taxAddress = response.data.taxAddress;
                        self.whatsapp = response.data.telefono;
                        self.taxName = response.data.taxName;
                        self.emailTienda = response.data.emailTienda;
                        self.descripcion = response.data.descripcionTienda;
                        self.logoUrl = response.data.logoTienda;
                        if (!response.data.bannerTienda) response.data.bannerTienda = {};

                        try {
                             if(!Tools.objectIsEmpty(JSON.parse(response.data.layoutIndex))){
                                 const dataTemp = JSON.parse(response.data.layoutIndex);
                                 if(!Tools.objectIsEmpty(dataTemp['colores'])){
                                     self.storeLayout.colores = dataTemp['colores'];
                                 }
                                 if(!Tools.objectIsEmpty(dataTemp['bloque1'])){
                                     self.storeLayout.bloque1 = dataTemp['bloque1'];
                                 }
                                 if(!Tools.objectIsEmpty(dataTemp['bloque2'])){
                                     self.storeLayout.bloque2 = dataTemp['bloque2'];
                                 }
                                 if(!Tools.objectIsEmpty(dataTemp['bloque3'])){
                                     self.storeLayout.bloque3 = dataTemp['bloque3'];
                                 }
                                 if(!Tools.objectIsEmpty(dataTemp['bloque4'])){
                                     self.storeLayout.bloque4 = dataTemp['bloque4'];
                                 }
                                 if(!Tools.objectIsEmpty(dataTemp['footer'])){
                                     self.storeLayout.footer = dataTemp['footer'];
                                 }
                                 self.carruselPrincipal = JSON.parse(response.data.bannerTienda);
                             }

                        } catch (e) {
                            self.carruselPrincipal = {};
                        }

                        if (!self.store.uriTienda) {
                            self.showChangeSubdomain = true;
                        }
                        else {
                            self.showChangeSubdomain = false;
                            self.enableWebSite = true;
                        }
                        if (self.taxCode !== '') {
                            self.masInformacion = true;
                        }
                        else {
                            self.masInformacion = false;
                        }
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        cancelDomainChange() {
            this.showChangeSubdomain = false;
            if (!this.store.uriTienda) {
                this.enableWebSite = false;
            }
        },
        enableStore() {
            const self = this;

            if (this.subdomain !== '') {
                API.send('GET', 'users/onlineStore/validateSubdomain/' + store.getters.AuthGetUserInfo.whiteLabelDomain + '/' + this.subdomain, {}, function (response) {
                        self.subdomainValid = true;
                        self.newStoreUri = response.data.fullUriTienda;
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            }
            else {
                API.showErrorAlert("Debes ingresar un subdominio para tu tienda");
            }
        },
        validateDomain() {
            const self = this;

            if (this.subdomain !== '') {
                API.send('GET', 'users/onlineStore/validateSubdomain/' + store.getters.AuthGetUserInfo.whiteLabelDomain + '/' + this.subdomain, {}, function (response) {
                        self.subdomainValid = true;
                        self.newStoreUri = response.data.fullUriTienda;
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            }
            else {
                API.showErrorAlert("Debes ingresar un subdominio para tu tienda");
            }
        },
        changeSubdomain() {
            const self = this;

            if (this.subdomain !== '') {

                let alertMsg = "¿Está seguro de aplicar los cambios?";

                if (this.store.uriTienda) {
                    alertMsg = "¡Precaución! Si cambia el subdominio de una tienda existente, el enlace o dirección antiguo quedará inaccesible, ¿está seguro de continuar?"
                }

                API.showConfirm('Antes de continuar', alertMsg, function () {

                    const data = {};
                    data['subdominio'] = self.subdomain;

                    API.send('PUT', 'users/onlineStore/save/subdomain', data, function (response) {
                            API.showSuccessAlert(response.msg);
                            self.loadStoreSettings();
                        },
                        function (data) {
                            API.showErrorAlert(data.msg);
                        })
                })
            }
            else {
                API.showErrorAlert("Debes ingresar un subdominio para tu tienda");
            }
        },

        refreshMultimedia() {
            const self = this;
            API.send('GET', 'products/img/gallery/' + this.$route.params.id, {},
                function (response) {
                    if (response.status) {
                        self.gallery = response.data;
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        logoUploadSuccess() {
            this.loadStoreSettings();
        },
        onEditorUpdate(value) {
            this.descripcion = value;
        },
        formSubmit() {

            const self = this;

            const validation = new DataValidation()
            validation.required('nombre', this.nombre, 'Este campo es requerido');
            validation.required('descripcion', this.descripcion, 'Este campo es requerido');

            this.Valid = validation.validate(function () {
                const dataToSend = {
                    nombre: self.nombre,
                    descripcion: self.descripcion,
                    telefono: self.whatsapp,
                    taxCode: self.taxCode,
                    taxAddress: self.taxAddress,
                    emailTienda: self.emailTienda,
                    taxName: self.taxName,
                    carroussel: JSON.stringify(self.carruselPrincipal),
                    layoutIndex: JSON.stringify(self.storeLayout)
                };

                API.showConfirm('Antes de continuar', '¿Está seguro de guardar?', function () {

                    API.send('PUT', 'users/onlineStore/save/settings', dataToSend, function (response) {
                            API.showSuccessAlert(response.msg);
                        },
                        function (data) {
                            API.showErrorAlert(data.msg);
                        })
                })
            });
        },
        fileSuccess(data) {
            this.carruselPrincipal = Tools.pushToObject(this.carruselPrincipal, data);
        },
        fileSuccessMobile(data) {
            this.carruselPrincipal = data;
        },
        deleteImageFromGallery(file, mobile = 0) {
            const self = this;
            const dataSend = {
                file: file,
                fileMobile: mobile
            }

            API.showConfirm('¿Esta seguro de eliminar?', 'Esta acción no se puede deshacer', function () {

                API.send('DELETE', 'users/onlineStore/delete/carrousel', dataSend,
                    function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);
                            self.carruselPrincipal = response.data;
                        }

                        if (!mobile) {
                            self.tabCarroussel = false;
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        },
        deleteItemLayout(bloque, key) {
            const self = this;
            console.log(bloque);
            console.log(key);

            API.showConfirm('¿Esta seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                delete self.storeLayout[bloque][key];
            })
        },
        addItem(bloque) {
            const self = this;
            if(bloque ==='bloque1'){
                const dataToPush = {
                    'titulo': '',
                    'desc': '',
                    'textB': '',
                    'linkB': '',
                    'bgColor': '',
                    'txtColor': '',
                };
                self.storeLayout[bloque] = Tools.pushToObject(self.storeLayout[bloque], dataToPush);
            }
        }
    },
};
</script>
