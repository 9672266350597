<template>
    <control-comercios-layout :active="60">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Detalle de concilia</h4>
            </div>
            <div class="card-body">
                <div class="form">
                    <div class="media d-block">
                        <div class="form-group">
                            <div v-if="id === 0">
                                <label>Seleccione las fechas de concilia</label>
                                <date-picker v-model.range="range" mode="date"/>
                            </div>
                            <div class="row mt-3">
                                <div class="col">
                                    <h4 v-if="typeof range.start !== 'undefined'"><b>Fecha Inicial</b>: {{dayjs(range.start).format('DD-MM-YYYY')}}</h4>
                                </div>
                                <div class="col">
                                    <h4 v-if="typeof range.end !== 'undefined'"><b>Fecha Final</b>: {{dayjs(range.end).format('DD-MM-YYYY')}}</h4>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 col-6">
                                <div class="form-group">
                                    <label>Tipo de concilia</label>
                                    <select v-model="type" class="form-control" :disabled="id > 0">
                                        <!--<option value="pago_terceros">Pago de terceros a clientes</option>
                                        <option value="comision_socios">Pago de comisiones a socios</option>-->
                                        <option value="comision_procesador">Cobro de comisiones a procesador</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="mt-3">
                            <div v-if="type === 'pago_terceros'">
                                <div class="row">
                                    <div class="col-6" v-for="(item, id) in comercios" :key="id">
                                        <input type="checkbox" value="1" v-model="item.active" class="mr-2" :id="'comercio_' + id"/>
                                        <label :for="'comercio_' + id">
                                            <span v-if="item.tienda.dominio">{{item.tienda.dominio}}</span>
                                            <span v-else>{{item.usuario.email}}</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div v-if="type === 'comision_socios'" class="text-muted text-center">
                                Los pagos de comisiones a socios se hacen de forma global para todos los comercios
                            </div>
                            <div v-if="type === 'comision_procesador'" class="text-muted text-center">
                                Los cobros de comisiones al procesador se hacen de forma global para todos los comercios
                            </div>
                        </div>
                        <div v-if="id > 0">
                            <div v-if="type === 'comision_socios' || type === 'pago_terceros'">
                                <h5 class="mt-5">Bancos disponibles para concilias</h5>
                                <div class="row mt-3">
                                    <div class="col-12 col-sm-4">
                                        <div class="widget-card text-center">
                                            <div class="widget-info">
                                                <div class="text-center w-100">
                                                    <img style="height: 50px;" src="staticAssets/global/brands/banco-bac-logo.png" alt="" class="m-auto"/>
                                                </div>
                                            </div>
                                            <div class="widget-info mt-4">
                                                <div class="text-center w-100">
                                                    <h5>BAC Credomatic</h5>
                                                    <div>
                                                        <hr>
                                                        <button class="btn btn-success m-1" @click="generate('bac_credomatic', true)">Previsualizar</button>
                                                        <button class="btn btn-primary m-1" @click="generate('bac_credomatic')">Generar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="widget-card text-center">
                                            <div class="widget-info">
                                                <div class="text-center w-100">
                                                    <img style="height: 50px;" src="staticAssets/global/brands/banco-bi-logo.png" alt="" class="m-auto"/>
                                                </div>
                                            </div>
                                            <div class="widget-info mt-4">
                                                <div class="text-center w-100">
                                                    <h5>Banco Industrial</h5>
                                                    <div>
                                                        <hr>
                                                        <button class="btn btn-success m-1" @click="generate('banco_industrial', true)">Previsualizar</button>
                                                        <button class="btn btn-primary m-1" @click="generate('banco_industrial')">Generar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-muted">
                                    Nota: Si el cliente aún no ha configurado su cuenta, no se tomará en cuenta para el pago.
                                </div>
                                <div v-if="previewStart">
                                    <h5>Previsualización</h5>
                                    <div class="conciliaPreviewContainer">
                                        <h6 class="text-success">A procesar</h6>
                                        <table class="table table-striped">
                                            <tbody>
                                            <tr v-for="(item, count) in preview" :key="count">
                                                <td v-for="cell in item" :key="cell">{{cell}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h6 class="text-danger">Con errores</h6>
                                        <table class="table table-striped">
                                            <tbody>
                                            <tr v-for="(item, count) in previewErrors" :key="count">
                                                <td v-for="cell in item" :key="cell">{{cell}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div v-if="type === 'comision_procesador'">
                                <h5 class="mt-5">Procesadores disponibles para concilia</h5>
                                <div class="row mt-3">
                                    <div class="col-12 col-sm-4">
                                        <div class="widget-card text-center">
                                            <div class="widget-info">
                                                <div class="text-center w-100">
                                                    <img style="height: 50px;" src="staticAssets/global/brands/banco-bac-logo.png" alt="" class="m-auto"/>
                                                </div>
                                            </div>
                                            <div class="widget-info mt-4">
                                                <div class="text-center w-100">
                                                    <h5>BAC Credomatic</h5>
                                                    <div>
                                                        <hr>
                                                        <button class="btn btn-success m-1" @click="generate('bac_credomatic', true)">Previsualizar</button>
                                                        <button class="btn btn-primary m-1" @click="generate('bac_credomatic')">Generar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-sm-4">
                                        <div class="widget-card text-center">
                                            <div class="widget-info">
                                                <div class="text-center w-100">
                                                    <img style="height: 50px;" src="staticAssets/global/brands/visanet.png" alt="" class="m-auto"/>
                                                </div>
                                            </div>
                                            <div class="widget-info mt-4">
                                                <div class="text-center w-100">
                                                    <h5>Visanet Guatemala</h5>
                                                    <div>
                                                        <hr>
                                                        <button class="btn btn-success m-1" @click="generate('visanet', true)">Previsualizar</button>
                                                        <button class="btn btn-primary m-1" @click="generate('visanet')">Generar</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="text-muted">
                                    Nota: Si el cliente aún no ha configurado su cuenta, no se tomará en cuenta para el pago.
                                </div>
                                <div v-if="previewStart">
                                    <h5>Previsualización</h5>
                                    <div class="conciliaPreviewContainer">
                                        <h6 class="text-success">A procesar</h6>
                                        <table class="table table-striped">
                                            <tbody>
                                            <tr v-for="(item, count) in preview" :key="count">
                                                <td v-for="cell in item" :key="cell">{{cell}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <h6 class="text-danger">Con errores</h6>
                                        <table class="table table-striped">
                                            <tbody>
                                            <tr v-for="(item, count) in previewErrors" :key="count">
                                                <td v-for="cell in item" :key="cell">{{cell}}</td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <span> <a href="https://anysubscriptions-public.s3.us-east-2.amazonaws.com/_anysubscriptions.com/plantilla.xlsx">Descargar plantilla <i class="fa fa-file-excel"></i></a></span>
                        <file-uploader></file-uploader>
                        <div class="text-center mt-4">
                            <button @click="save" class="btn btn-primary mx-1 mb-2"><i
                                class="mdi mdi-content-save"></i> Guardar
                            </button>
                            <button @click="$router.push('/sub-commerce/concilia')" class="btn btn-danger mx-1 mb-2"><i
                                class="mdi mdi-cancel"></i> Cancelar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </control-comercios-layout>
</template>
<script>
import controlComerciosLayout from "../../components/settings/controlComerciosLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import {DatePicker} from "v-calendar";
import {ref} from "vue";


export default {
    computed: {
        dayjs() {
            return dayjs
        }
    },
    components: {
        DatePicker,
        controlComerciosLayout,
        FileUploader,
    },
    data() {
        return {
            id: 0,
            type: '',
            range: {
                start: null,
                end: null,
            },
            pagado: false,
            comercios: {},

            previewStart: false,
            preview: {},
            previewErrors: {},
        }
    },
    mounted() {
        this.id = parseInt(this.$route.params.id);
        const dateNow = new Date();
        this.range = ref({
            start: new Date(dateNow.getFullYear(), dateNow.getMonth(), 1),
            end: new Date(dateNow.getFullYear(), dateNow.getMonth() + 1, 0),
        });
        this.loadItems();
        this.getComercios();
    },
    methods: {
        loadItems() {
            const self = this;

            if (this.id > 0) {
                API.send('GET', 'users/concilia/by/id/' + this.id, {},
                    function (response) {
                        if (response.status) {
                            self.type = response.data.type;
                            self.pagado = response.data.pagado;
                            self.range = ref({
                                start: new Date(response.data.fechaInicial + "T00:00:00"),
                                end: new Date(response.data.fechaFinal + "T00:00:00"),
                            });
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            }
        },
        save() {
            const self = this;
            API.send('POST', 'users/concilia/save', {
                    id: self.id,
                    fechaInicial: self.range.start,
                    fechaFinal: self.range.end,
                    type: self.type,
                    pagado: 0,
                }, function (response) {
                API.showSuccessAlert(response.msg);
                window.location.href = '/sub-commerce/concilia/' + response.data.id;
            },
            function (data) {
                API.showErrorNotify(data.msg);
            })
        },
        getComercios() {
            const self = this;
            API.send('GET', 'stores/sub/commerce-list', {},
                function (response) {
                    if (response.status) {
                        self.comercios = response.data.usuarios;
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
        generate(bank, preview) {
            const self = this;

            if (preview) {
                this.previewStart = 1;
            }

            API.send('POST', 'payments/generate/whitelabel/bank-authorization', {
                    bank: bank,
                    preview: preview,
                    id: self.id
                },
                function (response) {
                    if (response.status) {
                        if (typeof response.data.resultFile !== 'undefined') {
                            window.open(response.data.resultFile);
                        }
                        else {
                            self.preview = response.data.preview;
                            self.previewErrors = response.data.errors;
                        }
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        },
    },
};
</script>
