<template>
    <layout :active="20" :title="true">
        <div class="content-body">
            <div class="container">
                <div class="row">
                    <div class="col-xl-3 col-md-4">
                        <div class="card settings_menu">
                            <div class="card-header">
                                <h4 class="card-title">Configuración</h4>
                            </div>
                            <div class="card-body">
                                <ul>
                                    <li class="nav-item" v-if="Access('users-config-plan')">
                                        <router-link to="/plan-and-packages" class="nav-link" :class="active === 22 && 'active'">
                                            <i class="mdi mdi-credit-card"></i>
                                            <span>Plan y método de pago</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-config-perfil')">
                                        <router-link to="/account" class="nav-link" :class="active === 20 && 'active'">
                                            <i class="mdi mdi-account"></i>
                                            <span>Editar perfil</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-form')">
                                        <router-link to="/forms-list" class="nav-link" :class="active === 27 && 'active'">
                                            <i class="mdi mdi-file-document"></i>
                                            <span>Docs+</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/medic/dashboard" class="nav-link" :class="active === 28 && 'active'">
                                            <i class="mdi mdi-medical-bag"></i>
                                            <span>Medico</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-integraciones-admin')">
                                        <router-link to="/account-affiliations" class="nav-link" :class="active === 21 && 'active'">
                                            <i class="la la-boxes"></i>
                                            <span>Integraciones</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-integraciones-solicitud')">
                                        <router-link to="/account-solicitudes" class="nav-link" :class="active === 23 && 'active'">
                                            <i class="la la-list-ol"></i>
                                            <span>Solicitudes de integración</span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('users-localidad')">
                                        <router-link to="/account-locations" class="nav-link" :class="active === 26 && 'active'">
                                            <i class="mdi mdi-store"></i>
                                            <span>
                                                Localidad, bodegas, envíos
                                            </span>
                                        </router-link>
                                    </li>
                                    <li class="nav-item" v-if="Access('personal-usuarios_adicionales')">
                                        <router-link to="/account-personal" class="nav-link" :class="active === 25 && 'active'">
                                            <i class="la la-users"></i>
                                            <span>Usuarios adicionales</span>
                                        </router-link>
                                    </li>
                                    <!--<li class="nav-item">
                                        <router-link to="/account-shipping" class="nav-link" :class="active === 27 && 'active'">
                                            <i class="mdi mdi-truck-delivery"></i>
                                            <span>Envíos y coberturas</span>
                                        </router-link>
                                    </li>-->
                                    <li class="nav-item" v-if="Access('users-sistema')">
                                        <router-link to="/account-config" class="nav-link" :class="active === 24 && 'active'">
                                            <i class="mdi mdi-cogs"></i>
                                            <span>Sistema</span>
                                        </router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="col-xl-9 col-md-8">
                        <slot/>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import {mapGetters} from "vuex";

export default {
    name: "SettingLayout",
    components: {
        Layout,
    },
    props: {
        active: Number,
    },
    computed: {
        ...mapGetters({
            Access: 'Access',
        })
    },
};
</script>
