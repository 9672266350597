<template>
    <i class="fa fa-pencil-square-o miniDescripcionIcon" @click="show"></i>
    <div class="miniDescripcionModal" v-if="showModal">
        <div class="miniDescripcionModalContent">
            <div>
                <h4 v-if="!Tools.isEmptyString(title)"> {{title}} </h4>
                <h4 v-else>Datos de complemento</h4>
            </div>
            <div class="datosComplementoList">
                <table class="table table-striped">
                    <tr>
                        <th v-for="cell in dataHead" :key="cell"> {{ cell }} </th>
                    </tr>
                    <tr v-for="item in dataList" :key="item">
                        <td v-for="cell in item" :key="cell"> {{ cell }} </td>
                    </tr>
                </table>
            </div>
            <div class="datosComplemento" :id="id">
                <slot></slot>
            </div>
            <i class="fas fa-times-circle closeBtnModal" @click="close"></i>
            <div class="mt-4 text-right">
                <button class="btn btn-primary btn-sm" @click="save">Guardar</button>
            </div>
        </div>
    </div>
</template>
<script>

import Tools from "src/core/Tools";
import API from "src/core/Api";

export default {
    components: {
    },
    props: [
        /*'show',*/
        'title',
        'id',
    ],
    data() {
        return {
            showModal: false,
            dataHead: {},
            dataList: [],
        };
    },
    watch: {
        /*show: function (val) {
            this.showModal = val;
        }*/
    },
    computed: {
        Tools() {
            return Tools
        }
    },
    mounted() {
    },
    methods: {
        close() {
            this.showModal = false;
        },
        show() {
            this.showModal = true;
        },
        save() {
            const self = this;
            const inputs = document.getElementById(this.id).querySelectorAll('input, select, textarea');

            if (typeof inputs !== 'undefined') {
                const item = {};
                inputs.forEach(function (a) {
                    const name = a.getAttribute('name');
                    if (typeof name !== 'undefined') {
                        item[name] = a.value;
                    }
                    // busco label
                    const label = a.parentElement.querySelector('label');
                    if (typeof label !== 'undefined') {
                        self.dataHead[name] = label.textContent;
                    }

                    a.value = '';
                });

                this.dataList.push(item);
            }

            //console.log(responses);
            this.$emit('save', this.dataList);
        },
    },
};

</script>
