<template>
    <forms-layout :active="20">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de plantillas OCR</h4>
                <img src="staticAssets/formBuilder/docsplus.png" style="max-width: 150px">
            </div>
            <div class="card-body">
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in items" :key="item.id">
                            <div class="row">
                                <div class="col-12">
                                    <div class="media">
                                        <span class="mr-3"><i class="fa fa-bank"></i></span>
                                        <div class="media-body">
                                            <h5 class="mt-0 mb-1">{{item.nombre}}</h5>
                                            <div>
                                                <div>
                                                    Identificador de formulario (Token): {{item.token}}
                                                </div>
                                            </div>
                                        </div>
                                        <div class="edit-option">
                                            <!--<a href="#"><i class="fa fa-eye"></i></a>-->
                                            <a @click="$router.push('/docs-plus-ocr/' + item.id)"><i class="fa fa-pencil"></i></a>
                                            <a @click="deleteForm(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                    <div class="mt-5">
                        <router-link to="/docs-plus-ocr/0" class="btn btn-primary px-4 mr-3">Agregar plantilla</router-link>
                    </div>
                </div>
            </div>
        </div>
    </forms-layout>
</template>

<script>
import formsLayout from "../components/forms/formsLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";

export default {
    components: {
        formsLayout
    },
    data() {
        return {
            items: {},
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('GET', 'formularios/docs-plus/ocr-templates', {},
                function (data) {
                    if (data.status) {
                        self.items = data.data;
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                })
        },
        deleteForm(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'formularios/remove/form/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },

    },
};
</script>
