<template>
    <layout-admin v-if="this.AuthGetUserInfo.layout === 'admin' || this.AuthGetUserInfo.layout === 'cotizador'"><slot/></layout-admin>
    <layout-medico v-if="this.AuthGetUserInfo.layout === 'medico'"><slot/></layout-medico>

</template>

<script>

// importo los layouts
import LayoutMedico from "/src/layout/medico/Layout.vue";
import LayoutAdmin from "/src/layout/admin/Layout.vue";

// otros
import {CONFIG} from "src/config";
import {mapGetters} from "vuex";

export default {
    components: {
        LayoutAdmin,
        LayoutMedico,
    },
    props: {
        active: Number,
        title: Boolean,
        search: Boolean,
    },
    data() {
        return {
            appVersion: ''
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
        }),
    },
};
</script>
