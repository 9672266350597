export default function () {

    const validation = {};

    this.email = (identity, value, msgFail) => {
        const validate = String(value)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );

        validation[identity] = {
            identity: identity,
            valid: validate,
            msg: msgFail,
        };
    }

    this.required = (identity, value, msgFail) => {
        const validate = (value) ? true : false;

        validation[identity] = {
            identity: identity,
            valid: validate,
            msg: msgFail,
        };
    }

    this.validate = (callback) => {
        let boolError = false;
        const errorsToShow = {};
        for (let item in validation) {
            if (!validation[item].valid) {
                errorsToShow[validation[item].identity] = validation[item].msg;
                boolError = true;
            }
        }

        if (!boolError) {
            callback();
            return {};
        }
        else {
            return errorsToShow;
        }
    }
}
