<template>
    <layout>
        <div class="content-body">
            <div class="mb-80">
                <div class="container h-100">
                    <form id="formData" @submit="save" method="post">
                        <div class="row justify-content-center h-100 align-items-center">
                            <div class="col-xl-12 col-md-12">
                                <div class="card" v-if="!process.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            <router-link to="/products">
                                                <i class="back fa fa-arrow-circle-left"></i>
                                            </router-link>
                                            Carga masiva de imágenes para productos
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div>
                                                    Esta ventana te permite cargar imágenes y asociarlos a productos de forma masiva.
                                                </div>
                                                <div class="my-3 p-3">
                                                    <h6>Instrucciones: </h6>
                                                    <ul class="list-group mt-3">
                                                        <li class="list-group-item">
                                                            <div>
                                                                El nombre de cada imágen cargada, debe corresponder al SKU
                                                                <i class="fa fa-question-circle" v-tooltip="'Código de identificación único del producto, se encuentra en el campo SKU en la edición del producto.'"></i>
                                                                del producto.
                                                            </div>
                                                            <div class="font-weight-bold mt-2">
                                                                Por ejemplo:
                                                            </div>
                                                            <div>
                                                                Si tu producto posee un SKU: <span class="text-primary">PRODUCTO-1-BLANCO</span>
                                                            </div>
                                                            <div>
                                                                La imágen a cargar debe llamarse <span class="text-primary">PRODUCTO-1-BLANCO.jpg</span> o <span class="text-primary">PRODUCTO-1-BLANCO.png</span>
                                                            </div>
                                                            <div class="text-danger">
                                                                El sistema distingue entre mayúsculas y minúsculas.
                                                            </div>
                                                        </li>
                                                        <li class="list-group-item">
                                                            Únicamente es permitido cargar imágenes en formato: <span class="text-primary">jpg, jpeg, png</span>. Con un peso <span class="text-primary">máximo de 200kb o 0.2mb.</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="mt-4 text-dark text-center">
                                                    <i class="fa fa-arrow-circle-down"></i> Sube las imágenes que serán asociadas a tus productos.
                                                </div>
                                                <div class="mt-4">
                                                    <FileUploader url="products/massive-image/upload" max-files="2000" :accepted-mime="acceptedMime" remove-file-on-finish="0" :extra-data-form="formExtraData" @fileSuccess="fileSuccess" v-model:file-uploaded="fileUploaded"></FileUploader>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--<div class="card" v-if="preview.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Previsualización de datos
                                            <i class="fa fa-question-circle" v-tooltip="'Verifica si las columnas de tu archivo fueron detectadas correctamente'"></i>
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                    <div class="mb-4">
                                                        <h4>Se detectaron <span class="text-primary">{{preview.countRows}}</span> filas a procesar</h4>
                                                    </div>
                                                    <div>
                                                        La previsualización online está limitada a 5 filas por estado, para observar la previsualización completa, descargue el archivo.
                                                    </div>
                                                    <a class="btn btn-sm btn-primary mt-3" :href="preview.resultFile" target="_blank">Descargar archivo de previsualización</a>
                                                </div>
                                                <div class="mt-4">
                                                    <h6>Productos que se crearán</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.created" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>Productos que se actualizarán</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.updated" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h6>Productos con errores</h6>
                                                    <div class="products-massive-preview">
                                                        <table class="table table-striped">
                                                            <tr v-for="item in preview.error" :key="'uploaded_' + item">
                                                                <td v-for="cell in item" :key="'uploaded_'+ item + '_' + cell">
                                                                    {{ cell }}
                                                                </td>
                                                            </tr>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div class="text-center mt-4">
                                                    <div class="btn btn-sm btn-success mt-3" @click="doProccess">Realizar carga y procesar productos</div>
                                                    <div class="btn btn-sm btn-danger mt-3 ml-2" @click="$router.go()">Cancelar carga</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card" v-if="process.resultFile">
                                    <div class="card-header">
                                        <h4 class="card-title">
                                            Resultados de carga
                                        </h4>
                                    </div>
                                    <div class="card-body">
                                        <div class="row">
                                            <div class="col-12">
                                                <div class="text-center">
                                                    La carga ha finalizado, para ver los resultados, descargue el archivo.
                                                    <div class="mt-3">
                                                        <a class="btn btn-sm btn-primary mr-2" :href="process.resultFile" target="_blank">Descargar archivo de resultados</a>
                                                        <div @click="$router.go()" class="btn btn-sm btn-dark px-4">
                                                            Realizar otra carga
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>-->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
//import Tools from "src/core/Tools";
import FileUploader from 'src/components/files/FileUploader';

export default {
    components: {
        Layout,
        FileUploader,
    },
    data() {
        return {
            process: {},
            preview: {},
            formExtraData: {
                preview: 1
            },
            fileUploaded: [],
            acceptedMime: 'image/jpeg, image/jpg, image/png, image/webp'
        };
    },
    watch: {
        fileUploaded(value) {
            console.log(value);
        }
    },
    mounted: function () {

    },
    methods: {
        fileSuccess(response) {
            if (response.status) {
                if (typeof response.data.file !== 'undefined') {

                }
            }
        },
        doProccess() {
            if (typeof this.fileUploaded.pond !== 'undefined') {
                /*this.preview = {};
                this.formExtraData.preview = 0;*/
                this.fileUploaded.pond.processFile(this.fileUploaded.fileId);
            }
            else {
                API.showErrorNotify('Error al realizar carga');
            }

        }
    },
};
</script>
