<template>
    <setting-layout :active="33">
        <div class="row justify-content-center h-100 align-items-center">
            <div class="col-xl-12 col-md-12">
                <div class="row">
                    <div class="col-12 col-md-6">
                        <div class="card">
                            <div class="card-header">
                                <h4 class="card-title">
                                    Validador de asistencia
                                    <i class="fa fa-question-circle ml-3" v-tooltip="'Permite validar la asistencia de participantes a eventos'"></i>
                                </h4>
                            </div>
                            <div class="card-body">
                                <div class="text-justify">
                                    Valida la asistencia de tus eventos mediante el escaneo de invitaciones que incluyan código QR generado por el sistema.
                                </div>
                                <div class="text-center mt-3">
                                    <button @click="readerStart" class="btn btn-primary"><i class="fa-solid fa-qrcode mr-2"></i> Iniciar validador</button>
                                </div>
                                <div class="text-muted mt-3 text-justify">
                                    Recuerda, para utilizar el validador de asistencia debes brindar accesos al navegador para utilizar la cámara de tu preferencia.
                                </div>
                                <div id="fullScreenContainer">
                                    <QrReader v-if="validatorStarted" @qrScanned="qrScanned"  @readerStop="readerStop" >
                                        <div v-if="formDetails.info" class="text-left mb-3">
                                            <h5>{{formDetails.info.formName}}</h5>
                                            <div>
                                                <b class="mr-2">Código:</b> {{formDetails.info.codigoForm}}
                                            </div>
                                            <div>
                                                <b class="mr-2">Adquirido por:</b> {{formDetails.info.adquiridoPor}}
                                            </div>
                                            <div v-if="!formDetails.info.asistido" class="mt-3">
                                                <button @click="marcarAsistencia(formDetails.info.formToken)" class="btn btn-primary w-100 mb-2"><i class="fa-solid fa-check-square-o"></i> Marcar asistencia</button>
                                            </div>
                                            <div v-else class="text-danger text-center mt-3">
                                                <div>La asistencia ya ha sido marcada</div>
                                                <div>
                                                    {{formDetails.info.asistidoFecha}}
                                                </div>
                                            </div>
                                        </div>
                                        <div v-else>
                                            Error, código de asistencia inválido
                                        </div>
                                    </QrReader>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>>
</template>

<script>
import API from "src/core/Api";
//import Tools from "src/core/Tools";
import QrReader from 'src/components/QrReader';
import {mapGetters} from "vuex";
import settingLayout from "../../order/components/settings/settingLayout.vue";
//import {store} from 'src/store'
//import DataValidation from "src/core/DataValidation";

export default {
    components: {
        settingLayout,
        QrReader,
    },
    data() {
        return {
            validatorStarted: false,
            formDetails: {},
            qrScannedResult: '',
            token: ''
        };
    },
    watch: {
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
    mounted() {

    },
    methods: {

        qrScanned(data) {
            this.qrScannedResult = data;
            let token = /[^/]*$/.exec(this.qrScannedResult);
            this.token = (typeof token[0] !== 'undefined') ? token[0] : false;
            this.getFormInfo();
        },
        readerStart() {
            this.validatorStarted = true;
        },
        readerStop() {
            this.validatorStarted = false;
        },
        getFormInfo() {
            const self = this;
            if (self.token !== '') {
                API.send('GET', 'formularios/verifyAssistance/' + self.token, {},
                    function (response) {
                        if (response.status) {
                            self.formDetails = response.data;
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        marcarAsistencia(token) {
            const self = this;
            API.send('POST', 'formularios/verifyAssistance', {
                token: token
            },
            function (response) {
                if (response.status) {
                    self.getFormInfo();
                }
                else {
                    API.showErrorNotify(response.msg);
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
        }
    },
};
</script>
