<template>
    <div class="order-payment" v-show="showPaymentForm">
        <div class="paymentContainer">
            <div class="row m-0">
                <div class="col-12 col-sm-5 payLeft">
                    <div class="montoTopay">
                        <div>
                            Total a pagar
                        </div>
                        <div>
                            <h2 class="text-light">{{ Numbro(((totalPago) ? totalPago : 0)).formatCurrency() }}</h2>
                        </div>
                    </div>
                    <button class="cancelPayment" @click="hideSearch">
                        <i class="fa fa-arrow-circle-left mr-2"></i> Cancelar pago
                    </button>
                </div>
                <div class="col-12 col-sm-7 payRight">
                    <h3>Realizar pago</h3>
                    <div class="mt-3">
                        <span>
                            Pagar con tarjeta
                        </span>
                        <img class="visamaster" src="staticAssets/paymentForm/visamaster.png">
                    </div>
                    <div v-if="!IsOnPos">
                        <div class="mt-4">
                            <div>
                                <div class="form-group">
                                    <label>Tarjeta No.</label>
                                    <input type="tel" class="form-control" id="cardNumber" aria-describedby="Card Number" placeholder="---- ---- ---- ----" data-card-field  :value="cardNumber" @input="changeNumber" maxlength="19">
                                    <span class="validationError">{{ Valid.cardNumber }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="form-group">
                                    <label>Nombre</label>
                                    <input type="text" class="form-control" id="cardName" aria-describedby="Card Name" placeholder="Escribe aquí" data-card-field v-model="cardName">
                                    <span class="validationError">{{ Valid.cardName }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="row m-0">
                                    <div class="col-4 px-0">
                                        <div class="form-group">
                                            <label>Expiración</label>
                                            <select class="form-control" id="cardExpirationMonth" v-model="cardMonth" data-card-field>
                                                <option value disabled selected>Mes</option>
                                                <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12" v-bind:disabled="n < minCardMonth" v-bind:key="n">{{ generateMonthValue(n) }}</option>
                                            </select>
                                            <span class="validationError">{{ Valid.cardMonth }}</span>
                                        </div>
                                    </div>
                                    <div class="col-4 px-0">
                                        <div class="form-group pl-2">
                                            <label>&nbsp;</label>
                                            <select class="form-control" id="cardExpirationYear" v-model="cardYear" data-card-field>
                                                <option value disabled selected>Año</option>
                                                <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12" v-bind:key="n">{{ $index + minCardYear }}</option>
                                            </select>
                                            <span class="validationError">{{ Valid.cardYear }}</span>
                                        </div>
                                    </div>
                                    <div class="col-4 px-0">
                                        <div class="form-group pl-2">
                                            <label>CVV</label>
                                            <input type="password" class="form-control" id="cardCvv" aria-describedby="Card CVV" placeholder="---" data-card-field v-model="cardCvv" pattern="[0-9]*" inputmode="numeric">
                                        </div>
                                    </div>
                                    <div class="col-12 px-0" v-if="enableCuotas && parseFloat(totalPago) >= montoMinimoCuotas">
                                        <div class="form-group">
                                            <label>Cuotas</label>
                                            <select class="form-control" id="cuotasPago" v-model="cuotas" data-card-field>
                                                <option value="0">Sin cuotas</option>
                                                <option value="2">2 Cuotas</option>
                                                <option value="3">3 Cuotas</option>
                                                <option value="6">6 Cuotas</option>
                                                <option value="10">20 Cuotas</option>
                                                <option value="12">12 Cuotas</option>
                                                <option value="18">18 Cuotas</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-right">
                            <input type="button" value="Pagar ahora" class="btn btn-primary" @click="invalidCard">
                        </div>
                    </div>
                    <div v-else class="text-center">
                        <hr>
                        <div class="col-12 px-0" v-if="enableCuotas && parseFloat(totalPago) >= montoMinimoCuotas">
                            <div class="form-group">
                                <label>Cuotas</label>
                                <select class="form-control" id="cuotasPago" v-model="cuotas" data-card-field>
                                    <option value="0">Sin cuotas</option>
                                    <option value="2">2 Cuotas</option>
                                    <option value="3">3 Cuotas</option>
                                    <option value="6">6 Cuotas</option>
                                    <option value="10">20 Cuotas</option>
                                    <option value="12">12 Cuotas</option>
                                    <option value="18">18 Cuotas</option>
                                </select>
                            </div>
                        </div>
                        <div class="mt-3">
                            <h5>Escaneo de tarjeta</h5>
                            <div class="text-muted mt-3 mb-3">
                                Inserta, desliza o acerca la tarjeta
                            </div>
                        </div>
                        <div class="p-3 mt-3">
                            <img src="staticAssets/paymentForm/deslizacard.png" style="max-width: 170px">
                        </div>
                        <div class="col-12 px-0 mt-4" v-if="escaneoPOS && parseInt(afiliacionSelected.requireCvvForPos) === 1">
                            <hr>
                            <div class="row">
                                <div class="col-7">
                                    <div class="form-group pl-2">
                                        Código de seguridad
                                        <input type="password" class="form-control" id="cardCvv" aria-describedby="Card CVV" placeholder="---" data-card-field v-model="cardCvv" pattern="[0-9]*" inputmode="numeric">
                                    </div>
                                </div>
                                <div class="col-5">
                                    &nbsp;<br>
                                    <button @click="doPay" class="btn btn-primary">Pagar</button>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <img class="mt-3" src="staticAssets/global/loading.gif" width="40">
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PaymentRedirectionHandler :redirect-data="payRedirectionData" @paymentFinish="paymentFinish"></PaymentRedirectionHandler>
    </div>
</template>

<script>

import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import Tools from "src/core/Tools";
import Numbro from 'numbro';
import AndroidBridge from "src/core/AndroidBridge";
import PaymentRedirectionHandler from "src/components/PaymentRedirectionHandler";
import dayjs from "dayjs";

export default {
    name: "order-search",
    components: {
        PaymentRedirectionHandler
    },
    props: ['showPaymentForm', 'tipoOrden', 'totalPago', 'redirectData', "afiliacion"],
    data() {
        return {
            BridgeAndroid: false,
            IsOnPos: false,

            Valid: {},
            showForm: false,
            cardName: '',
            cardNumber: '',
            cardMonth: '',
            cardYear: '',
            cardCvv: '',
            minCardYear: new Date().getFullYear(),
            cuotas: 0,

            // pago con metodos
            payRedirectionData: 'none',

            // Datos de usuario o configuraciones
            enableCuotas: false,
            montoMinimoCuotas: 0,
            escaneoPOS: false,
            afiliacionSelected: {},

            makingPay: false
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        }),
        minCardMonth() {
            if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1
            return 1
        },
    },
    watch: {
        showPaymentForm: function (val) {
            const self = this;
            if (val) {
                //document.body.classList.add("overflowLocked");
                if (this.IsOnPos) {
                    setTimeout(function () {
                        self.startCardReader();
                    }, 1000);
                }
            }
            else {
                this.cardName = '';
                this.cardNumber = '';
                this.cardMonth = '';
                this.cardYear = '';
                this.cardCvv = '';
                //document.body.classList.remove("overflowLocked");
            }
        },
        redirectData(val) {
            this.payRedirectionData = val;
        },
        afiliacion(val) {
            this.afiliacionSelected = val;
        },
    },
    mounted() {
        const self = this;
        this.BridgeAndroid = new AndroidBridge();
        this.IsOnPos = this.BridgeAndroid.isOnTopWise();
        this.afiliacionSelected = this.afiliacion;

        console.log("***************** Starting ***************** ")
        const eventListener = function (e) {

            console.log("***************** AndroidBridgeCardRead ***************** ")

            if (!self.makingPay) {

                if (typeof e.detail !== 'undefined') {
                    if (parseInt(e.detail.status) === 0) {
                        alert(e.detail.msg);
                        self.resetPos();
                    }
                    else {
                        self.parseCardData(e.detail);
                    }
                }
                else {
                    alert('Error al leer tarjeta, por favor intente de nuevo');
                    self.resetPos();
                }

                self.makingPay = true;
            }
        };
        //window.removeEventListener('AndroidBridgeCardRead', eventListener, false);
        window.addEventListener('AndroidBridgeCardRead', eventListener, false);

        if (typeof this.AuthGetUserInfo.configuration !== 'undefined' && typeof this.AuthGetUserInfo.configuration.payments !== 'undefined') {
            this.enableCuotas = parseInt(this.AuthGetUserInfo.configuration.payments.enableCuotas);
            this.montoMinimoCuotas = parseFloat(this.AuthGetUserInfo.configuration.payments.cuotasMinTC);
        }
    },
    methods: {
        Numbro,
        ...mapActions({
            OrderAddProductToPos: 'OrderAddProductToPos',
        }),
        hideSearch: function () {

            if (this.IsOnPos) {
                this.resetPos();
            }

            this.$emit('update:showPaymentForm', false);
            this.showForm = false;
        },
        generateMonthValue(n) {
            return n < 10 ? `0${n}` : n
        },
        changeNumber(e) {
            this.cardNumber = e.target.value
            let value = this.cardNumber.replace(/\D/g, '')
            // american express, 15 digits
            if ((/^3[47]\d{0,13}$/).test(value)) {
                this.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 17
            }
            else if ((/^3(?:0[0-5]|[68]\d)\d{0,11}$/).test(value)) { // diner's club, 14 digits
                this.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{6})/, '$1 $2 ')
                this.cardNumberMaxLength = 16
            }
            else if ((/^\d{0,16}$/).test(value)) { // regular cc number, 16 digits
                this.cardNumber = value.replace(/(\d{4})/, '$1 ').replace(/(\d{4}) (\d{4})/, '$1 $2 ').replace(/(\d{4}) (\d{4}) (\d{4})/, '$1 $2 $3 ')
                this.cardNumberMaxLength = 19
            }
            // eslint-disable-next-line eqeqeq
            if (e.inputType === 'deleteContentBackward') {
                let lastChar = this.cardNumber.substring(this.cardNumber.length, this.cardNumber.length - 1)
                // eslint-disable-next-line eqeqeq
                if (lastChar === ' ') {
                    this.cardNumber = this.cardNumber.substring(0, this.cardNumber.length - 1)
                }
            }
            this.$emit('input-card-number', this.cardNumber)
        },
        invalidCard() {

            this.Valid.cardNumber = '';
            this.Valid.cardName = '';
            this.Valid.cardMonth = '';
            this.Valid.cardYear = '';
            this.Valid.cardCvv = '';

            let number = this.cardNumber.replace(/ /g, '')

            var sum = 0
            for (var i = 0; i < number.length; i++) {
                var intVal = parseInt(number.substr(i, 1))
                if (i % 2 === 0) {
                    intVal *= 2
                    if (intVal > 9) {
                        intVal = 1 + (intVal % 10)
                    }
                }
                sum += intVal
            }

            if ((sum % 10 !== 0) || Tools.isEmptyString(this.cardNumber)) {
                this.Valid.cardNumber = 'Número de tarjeta inválido';
            }
            else if (Tools.isEmptyString(this.cardName)) {
                this.Valid.cardName = 'Nombre inválido';
            }
            else if (Tools.isEmptyString(this.cardMonth)) {
                this.Valid.cardMonth = 'Mes inválido';
            }
            else if (Tools.isEmptyString(this.cardYear)) {
                this.Valid.cardYear = 'Año inválido';
            }
            else if (Tools.isEmptyString(this.cardCvv)) {
                this.Valid.cardCvv = 'CVV inválido';
            }
            else {
                this.doPay();
            }
        },
        doPay() {
            const data = {
                cardName: this.cardName,
                cardNumber: this.cardNumber.replace(/ /g, ''),
                cardMonth: this.cardMonth,
                cardYear: this.cardYear,
                cardCvv: this.cardCvv,
                cardCuotas: this.cuotas,
            }
            this.$emit('pay', data);
        },
        paymentFinish(response) {
            this.$emit('finish', response);
        },

        startCardReader() {
            const self = this;
            this.BridgeAndroid.CardReaderStart();

            setTimeout(function () {
                self.stopSensors();
            }, 20000);

            /*setTimeout(function () {
                const test = { detail: { status: 1, msg: 'Operación realizada', data: '{"response_code":"00","authorization_code":"071016","response_message":"APROBADO","approved":"true","suggested_nit":"FE19","voucher_code":"000013"}'}};
                let loading = new CustomEvent('AndroidBridgeVisanetPayApp',  test);
                window.dispatchEvent(loading);
            }, 2000);*/
        },
        parseCardData(data) {
            const self = this;

            this.stopSensors();

            if (typeof data.track2 !== 'undefined') {

                const trackParsed = data.track2.toString().split('D');
                const expYear = trackParsed[1].substring(0, 2);
                const expMonth = trackParsed[1].substring(2, 4);
                const maskedPan = '************'+data.pan.substr(-4);

                self.cardNumber = data.pan;
                self.cardName = data.nameholder;
                self.cardYear = "20"+expYear.toString();
                self.cardMonth = expMonth;
                self.escaneoPOS = true;

                if (parseInt(this.afiliacionSelected.requireCvvForPos) === 0) {
                    this.doPay();
                    this.resetPos();
                }

                /*const datavoucher = {
                    titulo: "PAGO REALIZADO (PRUEBA)",
                    subtitulo: "Comercio de prueba - Guatemala Guatemala",
                    fechaHora: "Fecha: " + dayjs().format('MM/DD/YYYY HH:mm'),
                    datosCobro: "AUTO:  0000000 \n" +
                        "REF:   000000000",
                    datosTarjeta: "CREDOMATIC       " + maskedPan,
                    datosCompra: "VENTA                                  Q" + this.amountNumber + "." + this.amountDecimals,
                    footer: "-------- BAC CREDOMATIC --------",
                };

                this.BridgeAndroid.PrintVoucher(JSON.stringify(datavoucher));*/
            }
        },
        resetPos() {
            this.makingPay = false;
            this.escaneoPOS = false;
            this.cardName = '';
            this.cardMonth = '';
            this.cardYear = '';
            this.cardCvv = '';
            this.minCardYear = new Date().getFullYear();
            this.cuotas = 0;
            this.BridgeAndroid.CardReaderStop();
        },
        stopSensors() {
            this.BridgeAndroid.CardReaderStop();
        }
    },
};
</script>
