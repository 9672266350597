<template>
    <setting-layout :active="21">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Integraciones</h4>
            </div>
            <div class="card-body">
                <div class="card-subtitle">
	                Te permiten integrar pagos, facturación electrónica; para configurar una, debes tener previamente tus credenciales obtenidas por parte de la empresa integradora.
                </div>
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in affiliations" :key="item.id">
                            <div class="row">
                                <div class="col-12">
                                    <div class="media">
                                        <span class="mr-3"><i class="fa fa-cogs"></i></span>
                                        <div class="media-body">
                                            <h5 class="mt-0 mb-1">{{item.nombre}}</h5>
                                            <p>Método de pago utilizado: {{item.tipoNombre}}</p>
                                        </div>
                                        <div class="edit-option">
                                            <a :href="'/account/affiliations/edit/'+item.id">
                                                <i class="fa fa-pencil"></i>
                                            </a>
                                            <a  @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="mt-5">
                        <router-link to="/account/affiliations/add" class="btn btn-primary px-4 mr-3">
                            Agregar nueva
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";

export default {
    components: {settingLayout},
    data() {
        return {
            affiliations: {},
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('GET','payments/affiliation', {},
                function (data) {
                    if (data.status) {
                        self.affiliations = data.data;
                    }
                    else {
                        self.msg = data.msg;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function (){
                API.send('DELETE','payments/affiliation', {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
    },
};
</script>
