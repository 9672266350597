<template>
    <div class="fichaMedicaComponent">
        <h2>
            Resumen de consulta
        </h2>
    </div>
    <box title="Motivo de consulta" icon="fa fa-question-circle"  v-if="typeof this.GetMedicoFichaActive.metadata.preclinica_motivo !== 'undefined'">
        <div class="row">
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label class="font-weight-bold">Motivo de Consulta: </label> {{this.GetMedicoFichaActive.metadata.preclinica_motivo.motivo_principal_consulta}}
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label class="font-weight-bold">Historial de la enfermedad actual (HPI): </label> {{this.GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi}}
                </div>
            </div>
            <div class="col-12 col-sm-6">
                <div class="form-group">
                    <label class="font-weight-bold">Fecha de inicio de síntomas:</label> {{ dayjs(this.GetMedicoFichaActive.metadata.preclinica_motivo.fecha_inicio_sintomas).format('DD-MM-YYYY H:mm:ss') || '' }}
                </div>
            </div>
        </div>
    </box>
    <box title="Signos Vitales" icon="fa fa-heartbeat">
        <div v-if="typeof GetMedicoFichaActive.metadata.preclinica_signos_v === 'undefined'">
            <div class="text-center text-danger">No tiene signos vitales ingresados</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Fecha y hora</th>
                    <th class="font-weight-bold">Presión arterial</th>
                    <th class="font-weight-bold">Frecuencia cardiaca</th>
                    <th class="font-weight-bold">Frecuencia respiratoria</th>
                    <th class="font-weight-bold">Saturación de oxígeno</th>
                    <th class="font-weight-bold">Temperatura</th>
                    <th class="font-weight-bold">Peso</th>
                    <th class="font-weight-bold">Talla</th>
                    <th class="font-weight-bold">IMC</th>
                    <th class="font-weight-bold">Glicemia</th>
                    <th class="font-weight-bold">Estado de conciencia</th>
                    <th class="font-weight-bold">Dolor</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.preclinica_signos_v" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.fechaHora }}</td>
                        <td>{{ item.detail.presionArterial }}</td>
                        <td>{{ item.detail.frecuenciaCardiaca }}</td>
                        <td>{{ item.detail.frecuenciaRespiratoria }}</td>
                        <td>{{ item.detail.saturacionDeOxigeno }}</td>
                        <td>{{ item.detail.temperatura }}</td>
                        <td>{{ item.detail.peso }}</td>
                        <td>{{ item.detail.talla }}</td>
                        <td>{{ item.detail.IMC }}</td>
                        <td>{{ item.detail.glicemia }}</td>
                        <td>{{ item.detail.estadoDeConciencia }}</td>
                        <td>{{ item.detail.dolor }}/10</td>
                        <td>
                            <i @click="editSignoVital(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deletesignoVital(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
    </box>
    <box title="Impresión Clínica" icon="fa fa-chart">
      <div v-if="typeof GetMedicoFichaActive.metadata.impresion_clinica_cie10 === 'undefined'">
        <div class="text-center text-danger">No tiene diagnostico ingresados</div>
      </div>
      <div v-else>
        <div class="row">
            <div class="form-group">
              <label class="font-weight-bold">Diagnóstico: </label>

              <span v-for="item in GetMedicoFichaActive.metadata.impresion_clinica_cie10">
                  <div>{{ item }}</div>
              </span>

            </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label class="font-weight-bold">Historial de la enfermedad actual (HPI): </label> {{this.GetMedicoFichaActive.metadata.preclinica_motivo.historial_de_la_enfermedad_actual_hpi}}
          </div>
        </div>
      </div>

    </box>
    <!--<box title="Tratamiento" icon="fas fa-pills">
        <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_receta === 'undefined'">
            <div class="text-center text-danger">No tiene datos en receta</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Medicamento</th>
                    <th class="font-weight-bold">Administración</th>
                    <th class="font-weight-bold">Dosis y frecuencia</th>
                    <th class="font-weight-bold">Observaciones</th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_receta" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.medicamento }}</td>
                        <td>{{ item.detail.administracion }}</td>
                        <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}} {{item.detail.duranteTiempo}}</td>
                        <td>{{ item.detail.observaciones }}</td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div>
            <button class="btn btn-primary" @click="printReceta">Imprimir receta</button>
        </div>
    </box>-->

    <box title="Procedimientos" icon="fas fa-pills">
        <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_procedimiento === 'undefined'">
            <div class="text-center text-danger">No tiene datos en receta</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Procedimiento</th>
                    <th class="font-weight-bold">Observaciones</th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_procedimiento" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.procedimiento }}</td>
                        <td>{{ item.detail.observaciones }}</td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
    </box>
    <box title="Receta" icon="fas fa-receipt">
        <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_receta === 'undefined'">
            <div class="text-center text-danger">No tiene datos en receta</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Medicamento</th>
                    <th class="font-weight-bold">Administración</th>
                    <th class="font-weight-bold">Dosis y frecuencia</th>
                    <th class="font-weight-bold">Observaciones</th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_receta" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.medicamento }}</td>
                        <td>{{ item.detail.administracion }}</td>
                        <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}} {{item.detail.duranteTiempo}}</td>
                        <td>{{ item.detail.observaciones }}</td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-3 text-right">
            <button class="btn btn-primary" @click="printReceta"><i class="fas fa-print"></i> Imprimir Receta</button>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from "src/modules/medico/views/fichas/Box.vue";
import Multiselect from 'vue-multiselect';

// Componentes

export default {
    components: {
        Box,
        Multiselect,
        DatePicker,
    },
    props: [
        'type',
    ],
    data() {
        return {

        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        printReceta() {

           const self = this;
            API.send('POST', 'medico/receta/print', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                }, function (response) {
                    console.log(response);
                    window.open(response.data.url);
                    // self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        printSection() {

        }
    },
};

</script>
