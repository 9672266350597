<template>
    <SettingLayout :active="27">
        <div class="row">
            <div class="col-xl-12">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Locaciones</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="formSubmit" method="post" name="myform" class="personal_validate">
                            <div class="form-row">
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Nombres</label>
                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="nombres"
                                        name="nombres"/>
                                </div>
                                <div class="form-group col-xl-6">
                                    <label class="mr-sm-2">Apellidos</label>
                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="apellidos"
                                        name="apellidos"/>
                                </div>
                                <div class="form-group col-xl-6">
                                    <div>
                                        <label class="mr-sm-2">Correo electrónico</label>
                                        <input type="email" class="form-control" placeholder="Hello@example.com"
                                            v-model="email" disabled/>
                                        <small>El correo electrónico princial no puede editarse</small>
                                    </div>
                                </div>
                                <div class="form-group col-xl-6 editPage-piker">
                                    <label class="mr-sm-2 d-block">Fecha de nacimiento</label>
                                    <input type="date" class="form-control" placeholder="Hello@example.com"
                                        v-model="fechaNacimiento"
                                        name="fechaNacimiento"/>
                                </div>
                                <div class="form-group col-xl-6 editPage-piker">
                                    <label class="mr-sm-2">Teléfono</label>
                                    <input type="tel" class="form-control" placeholder="" v-model="telefono"
                                        name="fechaNacimiento"/>
                                </div>
                                <div class="form-group col-xl-6 editPage-piker">
                                    <label class="mr-sm-2">Género</label>
                                    <select class="form-control" v-model="genero">
                                        <option value="M">Masculino</option>
                                        <option value="F">Femenino</option>
                                        <option value="N">Prefiero no decirlo</option>
                                    </select>
                                </div>
                                <div class="form-group col-12">
                                    <button class="btn btn-success pl-5 pr-5">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </SettingLayout>
</template>
<script>
import SettingLayout from "../components/settings/settingLayout";

import {mapGetters} from "vuex";
import API from "src/core/Api";
import DataValidation from "src/core/DataValidation";

export default {
    components: {
        SettingLayout,
    },
    data() {
        return {
            id: "",
            nombres: "",
            apellidos: "",
            email: "",
            fechaNacimiento: "",
            telefono: "",
            genero: "N",
            Valid: {},
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
    watch: {
        AuthGetUserInfo: {
            handler({id, nombres, apellidos, email, fecha_nac, genero, telefono}) {
                this.id = id;
                this.nombres = nombres;
                this.apellidos = apellidos;
                this.email = email;
                this.fechaNacimiento = fecha_nac;
                this.genero = genero;
                this.telefono = telefono;
            },
            deep: true, // deep is to listen to objects properly
            immediate: true // immediate so the watcher triggers right away when the component is mounted
        }
    },
    methods: {
        formSubmit() {
            const self = this;
            const validation = new DataValidation()
            validation.required('nombres', this.nombres, 'Ingrese un nombre válido');

            this.Valid = validation.validate(function () {
                API.send('POST', 'users/edit', {
                        id: self.id,
                        nombres: self.nombres,
                        apellidos: self.apellidos,
                        fechaNacimiento: self.fechaNacimiento,//moment(self.fechaNacimiento).utc().format('YYYY-MM-DD'),
                        email: self.email,
                        genero: self.genero,
                        telefono: self.telefono,
                    }, function (data) {
                        // self.$store.dispatch('AuthRefreshToken');
                        API.showSuccessAlert(data.msg);
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            });
        },
    },
};
</script>
