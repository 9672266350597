<template>
    <setting-layout :active="25">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Edición de rol</h4>
            </div>
            <div class="card-body">
                <div class="form">
                    <div class="media d-block">
                        <div class="row">
                            <div class="form-group col-sm-12 col-xl-6">
                                <label class="mr-sm-2">Nombre</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="nombre" name="nombres"/>
                            </div>
                            <div class="form-group col-sm-12 col-xl-6">
                                <label class="mr-sm-2">Slug de rol (identificador)</label>
                                <input type="text" class="form-control" placeholder="mi-tienda" aria-label="mi-tienda" v-model="roleSlug">
                            </div>
                            <div class="form-group col-sm-12 col-xl-6" v-if="parseInt(id) > 0">
                                Interfaz de sistema:
                                <select class="form-control" v-model="layout">
                                    <option value="admin">Administrador</option>
                                    <option value="medico">Interfaz médica</option>
                                    <option value="cotizador">Cotizador</option>
                                </select>
                            </div>
                            <div class="form-group col-sm-12 col-xl-6 text-center">
                                <label class="toggle pt-4">
                                    <input type="checkbox"
                                        v-model="active" :checked="active"
                                        value="1"
                                        class="toggle-checkbox">
                                    <div class="toggle-switch"></div>
                                    &nbsp; Habilitar rol
                                </label>
                            </div>
                        </div>
                        <div class="form-group" v-if="parseInt(id) > 0">
                            <hr>
                            Filtrar accesos de:
                            <select class="form-control" v-model="tipoRol">
                                <option value="plataforma">Panel de control</option>
                                <option value="sso">SSO (Inicio de sesión único)</option>
                                <option value="plataforma_sso">Panel de control y SSO</option>
                            </select>
                        </div>
                        <div v-if="parseInt(id) > 0">
                            <div v-if="tipoRol === 'plataforma' || tipoRol === 'plataforma_sso'">
                                <h4 class="mt-4">Accesos a panel de control</h4>
                                <div v-if="Object.keys(accesos_panel).length > 0 " class="mb-3">
                                    <div v-for="module in accesos_panel" :key="module.slug" class="mt-4 personalAccessModule">
                                        <h5>
                                            {{module.name}}
                                            <span class="enableAll" @click="enableAllModule(module.slug)">Activar todo</span>
                                            <span class="enableAll" @click="disableAllModule(module.slug)">Desactivar todo</span>
                                        </h5>
                                        <div class="my-3">
                                            <label class="toggle">
                                                <input type="checkbox"
                                                    v-model="module.active" :checked="module.active"
                                                    value="1"
                                                    class="toggle-checkbox">
                                                <div class="toggle-switch"></div>
                                                &nbsp;&nbsp; Módulo activo
                                            </label>
                                            <div class="text-muted mt-2">
                                                Si desactiva el módulo, el usuario no tendrá acceso a ninguna acción interna (aunque se encuentren activas)
                                            </div>
                                        </div>
                                        <div v-for="acceso in module.access" :key="acceso.slug" class="personalAccessSubmodule">
                                            <!--<h6 class="mt-4"></h6>-->
                                            <div class="my-2">
                                                <label class="toggle">
                                                    <input type="checkbox"
                                                        v-model="acceso.active" :checked="acceso.active"
                                                        value="1"
                                                        class="toggle-checkbox">
                                                    <div class="toggle-switch"></div>
                                                    &nbsp; <b>{{acceso.name}}</b>
                                                </label>
                                                <span class="enableAll" @click="enableAllAccess(module.slug, acceso.slug)">Activar todo</span>
                                                <span class="enableAll" @click="disableAllAccess(module.slug, acceso.slug)">Desactivar todo</span>
                                                <hr class="my-2">
                                            </div>
                                            <div>
                                                <div class="row m-0">
                                                    <div v-for="subAccess in acceso.sub" :key="subAccess.slug" class="col-12 col-sm-4 p-1">
                                                        <label class="toggle">
                                                            <input type="checkbox"
                                                                v-model="subAccess.active" :checked="subAccess.active"
                                                                value="1"
                                                                class="toggle-checkbox">
                                                            <div class="toggle-switch"></div>
                                                            &nbsp;
                                                            {{subAccess.name}}
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="tipoRol === 'sso' || tipoRol === 'plataforma_sso'">
                                <h4 class="mt-5">Accesos para aplicaciones de terceros (vía SSO)</h4>
                                <div v-if="Object.keys(accesos_thirdparty).length > 0 " class="mb-3">
                                    <div v-for="(app, idApp) in accesos_thirdparty" :key="app.app" class="mt-3 personalAccessModule">
                                        <h5>
                                            {{ app.name }}
                                            <span class="enableAll" @click="enableAllApp(idApp)">Activar todo</span>
                                            <span class="enableAll" @click="disableAllApp(idApp)">Desactivar todo</span>
                                        </h5>
                                        <div class="mt-4">
                                            <label class="toggle">
                                                <input type="checkbox"
                                                    v-model="app.active" :checked="app.active"
                                                    value="1"
                                                    class="toggle-checkbox">
                                                <div class="toggle-switch"></div>
                                                &nbsp;&nbsp; Aplicación activa
                                            </label>
                                            <div class="text-muted mt-2">
                                                Si desactiva la aplicación, el usuario no tendrá acceso a ninguna acción interna (aunque se encuentren activas)
                                            </div>
                                        </div>
                                        <div class="mt-3 personalAccessSubmodule">
                                            <div class="row mx-0">
                                                <div v-for="acceso in app.list" :key="acceso.slug" class="col-12 col-sm-6 p-1">
                                                    <label class="toggle">
                                                        <input type="checkbox"
                                                            v-model="acceso.active" :checked="acceso.active"
                                                            value="1"
                                                            class="toggle-checkbox">
                                                        <div class="toggle-switch"></div>
                                                        &nbsp;
                                                        {{acceso.name}}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else class="text-muted text-center">
                                    No se han configurado aplicaciones de terceros
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 text-center mt-2">
                                <button @click="saveRol" class="btn btn-primary mx-1 mb-2"><i
                                    class="mdi mdi-content-save"></i> Guardar
                                </button>
                                <button @click="$router.back()" class="btn btn-danger mx-1 mb-2"><i
                                    class="mdi mdi-cancel"></i> Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";


export default {
    components: {
        settingLayout,
        Multiselect,
    },
    data() {
        return {
            id: 0,
            nombre: '',
            roleSlug: '',
            tipoRol: 'plataforma_sso',
            layout: 'admin',
            active: 0,
            accesos_panel: {},
            accesos_thirdparty: {},

            // toogle checkboxes
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    watch: {
        nombre: function (val) {
            this.roleSlug = val.replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
            if (this.roleSlug !== '') {
                this.roleSlug = this.roleSlug.toLowerCase();
            }
        },
        roleSlug: function (val) {
            this.roleSlug = val.replace(/(?!\w|\s)./g, '-').replace(/\s+/g, '-').replace(/^(\s*)([\W\w]*)(\b\s*$)/g, '$2');
            if (this.roleSlug !== '') {
                this.roleSlug = this.roleSlug.toLowerCase();
            }
        },
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;

            this.id = parseInt(this.$route.params.id);

            if (this.id > 0) {
                API.send('GET', 'personal/roles/' + this.id, {},
                    function (response) {
                        if (response.status) {
                            self.id = response.data.id;
                            self.nombre = response.data.nombre;
                            self.slug = response.data.slug;
                            self.layout = response.data.layout;
                            self.active = response.data.active;
                            self.accesos_panel = response.data.accesos_panel;
                            self.accesos_thirdparty = response.data.accesos_thirdparty;
                        }
                        else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            }
            //this.getPaises();
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'personal/borrar/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
        saveRol() {
            const self = this;

            if (Tools.isEmptyString(this.nombre)) {
                API.showErrorAlert('Debe ingresar un nombre del rol');
                return false;
            }
            if (Tools.isEmptyString(this.roleSlug)) {
                API.showErrorAlert('No puede guardar un rol sin identificador');
                return false;
            }

            API.send('POST', 'personal/roles/save', {
                    id: self.id,
                    nombre: self.nombre,
                    slug: self.roleSlug,
                    layout: self.layout,
                    accesos_panel: self.accesos_panel,
                    accesos_thirdparty: self.accesos_thirdparty,
                    activo: self.active,
                }, function (data) {
                    API.showSuccessAlert(data.msg);
                    if (self.id === 0) {
                        window.location.href = '/account-personal/role/edit/' + data.data.id;
                    }
                },
                function (data) {
                    API.showErrorNotify(data.msg);
                })
        },
        enableAllModule(moduleSlug) {

            const self = this;
            this.accesos_panel[moduleSlug].active = 1;

            for (let module in self.accesos_panel[moduleSlug].access) {
                self.accesos_panel[moduleSlug].access[module].active = 1;
                for (let subaccess in self.accesos_panel[moduleSlug].access[module].sub) {
                    self.accesos_panel[moduleSlug].access[module].sub[subaccess].active = 1;
                }
            }
        },
        disableAllModule(moduleSlug) {
            const self = this;
            this.accesos_panel[moduleSlug].active = 0;
            for (let module in self.accesos_panel[moduleSlug].access) {
                self.accesos_panel[moduleSlug].access[module].active = 0;
                for (let subaccess in self.accesos_panel[moduleSlug].access[module].sub) {
                    self.accesos_panel[moduleSlug].access[module].sub[subaccess].active = 0;
                }
            }
        },
        enableAllAccess(moduleSlug, accessSlug) {
            const self = this;
            self.accesos_panel[moduleSlug].access[accessSlug].active = 1;
            for (let subaccess in self.accesos_panel[moduleSlug].access[accessSlug].sub) {
                self.accesos_panel[moduleSlug].access[accessSlug].sub[subaccess].active = 1;
            }
        },
        disableAllAccess(moduleSlug, accessSlug) {
            const self = this;
            self.accesos_panel[moduleSlug].access[accessSlug].active = 0;
            for (let subaccess in self.accesos_panel[moduleSlug].access[accessSlug].sub) {
                self.accesos_panel[moduleSlug].access[accessSlug].sub[subaccess].active = 0;
            }
        },
        enableAllApp(appId) {
            const self = this;
            self.accesos_thirdparty[appId].active = 1;
            for (let subaccess in self.accesos_thirdparty[appId].list) {
                self.accesos_thirdparty[appId].list[subaccess].active = 1;
            }
        },
        disableAllApp(appId) {
            const self = this;
            self.accesos_thirdparty[appId].active = 0;
            for (let subaccess in self.accesos_thirdparty[appId].list) {
                self.accesos_thirdparty[appId].list[subaccess].active = 0;
            }
        },
    },
};
</script>
