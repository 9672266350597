<template>
    <div class="page_title mt-0 pt-0">
        <div class="row mt-0 card p-2">
            <div class="col-12">
                <div class="row">
                    <div class="col-10">
                        <div class="input-group rounded">
                            <input type="search" class="form-control rounded" placeholder="Buscar por nombre de paciente" aria-label="Search" aria-describedby="search-addon" />
                            <span class="input-group-text border-0" id="search-addon">
                        <i class="fas fa-search"></i>
                      </span>
                        </div>
                    </div>
                    <div class="col-1 m-0 p-0">
                        <div class="input-group rounded">
                            <span class="input-group-text border-0">
                                <i class="fas fa-bell"></i>
                            </span>
                        </div>

                    </div>
                    <div class="col-1 m-0 p-0">
                        <div class="input-group rounded">
                            <span class="input-group-text border-0">
                                <i class="fas fa-user"></i>
                            </span>
                        </div>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
    name: "PageTitle",
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
};
</script>
