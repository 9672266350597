//import Lock from "../../views/Lock";
import Reset from "./views/Reset";
import Signin from "./views/Signin";
import SigninStaff from "./views/SigninStaff";
import Signup from "./views/Signup";
import AppConfig from "./views/AppConfig";

export default [
    {
        path: "/app-config",
        name: "AppConfig",
        component: AppConfig,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/signup",
        name: "Signup",
        component: Signup,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/signin",
        name: "Signin",
        component: Signin,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/signin-staff/:store?",
        name: "Signin Staff",
        component: SigninStaff,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/reset/password",
        component: Reset,
        meta: {
            isPublic: true
        }
    },
    {
        path: "/reset/token/:token",
        component: Reset,
        meta: {
            isPublic: true
        }
    },
];
