<template>
    <layout :active="34">
        <div class="content-body">
            <div class="container">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Agenda</h4>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-12 col-sm-6">
                                <div class="mr-sm-2">Filtrar por personal</div>
                                <div class="mb-4">
                                    <multiselect v-model="asignaciones"
                                        :options="personal"
                                        track-by="id" label="name"
                                        :close-on-select="false"
                                        :clear-on-select="false"
                                        :preserve-search="true"
                                        placeholder="Seleccione"
                                        :multiple="true"
                                        @remove="handlePersonalClick"
                                        @select="handlePersonalClick"
                                        @tag="handlePersonalClick">
                                    </multiselect>
                                </div>
                            </div>
                            <div class="col-12 col-sm-6 text-right">
                                <button @click="showProductSearchBox = true" class="btn btn-primary btn-lg mt-4"><i class="fas fa-plus-circle mr-2"></i> Agendar Cita</button>
                            </div>
                        </div>
                        <div>
                            <Qalendar :events="eventosList" :config="config" @updatedPeriod="cambioFechas" @updated="cambioFechas" @editEvent="editarEvento" @deleteEvent="eliminarEvento"/>
                        </div>
                        <order-product-search v-model:show-product-search-box="showProductSearchBox" :tipo-orden="tipoOrden" ref="searchComponent" @productSelected="productSelected" tipo-productos="agendable"></order-product-search>
                        <order-client-search v-model:show-client-search="showClientSearch" @clientSelected="selectClient"></order-client-search>
                        <reservas-agendar :show-agendamiento="showAgendamiento" v-model:show-agendamiento="showAgendamiento" :producto="producto" @selected="selectFecha"></reservas-agendar>
                        <div class="reservasAgendar" v-if="showResumen">
                            <div class="reservasAgendarContainer">
                                <h3><i class="fas fa-calendar mr-2"></i> Se agendará una cita</h3>
                                <hr>
                                <div>
                                    <h4>
                                        <span class="text-primary mr-2">Servicio:</span> {{producto.nombre}}
                                        <span v-if="producto.onSale">({{producto.precioOnSale}})</span>
                                        <span v-else>({{producto.precio}})</span>
                                    </h4>
                                </div>
                                <div>
                                    <h4 class="mt-3">Asistirá:</h4>
                                    <h5><span class="text-primary mr-2">DPI o Pasaporte:</span> {{fecha.clienteCita.dpi}}</h5>
                                    <h5><span class="text-primary mr-2">Nombre:</span> {{fecha.clienteCita.nombres}}</h5>
                                    <h5><span class="text-primary mr-2">Apellidos:</span> {{fecha.clienteCita.apellidos}}</h5>
                                </div>
                                <div>
                                    <h4 class="mt-3">Datos de cita:</h4>
                                    <h5><span class="text-primary mr-2">Ubicación:</span> {{fecha.locacion}}</h5>
                                    <h5><span class="text-primary mr-2">Hora y fecha:</span> {{fecha.horario}} el {{ dayjs(fecha.fecha).format('DD/MM/YYYY') }}</h5>
                                    <h5><span class="text-primary mr-2">Atendido por:</span> {{fecha.personalNombre}}</h5>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-6">
                                        <button @click="showResumen = false" class="btn btn-danger w-100">Cancelar</button>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-primary w-100" @click="createOrder">Agendar</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import Multiselect from 'vue-multiselect'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import esLocale from '@fullcalendar/core/locales/es';

// otros
import ReservasAgendar from "../../components/ReservasAgendar.vue";
import OrderClientSearch from "../../components/OrderClientSearch";
import OrderProductSearch from "../../components/OrderProductSearch";
import {mapGetters} from "vuex";
import { Qalendar } from "qalendar";
import "qalendar/dist/style.css";

export default {
    components: {
        Layout,
        Multiselect,
        OrderClientSearch,
        ReservasAgendar,
        OrderProductSearch,
        Qalendar,
    },
    data() {
        return {
            // agendamiento
            showClientSearch: false,
            showAgendamiento: false,
            showProductSearchBox: false,
            showResumen: false,
            agendarCliente: {},
            tipoOrden: 'directo',
            producto: {},
            fecha: {},
            paymentMethod: 'later_on_store',
            montoProductos: 0,
            impuestos: 0,

            startUp: {},
            personal: [],
            asignaciones: [],
            asignacion: [],

            // Q calendar
            dateStart: '',
            dateEnd: '',
            config: {
                week: {
                    // Takes the value 'sunday' or 'monday'
                    // However, if startsOn is set to 'sunday' and nDays to 5, the week displayed will be Monday - Friday
                    startsOn: 'monday',
                    // Takes the values 5 or 7.
                    nDays: 7,
                    // Scroll to a certain hour on mounting a week. Takes any value from 0 to 23.
                    // This option is not compatible with the 'dayBoundaries'-option, and will simply be ignored if custom day boundaries are set.
                    scrollToHour: 5,
                },
                month: {
                    // Hide leading and trailing dates in the month view (defaults to true when not set)
                    showTrailingAndLeadingDates: false,
                },
                // if not set, the mode defaults to 'week'. The three available options are 'month', 'week' and 'day'
                defaultMode: 'month',
                // The silent flag can be added, to disable the development warnings. This will also bring a slight performance boost
                isSilent: true,

                showCurrentTime: true, // Display a line indicating the current time,
                style: {
                    colorSchemes: {
                        meetings: {
                            color: "#fff",
                            backgroundColor: "#131313",
                        },
                        sports: {
                            color: "#fff",
                            backgroundColor: "#ff4081",
                        },
                    },
                }
            },
            eventosList: [],
            eventosListTmp: [],
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
            OrderGetPosCart: 'OrderGetPosCart',
            OrderGetClientSelected: 'OrderGetClientSelected',
        }),
    },
    watch: {
        OrderGetPosCart: function () {
            this.calculateProductsAmount();
        },
    },
    mounted() {
        this.loadItems();
        this.loadPersonal();
    },
    methods: {
        Numbro,
        dayjs,
        loadItems() {
            const self = this;
            const personalArray = self.asignaciones.map(item => ({ id: item.id }));
            API.send('POST', 'orders/reservas/list', {
                    dateStart: self.dateStart,
                    dateEnd: self.dateEnd,
                    personal: personalArray,
                },
                function (response) {
                    if (response.status) {

                        self.dateStart = response.data.dateStart;
                        self.dateEnd = response.data.dateEnd;
                        console.log("Start: "+ self.dateStart+ " End: "+ self.dateEnd);
                        const eventosListTmp = [];

                        if (typeof response.data.detalle !== 'undefined') {
                            Object.keys(response.data.detalle).map(function (a) {

                                const evento = response.data.detalle[a];
                                const motivo = (typeof evento.metadata !== 'undefined' && typeof evento.metadata.preclinica_motivo !== 'undefined' && typeof evento.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined') ? evento.metadata.preclinica_motivo.motivo_principal_consulta : 'Sin motivo de asistencia especificado';
                                const cancelada = (parseInt(evento.estado) === 1);

                                eventosListTmp.push({
                                    title: evento.nombre,
                                    with: evento.personalNombre,
                                    time: { start: evento.fechaInicial, end: evento.fechaFinal },
                                    color: cancelada ? "green" : "red",
                                    isEditable: cancelada,
                                    id: evento.id,
                                    description: '<b>Motivo de cita:&nbsp;</b>' + motivo + (evento.entrecita ? '<br/> <b>Entre cita:&nbsp;</b> ' + evento.entrecitaMotivo : ''),
                                    disableDnD: [
                                        'month', 'week', 'day'
                                    ],
                                    disableResize: [
                                        'month', 'week', 'day'
                                    ],
                                })
                            })
                        }
                        self.eventosList = eventosListTmp;
                        //self.eventosList = response.data.detalle;

                        //self.calendarOptions.events = response.data.eventos;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        cancelSuscription(suscripcionId) {

            const self = this;
            //console.log(suscripcionId);

            API.showConfirm('Antes de continuar', 'La cancelación de una suscripción es definitiva, si la cancela, no podrá reactivarla.', function () {
                API.send('POST', 'orders/subscription/cancel', {
                        "suscripcionId": suscripcionId,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                            self.loadItems();
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            })
        },
        loadPersonal() {
            const self = this;
            self.personal = [];
            self.asignaciones = [];
            API.send('GET', 'personal/all/', {}, function (response) {
                    if (response.status) {

                        for (const [key, value] of Object.entries(response.data)) {
                            //console.log(key, value);
                            self.personal.push({name: value.nombres + ' ' + value.apellidos, id: value.id});
                        }
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        handleDateClick: function (arg) {
            alert('date click! ' + arg.dateStr)
        },
        eventClick: function (info) {
            info.jsEvent.preventDefault(); // don't let the browser navigate
        },

        // Calendario
        cambioFechas(data) {
            this.dateStart = data.start;
            this.dateEnd = data.end;
            this.loadItems();
        },
        editarEvento(data) {
            window.location.href = '/medic/ficha/'+data;
        },
        eliminarEvento(data) {
            const self = this;

            API.showConfirm('Antes de continuar', 'Si cancela esta reserva, el espacio estará disponible nuevamente, ¿desea continuar?', function () {
                API.send('POST', 'orders/reservas/cancel', {
                        id: data
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.loadItems();
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            })
        },
        handlePersonalClick(){
            this.loadItems();
        },

        // Agendamiento
        selectClient: function (data) {
            this.agendarCliente = data;
            this.showAgendamiento = true;
        },
        productSelected: function (data) {

            this.$store.dispatch('OrderClearProducts');
            this.$store.dispatch('OrderPosClientClear');

            this.producto = data;
            this.showProductSearchBox = false;
            this.showClientSearch = true;
        },
        selectFecha: function (data) {
            this.fecha = data;
            this.showResumen = true;
        },
        cancelarAgendamiento: function () {
            this.showResumen = false;
            this.showAgendamiento = false;
            this.showClientSearch = false;
            this.showProductSearchBox = false;
            this.fecha = {};
            this.producto = {};
            this.agendarCliente = {};
        },
        calculateProductsAmount() {
            this.montoProductos = 0;
            this.impuestos = 0;
            //console.log(this.OrderGetPosCart);
            for (let item in this.OrderGetPosCart) {
                this.OrderGetPosCart[item]['product']['subTotal'] = 0;
                this.OrderGetPosCart[item]['product']['impuestoTotal'] = 0;
                this.OrderGetPosCart[item]['product']['iva'] = 0;

                if (typeof this.OrderGetPosCart[item] !== 'undefined') {

                    // si es un producto por pago directo
                    if (this.OrderGetPosCart[item]['product']['id'] === '_DIRECT_AMOUNT_') {
                        this.hasProducts = false;
                    }
                    const productPrice = (this.OrderGetPosCart[item]['product']['onSale']) ? this.OrderGetPosCart[item]['product']['precioOnSale'] : this.OrderGetPosCart[item]['product']['precio'];


                    if ((productPrice > 0) && (typeof this.OrderGetPosCart[item]['quantity'] !== 'undefined')) {
                        const amount = parseFloat(this.OrderGetPosCart[item]['quantity']) * parseFloat(productPrice);
                        this.OrderGetPosCart[item]['product']['subTotal'] = amount;
                        //console.log(productPrice);
                        //const iva = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestos']);
                        if (this.emitirFactura) {
                            if (this.hasProducts) {
                                if (typeof this.OrderGetPosCart[item]['product']['impuestoMonto'] !== 'undefined' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '0' && this.OrderGetPosCart[item]['product']['impuestoMonto'] !== '') {
                                    const impuestos = parseInt(this.OrderGetPosCart[item]['quantity']) * parseFloat(this.OrderGetPosCart[item]['product']['impuestoMonto']);
                                    this.montoProductos += impuestos + amount;

                                    this.OrderGetPosCart[item]['product']['subTotal'] = impuestos + amount;
                                    this.OrderGetPosCart[item]['product']['impuestoTotal'] = impuestos;
                                    this.impuestos = parseFloat(this.impuestos) + impuestos;
                                }

                            }
                            else {
                                const impuestos = 0;
                                this.montoProductos += impuestos + amount;

                            }

                            const montoGravable = amount / 1.12;
                            const IVA = amount - montoGravable;
                            this.OrderGetPosCart[item]['product']['iva'] = IVA;
                        }
                        else {
                            this.montoProductos += amount;
                        }
                    }
                }
            }
            this.montoProductos = this.montoProductos.toFixed(2);
        },
        createOrder(data) {

            const self = this;
            if (!data) data = {};

            const ordenDetalle = {};
            let counter = 0;
            this.calculateProductsAmount();

            // si es monto directo
            if (typeof self.OrderGetPosCart['_DIRECT_AMOUNT_'] !== 'undefined') {
                ordenDetalle[counter] = {
                    "productoId": 0,
                    "productoPrecio": self.OrderGetPosCart['_DIRECT_AMOUNT_']['product']['precio'],
                    "productoCantidad": self.OrderGetPosCart['_DIRECT_AMOUNT_']['quantity'],
                    "productoDescMontoDirecto": self.OrderGetPosCart['_DIRECT_AMOUNT_']['product']['nombre']
                };
            }
            else {
                // validate if posCart is corrupted or something0
                for (let item in self.OrderGetPosCart) {
                    if (typeof self.OrderGetPosCart[item] !== 'undefined') {

                        const precio = (self.OrderGetPosCart[item]['product']['onSale']) ? self.OrderGetPosCart[item]['product']['precioOnSale'] : self.OrderGetPosCart[item]['product']['precio'];
                        const productTmp = self.OrderGetPosCart[item]['product'];

                        ordenDetalle[counter] = {};
                        ordenDetalle[counter]['productoId'] = productTmp.id;
                        ordenDetalle[counter]['productoPrecio'] = precio;
                        ordenDetalle[counter]['productoCantidad'] = (productTmp.agendable) ? 1 : productTmp.cantidad; // Si es agendable, siempre llevará cantidad 1
                        ordenDetalle[counter]['citaLocacion'] = self.fecha.locacionId;
                        ordenDetalle[counter]['citaFecha'] = self.fecha.fecha;
                        ordenDetalle[counter]['citaHora'] = self.fecha.horario;
                        ordenDetalle[counter]['citaEntrecita'] = self.fecha.entrecita;
                        ordenDetalle[counter]['citaEntrecitaMotivo'] = self.fecha.entrecitaMotivo;
                        ordenDetalle[counter]['citaPersonal'] = self.fecha.personal;

                        ordenDetalle[counter]['clienteId'] = self.fecha.clienteCita.id;
                        ordenDetalle[counter]['clienteNombre'] = self.fecha.clienteCita.nombres;
                        ordenDetalle[counter]['clienteApellido'] = self.fecha.clienteCita.apellidos;
                        ordenDetalle[counter]['clienteCuiPassport'] = self.fecha.clienteCita.dpi;
                        ordenDetalle[counter]['clienteTelefono'] = self.fecha.clienteCita.telefono;
                        ordenDetalle[counter]['clienteEmail'] = self.fecha.clienteCita.email;
                        ordenDetalle[counter]['clienteFechaNacimiento'] = self.fecha.clienteCita.fechaNacimiento;
                        ordenDetalle[counter]['clienteGenero'] = self.fecha.clienteCita.genero;
                        ordenDetalle[counter]['clienteMotivoCita'] = self.fecha.clienteCita.motivoCita;
                    }
                    counter++;
                }
            }

            if (Object.keys(ordenDetalle).length === 0) {
                API.showErrorAlert('Su orden no contiene productos');
                return false;
            }

            if (typeof self.OrderGetClientSelected.nombres === 'undefined') {
                API.showErrorAlert('Debe seleccionar un cliente o Consumidor Final');
                return false;
            }

            const dataPay = {
                "pagoMetodo": self.paymentMethod, // card, pos, cash, qr, contra entrega, recoger en tienda
                "pagoTcNombre": '',
                "pagoTcNumero": '',
                "pagoTcExp": '',
                "pagoTcCvv": '',
                "pagoCuotas": '',
                "pagoAfiliacion": '',

                "clienteNombre": (typeof self.OrderGetClientSelected.nombres !== 'undefined') ? self.OrderGetClientSelected.nombres + ' ' + self.OrderGetClientSelected.apellidos : '',
                "clienteTaxId": (typeof self.OrderGetClientSelected.taxId !== 'undefined') ? self.OrderGetClientSelected.taxId : '',
                "clienteEmail": (typeof self.OrderGetClientSelected.email !== 'undefined') ? self.OrderGetClientSelected.email : '',
                "clienteTelefono": (typeof self.OrderGetClientSelected.telefono !== 'undefined') ? self.OrderGetClientSelected.telefono : '',
                "clienteDireccion": (typeof self.OrderGetClientSelected.direccion !== 'undefined') ? self.OrderGetClientSelected.direccion : '',

                "ordenTiendaDomain": self.AuthGetUserInfo.s,
                "ordenMonto": self.montoProductos,
                "ordenDetalle": ordenDetalle,
                "ordenDesactivarNotificaciones": 1,
                "ordenId": 0,

                "facturaEmitir": false,
                "reciboEmitir": false,
                "facturaCambiaria": false,
                "facturaFecha": false,
            }

            //console.log(dataPay);
            // return false;

            API.send('POST', 'payments/create-pay-order', dataPay,
                function (response) {

                    if (typeof response.data !== 'undefined') {
                        /*self.orderCreated = response.data.ordenCodigo;
                        self.orderCreatedId = response.data.ordenId;
                        self.orderPayLink = response.data.paylink;
                        if (typeof response.data.extra.factura !== 'undefined') {
                            self.facturaDetails = response.data.extra.factura;
                        }
                        if (typeof response.data.extra.recibo !== 'undefined') {
                            self.reciboDetails = response.data.extra.recibo;
                        }
                        self.$store.dispatch('OrderSaveCurrentOrder', self.orderCreatedId);
                        self.showOrderDetailsCreation = true;*/
                        self.$store.dispatch('OrderClearProducts');
                        self.$store.dispatch('OrderPosClientClear');

                        self.showResumen = false;
                        self.fecha = {};
                        self.producto = {};
                        self.agendarCliente = {};

                        API.showSuccessAlert('Cita agendada con éxito');
                        self.cancelarAgendamiento();

                        self.loadItems();
                    }
                    else {
                        API.showErrorAlert('Error al crear orden, por favor, intente de nuevo');
                    }
                },
                function (response) {
                    if (typeof response.data.extra !== 'undefined' && typeof response.data.extra.error !== 'undefined') {
                        API.showErrorAlert(response.data.extra.error);
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                })
        },
    },

};
</script>
