<template>
    <setting-layout :active="41">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de clientes</h4>
                <div>
                    <router-link to="/client/0" class="btn btn-primary btn-sm mr-2">
                        <i class="fa fa-plus-circle"></i> Agregar
                    </router-link>
                    <router-link to="/clients/add/massive" class="btn btn-primary btn-sm mr-2">
                        <i class="fa fa-plus-circle"></i> Carga masiva
                    </router-link>

                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12">
                        Buscar por:
                        <div class="row">
                            <div class="col-12 col-sm-10">
                                <div class="row">
                                    <div class="col-12 col-sm-3">
                                        <input type="text" placeholder="DPI o Pasaporte" v-model="filterDPI" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <input type="text" placeholder="Nombre" v-model="filterNombre" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <input type="text" placeholder="Apellido" v-model="filterApellido" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                    <div class="col-12 col-sm-3">
                                        <input type="text" placeholder="Correo Electrónico" v-model="filterEmail" class="form-control mb-1" v-on:keyup.enter="doSearch">
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-sm-2 text-right">
                                <button class="btn btn-primary w-100"><i class="fa fa-search mr-2"></i> Buscar</button>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <nav aria-label="navigation" class="p-2">
                            <ul class="pagination">
                                <li class="page-item" v-for="item in pagination" :key="'page' + item">
                                    <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                    <div v-else class="page-link">{{ item }}</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div class="form">
                    <ul>
                        <li v-for="item in itemList" :key="item.id" class="h-50">
                            <div class="listItem">
                                <div class="row">
                                    <div class="col-12 col-md-8">
                                        <div>
                                            <h5 class="mt-0 mb-1">{{item.nombres}} {{item.apellidos}}</h5>
                                            <div><b>Correo electrónico:</b> {{ item.email }}</div>
                                            <div class="text-primary" v-if="item.edad"><b>Edad:</b> {{ item.edad }} años</div>
                                            <div v-if="item.cui_passport"><b>Documento de identificación</b> {{ item.cui_passport }}</div>
                                            <div>
                                                <b>Género:</b>
                                                <span v-if="item.genero === 'M'">Hombre</span>
                                                <span v-else-if="item.genero === 'F'">Mujer</span>
                                                <span v-else>No especificado</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 text-right">
                                        <div class="edit-option">
                                            <router-link :to="'/client/'+item.id">
                                                <i class="fa fa-pencil"></i>
                                            </router-link>
                                            <!--<a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>-->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";
import dayjs from "dayjs";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            itemList: {},
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filterDPI: '',
            filterNombre: '',
            filterApellido: '',
            filterEmail: '',
            searchTerm: 0
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    mounted() {
        this.loadItems();
    },
    watch: {
    },
    methods: {
        dayjs,
        doSearch() {
            const self = this;
            self.pageSelected = 1;
            self.limit = 20;
            self.loadItems();
        },
        loadItems() {
            const self = this;

            API.send('POST', 'clients/get-list', {
                    page: self.pageSelected,
                    perPage: self.limit,
                    filterDPI: self.filterDPI,
                    filterNombre: self.filterNombre,
                    filterApellido: self.filterApellido,
                    filterEmail: self.filterEmail,
                },
                function (data) {
                    if (data.status) {
                        self.itemList = data.data.clientes;
                        self.pagination = data.data.paginas;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'products/remove/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessNotify(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            })
        },
    },
};
</script>
