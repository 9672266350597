<template>
    <setting-layout :active="33">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">{{menu[tipo].titulo}}</h4>
                <router-link to="/products/brand/add" class="btn btn-primary px-4 mr-3">
                    <i class="fa fa-plus-circle"></i> Nueva
                </router-link>
            </div>
            <div class="card-body">
                <div class="card-subtitle">
                    {{menu[tipo].marcas}}
                </div>
                <div class="form">
                    <ul class="linked_account">
                        <li v-for="item in categories" :key="item.id">
                            <div class="row">
                                <div class="col-12">
                                    <div class="media">
                                        <span class="mr-3"><i class="fa fa-list-alt"></i></span>
                                        <div class="media-body">
                                            <h5 class="mt-0 mb-1">{{ item.nombre }}</h5>
                                            <p>Identificador o Slug: <b>&nbsp; {{ item.slug }}</b></p>
                                            <p v-if="item.descripcion !== ''">Descripción: {{ item.descripcion }}</p>
                                        </div>
                                        <div class="edit-option">
                                            <router-link :to="'/products/brand/edit/'+item.id">
                                                <i class="fa fa-pencil"></i>
                                            </router-link>
                                            <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";
import {store} from "/src/store";

export default {
    components: {
        settingLayout,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    data() {
        return {
            categories: {},
            tipo: (typeof (store.getters.whiteLabelInfo.tpl) !== 'undefined' && store.getters.whiteLabelInfo.tpl === 'medicina')?'medicina':'default',
            menu: {
                default: {
                    titulo:'Marcas',
                    single:'Marca',
                    marcas:'Editor de marcas',
                    logo:'Logotipo de la marca',
                    logoHelp:'Imagen a mostrar referente a la marca',
                },
                medicina: {
                    titulo:'Especialidades',
                    single:'Especialidad',
                    marcas:'Editor de especialidades',
                    logo:'Icono para la especialidad',
                    logoHelp:'Este icono o imagen se mostrará en tu página pública',
                }
            },
        };
    },
    mounted() {
        this.loadItems();
    },
    methods: {
        loadItems() {
            const self = this;
            API.send('GET', '/products/allBrand/1', {},
                function (data) {
                    if (data.status) {
                        self.categories = data.data;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue, 'Enlace de pago copiado al portapapeles');
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'products/removeCategoria/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
    },
};
</script>
