<template>
<layout>
	<div class="content-body">
		<div class="verification section-padding mb-80">
			<div class="container h-100">
				<div class="row justify-content-center h-100 align-items-center">
					<div class="col-xl-8 col-md-6">
						<div class="auth-form card">
							<div class="card-header">
								<h4 class="card-title">
									<a href="/account-affiliations" v-if="tipo === false">
										<i class="back fa fa-arrow-circle-left"></i>
									</a>
									<div @click="id=0; tipo=false;" v-if="tipo !== false && !nuevo">
										<i class="back fa fa-arrow-circle-left"></i>
									</div>
									<div @click="id=0; nuevo = false;" v-if="nuevo">
										<i class="back fa fa-arrow-circle-left"></i>
									</div>
									<div v-if="id === 0">
										Agregar nueva
									</div>
									<div v-else>
										Editar
									</div>
								</h4>
							</div>
							<div class="card-body">
								<div class="form-row">
									<div class="form-group col-12 editPage-piker">
										<label class="mr-sm-2">Seleccione la empresa para integrar sus ordenes</label>
                                        <div class="row">
                                            <div class="col-sm-6 cursor-pointer" v-if="tipo === false"  v-for="(config, keyform) in campos"
                                                 :key="keyform"
                                                 :value="config.slug"
                                                 @click="tipo=keyform; getDataGoal(keyform)">
                                                <div  class="widget-card">
                                                    <div class="widget-info">
                                                        <img class="img-fluid"  style="height: 50px;" :src="config.logo" alt=""/>
                                                    </div>
                                                    <div class="widget-title card-body">
                                                        <h5>{{config.nombre}}</h5>
                                                        <p class="text-success">{{config.empresa}} <span></span></p>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
										<span class="validationError">{{Valid.tipo}}</span>
									</div>
								</div>
								<!-- Visanet-->
								<div class="form-row" v-if="tipo !== false">
									<div class="col-12 mb-4">
										<div v-if="!nuevo && camposFinales">
											Encontrarás estos datos en tu panel de control de
											{{campos[tipo].nombre}}, si no tienes uno puedes iniciar el proceso para
											obtener tus claves <br/>
										</div>
										<div v-if="!camposFinales && tipo !== false">

											No hay campos configurados para esta categoría
										</div>
										<div class="btn-group btn-group-sm mb-1 m-1 text-sm-left">
											<button class="btn btn-outline-secondary btn-sm"
											        v-on:click.native="nuevaSolicitud(tipo)"
											        v-if="!nuevo && camposFinales">
												<i class="mdi mdi-plus"></i> No tengo credenciales
											</button>
											<button class="btn btn-outline-secondary btn-sm"
											        v-on:click.native="cancelarNuevo()" v-if="nuevo">
												<i class="mdi mdi-cancel"></i> Cancelar
											</button>
											<button class="btn btn-outline-primary btn-sm"
											        v-on:click.native="crearSolicitud(categoriaConfig)"
											        v-if="solicitud == null && nuevo">
												<i class="mdi mdi-file-send"></i> Crear Solicitud
											</button>
											<button class="btn btn-outline-primary btn-sm"
											        v-if="solicitud && nuevo && camposFinales">
												<i class="mdi mdi-eye-settings"></i> Ver solicitud
											</button>
										</div>
									</div>

									<div class="row" v-if="!nuevo && tipo !== false">
										<div class="form-group col-sm-12 col-xl-6">
											<label class="mr-sm-2">Nombre o descripción</label>
											<input type="text" class="form-control" placeholder="Escribe aquí"
											       v-model="nombre" name="nombre"/>
											<span class="validationError">{{Valid.nombre}}</span>
										</div>
										<div class="form-group col-sm-12 col-xl-6" v-for="(value, key) in dataToGoal">
											<label class="mr-sm-2">{{key}}</label>
											<input type="text" class="form-control" placeholder="Escribe aquí"
											       v-model="valores[key]"/>
											<span class="validationError">{{Valid.key}}</span>
										</div>
									</div>
									<div v-if="nuevo && camposFinales">
										<div class="form-row">
											<div class="form-group col-12 editPage-piker">
												<label class="mr-sm-2">Seleccione una opción</label>
												<select class="form-control" v-model="itemPadre" v-on:change="getCampos">

													<option v-for="(value, key) in categoriasCampos" :key="key"
													        :value="key">{{key}}
													</option>
												</select>
											</div>
										</div>
										<div class="row" v-if="camposFinales">
											<div class="form-group col-xl-6 col-sm-12"
											     v-for="(value, key) in camposFinales">
												<div class="card-header">
													<h4 class="card-title">
														{{value.nombre}}
														<i class="fa fa-question-circle" v-tooltip="''"></i>
													</h4>
												</div>
												<div class="card-body card-form">
													<div class="galleryContainer"
													     v-if="value.tipoCampo == 'imagen' || value.tipoCampo =='archivo'">
														<div class="galleryItem"
														     v-for="item in camposFinales[key].valores"
														     :key="item.id" v-tooltip="item.nombreOriginal">
															<div class="galleryItemImg"
															     :style="'background-image: url('+item.valor+')' ">
																<div class="overlay">
																	<!--                                                                        <i class="fa fa-search mr-2"></i>-->
																	<i class="fa fa-trash"
																	   @click="deleteImageFromGallery(item.id, tipo)"></i>
																</div>
															</div>
														</div>
													</div>
													<div
														v-if="(camposFinales[key].valores.length < value.max || !camposFinales[key].valores.length) && (value.tipoCampo == 'imagen' || value.tipoCampo =='archivo')">
														<FileUploader field-name="papeleria"
														              url="users/integraciones/form/fields"
														              accepted-mime="image/jpg, image/jpeg, image/png, image/gif, application/pdf, .pfx"
														              remove-file-on-finish="1"
														              :extra-data-form="{campoId : value.id, campoType: value.tipoCampo}"
														              @fileSuccess="fileSuccess">
														</FileUploader>
													</div>
													<div class="form-group" v-if="value.tipoCampo == 'texto'">
														<input type="text" class="form-control"
														       placeholder="Escribe aquí"/>
														<span class="validationError">{{Valid.key}}</span>
													</div>
													<div class="form-group" v-if="value.tipoCampo == 'numero'">
														<input type="number" class="form-control"
														       placeholder="Escribe aquí"/>
														<span class="validationError">{{Valid.key}}</span>
													</div>
													<div class="form-group" v-if="value.tipoCampo == 'email'">
														<input type="email" class="form-control"
														       placeholder="Escribe aquí"/>
														<span class="validationError">{{Valid.key}}</span>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="form-row mt-3" v-if="!nuevo && tipo !== false">
									<button v-on:click.native="formSubmit()" class="btn btn-success pl-5 pr-5">Guardar
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import DataValidation from "src/core/DataValidation";
import Tools from "../../../../core/Tools";
//import {store} from "store";

export default {
	components: {
		Layout,
		FileUploader,
	},
	data() {
		return {
			Valid: {},
			id: 0,
			nuevo: false,
			nombre: '',
			tipo: false,
			merchant: '',
			merchantUser: '',
			merchantPasswd: '',
			terminalId: '',
			dataToGoal: {},
			categoriasCampos: {},
			campos: {},
			valores: {},
			camposFinales: false,
			itemPadre: false,
			tipos: false,
			categoriaConfig: false,
			solicitud: false,
			slug: '',
			slugSel: '',
		};
	},
	mounted: function () {
		const self = this;
		if (parseInt(this.$route.params.id) > 0) {
			API.send('GET', 'payments/affiliation/' + this.$route.params.id, {},
				function (response) {
					if (response.status) {
						self.nombre = response.data.nombre;
						self.slugSel = response.data.tipo;
						self.valores = response.data.payload;
						self.id = response.data.id;
					} else {
						self.msg = response.msg;
					}
				},
				function (response) {
					API.showErrorAlert(response.msg);
				})
		}
		API.send('GET', 'users/integraciones/campos/', {},
			function (response) {
				if (response.status) {
					self.campos = response.data;
					const valObj = response.data.filter(function (elem) {
						if (elem.slug == self.slugSel) return elem;
					});
					if (typeof (valObj) != 'undefined' && valObj.length > 0) {
						//console.log(valObj);
						let [first] = Object.keys(valObj)
						self.slug = valObj.slug;
						self.campos = valObj;
						self.tipo = first;
						self.dataToGoal = JSON.parse(self.campos[self.tipo].dataGoal);
						self.slug = self.campos[self.tipo].slug;
						if (typeof self.campos[self.tipo].campos === 'undefined') {
							self.camposFinales = false;
						} else {
							self.camposFinales = true;
							self.categoriasCampos = self.campos[self.tipo].campos;
						}
						//console.log(first);

					}
				} else {
					self.msg = response.msg;
				}
			},
			function (response) {
				API.showErrorAlert(response.msg);
			})
	},
	methods: {
		formSubmit() {

			const self = this;
			//console.log(id);
			//self.id = id;
			const dataToSend = {
				nombre: self.nombre,
				tipo: self.slug,
				payload: self.valores
			};

			if (self.id > 0) {
				API.send('PUT', 'payments/affiliation/' + self.id, dataToSend,
					function (data) {
						API.showSuccessNotify(data.msg);
						//self.$router.go(-1);
						//self.$router.push('/account-affiliations')
					},
					function (data) {
						API.showErrorNotify(data.msg);
					})
			} else {
				API.send('POST', 'payments/affiliation', dataToSend,
                    function (data) {
						    self.id = data.data.id;
						//console.log(data)
						API.showSuccessNotify(data.msg);
						//self.$router.go(-1);
						//self.$router.push('/account-affiliations')
					},
					function (data) {
						API.showErrorNotify(data.msg);
					})
			}
		},
		getDataGoal: function (key) {
			const self = this;
			self.dataToGoal = JSON.parse(self.campos[key].dataGoal);
			self.slug = self.campos[key].slug;
			if (typeof self.campos[key].campos === 'undefined') {
				self.camposFinales = false;
			} else {
				self.camposFinales = true;
				self.categoriasCampos = self.campos[key].campos;
			}
			//console.log(self.tipo);
		},
		nuevaSolicitud: function (tipos) {
			const self = this;
			self.categoriasCampos = self.campos[tipos].campos;
			self.slug = self.campos[tipos].slug;

			if (self.categoriasCampos.length <= 0) {
				self.camposFinales = false;
			}
			self.nuevo = true;
		},
		cancelarNuevo: function () {
			this.nuevo = false;
		},
		getCampos: function (e) {
			const self = this;
			let key = e.target.value;
			self.nuevo = true;
			self.slug = self.campos[self.tipo].slug;
			self.itemPadre = key;
			self.categoriaConfig = self.campos[self.tipo].id;
			self.solicitud = self.campos[self.tipo].solicitud;
			//console.log(self.solicitud);
			self.camposFinales = self.campos[self.tipo].campos[key];
		},
		deleteImageFromGallery(id, tipos) {
			const self = this;
			API.showConfirm('¿Esta seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
				API.send('DELETE', 'users/integraciones/form/field/' + id, {},
					function (response) {
						if (response.status) {
							self.campos = response.data.data;
							self.camposFinales = self.campos[tipos].campos[self.itemPadre];
							self.slug = self.campos[tipos].slug;
							API.showSuccessNotify(response.msg);

						}
					},
					function (response) {
						API.showErrorNotify(response.msg);
					})
			})
		},
		crearSolicitud(id) {
			const self = this;
			const data = {
				configuracionId: id,
			}
			API.send('POST', 'users/integraciones/crearSolicitud', data,
				function (response) {
					if (response.status) {
						self.campos = response.data.data;
						self.camposFinales = self.campos[self.tipo].campos[self.itemPadre];
						API.showSuccessNotify(response.msg);
					}
				},
				function (response) {
					API.showErrorNotify(response.msg);
				})
		},
		fileSuccess(data) {
			const self = this;
			self.campos = data.data;
			self.camposFinales = self.campos[self.tipo].campos[self.itemPadre];
		},
	},
    watch: {
        redirectData(val) {
            this.payRedirectionData = val;
        }
    }
};
</script>
