<template>
    <div>
        <ficha-layout title="Odontología" color="#FF0000FF" :tabs="tabs" tab-default="odontograma" enable-timeline="true">

            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #odontograma>
                <odontograma></odontograma>
            </template>

            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
        </ficha-layout>
    </div>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


import FichaLayout from './components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';

// componentes medicos
import Antecedentes from './components/Antecedentes.vue';
import ExploracionFisica from './components/ExploracionFisica.vue';
import Tratamiento from './components/Tratamiento.vue';
import LaboratorioRadiologia from './components/LaboratorioRadiologia.vue';
import PreClinica from './components/PreClinica.vue';
import ImpresionClinica from './components/ImpresionClinica.vue';
import Odontograma from './components/Odontologia.vue';
import Resumen from './components/Resumen.vue';

export default {
    components: {
        Box,
        FichaLayout,
        Antecedentes,
        ExploracionFisica,
        Tratamiento,
        LaboratorioRadiologia,
        PreClinica,
        ImpresionClinica,
        Resumen,
        Odontograma,
    },
    props: [],
    data() {
        return {
            tabs: {

                /*consulta: {
                    icon: 'fas fa-stethoscope',
                    name: 'Medicina general',
                },*/
                /*impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },*/
                odontograma: {
                    icon: 'fas fa-tooth',
                    name: 'Odontograma', // cie10 mover para acá y laboratorios y procedimientos
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },

                /*resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },*/
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    watch: {
    },
    mounted() {
        // this.loadItems();
    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
    },
};

</script>
