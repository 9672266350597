<template>
    <setting-layout :active="31">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Listado de productos</h4>
                <div>
                    <router-link to="/products/add" class="btn btn-primary btn-sm mr-2">
                        <i class="fa fa-plus-circle"></i> Agregar
                    </router-link>
                    <router-link to="/products/add/massive" class="btn btn-primary btn-sm d-none d-sm-inline mr-2">
                        <i class="fa fa-rocket"></i> Carga masiva
                    </router-link>
                    <router-link to="/products/add-images/massive" class="btn btn-primary btn-sm d-none d-sm-inline">
                        <i class="fa fa-upload"></i> Imágenes
                    </router-link>
                </div>
            </div>
            <div class="card-body">
                <div class="card-subtitle">
                    <div v-if="typeof whiteLabelInfo.enlaces !== 'undefined'" class="mb-2">
                        Te recomendamos leer: <a :href="whiteLabelInfo.enlaces.carga_productos" class="text-primary text-decoration-underline" target="_blank">Guía sobre cómo cargar productos</a>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-6">
                        <nav aria-label="navigation" class="p-2">
                            <ul class="pagination">
                                <li class="page-item" v-for="item in pagination" :key="'page' + item">
                                    <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                    <div v-else class="page-link">{{ item }}</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="input-group pb-sm-2">
                            <input type="text" placeholder="Filtrar" v-model="filter" class="form-control">
                            <div class="input-group-append">
                            <span id="basic-addon2" class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form">
                    <ul>
                        <li v-for="item in productList" :key="item.id" class="h-50">
                            <div class="listItem">
                                <div class="row">
                                    <div class="col-6 col-md-2">
                                        <div>
                                            <img v-if="item.thumbnail !== ''" :src="item.thumbnail" class="img-thumbnail">
                                            <i v-else class="fa fa-image fa-3x"></i>
                                        </div>
                                    </div>
                                    <div class="col-6 col-md-6">
                                        <div>
                                            <h5 class="mt-0 mb-1">{{ item.nombre }}</h5>
                                            <div class="text-primary">SKU: {{ item.sku }}</div>
                                            <div>Precio: {{ item.precio }}</div>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-4 text-right">
                                        <div class="edit-option">
                                            <a class="text-primary" @click="copyLink(item.payLink)" v-tooltip="'Copiar enlace de pago a portapapeles'">
                                                <i class="fa fa-link"></i>
                                            </a>
                                            <router-link :to="'/products/edit/'+item.id">
                                                <i class="fa fa-pencil"></i>
                                            </router-link>
                                            <a @click="deleteItem(item.id)"><i class="fa fa-trash"></i></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {mapGetters} from "vuex";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            productList: {},
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filter: '',
            searchTerm: 0
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    mounted() {
        this.loadItems();
    },
    watch: {
        filter(val) {
            const self = this;

            if(val.length >= 1){
                self.pageSelected = 1;
                self.limit = 20;
                self.loadItems();
            }
            else{
                self.filter = '';
                self.limit = 10;
                self.loadItems();
            }
        }
    },
    methods: {
        loadItems() {
            const self = this;
            if(self.filter.length === 0){
                 self.searchTerm = 0;
            }
            else{
                 self.searchTerm = self.filter;
            }

            API.send('GET', 'products/all/1/'+self.pageSelected+'/'+self.limit+'/'+self.searchTerm, {},
                function (data) {
                    if (data.status) {
                        self.productList = data.data.productos;
                        self.pagination = data.data.paginas;
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue, 'Enlace de pago copiado al portapapeles');
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'products/remove/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessNotify(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            })
        },
    },
};
</script>
