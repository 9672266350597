<template>
    <layout>
        <div class="content-body">
            <div class="mb-80">
                <div class="container h-100">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div v-if="errors.length">
                            <b>Por favor corrija los siguientes errores(s):</b>
                            <ul>
                                <li v-for="error in errors" :key="error">
                                    {{ error }}
                                </li>
                            </ul>
                        </div>
                        <div class="col-xl-12 col-md-12">
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                <router-link to="/docs-plus-ocr">
                                                    <i class="back fa fa-arrow-circle-left"></i>
                                                </router-link>
                                                <span>
                                                    Editar plantilla OCR
                                                </span>
                                            </h4>
                                            <img src="staticAssets/formBuilder/docsplus.png"
                                                 style="max-width: 100px">
                                        </div>
                                        <div class="card-body">
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Nombre</label>
                                                    <input type="text" class="form-control"
                                                           placeholder="Escribe aquí" v-model="nombre"/>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Token</label>
                                                    <div class="text-center font-weight-bold">{{token}}</div>
                                                </div>
                                            </div>
                                            <div class="text-center mt-4">
                                                <button type="button" class="btn btn-primary mr-3" @click="save">
                                                    Guardar
                                                </button>
                                                <router-link to="/docs-plus-ocr">
                                                    <button type="button" class="btn btn-danger">Cancelar</button>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 col-md-6">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                <span>
                                                    Pruebas
                                                </span>
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div v-if="id > 0">
                                                <label>Subir archivo de prueba</label>
                                                <div class="input-group mb-3">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Subir archivo de prueba</span>
                                                    </div>
                                                    <div class="custom-file">
                                                        <input type="file" class="custom-file-input" id="loadTestFile">
                                                        <label class="custom-file-label" for="loadTestFile">Clic para
                                                            buscar archivo</label>
                                                    </div>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Separador de página</label>
                                                    <input type="text" class="form-control"
                                                           placeholder="Escribe aquí" v-model="pageSeparator"/>
                                                </div>
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Remover páginas</label>
                                                    <select class="form-control" v-model="removePages">
                                                        <option value="1">Si</option>
                                                        <option value="0">No</option>
                                                    </select>
                                                </div>
                                                <div class="text-right">
                                                    <button class="btn btn-primary" @click="uploadTest">Realizar
                                                        prueba
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Instrucciones:
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div>
                                                <b>Type:</b> Especifica el tipo de token a buscar.
                                            </div>
                                            <div>
                                                <b>ID:</b> Especifica el identificador asignado al token.
                                            </div>
                                            <div>
                                                <b>Ini:</b> Especifica la cadena de búsqueda inicial para el token.
                                            </div>
                                            <div>
                                                <b>Fin:</b> Especifica la cadena de búsqueda final, esta detendrá el volcado de datos al token. Si no coloca fin de token, el token se cerrará hasta encontrar el inicio de otro token o el fin del archivo.
                                            </div>
                                            <div>
                                                <b>Linea:</b> El buscador de línea permite iniciar la búsqueda desde una línea específica. Ejemplo: 10. También puede enviar un rango separado por coma para especificar la línea final en la cual se buscará el token. Ejemplo: 10,15
                                            </div>
                                        </div>
                                    </div>
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Búscar tokens
                                            </h4>
                                        </div>
                                        <div class="card-body">
                                            <div v-for="(item, key) in tokens" :key="item">
                                                <div class="input-group mt-1">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">Type</span>
                                                    </div>
                                                    <div class="input-group-prepend">
                                                        <select class="input-group-text" v-model="item.type">
                                                            <option value="string">String</option>
                                                            <option value="regex">Regex</option>
                                                        </select>
                                                    </div>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text">ID:</span>
                                                    </div>
                                                    <input type="text" class="form-control" v-model="item.id">
                                                    <template v-if="item.type === 'string'">
                                                        <div class="input-group-prepend">
                                                            <span class="input-group-text">Ini:</span>
                                                        </div>
                                                        <input type="text" class="form-control" v-model="item.ini">
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">Fin:</span>
                                                        </div>
                                                        <input type="text" class="form-control" v-model="item.fin">
                                                    </template>
                                                    <template v-if="item.type === 'regex'">
                                                        <div class="input-group-append">
                                                            <span class="input-group-text">Regex:</span>
                                                        </div>
                                                        <input type="text" class="form-control" v-model="item.regex">
                                                    </template>
                                                    <div class="input-group-append">
                                                        <span class="input-group-text">Lineas:</span>
                                                    </div>
                                                    <input type="text" class="form-control" v-model="item.lineas">
                                                </div>
                                                <div class="text-right">
                                                    <a class="text-danger text-small" @click="removeToken(key)"><i
                                                        class="fas fa-trash mr-2"></i>Eliminar</a>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <button @click="addToken" class="btn btn-success btn-sm"><i
                                                    class="fas fa-circle-plus mr-2"></i> Agregar token
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-4">
                                    <div class="card" v-if="id > 0">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Resultados de prueba
                                            </h4>
                                            <button class="btn btn-primary float-right" @click="uploadTest">
                                                Realizar prueba
                                            </button>
                                        </div>
                                        <div class="card-body" v-if="typeof pruebaResponse.tokens !== 'undefined'">
                                            <div v-for="(page, pageKey) in pruebaResponse.tokens.pages" :key="page">
                                                <h4>Página No.{{pageKey+1}}</h4>
                                                <table class="table table-striped">
                                                    <thead>
                                                    <tr>
                                                        <th scope="col"><b>Identificador (ID)</b></th>
                                                        <th scope="col"><b>Valor</b></th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    <tr v-for="(itemValor, itemId) in page" :key="itemId">
                                                        <td>{{itemId}}</td>
                                                        <td>
                                                            <div v-for="valor in itemValor" :key="valor" class="bg-white mb-2 p-2">
                                                                <div v-html="valor"></div>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>

<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import FileUploader from 'src/components/files/FileUploader';

export default {
    components: {
        Layout,
        FileUploader,
    },
    data() {
        return {
            errors: [],
            id: 0,
            nombre: '',
            token: '',
            tokens: [],

            // pruebas
            pageSeparator: '',
            removePages: 0,
            pruebaResponse: {},
        };
    },
    watch: {
        nombre: function (val) {
            this.slug = Tools.createSlug(val);
        },
    },
    mounted: function () {
        this.load();
    },
    methods: {
        load() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {

                API.send('GET', 'formularios/docs-plus/ocr-template/' + this.$route.params.id, {},
                    function (response) {
                        if (response.status) {
                            self.id = response.data.id;
                            self.nombre = response.data.nombre;
                            self.token = response.data.token;
                            self.tokens = response.data.template;
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }
        },
        UploadSuccess() {
            this.load();
        },
        save(e) {

            this.errors = [];
            const self = this;

            if (!this.nombre) this.errors.push('El nombre de la plantilla es requerido');

            if (this.errors.length === 0) {
                const data = {
                    id: this.id,
                    nombre: this.nombre,
                    tokens: this.tokens,
                }

                API.send('POST', 'formularios/docs-plus/ocr-templates/save/', data, function (response) {
                        if (response.status) {
                            API.showSuccessAlert(response.msg);

                            if (parseInt(response.data.id) === 0) {
                                window.location.href = '/docs-plus-ocr/' + response.data.id;
                            }
                        } else {
                            API.showErrorAlert(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            } else {
                API.showFormValidationErrors(this.errors);
            }
            e.preventDefault();
        },
        addToken() {
            this.tokens.push({
                id: '',
                type: 'string',
                ini: '',
                fin: '',
                lineas: '',
            })
        },
        removeToken(key) {
            this.tokens.splice(key, 1);
        },
        uploadTest() {

            const self = this;

            const file = document.getElementById('loadTestFile').files[0];
            const removePages = (this.removePages === '1') ? 1 : 0;

            const fd = new FormData();
            fd.append('file', file);
            fd.append('templateToken', this.token);
            fd.append('process', 'auto');
            fd.append('pageSeparator', this.pageSeparator);
            fd.append('removePages', removePages);
            fd.append('htmlEndlines', 1);
            fd.append('noReturnEndlines', 1);
            /*fd.append('level', self.compressLevel);
            fd.append('response', 'base64');*/

            API.send('FILE', 'formularios/docs-plus/ocr', fd, function (response) {
                    self.pruebaResponse = response.data;
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        }
    },
};
</script>
