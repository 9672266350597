<template>
<layout>
    <div class="content-body">
        <div class="mb-80">
            <div class="container h-100">
                <form id="formData" @submit="save" method="post">
                    <div class="row justify-content-center h-100 align-items-center">
                        <div class="col-xl-12 col-md-12">
                            <div class="row">
                                <div class="col-12">
                                    <div class="card">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                <router-link to="/clients">
                                                    <i class="back fa fa-arrow-circle-left"></i>
                                                </router-link>
                                                Edición de cliente:
                                                <span class="text-primary ml-4" v-if="client.nombres !== ''">{{client.nombres}} {{client.apellidos}}</span>
                                                <span class="text-muted ml-4" v-else>Cliente nuevo</span>
                                            </h4>
                                            <a class="btn btn-primary float-right" href="/client/0">
                                                <i class="fa fa-plus"></i> Agregar
                                            </a>
                                        </div>
                                        <div class="card-body">
                                            <h5 class="text-primary">Datos de cliente</h5>
                                            <hr>
                                            <div class="row">
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">DPI o Pasaporte</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.cui_passport"/>
                                                </div>
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Nombres</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.nombres"/>
                                                </div>
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Apellidos</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.apellidos"/>
                                                </div>
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Género</label>
                                                    <select class="form-control" v-model="client.genero">
                                                        <option value="M">Masculino</option>
                                                        <option value="F">Femenino</option>
                                                    </select>
                                                </div>
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Fecha de nacimiento</label>
                                                    <DatePicker v-model="client.fechaNacimiento">
                                                        <template #default="{ inputValue, inputEvents }">
                                                            <input type="text" class="form-control" placeholder="Seleccione la fecha" :value="inputValue" v-on="inputEvents"/>
                                                        </template>
                                                    </DatePicker>
                                                </div>
                                            </div>
                                            <h5 class="text-primary mt-3">Datos de contacto</h5>
                                            <hr>
                                            <div class="row">
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Correo electrónico</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.email"/>
                                                </div>
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Teléfono de contacto</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.telefono"/>
                                                </div>
                                            </div>
                                            <h5 class="text-primary mt-3">Datos de facturación</h5>
                                            <hr>
                                            <div class="row">
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">NIT</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.taxId"/>
                                                </div>
                                                <div class="form-group col-12 col-sm-4">
                                                    <label class="mr-sm-2">Dirección</label>
                                                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="client.direccion"/>
                                                </div>
                                            </div>
                                            <h5 class="text-primary mt-3">Otros</h5>
                                            <hr>
                                            <div class="row">
                                                <div class="form-group col-12">
                                                    <label class="mr-sm-2">Notas</label>
                                                    <textarea class="form-control" v-model="client.notas"></textarea>
                                                </div>
                                            </div>
                                            <div class="mt-4">
                                                <div class="text-right">
                                                    <button type="button" class="btn btn-primary mr-2" @click="save">
                                                        Guardar
                                                    </button>
                                                    <router-link to="/clients">
                                                        <button type="button" class="btn btn-danger">Cancelar</button>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</layout>
</template>
<script>
import Layout from "/src/layout/LayoutLoader.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import {DatePicker} from 'v-calendar';
import {mapGetters} from "vuex";

export default {
    components: {
        Layout,
        DatePicker,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        }),
    },
    data() {
        return {
            id: 0,
            client: {
                id: 0,
                taxId: '',
                email: '',
                nombres: '',
                apellidos: '',
                telefono: '',
                direccion: '',
                fechaNacimiento: '',
                cui_passport: '',
                genero: '',
            }
        };
    },
    watch: {
    },
    mounted: function () {
        this.id = this.$route.params.id;
        this.getClient();

    },
    methods: {
        getClient() {
            const self = this;
            if (parseInt(this.$route.params.id) > 0) {

                API.send('GET', 'clients/search/' + this.$route.params.id, {},
                    function (response) {

                        if (response.status) {
                            self.client = (typeof response.data[0] !== 'undefined') ? response.data[0] : {};
                        }
                    },
                    function (response) {
                        API.showErrorNotify(response.msg);
                    })
            }
        },
        save() {
            const self = this;
            API.send('POST', 'clients/edit/', this.client, function (response) {
                if (typeof response.status !== 'undefined') {

                    API.showSuccessAlert(response.msg);

                    if (parseInt(self.id) === 0) {
                        window.location.href = '/client/' + response.data.id;
                    }
                } else {
                    API.showErrorNotify('Error al guardar cliente, por favor intente de nuevo');
                }
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })
        },
    },
};
</script>
