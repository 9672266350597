import {CONFIG} from 'src/config';

export default {
    write: (key, data, customDisk) => {

        if (!customDisk) customDisk = false;

        const tmp = localStorage.getItem(customDisk || CONFIG.localStorageDisk);
        let objTMP = {};

        if (tmp) {
            try {
                objTMP = JSON.parse(tmp);
                objTMP[key] = data;
            }
            catch (e) {
                objTMP = {};
            }
        }
        // save object with key
        objTMP[key] = data;
        localStorage.setItem(customDisk || CONFIG.localStorageDisk, JSON.stringify(objTMP));
    },
    read: (key, customDisk) => {

        if (!customDisk) customDisk = false;

        const tmp = localStorage.getItem(customDisk || CONFIG.localStorageDisk);
        let objTMP = {};

        if (tmp) {
            try {
                objTMP = JSON.parse(tmp);
                if (typeof objTMP[key] !== 'undefined') {
                    return objTMP[key];
                }
            }
            catch (e) {
                return null;
            }
        }
        else {
            return null;
        }
    },
    clearDisk: (customDisk) => {
        if (!customDisk) customDisk = false;
        localStorage.setItem(customDisk || CONFIG.localStorageDisk, null);
    }
}
