<template>
    <div id="main-wrapper" class="show">
        <sidebar v-bind:active="active"/>
        <!--<search-bar v-if="search"/>-->
        <page-title v-if="title"/>
        <div class="startModals">
            <div class="startModalsContent"></div>
        </div>
        <slot/>
        <div class="appVersion">
            V.{{appVersion}}
        </div>
    </div>
</template>

<script>

import controlPanelPopupStyle from "./assets/css/popup.lazy.css";
import controlPanelStyle from "./assets/css/style.lazy.css";
import componentsStyleGlobal from "./assets/css/Components.lazy.css";

import PageTitle from "./PageTitle.vue";
import SearchBar from "./SearchBar.vue";
import Sidebar from "./Sidebar.vue";
import {CONFIG} from "src/config";

export default {
    components: {Sidebar, PageTitle, SearchBar},
    props: {
        active: Number,
        title: Boolean,
        search: Boolean,
    },
    data() {
        return {
            appVersion: ''
        };
    },
    beforeCreate() {
        controlPanelStyle.use();
        controlPanelPopupStyle.use();
        componentsStyleGlobal.use();
    },
    unmounted() {
        controlPanelStyle.unuse();
        controlPanelPopupStyle.unuse();
        componentsStyleGlobal.unuse();
    },
    mounted() {
        this.appVersion = CONFIG.appVersion;
    }
};
</script>
