<template>
    <!--<box title="Procedimiento" icon="fas fa-syringe">
        <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_procedimiento === 'undefined'">
            <div class="text-center text-danger">No tiene procedimientos</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Procedimiento</th>
                    <th class="font-weight-bold">Observaciones</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_procedimiento" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.procedimiento }}</td>
                        <td>{{ item.detail.observaciones }}</td>
                        <td>
                            <i @click="editProcedimiento(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deleteProcedimiento(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5" v-if="showEditionProcedimiento">
            <div>
                <h5>Agregar procedimiento</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Procedimiento</label>
                        <input type="text" class="form-control" v-model="procedimiento.detail.procedimiento"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Observaciones</label>
                        <input type="text" class="form-control" v-model="procedimiento.detail.observaciones"/>
                    </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditProcedimiento" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveProcedimiento" class="btn btn-primary mr-3">Guardar</button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addProcedimiento">
                <i class="fa fa-plus mr-2"></i>Agregar a receta
            </button>
        </div>
    </box>
    <box title="Medicamentos o productos aplicados en consulta" icon="fas fa-pills">
        <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_receta === 'undefined'">
            <div class="text-center text-danger">No tiene medicamentos aplicados</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Medicamento o producto</th>
                    <th class="font-weight-bold">Cantidad</th>
                    <th class="font-weight-bold">Observaciones</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_receta" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.medicamento }}</td>
                        <td>{{ item.detail.administracion }}</td>
                        <td>{{ item.detail.observaciones }}</td>
                        <td>
                            <i @click="editMedicamento(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deleteMedicamento(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5" v-if="showEditionProducto">
            <div>
                <h5>Aplicar en consulta</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-6">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Escribe el medicamento o producto</label>
                                <input type="text" class="form-control" v-model="producto.detail.nombre"/>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group pt-1">
                                <button @click="buscarMedicamento" class="btn btn-primary mt-4">o buscar en catálogo</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Cantidad</label>
                        <input class="form-control" v-model="producto.detail.cantidad" type="text">
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Precio</label>
                        <input type="text" class="form-control" v-model="producto.detail.precio"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Observaciones</label>
                        <input type="text" class="form-control" v-model="producto.detail.observaciones"/>
                    </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditMedicamento" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveMedicamento" class="btn btn-primary mr-3">Guardar</button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addMedicamento">
                <i class="fa fa-plus mr-2"></i>Aplicar nuevo
            </button>
        </div>
        <order-product-search v-model:show-product-search-box="showProductSearchBox" tipo-orden="directo" ref="searchComponent" @productSelected="productSelected" tipo-productos="product"></order-product-search>
    </box>-->
    <box title="Tratamiento" icon="fa fa-comment-medical">
        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="form-group">
                    <label>Ingrese el tratamiento</label>
                    <textarea v-model="observaciones" class="form-control" placeholder="Escribe aquí" @change="saveOtros" style="min-height: 250px"></textarea>
                </div>
            </div>
        </div>
    </box>
    <box title="Receta" icon="fas fa-receipt">
        <div v-if="typeof GetMedicoFichaActive.metadata.tratamiento_receta === 'undefined'">
            <div class="text-center text-danger">No tiene datos en receta</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Medicamento</th>
                    <th class="font-weight-bold">Administración</th>
                    <th class="font-weight-bold">Dosis y frecuencia</th>
                    <th class="font-weight-bold">Observaciones</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.tratamiento_receta" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.medicamento }}</td>
                        <td>{{ item.detail.administracion }}</td>
                        <td>{{ item.detail.dosis }} cada {{ item.detail.cada }}, durante {{item.detail.durante}}</td>
                        <td>{{ item.detail.observaciones }}</td>
                        <td>
                            <i @click="editReceta(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deleteReceta(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5" v-if="showEditionReceta">
            <div>
                <h5>Agregar a receta</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Medicamento</label>
                        <input type="text" class="form-control" v-model="receta.detail.medicamento"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Administración</label>
                        <!--<input type="text" class="form-control" v-model="receta.detail.fuente"/>-->
                        <select v-model="receta.detail.administracion" class="form-control">
                            <option :key="item.name" v-for="item in administracionOptions" :value="item.name">{{ item.name }}</option>
                        </select>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Dosis</label>
                        <input class="form-control" v-model="receta.detail.dosis" type="text">
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Cada</label>
                        <input type="text" class="form-control" v-model="receta.detail.cada"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Durante</label>
                        <input type="text" class="form-control" v-model="receta.detail.durante"/>
                    </div>
                </div>
<!--                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>&nbsp;</label>
                        <select v-model="receta.detail.duranteTiempo" class="form-control">
                            <option :key="item.id" v-for="item in duranteOptions" :value="item.name">{{ item.name }}</option>
                        </select>
                    </div>
                </div>-->
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Observaciones</label>
                        <input type="text" class="form-control" v-model="receta.detail.observaciones"/>
                    </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditReceta" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveReceta" class="btn btn-primary mr-3">Guardar</button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addReceta">
                <i class="fa fa-plus mr-2"></i>Agregar a receta
            </button>
        </div>
    </box>
    <!--<box title="Receta" icon="fas fa-file-prescription">
        <div class="row">
            <div class="col-6">
                <div>
                    <label class="toggle">
                        <input class="toggle-checkbox" type="checkbox" v-model="recetaMedicamentoEnInventario" :checked="recetaMedicamentoEnInventario"/>
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                            Médicamento en inventario
                        </span>
                    </label>
                </div>
                <div class="form-group" v-if="recetaMedicamentoEnInventario">
                    <label>Medicamento</label>
                    <multiselect v-model="recetaMedicamento"
                        :options="recetaSearchMedicamentos"
                        track-by="id" label="nombre"
                        :close-on-select="true"
                        :clear-on-select="true"
                        :preserve-search="true"
                        placeholder=""
                        :multiple="false"
                        :internal-search="false"
                        @search-change="getData">
                    </multiselect>
                </div>
                <div v-else class="form-group">
                    <label>Medicamento</label>
                    <input class="form-control" v-model="recetaMedicamento" type="text">
                </div>
            </div>
            <div class="col-6">
                <div class="form-group">
                    <label>
                        Administración
                    </label>
                    <multiselect v-model="recetaAdministracion" :options="administracionOptions" track-by="name" label="name"></multiselect>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-3">
                <div class="form-group">
                    <label>Dosis</label>
                    <input class="form-control" v-model="recetaDosis" type="text">
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label>
                        Cada
                    </label>
                    <select v-model="recetaCada" class="form-control">
                        <option :key="item.id" v-for="item in cadaOptions" :value="item">{{ item.name }}</option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label>
                        Durante
                    </label>
                    <select v-model="recetaDurante" class="form-control">
                        <option :key="item" v-for="item in 60" :value="item">{{ item }}</option>
                    </select>
                </div>
            </div>
            <div class="col-3">
                <div class="form-group">
                    <label>&nbsp;</label>
                    <select v-model="recetaDuranteTiempo" class="form-control">
                        <option :key="item.id" v-for="item in duranteOptions" :value="item">{{ item.name }}</option>
                    </select>
                </div>
            </div>
        </div>
        <div class="mt-4">
            <div class="text-right">
                <div @click="addItemReceta" class="btn btn-success btn-sm">
                    <i class="fa fa-plus"></i> Agregar a receta
                </div>
            </div>
        </div>
        <table class="table table-striped w-100 mt-3">
            <tr v-for="item in rowDataReceta" :key="item.medicamento.nombre+'_'+item.cantidad">
                <th scope="row">
                    <b>{{ item.medicamento.nombre }}</b>
                    <div class="small">{{ stripTags(item.medicamento.descripcion) }}</div>
                    <b>Dósis:</b> {{ item.cantidad }} {{ item.unidad }}, <b>cada:</b> {{ item.cada }} {{ item.cadaTiempo }}, <b>durante:</b> {{ item.durante }} {{ item.duranteTiempo }}
                </th>
                <td>
                    <b>Administración:</b>
                    {{ item.administracion }}
                </td>
                <td>
                    <textarea class="form-control" v-model="item.recomendaciones"></textarea>
                </td>
            </tr>
        </table>
        <div>
            <div class="form-group">
                <label>
                    Observaciones
                </label>
                <textarea class="form-control"></textarea>
            </div>
        </div>
    </box>-->
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';

import Box from "src/modules/medico/views/fichas/Box.vue";
import Multiselect from 'vue-multiselect';
import OrderProductSearch from "/src/modules/order/components/OrderProductSearch";

// Componentes

export default {
    components: {
        OrderProductSearch,
        Box,
        Multiselect,
        DatePicker,
    },
    props: [
        'type',
    ],
    data() {
        return {
            recetaSearchMedicamentos: [],

            recetaMedicamentoEnInventario: false,

            recomendaciones: '',
            recetaMedicamento: '',
            recetaAdministracion: '',
            recetaDosis: '',
            recetaCada: '',
            cadaOptions: [
                {
                    "name": "Diario",
                    "id": 0
                },
                {
                    "name": "Antes de cada comida",
                    "id": 1
                },
                {
                    "name": "Después de cada comida",
                    "id": 2
                },
                {
                    "name": "q1h",
                    "id": 3
                },
                {
                    "name": "q2h",
                    "id": 4
                },
                {
                    "name": "q3h",
                    "id": 5
                },
                {
                    "name": "q4h",
                    "id": 6
                },
                {
                    "name": "q6h",
                    "id": 7
                },
                {
                    "name": "q8h",
                    "id": 8
                },
                {
                    "name": "q12h",
                    "id": 9
                },
                {
                    "name": "HS",
                    "id": 10
                }
            ],


            recetaDurante: '',
            recetaDuranteTiempo: '',
            duranteOptions: [
                {
                    'name': 'minuto(s)',
                    'id': 0,
                },
                {
                    'name': 'hora(s)',
                    'id': 1,
                },
                {
                    'name': 'dia(s)',
                    'id': 2,
                },
                {
                    'name': 'semana(s)',
                    'id': 3,
                },
                {
                    'name': 'mes(es)',
                    'id': 4,
                },
                {
                    'name': 'año(s)',
                    'id': 5,
                }
            ],

            rowDataReceta: [],

            administracionOptions: [
                {
                    'name': 'Vía oral',
                },
                {
                    'name': 'Subcutánea',
                },
                {
                    'name': 'Intramuscular',
                },
                {
                    'name': 'Intravenosa',
                },
                {
                    'name': 'Intratecal',
                },
                {
                    'name': 'Vía sublingual',
                },
                {
                    'name': 'Vía rectal',
                },
                {
                    'name': 'Vía vaginal',
                },
                {
                    'name': 'Vía ocular',
                },
                {
                    'name': 'Vía ótica',
                },
                {
                    'name': 'Vía nasal',
                },
                {
                    'name': 'Vía inhalatoria',
                },

                {
                    'name': 'Vía cutánea',
                },
                {
                    'name': 'Vía transdérmica',
                },
                {
                    'name': 'Nebulizaciones',
                },
            ],

            // procedimientos
            data_clicked_id: '',

            // medicamentos
            showEditionReceta: false,
            showProductSearchBox: false,
            receta: {},

            // Procedimientos
            showEditionProcedimiento: false,
            procedimiento: {},

            // Medicamentos o productos
            showEditionProducto: false,
            producto: {},

            // otros
            observaciones: '',
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
        this.observaciones = (this.GetMedicoFichaActive.metadata.tratamiento) ? this.GetMedicoFichaActive.metadata.tratamiento.observaciones : '';
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,
        stripTags: function (text) {
            return Tools.stripTags(text);
        },
        getData(query) {
            const self = this;
            self.isLoading = true;
            if (query.length > 0) {
                self.queryData = JSON.parse(JSON.stringify(query));

                API.send('GET', '/products/search/' + query, {},
                    function (res) {
                        self.recetaSearchMedicamentos = res.data;
                        self.isLoading = false;

                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                        self.isLoading = false;
                    });
            }
        },
        addItemReceta() {
            let receta = {
                medicamento: this.recetaMedicamento,
                cantidad: this.recetaDosis,
                cada: this.recetaCada.name,
                cadaTiempo: this.recetaCadaTiempo,
                durante: this.recetaDurante,
                //duranteTiempo: this.recetaDuranteTiempo.name,

                unidad: this.recetaUnidadMedida,
                recomendaciones: '',
                administracion: this.recetaAdministracion.name,
            };
            this.rowDataReceta.push(receta);

            this.recetaMedicamento = '';
            this.recetaCantidad = '';
            this.recetaCada = '';
            this.recetaCadaTiempo = '';
            this.recetaDurante = '';
            //this.recetaDuranteTiempo = '';
            this.recetaAdministracion = '';
        },

        // Procedimientos
        part_clicked(val) {
            this.data_clicked_id = val.target.getAttribute('id')
            //console.log(val.target.getAttribute('id'))
        },

        // Recetas
        resetProcedimiento() {
            this.procedimiento = {
                id: 0,
                detail: {
                    procedimiento: '',
                    observaciones: '',
                }
            };
        },
        cancelEditProcedimiento() {
            this.showEditionProcedimiento = false;
            this.resetProcedimiento();
        },
        saveProcedimiento() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.procedimiento.id,
                    slug: 'tratamiento_procedimiento',
                    detail: self.procedimiento.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEditProcedimiento();
        },
        addProcedimiento() {
            this.resetProcedimiento();
            this.showEditionProcedimiento = true;
        },
        editProcedimiento(item) {
            this.resetProcedimiento();
            this.procedimiento = item;
            this.showEditionProcedimiento = true;
        },
        deleteProcedimiento(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },

        // Medicamentos
        // Recetas
        resetMedicamento() {
            this.producto = {
                id: 0,
                detail: {
                    productoId: '',
                    nombre: '',
                    cantidad: '',
                    observaciones: '',
                }
            };
        },
        cancelEditMedicamento() {
            this.showEditionProcedimiento = false;
            this.resetProcedimiento();
        },
        saveMedicamento() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.procedimiento.id,
                    slug: 'tratamiento_medicamentos',
                    detail: self.procedimiento.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEditMedicamento();
        },
        addMedicamento() {
            this.resetMedicamento();
            this.showEditionProducto = true;
        },
        editMedicamento(item) {
            this.resetMedicamento();
            this.producto = item;
            this.showEditionProducto = true;
        },
        deleteMedicamento(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },

        buscarMedicamento() {
            this.showProductSearchBox = true;
        },
        productSelected: function (data) {

            console.log(data);

            /*this.$store.dispatch('OrderClearProducts');
            this.$store.dispatch('OrderPosClientClear');*/

            const precio = Numbro((data.onSale) ? data.precioOnSale : data.precio).formatCurrency();

            this.producto = {
                id: data.id,
                detail: {
                    productoId: data.id,
                    nombre: data.nombre,
                    cantidad: 1,
                    observaciones: '',
                    precio: precio,
                },
            };
            this.showProductSearchBox = false;
            this.showClientSearch = true;
        },

        // Recetas
        resetReceta() {
            this.receta = {
                id: 0,
                detail: {
                    nombreYFormulacion: '',
                    fechaInicio: dayjs().format('DD-MM-YYYY HH:mm'),
                    fuente: '',
                    observaciones: '',
                }
            };
        },
        cancelEditReceta() {
            this.showEditionReceta = false;
            this.resetMedicamento();
        },
        saveReceta() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.receta.id,
                    slug: 'tratamiento_receta',
                    detail: self.receta.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEditReceta();
        },
        addReceta() {
            this.resetReceta();
            this.showEditionReceta = true;
        },
        editReceta(item) {
            this.resetReceta();
            this.receta = item;
            this.showEditionReceta = true;
        },
        deleteReceta(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
        saveOtros() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            arrToSave['observaciones'] = this.observaciones;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    slug: 'tratamiento',
                    detail: arrToSave,
                    uniqueMeta: 1,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
    },
};

</script>
