<template>
    <div>
        <ficha-layout title="Ginecología" color="#f69bab" :tabs="tabs" tab-default="preclinica" enable-timeline="true">
            <template #preclinica>
                <pre-clinica></pre-clinica>
            </template>
            <template #antecedentes>
                <antecedentes></antecedentes>
            </template>
            <template #exploracion_fisica>
                <exploracion-fisica></exploracion-fisica>
            </template>
            <template #consulta>
                <box title="Antecedentes Ginecológicos" icon="fas fa-notes-medical">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Grupo - Rh</label>
                                <input type="text" class="form-control" aria-describedby="grupo rh" placeholder="Escribe aquí" v-model="ginecologicos.grupo_rh">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Menarquía</label>
                                <input type="text" class="form-control" aria-describedby="menarquia" placeholder="Escribe aquí" v-model="ginecologicos.menarquia">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Último Papanicolau</label>
                                <input type="date" class="form-control" aria-describedby="Último Papanicolau" placeholder="Escribe aquí" v-model="ginecologicos.ultimo_papanicolau">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>TRH</label>
                                <input type="text" class="form-control" aria-describedby="TRH" placeholder="Escribe aquí" v-model="ginecologicos.trh">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Menopausia</label>
                                <input type="text" class="form-control" aria-describedby="Menopausia" placeholder="Escribe aquí" v-model="ginecologicos.menopausia">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Papanicolau</label>
                                <input type="text" class="form-control" aria-describedby="Papanicolau" placeholder="Escribe aquí" v-model="ginecologicos.papanicolau">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Otras Observaciones</label>
                                <input type="text" class="form-control" aria-describedby="Otras Observaciones" placeholder="Escribe aquí" v-model="ginecologicos.otras_observaciones">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Parejas Sexuales</label>
                                <input type="text" class="form-control" aria-describedby="Parejas Sexuales" placeholder="Escribe aquí" v-model="ginecologicos.parejas_sexuales">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Ciclo Mensual</label>
                                <input type="text" class="form-control" aria-describedby="Ciclo Mensual" placeholder="Escribe aquí" v-model="ginecologicos.ciclo_mensual">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Planificación familiar</label>
                                <select class="form-control" v-model="ginecologicos.planificacion_familiar">
                                    <option value="no">No planifica</option>
                                    <option value="si">Sí planifica</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4" v-if="ginecologicos.planificacion_familiar === 'si'">
                            <div class="form-group">
                                <label>Tipo de planificación</label>
                                <select class="form-control" v-model="ginecologicos.planificacion_familiar_tipo">
                                    <option value="orales">Orales</option>
                                    <option value="inyectados">Inyectados</option>
                                    <option value="diversas">Diversas</option>
                                    <option value="otb">OTB</option>
                                    <option value="vasectomia">Vasectomía</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Observaciones</label>
                                <input type="text" class="form-control" aria-describedby="Observaciones" placeholder="Escribe aquí" v-model="ginecologicos.observaciones">
                            </div>
                        </div>
                    </div>
                </box>
                <box title="Antecedentes Obstétricos" icon="fas fa-notes-medical">
                    <div class="row">
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Gesta</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.gesta">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Hijos vivos</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.hijos_vivos">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Hijos muertos</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.hijos_muertos">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Paras</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.paras">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Abortos</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.abortos">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Cesáreas</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.cesareas">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Fecha última regla</label>
                                <input type="date" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.fecha_ultima_regla">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Fecha probable parto</label>
                                <input type="date" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.fecha_probable_parto">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Edad gestacional</label>
                                <select class="form-control" v-model="obstetricos.edad_gestacional">
                                    <option v-for="semana in 42" :key="semana" :value="semana">{{ semana }} semana</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Planificación previa</label>
                                <select class="form-control" v-model="obstetricos.planificacion_previa">
                                    <option value="no">No planifica</option>
                                    <option value="si">Sí planifica</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Confiabilidad</label>
                                <select class="form-control" v-model="obstetricos.confiabilidad">
                                    <option value="no">No</option>
                                    <option value="si">Sí</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Menarca</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.menarca">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Ciclos</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.ciclos">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Fecha último parto</label>
                                <input type="date" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.fecha_ultimo_parto">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Peso (kg)</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.peso">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Rn con mayor peso (kg)</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.rn_con_mayor_peso">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Rn con menor peso (kg)</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.rn_con_menor_peso">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Complicaciones</label>
                                <select class="form-control" v-model="obstetricos.complicaciones">
                                    <option value="no">No</option>
                                    <option value="si">Sí</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4" v-if="obstetricos.complicaciones === 'si'">
                            <div class="form-group">
                                <label>Descripción de complicaciones</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.complicaciones_descripcion">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Control Prenatal</label>
                                <select class="form-control" v-model="obstetricos.control_prenatal">
                                    <option value="no">No</option>
                                    <option value="si">Sí</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-4" v-if="obstetricos.control_prenatal === 'si'">
                            <div class="form-group">
                                <label>Número de Consultas</label>
                                <input type="number" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.numero_de_consultas">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4" v-if="obstetricos.control_prenatal === 'si'">
                            <div class="form-group">
                                <label>Lugar de Control Prenatal</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.lugar_de_control_prenatal">
                            </div>
                        </div>
                        <div class="col-12 col-sm-4">
                            <div class="form-group">
                                <label>Observaciones</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" v-model="obstetricos.observaciones">
                            </div>
                        </div>
                    </div>
                </box>
            </template>
            <template #embarazo_y_parto>
                Embarazo y parto
            </template>
            <template #impresion_clinica>
                <impresion-clinica></impresion-clinica>
            </template>
            <template #tratamiento>
                <tratamiento></tratamiento>
            </template>
            <template #resumen>
                <resumen></resumen>
            </template>
            <!--<div class="fas fa-heart-circle-check"></div>-->
        </ficha-layout>
    </div>
</template>
<script>

import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';


// Componentes globales
import FichaLayout from './components/FichaLayout.vue';
import Box from '/src/modules/medico/views/fichas/Box.vue';

// componentes medicos
import Antecedentes from './components/Antecedentes.vue';
import ExploracionFisica from './components/ExploracionFisica.vue';
import ImpresionClinica from './components/ImpresionClinica.vue';
import Tratamiento from './components/Tratamiento.vue';
import Resumen from './components/Resumen.vue';
import PreClinica from './components/PreClinica.vue';

export default {
    components: {
        Box,
        FichaLayout,
        PreClinica,
        Antecedentes,
        ExploracionFisica,
        ImpresionClinica,
        Tratamiento,
        Resumen,

    },
    props: ['detail'],
    data() {
        return {
            tabs: {
                preclinica: {
                    icon: 'fas fa-heart-circle-check',
                    name: 'Pre clínica',// motivo de consulta, signos vitales, tratamiento en curso
                },
                antecedentes: {
                    icon: 'fas fa-history',
                    name: 'Antecedentes',
                },
                exploracion_fisica: {
                    icon: 'fas fa-search-plus',
                    name: 'Examen físico',
                },
                consulta: {
                    icon: 'fas fa-stethoscope',
                    name: 'Ginecología',
                },
                /*embarazo_y_parto: {
                    icon: 'fas fa-baby-carriage',
                    name: 'Embarazo y parto',
                },*/
                impresion_clinica: {
                    icon: 'fas fa-laptop-medical',
                    name: 'Impresión clínica', // CIE10
                },
                tratamiento: {
                    icon: 'fas fa-file-prescription',
                    name: 'Tratamiento', // cie10 mover para acá y laboratorios y procedimientos
                },
                resumen: {
                    icon: 'fas fa-check',
                    name: 'Resumen', // agregar estado de cuenta
                },
            },
            ginecologicos: {
                grupo_rh: '',
                menarquia: '',
                ultimo_papanicolau: '',
                trh: '',
                menopausia: '',
                papanicolau: '',
                otras_observaciones: '',
                parejas_sexuales: '',
                ciclo_mensual: '',
                planificacion_familiar: 'no',
                planificacion_familiar_tipo: '',
                observaciones: '',
            },
            obstetricos: {
                gesta: '',
                hijos_vivos: '',
                hijos_muertos: '',
                paras: '',
                abortos: '',
                cesareas: '',
                fecha_ultima_regla: '',
                fecha_probable_parto: '',
                edad_gestacional: '',
                planificacion_previa: 'no',
                confiabilidad: 'no',
                menarca: '',
                ciclos: '',
                fecha_ultimo_parto: '',
                peso: '',
                rn_con_mayor_peso: '',
                rn_con_menor_peso: '',
                complicaciones: 'no',
                complicaciones_descripcion: '',
                control_prenatal: 'no',
                numero_de_consultas: '1',
                lugar_de_control_prenatal: '',
                observaciones: '',
            },
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    watch: {
    },
    mounted() {
        // this.loadItems();
        // console.log(this.metadata);
    },
    methods: {
        ...mapActions({
        }),
        dayjs,
        Numbro,
        refresh() {
            this.$emit('refresh');
        }
    },
};

</script>
