<template>
    <template v-if="type === 'checkbox'">
        <div class="form-group">
            <label class="toggle">
                <input class="toggle-checkbox" type="checkbox" :value="modelValue" :checked="modelValue" @change="onChanged"/>
                <div class="toggle-switch"></div>
                <span class="toggle-label">
                {{ data.name }}
            </span>
            </label>
        </div>
    </template>
    <template v-if="type === 'number'">
        <div class="form-group">
            <label>{{ data.name }}</label>
            <input type="number" class="form-control" :value="modelValue" @change="onChanged"/>
        </div>
    </template>
    <template v-if="type === 'text'">
        <div class="form-group">
            <label>{{ data.name }}</label>
            <input type="text" class="form-control" :value="modelValue" @change="onChanged"/>
        </div>
    </template>
    <template v-if="type === 'textarea'">
        <div class="form-group">
            <label>{{ data.name }}</label>
            <textarea class="form-control" :value="modelValue" @change="onChanged"></textarea>
        </div>
    </template>
    <template v-if="type === 'date'">
        <div class="form-group">
            <label>{{ data.name }}</label>
            <input type="date" class="form-control" :value="modelValue" @change="onChanged"/>
        </div>
    </template>
    <template v-if="type === 'time'">
        <div class="form-group">
            <label>{{ data.name }}</label>
            <input type="time" class="form-control" :value="modelValue" @change="onChanged"/>
        </div>
    </template>
    <template v-if="type === 'select'">
        <div class="form-group">
            <label>{{ data.name }}</label>
            <select class="form-control" :value="modelValue" @change="onChanged">
                <option>-- Seleccione una opción --</option>
                <option v-for="item in options" :value="item" :key="'select_'+item">{{item}}</option>
            </select>
        </div>
    </template>
</template>
<script>

export default {
    components: {
    },
    props: [
        'data',
        'modelValue',
    ],
    emits: ['update:modelValue', 'change'],
    data() {
        return {
            type: '',
            options: [],
        };
    },
    computed: {
        /*inputVal: {
            get() {
                return this.value;
            },
            set(val) {
                this.$emit('input', val);
            }
        }*/
    },
    mounted() {
        this.type = this.data.type || '';
        this.options = this.data.options || [];
    },
    methods: {
        onChanged(e) {
            this.$emit('update:modelValue', e.currentTarget.value);
            this.$emit('change');
        }
    },
};

</script>
