// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js!@ditdot-dev/vue-flow-form/dist/vue-flow-form.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../../../../node_modules/css-loader/dist/cjs.js!@ditdot-dev/vue-flow-form/dist/vue-flow-form.theme-minimal.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "\n@media (prefers-color-scheme: dark) {\n:root {\r\n        --vff-bg-color: #FFFFFF; /* Fondo principal en blanco */\r\n        --vff-main-text-color: #0000FF; /* Texto principal en azul oscuro */\r\n        --vff-secondary-text-color: #333333; /* Texto secundario en gris oscuro */\r\n        --vff-tertiary-text-color: #0000FF; /* Texto terciario en azul oscuro */\r\n        --vff-main-accent-color: #3399FF; /* Acento principal en azul claro */\r\n        --vff-secondary-accent-color: #FFFF00; /* Acento secundario en amarillo */\n}\n.vff,\r\n    .vff textarea,\r\n    .vff input,\r\n    .vff select,\r\n    .vff select option,\r\n    .vff kbd,\r\n    .vff-header,\r\n    .vff-footer {\r\n        font-family: 'gordita';\n}\n.vff-footer .f-progress-bar {\r\n        display: inline-block;\r\n        height: 4px;\r\n        margin: 0 6px 0 0;\r\n        position: static;\r\n        vertical-align: middle;\r\n        width: 64px;\n}\n.vff-footer .f-progress-bar-inner {\r\n        height: 4px;\r\n        -webkit-transition: width .3s ease;\r\n        transition: width .3s ease;\n}\n.vff-footer .footer-inner-wrap {\r\n        background-color: var(--vff-bg-color);\n}\n}\n.voucher {\r\n    width: 100%;\r\n    padding: 20px;\r\n    border: 1px solid #ccc;\r\n    background-color: #fff;\r\n    margin: 0 auto; /* Agrega esta línea */\n}\n.voucher-title {\r\n    font-size: 18px;\r\n    margin-bottom: 10px;\n}\n.voucher-body {\r\n    margin-bottom: 20px;\n}\n.voucher-footer {\r\n    text-align: right;\n}\r\n\r\n\r\n", ""]);
// Exports
module.exports = exports;
