<template>
    <SettingLayout :active="24">
        <div class="row">
            <div class="col-xl-12">
                <div class="card configuracionContainer">
                    <div class="card-header">
                        <h4 class="card-title">Parámetros</h4>
                    </div>
                    <div class="card-body">
                        <form @submit.prevent="formSubmit" method="post" name="configuracion">
                            <div>
                                <h5 class="mb-3">Notificaciones</h5>
                                <div class="miniContainer mb-4">
                                    <div>
                                        <div>
                                            <div class="mb-2">
                                                <div class="text-primary font-weight-bold mb-2">
                                                    Notificar al administrador después de una compra
                                                </div>
                                                <div>
                                                    Enviaremos una notificación al administrador del comercio cuando un cliente realice una compra.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.notifyOrderByEmailAdmin" :checked="modulos.notify.notifyOrderByEmailAdmin"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                                Correo electrónico
                                                                <i class="fa fa-question-circle" v-tooltip="'Se enviará una notificación por medio de E-mail cuando un cliente realice una compra'"></i>
                                                            </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.notifyOrderBySMSAdmin" :checked="modulos.notify.notifyOrderBySMSAdmin"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            SMS
                                                            <i class="fa fa-question-circle" v-tooltip="'Se activará la notificación de compra por medio de SMS'"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="mt-4">
                                            <div class="mb-2">
                                                <div class="text-primary font-weight-bold mb-2">
                                                    Notificar a mis clientes después de una compra
                                                </div>
                                                <div>
                                                    Enviaremos una notificación a tus clientes cuando realicen una compra.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.notifyOrderByEmail" :checked="modulos.notify.notifyOrderByEmail"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                                Correo electrónico
                                                                <i class="fa fa-question-circle" v-tooltip="'Se enviará una notificación por medio de E-mail cuando un cliente realice una compra'"></i>
                                                            </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.notifyOrderBySMS" :checked="modulos.notify.notifyOrderBySMS"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            SMS
                                                            <i class="fa fa-question-circle" v-tooltip="'Se activará la notificación de compra por medio de SMS'"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="text-danger text-small mt-4 text-center">
                                                    El envío de SMS tiene costo adicional, se cobrarán adicionales a tu plan Q 0.25 por cada mensaje enviado desde tu cuenta.
                                                </div>
                                            </div>
                                            <div v-if="modulos.notify.notifyOrderBySMS">
                                                <div class="mb-2 mt-3">
                                                    <div class="text-primary font-weight-bold mb-2">
                                                        Plantillas de notificación SMS
                                                    </div>
                                                    <div>
                                                        Puedes configurar el texto que enviarás en tus SMS para la confirmación de orden.
                                                    </div>
                                                    <small class="text-muted">
                                                        Esta plantilla se utilizará al confirmar la orden, puedes utilizar los modificadores para colocar los datos que desees.
                                                        <div>
                                                            #orden, #comercio, #monto
                                                        </div>
                                                    </small>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12">
                                                        <label>Confirmación de orden</label>
                                                        <input class="form-control" type="text" maxlength="155" v-model="modulos.notify.tplOrderConfirm" placeholder="Orden realizada con éxito desde #comercio, tú número de orden es: #orden, por un monto de #monto">
                                                    </div>
                                                </div>
                                                <div class="font-weight-bold mb-3 mt-4">
                                                    Plantilla SMS para ordenes con recurrencia
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 mb-2">
                                                        <label>Recurrencia mensual</label>
                                                        <input class="form-control" type="text" maxlength="155" v-model="modulos.notify.tplOrderConfirmMen" placeholder="Orden realizada con éxito desde #comercio, tú número de orden es: #orden, por un monto de #monto. Tu pago se realizará automáticamente de forma mensual.">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 mb-2">
                                                        <label>Recurrencia bimestral</label>
                                                        <input class="form-control" type="text" maxlength="155" v-model="modulos.notify.tplOrderConfirmBim" placeholder="Orden realizada con éxito desde #comercio, tú número de orden es: #orden, por un monto de #monto. Tu pago se realizará automáticamente cada dos meses.">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 mb-2">
                                                        <label>Recurrencia trimestral</label>
                                                        <input class="form-control" type="text" maxlength="155" v-model="modulos.notify.tplOrderConfirmTri" placeholder="Orden realizada con éxito desde #comercio, tú número de orden es: #orden, por un monto de #monto. Tu pago se realizará automáticamente cada tres meses.">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 mb-2">
                                                        <label>Recurrencia semestral</label>
                                                        <input class="form-control" type="text" maxlength="155" v-model="modulos.notify.tplOrderConfirmSem" placeholder="Orden realizada con éxito desde #comercio, tú número de orden es: #orden, por un monto de #monto. Tu pago se realizará automáticamente cada seis meses.">
                                                    </div>
                                                </div>
                                                <div class="row">
                                                    <div class="col-12 col-sm-12 mb-2">
                                                        <label>Recurrencia anual</label>
                                                        <input class="form-control" type="text" maxlength="155" v-model="modulos.notify.tplOrderConfirmAnu" placeholder="Orden realizada con éxito desde #comercio, tú número de orden es: #orden, por un monto de #monto. Tu pago se realizará automáticamente cada año.">
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-3">Carrito de compras</h5>
                                <div class="miniContainer mb-4">
                                    <div class="mb-4">
                                        <div>
                                            <div class="mb-2">
                                                <div class="text-primary font-weight-bold mb-2">
                                                    Ocultar métodos de pago (Solo aplica para enlaces de pago)
                                                </div>
                                                <div>
                                                    Se ocultarán los métodos de pago que selecciones.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.storePay" :checked="modulos.cart.storePay"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar "Recoger en tienda"
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.cardPay" :checked="modulos.cart.cardPay"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar "Pago con tarjeta"
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div>
                                            <div class="mb-2">
                                                <div class="text-primary font-weight-bold mb-2">
                                                    Ocultar campos de carrito (Solo aplica para enlaces de pago)
                                                </div>
                                                <div>
                                                    Se ocultarán los tipos de pago que selecciones.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hideDirection" :checked="modulos.cart.hideDirection"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar dirección de facturación
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hideTax" :checked="modulos.cart.hideTax"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar identificador tributario (NIT, Tax)
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hideDPI" :checked="modulos.cart.hideDPI"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar DPI o Pasaporte en la orden
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hideEmail" :checked="modulos.cart.hideEmail"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar correo electrónico
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hideName" :checked="modulos.cart.hideName"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar nombre de cliente
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hidePhone" :checked="modulos.cart.hidePhone"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar teléfono
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-12 col-sm-6 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.hideObs" :checked="modulos.cart.hideObs"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Ocultar observaciones de orden
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="mt-4">
                                        <div>
                                            <div class="mb-2">
                                                <div class="text-primary font-weight-bold mb-2">
                                                    Distribución y vista de carrito
                                                </div>
                                                <div>
                                                    Puedes hacer cambios sobre la distribución de tu carrito.
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 mb-2">
                                                    <label class="toggle">
                                                        <input class="toggle-checkbox" type="checkbox" v-model="modulos.cart.blockUser" :checked="modulos.cart.blockUser"/>
                                                        <div class="toggle-switch"></div>
                                                        <span class="toggle-label">
                                                            Mantener a cliente en carrito cuando se paga mediante enlace de pago.
                                                            <i class="fa fa-question-circle" v-tooltip="'Oculta el menú principal para evitar que tu cliente salga del carrito'"></i>
                                                        </span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-3">POS Virtual</h5>
                                <div class="miniContainer mb-4">
                                    <div class="form-row">
                                        <div class="col-12 m-1">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="modulos.order.verificarOrdenAlFinalizar" :checked="modulos.order.verificarOrdenAlFinalizar"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                Doble confirmación para colocar orden en estado finalizado
                                                <i class="fa fa-question-circle" v-tooltip="'Al cambiar una orden a estado finalizada, se solicitará el ingreso del código de orden de forma manual para validar que dicha orden coincide con la operada físicamente por el personal.'"></i>
                                            </span>
                                            </label>
                                        </div>
                                        <div class="col-12 m-1">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.notifyOrderShowLogo" :checked="modulos.notify.notifyOrderShowLogo"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                Mostrar logotipo en facturación
                                                <i class="fa fa-question-circle" v-tooltip="'Al imprimirse la factura no se imprimirá con el logo configurado en la tienda'"></i>
                                            </span>
                                            </label>
                                        </div>
                                        <div class="col-12 m-1">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.printCarta" :checked="modulos.notify.printCarta"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                Imprimir tamaño carta
                                                <i class="fa fa-question-circle" v-tooltip="'Al imprimirse la factura no se imprimirá en tamaño carta'"></i>
                                            </span>
                                            </label>
                                        </div>
                                        <div class="col-12 col-sm-12 m-1">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="modulos.notify.redireccionLink" :checked="modulos.notify.redireccionLink"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                    Dashboard médico
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-3">Pagos con tarjeta</h5>
                                <div class="miniContainer mb-4">
                                    <div class="form-row">
                                        <div class="form-group col-xl-6">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="modulos.payments.enableCuotas" :checked="modulos.payments.enableCuotas"/>
                                                <div class="toggle-switch"></div>
                                                <span class="toggle-label">
                                                    Activar pago con cuotas
                                                    <i class="fa fa-question-circle" v-tooltip="'Permite el pago en tu tienda mediante cuotas (Visacuotas, Credicuotas, Etc)'"></i>
                                                </span>
                                            </label>
                                            <br>
                                            <label>Monto mínimo de compra para aplicar a cuotas</label>
                                            <input class="form-control" type="number" v-model="modulos.payments.cuotasMinTC">
                                            <small class="text-muted">
                                                Tus clientes deberán comprar arriba de este monto para poder seleccionar cuotas (Visacuotas, Credicuotas, Etc).
                                            </small>
                                        </div>
                                        <div class="form-group col-xl-6">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="AuthGetUserInfo.wlAdmin">
                                <h5 class="mb-3">Tienda Multi Vendor</h5>
                                <div class="miniContainer mb-3">
                                    <div class="form-row">
                                        <div class="form-group col-xl-6">
                                            <label>Porcentaje (%) de comisión por venta de comercios afiliados</label>
                                            <input class="form-control" type="text" v-model="modulos.multivendor.comisionSobreVenta">
                                        </div>
                                        <div class="form-group col-xl-6">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <h5 class="mb-3">Seguimiento y analítica</h5>
                                <div class="miniContainer mb-3">
                                    <div class="form-row">
                                        <div class="form-group col-xl-6">
                                            <label>Código GTAG (Identificador gtag Google Analytics)</label>
                                            <input class="form-control" type="text" v-model="modulos.analytics.gtag">
                                        </div>
                                        <div class="form-group col-xl-6">

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-4">
                                <div class="col-12">
                                    <button class="btn btn-success">Guardar</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </SettingLayout>
</template>
<script>
import SettingLayout from "../components/settings/settingLayout";

import {mapGetters} from "vuex";
import API from "src/core/Api";
import {store} from "src/store";

export default {
    components: {
        SettingLayout,
    },
    data() {
        return {
            modulos: {
                notify: {
                    notifyOrderByEmailAdmin: false,
                    notifyOrderBySMSAdmin: false,
                    notifyOrderByEmail: true,
                    notifyOrderBySMS: false,
                    notifyOrderShowLogo: true,
                    printCarta: false,
                    redireccionLink: false,
                    tplOrderConfirm: '',
                    tplOrderConfirmMen: '',
                    tplOrderConfirmBim: '',
                    tplOrderConfirmTri: '',
                    tplOrderConfirmSem: '',
                    tplOrderConfirmAnu: '',
                },
                cart: {
                    hideDirection: false,
                    hidePhone: false,
                    hideTax: false,
                    hideDPI: false,
                    hideObs: false,
                    hideName: false,
                    hideEmail: false,
                    storePay: false,
                    cardPay: false,
                    blockUser: false,
                },
                order: {
                    verificarOrdenAlFinalizar: false,
                },
                multivendor: {
                    comisionSobreVenta: 0,
                },
                payments: {
                    enableCuotas: false,
                    cuotasMinTC: 0,
                },
                analytics: {
                    gtag: '',
                },
            }
        };
    },
    computed: {
        ...mapGetters({
            AuthGetUserInfo: 'AuthGetUserInfo'
        })
    },
    mounted() {
        const self = this;
        const valueConfig = (store.getters.AuthGetUserInfo.configuration) ? store.getters.AuthGetUserInfo.configuration : {};

        //console.log(valueConfig);

        // carga de modulos
        Object.keys(self.modulos).map(function (value) {

            if (typeof valueConfig[value] !== 'undefined') {
                Object.keys(self.modulos[value]).map(function (tmp) {
                    if (typeof valueConfig[value][tmp] !== 'undefined') {
                        self.modulos[value][tmp] = valueConfig[value][tmp];
                    }
                    else {
                        self.modulos[value][tmp] = '';
                    }
                })
            }
        })
        /*this.modulos.notify = (typeof valueConfig.notify !== 'undefined') ? valueConfig.notify : {};
        this.modulos.order = (typeof valueConfig.order !== 'undefined') ? valueConfig.order : {};
        this.modulos.multivendor = (typeof valueConfig.multivendor !== 'undefined') ? valueConfig.multivendor : {};
        this.modulos.payments = (typeof valueConfig.payments !== 'undefined') ? valueConfig.payments : {};
        this.modulos.analytics = (typeof valueConfig.analytics !== 'undefined') ? valueConfig.analytics : {};
        this.modulos.cart = (typeof valueConfig.cart !== 'undefined') ? valueConfig.cart : {};*/

        //console.log(valueConfig);
    },
    methods: {
        formSubmit() {
            const self = this;

            API.send('POST', 'users/system-configuration',
                {
                    configuracion: self.modulos
                },
                function (response) {
                    if (response.status) {
                        API.showSuccessAlert(response.msg);
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }

                },
                function (response) {
                    API.showErrorNotify(response.msg);
                });
        },
    },
};
</script>
