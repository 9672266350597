<template>
    <setting-layout :active="31">
        <div class="card ordenesListado">
            <div class="card-header">
                <h4 class="card-title">Ordenes</h4>
                <div>
                    <router-link to="/order/creation" class="btn btn-primary btn-sm px-3 mr-3 mb-1">
                        <i class="fa fa-plus-circle"></i> Nueva
                    </router-link>
                    <button class="btn btn-primary  btn-sm mb-1 px-3" @click="loadItems">
                        <i class="fa fa-refresh"></i>
                    </button>
                </div>
            </div>
            <div class="card-body">
                <div class="row orderItem">
                    <div class="col-12 col-md-6">
                        <nav aria-label="navigation" class="p-2">
                            <ul class="pagination">
                                <li class="page-item" v-for="item in pagination" :key="'paginate_' + parseFloat(item)">
                                    <a v-if="!isNaN(parseFloat(item))" @click="pageSelected = item; loadItems(); " :class="pageSelected == item?'page-link cursor-pointer page-link-active':'page-link cursor-pointer'">{{ item }}</a>
                                    <div v-else class="page-link">{{ item }}</div>
                                </li>
                            </ul>
                        </nav>
                    </div>
                    <div class="col-12 col-md-6">
                        <div class="input-group pb-sm-2">
                            <input type="text" placeholder="Filtrar por: código | nombres | nit | teléfono | correo" v-model="filter" class="form-control">
                            <div class="input-group-append">
                            <span id="basic-addon2" class="input-group-text">
                                <i class="fa fa-search"></i>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="orderItem" v-for="item in orderList" :key="item.id">
                    <!--
                    <span class="buy-thumb"><i class="la la-arrow-up"></i></span>
                    -->
                    <div class="row">
                        <div class="col-12 col-sm-9">
                            <div :class="(item.montoPagado >= item.monto || item.ordenIdParent > 0) ? 'buy-thumb orderStatus' : 'sold-thumb orderStatus'"></div>
                            <div>
                                <div :class="{'text-danger': item.deleted, '': !item.deleted}">
                                    <i class="mdi mdi-check-circle mr-2"></i>
                                    <span>Orden código: {{ item.codigoOrden }} </span> <span v-if="item.deleted"> - Eliminada</span>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <div>
                                        <i class="mdi mdi-account-circle mr-2"></i>
                                        <span>
                                            {{ (item.clientName !== '') ? item.clientName : 'Cliente sin nombre' }}
                                        </span>
                                        <span class="ml-2">
                                            ({{ (item.taxCode !== '' && item.taxCode !== '0') ? item.taxCode : 'CF' }})
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                    <div>
                                        <i class="mdi mdi-email-variant mr-2"></i>
                                        <span>
                                            {{ (item.correoContacto !== '') ? item.correoContacto : 'Sin correo' }}
                                        </span>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-4">
                                    <div>
                                        <i class="mdi mdi-phone mr-2"></i>
                                        <span>
                                            {{ (item.telefonoContacto !== '') ? item.telefonoContacto : 'Sin teléfono' }}
                                        </span>
                                    </div>
                                </div>
                                <div class="col-12 col-sm-8">
                                    <div class="wallet-address">
                                        <i class="mdi mdi-map-marker mr-2"></i>
                                        {{ (notEmptyObject(item.direccionEnvio) && item.direccionEnvio !== '0') ? item.direccionEnvio : 'Confirmar dirección' }}
                                    </div>
                                </div>
                            </div>
                            <div class="mt-2 bg-light rounded p-2" v-if="Object.keys(item.reservas).length > 0">
                                <div>
                                    <i class="mdi mdi-calendar mr-2"></i>
                                    Agenda relacionada:
                                </div>
                                <div v-for="reserva in item.reservas" :key="reserva.id">
                                    <div class="row">
                                        <div class="col-12 col-sm-6">
                                            Fecha de cita: {{ dayjs(reserva.fechaReservada).format('DD-MM-YYYY') }}
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            Hora de cita: {{ reserva.horaInicio }} - {{ reserva.horaFin }}
                                        </div>
                                    </div>
                                    <div class="row" v-if="reserva.cliente">
                                        <div class="col-12 col-sm-6">
                                            Cliente: {{ reserva.cliente.nombres }} {{ reserva.cliente.apellidos }}
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            Identificación: {{ reserva.cliente.cui_passport }}
                                        </div>
                                    </div>
                                    <div v-else>
                                        Sin información de persona que asistirá a cita
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-sm-3">
                            <div class="text-right">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="orderActions">
                                            <div :class="{'text-dark-gray': item.estado === 'creada', 'text-info': item.estado === 'finalizada', 'text-danger': item.estado === 'cancelada'}">
                                                <div v-if="item.estado === 'creada'">
                                                    <div v-if="item.montoPagado">
                                                        <span v-if="item.estado === 'creada' && parseFloat(item.montoPagado) > 0" class="font-weight-bold">Pendiente de procesar</span>
                                                        <span v-if="item.estado !== 'creada'">{{Tools.stringCapitalize(item.estado)}}</span>
                                                    </div>
                                                    <span v-else class="text-danger">Pendiente de pago</span>
                                                </div>
                                                <div v-else>
                                                    <span>{{Tools.stringCapitalize(item.estado)}}</span>
                                                </div>
                                            </div>
                                            <div class="text-dark-gray">
                                                Creada el <span>{{ dayjs(item.createdAt).format('DD-MM-YYYY H:mm') }}</span>
                                            </div>
                                            <div>
                                                Total {{ Numbro((item.monto) ? item.monto : 0).formatCurrency() }}
                                            </div>
                                            <div v-if="item.ordenIdParent > 0" class="text-success">
                                                Pagado desde {{whiteLabelInfo.domain}}
                                            </div>
                                            <div v-else>
                                                <div :class="{'text-success': item.montoPagado > 0, 'text-danger': item.montoPagado === 0 }">
                                                    Pagado {{ Numbro((item.montoPagado) ? item.montoPagado : 0).formatCurrency() }}
                                                </div>
                                                <div v-if="item.pagos.length > 0" class="text-muted">
                                                    <div>Detalle de pago:</div>
                                                    <div v-for="pago in item.pagos" :key="'pagoid_'+pago.id">
                                                        <div>
                                                            {{Numbro((pago.monto) ? pago.monto : 0).formatCurrency()}}
                                                            <span v-if="pago.type === 'cash'">con efectivo</span>
                                                            <span v-if="pago.type === 'card'">con tarjeta</span>
                                                            <span v-if="pago.type === 'card' && pago.cuotas > 0"> ({{pago.cuotas}} cuotas)</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-if="item.fcUID">
                                                <span v-if="!item.acUID">
                                                    <i class="fas fa-check-circle-o text-success" style="font-size: 1em"></i> Factura emitida
                                                </span>
                                                <span v-else>
                                                    <i class="fas fa-check-circle-o text-danger" style="font-size: 1em"></i> Factura anulada
                                                </span>
                                            </div>
                                            <div class="mt-3">
                                                <a class="p-3" @click="copyLink(item.tokenUrl)" v-tooltip="'Copiar enlace de pago a portapapeles'" v-if="!item.montoPagado || parseFloat(item.montoPagado) === 0">
                                                    <i class="fa fa-link fa-2xl"></i>
                                                </a>
                                                <router-link :to="'/order/view/'+item.id" class="p-3" v-tooltip="'Ver detalles'">
                                                    <i class="fa fa-eye fa-2xl text-dark"></i>
                                                </router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {mapGetters} from "vuex";

export default {
    components: {
        settingLayout,
    },
    data() {
        return {
            Tools: Tools,
            orderList: {},
            pagination: {},
            pageSelected: 1,
            limit: 10,
            filter: '',
            searchTerm: 0
        };
    },
    mounted() {
        this.loadItems();
    },
    watch: {
        filter(val) {
            const self = this;

            if(val.length >= 1){
                self.pageSelected = 1;
                self.limit = 20;
                self.loadItems();
            }
            else{
                self.filter = '';
                self.limit = 10;
                self.loadItems();
            }
        }
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    methods: {
        Numbro,
        dayjs,
        loadItems() {
            const self = this;
            if(self.filter.length === 0){
                self.searchTerm = 0;
            }
            else{
                self.searchTerm = self.filter;
            }
            API.send('GET', 'orders/all/1/'+self.pageSelected+'/'+self.limit+'/'+self.searchTerm, {},
            function (data) {
                if (data.status) {
                    self.orderList = data.data.ordenes;
                    self.pagination = data.data.paginas;
                }
            },
            function (data) {
                API.showErrorAlert(data.msg);
            })
        },
        copyLink(copyvalue) {
            Tools.copyToClipboard(copyvalue, 'Enlace de pago copiado al portapapeles');
        },
        formatDate(dateString) {
            const date = new Date(dateString);
            // Then specify how you want your dates to be formatted
            return new Intl.DateTimeFormat('default', {dateStyle: 'long'}).format(date);
        },
        formatPrice(value) {
            let val = (value / 1).toFixed(2)
            return val.toString()
        },
        notEmptyObject(someObject) {
            if (someObject) {
                return Object.keys(someObject).length
            }
            else {
                return false;
            }
        },
    },
};
</script>
