<template>
    <setting-layout :active="23">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Solicitudes</h4>
            </div>
            <div class="card-body">
                <div class="card-subtitle">
	                Aqui verás tus solicitudes creadas
                </div>
                <div class="form">
                    <ul class="linked_account">

                        <li v-for="item in affiliations" :key="item.id">
                        </li>
                    </ul>

                    <div class="mt-5">
                        <router-link to="/account/affiliations/add" class="btn btn-primary px-4 mr-3">
                            Agregar nueva
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";

export default {
    components: {settingLayout},
    data() {
        return {
            affiliations: {},
        };
    },
    mounted() {
    },
    methods: {

    },
};
</script>
