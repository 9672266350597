<template>
    <setting-layout :active="25">
        <div class="card">
            <div class="card-header">
                <h4 class="card-title">Edición de personal</h4>
            </div>
            <div class="card-body">
                <!--<div class="card-subtitle">
                    Sabemos lo importante que es hacer crecer tu negocio, aquí puedes administrar a tu personal.
                </div>
                <div class="mt-2 mb-3">
                    <a @click="nuevo = 1; persona = {}; tab = 'info'; setHorarios();" role="button" tabindex="0"
                        class="btn btn-primary px-4 mr-3">
                        Agregar nuevo
                    </a>
                </div>-->
                <div class="form">
                    <div class="media d-block">
                        <div class="row">
                            <div class="col-9">
                                <h4 v-if="id > 0" class="mt-0 mb-1">{{ persona.nombres }} {{ persona.apellidos }}</h4>
                                <h4 v-else class="mt-0 mb-1">Crear nuevo</h4>
                            </div>
                            <div class="col-3 text-right" v-if="id > 0">
                                <div class="edit-option text-right">
                                    <a @click="deleteItem(persona.id)"><i class="fa fa-trash"></i></a>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 mb-3 card-header">
                                <ul class="nav footer-main-links row text-center">
                                    <li class="nav-item col-4 col-md active">
                                        <div @click="tab = 'info'" role="button" tabindex="0"
                                            :class="tab ==='info' ? 'text-primary' : ''">
                                            <span class="fa fa-info"></span>
                                            <span
                                                class="d-none d-sm-none d-lg-block d-md-block d-xl-block"> Info.</span>
                                        </div>
                                    </li>
                                    <li class="nav-item col-4 col-md" v-if="id > 0">
                                        <div @click="tab = 'horario';" role="button"
                                            tabindex="0" :class="tab ==='horario' ? 'text-primary' : ''">
                                            <span class="fa fa-calendar"></span>
                                            <span class="d-none d-sm-none d-lg-block d-md-block d-xl-block"> Horarios</span>
                                        </div>
                                    </li>
                                    <li class="nav-item col-4 col-md" v-if="id > 0">
                                        <div @click="tab = 'archivos'" role="button" tabindex="0"
                                            :class="tab ==='archivos' ? 'text-primary' : ''">
                                            <span class="fa fa-image"></span>
                                            <span
                                                class="d-none d-sm-none d-lg-block d-md-block d-xl-block"> Archivo</span>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                            <div class="col-12" v-if="tab === 'info'">
                                <div class="row">
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="mr-sm-2">Nombres</label>
                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="persona.nombres" name="nombres"/>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="mr-sm-2">Apellidos</label>
                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="persona.apellidos" name="apellidos"/>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="mr-sm-2">Teléfono</label>
                                        <input type="tel" class="form-control" placeholder="Escribe aquí" v-model="persona.telefono" name="telefono"/>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="mr-sm-2">Correo electrónico</label>
                                        <input type="email" class="form-control" placeholder="Escribe aquí" v-model="persona.email" name="email" autocomplete="off"/>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-12">
                                        <label class="mr-sm-2">Dirección Completa</label>
                                        <textarea class="form-control"
                                            v-model="persona.direccionCompleta"></textarea>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-12">
                                        <label class="mr-sm-2">Establecimientos o locaciones asignadas</label>
                                        <div>
                                            <multiselect v-model="persona.locacionesList" :options="locaciones" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona localidades" :multiple="true"></multiselect>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-3">
                                    <div class="col-12">
                                        <h4>Inicio de sesión</h4>
                                        <div>
                                            El inicio de sesión permite que el personal ingrese a la plataforma con su propio usuario y contraseña.
                                            <div class="text-primary font-italic">Puedes activar el uso de SSO (Single Sign-On) para integrar a tus usuarios con plataformas de terceros.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="toggle text-center">
                                            Habilitar inicio de sesión en plataforma<br>
                                            <input type="checkbox"
                                                v-model="persona.habilitarInicioDeSesion" :checked="persona.habilitarInicioDeSesion"
                                                value="1"
                                                class="toggle-checkbox">
                                            <div class="toggle-switch"></div>
                                        </label>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="toggle  text-center">
                                            Habilitar inicio de sesión mediante SSO<br>
                                            <input type="checkbox"
                                                v-model="persona.habilitarInicioDeSesionSso"  :checked="persona.habilitarInicioDeSesionSso"
                                                value="1"
                                                class="toggle-checkbox">
                                            <div class="toggle-switch"></div>
                                        </label>
                                    </div>
                                </div>
                                <div class="row" v-if="persona.habilitarInicioDeSesion || persona.habilitarInicioDeSesionSso">
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="mr-sm-2">Nombre de usuario</label>
                                        <input type="text" class="form-control" placeholder="Escribe aquí" v-model="persona.nombreUsuario" name="username" autocomplete="off" @blur="validateUsername"/>

                                        <div class="text-success text-small" v-if="usernameValid && usernameValidatedText !== ''">
                                            <i class="fa fa-check mr-2"></i> {{usernameValidatedText}}
                                        </div>
                                        <div class="text-danger  text-small" v-if="!usernameValid && usernameValidatedText !== ''">
                                            <i class="fa fa-ban mr-2"></i> {{usernameValidatedText}}
                                        </div>
                                    </div>
                                    <div class="form-group col-sm-12 col-xl-6">
                                        <label class="mr-sm-2">Contraseña</label>
                                        <input type="password" class="form-control" placeholder="Escribe aquí" v-model="persona.password" name="password" autocomplete="off"/>
                                    </div>
                                    <div class="form-group col-12">
                                        <label class="mr-sm-2">Roles asignados</label>
                                        <multiselect v-model="persona.roles" :options="roles" track-by="id" label="nombre" :close-on-select="true" :clear-on-select="false" :preserve-search="true" placeholder="Selecciona las categorías" :multiple="true"></multiselect>
                                    </div>
                                </div>
                                <div class="mb-3 mt-2">
                                    <div>
                                        <label class="toggle">
                                            <input type="checkbox"
                                                v-model="persona.notificarPorEmail"  :checked="persona.notificarPorEmail"
                                                value="1"
                                                class="toggle-checkbox">
                                            <div class="toggle-switch"></div>
                                            <span class="ml-3">Notificar datos de usuario al correo electrónico al guardar <i class="fa fa-envelope-circle-check ml-2"></i></span>
                                        </label>
                                        <div class="text-muted mt-2">
                                             La contraseña se mostrará en la notificación únicamente al colocar la contraseña inicial o realizando un cambio de la misma.
                                        </div>
                                        <hr>
                                    </div>
                                </div>
                                <div class="mb-3">
                                    <h6 class="text-muted mb-2">Historial de acceso (Últimos 10 registros)</h6>
                                    <div class="personalAccessLog">
                                        <table class="table">
                                            <thead>
                                            <tr>
                                                <th scope="col">Fecha</th>
                                                <th scope="col">IP</th>
                                                <th scope="col">Método</th>
                                                <th scope="col">Tipo</th>
                                                <th scope="col">Dispositivo</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr v-for="log in persona.accessLog" :key="log.createdAt">
                                                <td>{{dayjs(log.createdAt).format('DD-MM-YYYY H:mm')}}</td>
                                                <td>{{log.ipFrom}}</td>
                                                <td>{{log.accessMethod}}</td>
                                                <td>
                                                    <span v-if="log.deviceType === 'phone'"><i class="fa fa-mobile"></i> Móvil</span>
                                                    <span v-else-if="log.deviceType === 'computer'"><i class="fa fa-laptop"></i> PC</span>
                                                    <span v-else><i class="fa fa-terminal"></i> Servicio</span>
                                                </td>
                                                <td>
                                                    {{log.deviceOs}} ({{log.deviceOsVersion}}), Navegador: {{log.browser}} -> {{log.userAgent}}
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="tab === 'horario'">
                                <div class="row">
                                    <div class="form-group col-12">
                                        <div class="text-center my-3" v-if="Object.keys(persona.locaciones).length === 0">Para agregar horarios debe asociar establecimientos o locaciones al personal</div>
                                        <div v-for="locacionItem in persona.locaciones" :key="'locacionp'+persona.id+'_'+locacionItem.id">
                                            <h5>{{ locacionItem.locacion.nombre }}</h5>
                                            <div class="text-muted my-1 text-center">
                                                Horarios en formato de 24 horas, horas:minutos
                                            </div>
                                            <table class="table table-responsive table-borderless table-hover">
                                                <thead>
                                                <tr>
                                                    <th class="col-1">Habilitado</th>
                                                    <th class="col-1">Dia</th>
                                                    <th class="col-2">Inicio</th>
                                                    <th class="col-2">Fin</th>
                                                    <th class="col-1">Descanso</th>
                                                    <th class="col-2">Inicio</th>
                                                    <th class="col-2">Fin</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr v-for="dia in locacionItem.horarios" :key="'horario_'+locacionItem.locacion.id+'_'+dia.diaSemana">
                                                    <td class="text-center">
                                                        <label class="toggle">
                                                            <input type="checkbox"
                                                                v-model="dia.estado"
                                                                true-value="1"
                                                                false-value="0"
                                                                class="toggle-checkbox">
                                                            <div class="toggle-switch"></div>
                                                        </label>
                                                    </td>
                                                    <td>{{ dia.nombreDia }}</td>
                                                    <td>
                                                        <input type="text" class="form-control" v-model="dia.horaInicio" name="horaInicio" v-maska data-maska="##:##" placeholder="00:00"/>
                                                    </td>
                                                    <td>
                                                        <input type="text" class="form-control" v-model="dia.horaFin" name="horaFin" v-maska data-maska="##:##" placeholder="00:00"/>
                                                    </td>
                                                    <td class="text-center">
                                                        <label class="toggle">
                                                            <input type="checkbox"
                                                                v-bind:id="persona.id"
                                                                v-bind:checked="!!dia.tieneDescanso && dia.estado == 1"
                                                                v-model="dia.tieneDescanso"
                                                                v-bind:disabled="dia.estado == 0"
                                                                true-value="1"
                                                                class="toggle-checkbox">
                                                            <div class="toggle-switch"></div>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <div v-if="dia.tieneDescanso == 1">
                                                            <input type="text" class="form-control" v-model="dia.horaDescansoInicio" name="horaDescansoInicio"  v-maska data-maska="##:##" placeholder="00:00"/>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div v-if="dia.tieneDescanso == 1">
                                                            <input type="text" class="form-control" v-model="dia.horaDescansoFin" name="horaDescansoFin"  v-maska data-maska="##:##" placeholder="00:00"/>
                                                        </div>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12" v-if="tab === 'archivos'">
                                <div class="row">
                                    <div class="form-group col-xl-6 col-sm-12">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Foto
                                                <i class="fa fa-question-circle" v-tooltip="''"></i>
                                            </h4>
                                        </div>
                                        <FileUploader field-name="foto"
                                            url="personal/uploadFoto"
                                            accepted-mime="image/jpg, image/jpeg, image/png"
                                            remove-file-on-finish="1"
                                            :extra-data-form="{personalId : persona.id}"
                                            @fileSuccess="fotoSuccess">
                                        </FileUploader>
                                        <div class="text-center">
                                            <img :src="persona.imagenUrl" v-if="persona.imagenUrl !== ''"  class="img-fluid" style="max-width: 150px">
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 col-sm-12">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Firma
                                                <i class="fa fa-question-circle" v-tooltip="''"></i>
                                            </h4>
                                        </div>
                                        <FileUploader field-name="firma"
                                            url="personal/uploadFirma"
                                            accepted-mime="image/jpg, image/jpeg, image/png"
                                            remove-file-on-finish="1"
                                            :extra-data-form="{personalId : persona.id}"
                                            @fileSuccess="firmaSuccess">
                                        </FileUploader>
                                        <div class="text-center">
                                          <img :src="persona.firmaUrl" v-if="persona.firmaUrl !== ''" class="img-fluid" style="max-width: 150px">
                                        </div>
                                    </div>
                                    <div class="form-group col-xl-6 col-sm-12">
                                        <div class="card-header">
                                            <h4 class="card-title">
                                                Sello
                                                <i class="fa fa-question-circle" v-tooltip="''"></i>
                                            </h4>
                                        </div>
                                        <FileUploader field-name="sello"
                                            url="personal/uploadSello"
                                            accepted-mime="image/jpg, image/jpeg, image/png"
                                            remove-file-on-finish="1"
                                            :extra-data-form="{personalId : persona.id}"
                                            @fileSuccess="selloSuccess">
                                        </FileUploader>
                                        <div class="text-center">
                                          <img :src="persona.selloUrl" v-if="persona.selloUrl !== ''" class="img-fluid" style="max-width: 150px">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 text-center mt-2">
                                <button @click="savePersona" class="btn btn-primary mx-1 mb-2"><i
                                    class="mdi mdi-content-save"></i> Guardar
                                </button>
                                <button @click="$router.push('/account-personal')" class="btn btn-danger mx-1 mb-2"><i
                                    class="mdi mdi-cancel"></i> Cancelar
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </setting-layout>
</template>
<script>
import settingLayout from "../../components/settings/settingLayout.vue";
import API from "src/core/Api";
import FileUploader from 'src/components/files/FileUploader';
import Multiselect from 'vue-multiselect'
import {store} from "src/store";
import Tools from "src/core/Tools";
import dayjs from "dayjs";


export default {
    computed: {
        dayjs() {
            return dayjs
        }
    },
    components: {
        settingLayout,
        FileUploader,
        Multiselect,
    },
    data() {
        return {
            id: 0,
            /*item: {
                id: 0,
                nombres: "",
                apellidos: "",
                telefono: "",
                email: "",
                direccionCompleta: "",
                locaciones: [],
            },*/

            descansoActivo: false,
            tab: 'info',
            horarioDescanso: 0,
            horario: {},
            persona: {
                nombreUsuario: '',
                nombres: '',
                apellidos: '',
                telefono: '',
                email: '',
                direccionCompleta: '',
                password: '',
                locacionesList: [],
                habilitarInicioDeSesion: false,
                habilitarInicioDeSesionSso: false,
                notificarPorEmail: false,
                roles: [],
                accessLog: {},
                imagenUrl: '',
                firmaUrl: '',
                selloUrl: '',
            },
            roles: [],
            usernameSaved: '',
            usernamePrev: '',
            usernameValid: false,
            usernameValidatedText: '',
            /*paises: {},
            deptos: {},
            municipios: {},*/
            locaciones: []
        }
    },
    mounted() {

        const self = this;
        API.send('GET', 'personal/locaciones/', {},
            function (response) {
                self.locaciones = response.data;
                self.loadItems();
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });

        this.getRoles();
    },
    methods: {
        loadItems() {
            /*this.locaciones = [];
            this.editar = false;
            this.horario = false;
            this.nuevo = false;
            this.personal = {};
            this.persona = {};*/
            const self = this;

            this.id = parseInt(this.$route.params.id);

            if (this.id > 0) {
                API.send('GET', 'personal/by/id/' + this.id, {},
                    function (data) {
                        if (data.status) {
                            self.loadPersona(data.data);
                        }
                        else {
                            API.showErrorAlert(data.msg);
                        }
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    });
            }
            //this.getPaises();
        },
        getRoles() {
            const self = this;
            API.send('GET', 'personal/roles/0', {},
                function (response) {
                    if (response.status) {

                        self.roles = [];
                        Object.keys(response.data).map(function (key) {
                            self.roles.push({nombre: response.data[key].nombre, id: response.data[key].id});
                        });
                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });
        },
        deleteItem(id) {
            const self = this;

            API.showConfirm('¿Está seguro de eliminar?', 'Esta acción no se puede deshacer', function () {
                API.send('DELETE', 'personal/borrar/' + id, {
                        id: id,
                    }, function (data) {
                        API.showSuccessAlert(data.msg);
                        self.loadItems();
                    },
                    function (data) {
                        API.showErrorAlert(data.msg);
                    })
            })
        },
        loadPersona(item) {
            const self = this;
            this.persona = item;
            self.persona.locacionesList = [];
            Object.keys(item.locaciones).map(function (key) {
                self.persona.locacionesList.push({nombre: item.locaciones[key]['locacion'].nombre, id: key});
            });
            self.usernamePrev = self.persona.nombreUsuario;
            self.usernameSaved = self.persona.nombreUsuario;
        },
        savePersona() {
            const self = this;

            if (Tools.isEmptyString(this.persona.nombres)) {
                API.showErrorAlert('Debe ingresar un nombre válido');
                return false;
            }
            if (Tools.isEmptyString(this.persona.nombres)) {
                API.showErrorAlert('Debe ingresar un apellido válido');
                return false;
            }

            if (self.persona.notificarPorEmail) {
                if (!Tools.validateEmail(this.persona.email)) {
                    API.showErrorAlert('Debe configurar un correo electrónico válido para enviar la notificación');
                    return false;
                }
            }

            //console.log(self.persona);
            if (self.id > 0) {
                API.send('PUT', 'personal/edit/' + self.id, self.persona, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.loadItems();
                        self.usernameSaved = self.persona.nombreUsuario;
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            }
            else {
                const self = this;
                API.send('POST', 'personal/new/', self.persona, function (data) {
                        API.showSuccessAlert(data.msg);
                        window.location.href = '/account-personal/edit/' + data.data.id;
                        /*self.personal = data.data;
                        self.loadItems();*/
                    },
                    function (data) {
                        API.showErrorNotify(data.msg);
                    })
            }
        },
        setHorarios() {
            this.persona.horarios = this.horarios;
            //this.horario = this.horarios;
        },
        firmaSuccess(data) {
            const self = this;
            self.persona.firmaUrl = data.imgUrl;
        },
        fotoSuccess(data) {
          const self = this;
          self.persona.imagenUrl = data.imgUrl;
        },
        selloSuccess(data) {
            const self = this;
            self.persona.selloUrl = data.imgUrl;
        },
        validateUsername() {
            const self = this;

            if (self.usernamePrev === self.persona.nombreUsuario || self.persona.nombreUsuario === self.usernameSaved) return false;

            self.usernameValid = false;
            self.usernameValidatedText = '';

            API.send('POST', 'personal/validate-username/', {
                    username: self.persona.nombreUsuario,
                }, function (data) {
                self.usernameValid = true;
                self.usernameValidatedText = data.msg;
                self.usernamePrev = self.persona.nombreUsuario;
            },
            function (data) {
                self.usernameValid = false;
                self.usernameValidatedText = data.msg;
            })
        },

    },
};
</script>
