// TODO: UPGRADEAR A CAPACITOR 3 CUANDO SEAMOS MILLONARIOS
//import AnyBridgeDroid from 'src/capacitorPlugin';
import {Capacitor} from "@capacitor/core";
import { App } from '@capacitor/app';
//import API from "src/core/Api";

// Other codes...
import { registerPlugin } from '@capacitor/core';

const AndroidPlugin = registerPlugin('AndroidPlugin');

//import { Plugins, Capacitor, Device } from '@capacitor/core';



//const { TopWisezPlugin, App } = Plugins;

export default class AndroidBridge {

    appEnabled() {
        // console.log(Capacitor.getPlatform());
        return (Capacitor.getPlatform() === 'android');
    }

    /*async DeviceLoad() {
        if (this.appEnabled()) {
            console.log('******** DeviceLoad *********');
            //TopWisezPlugin.DeviceLoad();

            const value = await AndroidPlugin.echo({ value: 'Hello World!' });
            console.log(value);
        }
    }*/

    /*async VisanetPayApp(amount, audit, visacoutas) {
        if (this.appEnabled()) {
            console.log('******** VisanetPayApp *********');
            console.log('******** AUDIT:  *********' + audit);
            console.log('******** AMOUNT:  *********' + amount);
            TopWisezPlugin.VisanetPayApp({amount: amount, audit: audit, visacoutas: visacoutas});
        }
    }*/

    /*async VisanetPayAppAnular(amount, audit) {
        if (this.appEnabled()) {
            console.log('******** VisanetPayAppAnular *********');
            console.log('******** AUDIT:  *********' + audit);
            console.log('******** AMOUNT:  *********' + amount);
            TopWisezPlugin.VisanetPayAppAnular({amount: amount, audit: audit});
        }
    }*/

    /*async VisanetPayAppCierre() {
        if (this.appEnabled()) {
            console.log('******** VisanetPayAppCierre *********');
            TopWisezPlugin.VisanetPayAppCierre();
        }
    }*/

    async CardReaderStart() {
        if (this.appEnabled()) {
            console.log('******** CardReaderStart *********');
            await AndroidPlugin.CardReaderStart();
        }
    }

    async CardReaderStop() {
        if (this.appEnabled()) {
            console.log('******** CARD CardReaderStop *********');
            await AndroidPlugin.CardReaderStop();
        }
    }

    async PrintFactura(data) {
        if (this.appEnabled()) {
            console.log('******** AndroidBridge PrintFactura *********');
            const stringData = JSON.stringify(data);
            await AndroidPlugin.PrintFactura({data: stringData});
        }
    }

    /*async PrintRecibo(data) {
        if (this.appEnabled()) {
            console.log('******** PrintRecibo *********');
            TopWisezPlugin.PrintRecibo({data: data});
        }
    }*/

   /* async PrintVoucher(data) {
        if (this.appEnabled()) {
            console.log('******** PrintVoucher *********');
            //TopWisezPlugin.PrintVoucher({data: data});
        }
    }*/

    // TOP WISE
    isOnTopWise() {
        return Capacitor.isPluginAvailable('AndroidPlugin');
    }

    TopWiseCloseApp(url) {
        console.log('******** enablePressBackToOut *********');
        if (url) {
            App.removeAllListeners();
            App.addListener('backButton', () => {
                console.log('******** Back button pressed *********');
                url = url.replace(/^\/|\/$/g, '');
                let location = window.location.href;
                location = location.replace(/^\/|\/$/g, '');

                if (location === url) {
                    if (confirm("¿Desea salir de la aplicación?")) {
                        App.exitApp();
                    }
                }
                else {
                    window.history.back();
                }
            });
        }
    }
}
