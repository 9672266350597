<template>
    <div class="row p-3">
        <div class="col-12">
            <h3
                    class="rounded text-white"
                    style="background-color: var(--primary-color); padding: 6px;"
            >
                Odontograma
            </h3>
        </div>

        <div class="col-lg-8 col-md-7 col-sm-12">
            <canvas @mousedown="handleMouseEvent"
                    class="img-responsive border border-primary rounded"
                    id="canvas"
                    width="660"
                    height="700"
                    ref="odontoCanvas"
            ></canvas>
        </div>
        <div class="col-sm-12 col-md-5 col-lg-4">
            <div class="form-group">
                <b>Tipo Paciente</b>
                <select
                        v-model="data.patienttype"
                        class="form-control form-control-sm"
                >
                    <option selected disabled value="">--Seleccione Paciente--</option>
                    <option value="adult">Adulto</option>
                    <option value="childish">Infantil</option>
                </select>
                <div class="help-block" v-if="data.patienttype.$error">
                    <small class="text-danger" v-if="!data.patienttype.required"
                    >El campo es requerido</small
                    >
                </div>
            </div>
            <div class="form-group">
                <b>Posición</b>
                <select v-model="data.position" class="form-control form-control-sm">
                    <option selected disabled value="">--Seleccione Posición--</option>
                    <option value="sup">Superior</option>
                    <option value="inf">Inferior</option>
                </select>
                <div class="help-block" v-if="data.position.$error">
                    <small class="text-danger" v-if="!data.position.required"
                    >El campo es requerido</small
                    >
                </div>
            </div>
            <div class="form-group">
                <b>Pieza</b>
                <select v-model="data.tooth" class="form-control form-control-sm">
                    <option selected disabled>--Seleccione Pieza--</option>
                    <option v-for="x in teeth" :key="x.name" :value="x.name">{{
                            x.label
                        }}
                    </option>
                </select>
                <div class="help-block" v-if="data.tooth.$error">
                    <small class="text-danger" v-if="!data.tooth.required"
                    >El campo es requerido</small
                    >
                </div>
            </div>
            <div class="form-group">
                <h6><b>Cuadrante a Trabajar</b></h6>

                <div class="form-check form-check-inline">
                    <label class="toggle">
                        <input class="toggle-checkbox" v-model="data.quadrant.v"
                               type="checkbox"
                               name="checkboxCuadrante"
                               id="checkboxCuadranteV"
                        />
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                    V
                </span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="toggle">
                        <input v-model="data.quadrant.d"
                               class="toggle-checkbox"
                               type="checkbox"
                               name="checkboxCuadrante"
                               id="checkboxCuadranteD"
                        />
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                    D
                </span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="toggle">
                        <input v-model="data.quadrant.o"
                               class="toggle-checkbox"
                               type="checkbox"
                               name="checkboxCuadrante"
                               id="checkboxCuadranteO"
                        />
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                    O
                </span>
                    </label>

                </div>
                <div class="form-check form-check-inline">
                    <label class="toggle">
                        <input v-model="data.quadrant.m"
                               class="toggle-checkbox"
                               type="checkbox"
                               name="checkboxCuadrante"
                               id="checkboxCuadranteM"
                        />
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">
                    M
                </span>
                    </label>
                </div>
                <div class="form-check form-check-inline">
                    <label class="toggle">
                        <input v-model="data.quadrant.p"
                               class="toggle-checkbox"
                               type="checkbox"
                               name="checkboxCuadrante"
                               id="checkboxCuadranteP"
                        />
                        <div class="toggle-switch"></div>
                        <span class="toggle-label">P</span>
                    </label>
                </div>
                <div class="form-group">
                    <b>Diagnóstico</b>
                    <select
                            v-model="data.diagnostic"
                            class="form-control form-control-sm"
                    >
                        <option selected disabled>--Seleccione Diagnóstico--</option>
                        <option v-for="x in diagnostic" :key="x.name" :value="x.value">
                            {{ x.label }}
                        </option>
                    </select>
                </div>
                <div class="form-group">
                    <div class="form-check form-check-inline">
                        <label class="toggle">
                            <input v-model="data.treat"
                                   class="toggle-checkbox"
                                   type="checkbox"
                                   name="checkboxTratar"
                                   id="checkboxTratar"
                            />
                            <div class="toggle-switch"></div>
                            <span class="toggle-label">Marcar para tratar</span>
                        </label>
                    </div>
                </div>
                <div class="form-group">
                    <b>Detalle</b>
                    <textarea
                            v-model="data.detail"
                            class="form-control form-control-sm border-patient-controls"
                    />
                </div>
                <div class="form-group">
                    <button class="btn btn-outline-primary btn-sm" @click="add">Agregar</button>
                </div>
            </div>
        </div>
        <div class="col-lg-12 col-md-12 col-sm-12">
            <div v-if="odonto.datalist.length > 0">
                <table class="table">
                    <tr>
                        <th>
                            Pieza
                        </th>
                        <th>
                            Cuadrantes
                        </th>
                        <th>
                            Diagnóstico
                        </th>
                    </tr>
                    <tr v-for="(x, index) in odonto.datalist" :key="index">
                        <td>
                            {{ x.tooth }}
                        </td>
                        <td>
                            <pre>{{ x.quadrant }}</pre>
                        </td>
                        <td>
                            {{ x.diagnostic }}
                        </td>
                    </tr>
                </table>
            </div>
            <div v-else>
                <span>No existen registros en el histórico.</span>
            </div>
        </div>
    </div>
</template>
<script>
import teethdata from "../assets/data/teethdata.json";
import diagnostic from "../assets/data/diagnostic.json";

const defaultColor = "color-primary";

export default {
    name: "odontogram",
    data: () => ({
        data: {
            position: "",
            patienttype: "",
            tooth: "",
            diagnostic: "",
            detail: "",
            treat: false,
            quadrant: {
                v: false,
                d: false,
                o: false,
                m: false,
                p: false
            }
        },
        ctx: {},
        diagnostic: diagnostic,
        odon: teethdata,
        odonto: {
            datalist: []
        }
    }),
    methods: {
        clean: function() {
            this.data = {
                position: "",
                patienttype: "",
                tooth: "",
                diagnostic: "",
                detail: "",
                treat: false,
                quadrant: {
                    v: false,
                    d: false,
                    o: false,
                    m: false,
                    p: false
                }
            };
            this.ctx.fillStyle = defaultColor;
        },
        init: function() {
            const self = this;
            const canvas = document.getElementById("canvas");
            self.ctx = canvas.getContext("2d");
            // clean context.
            self.ctx.setTransform(1, 0, 0, 1, 0, 0);
            self.ctx.clearRect(0, 0, canvas.width, canvas.height);
            self.ctx.restore();

            self.ctx.textAlign = "center";
            self.ctx.fillStyle = defaultColor;
            self.ctx.font = "13px Arial";
            //Horizontal Line.
            self.drawLine(0, 340, 660, 340, "#C0C0C0");
            //Vertical Line.
            self.drawLine(330, 0, 330, 700, "#C0C0C0");

            for (let l of self.odon.settings.lines) {
                let x = l.initial;
                for (let i = 0; i < l.total; i++) {
                    self.drawLine(x, l.x2, x, l.y2, l.color);
                    x += l.length;
                }
            }

            for (let info of self.odon.values) {
                self.addImage(info);
            }

            canvas.addEventListener(
                "mousedown",
                function(event) {
                    const bounds = canvas.getBoundingClientRect();
                    self.captureEvent(event, bounds);
                },
                false
            );
        },
        captureEvent(event, bounds) {
            const mouseX = event.clientX - bounds.left;
            const mouseY = event.clientY - bounds.top;

            // Definir porcentaje de tolerancia
            const tolerancePercentage = 0.1; // 80% de tolerancia

            // Calcular tolerancia en píxeles
            const toleranceX = bounds.width * tolerancePercentage;
            const toleranceY = bounds.height * tolerancePercentage;

            // Buscar en todo el JSON this.odon
            const selectedTeeth = [];
            for (const tooth of this.odon.values) {
                const withinRange =
                    tooth.imageposition.x >= mouseX - toleranceX &&
                    tooth.imageposition.x <= mouseX + toleranceX &&
                    tooth.imageposition.y >= mouseY - toleranceY &&
                    tooth.imageposition.y <= mouseY + toleranceY;

                if (withinRange) {
                    selectedTeeth.push(tooth);
                }
            }

            // Realizar acciones adicionales con los dientes seleccionados
            if (selectedTeeth.length > 0) {
                console.log("Dientes seleccionados:", selectedTeeth);
            }
            else {
                console.log("Ningún diente seleccionado");
            }
        },

        drawLine: function(x, y, x2, y2, color) {
            this.ctx.beginPath();
            this.ctx.moveTo(x, y);
            this.ctx.strokeStyle = color;
            this.ctx.lineTo(x2, y2);
            this.ctx.stroke();
        },
        addImage: function(info) {
            const self = this;
            let image = new Image();

            // Combinar la ruta base con la ruta relativa de la imagen
            let imagePath = `https://app.isi.clinic/staticAssets/odontograma/dentadura-${info.prefix}-${info.name}.png`;

            image.onload = function() {
                self.ctx.fillText(info.value, info.textposition.x, info.textposition.y);
                self.ctx.drawImage(image, info.imageposition.x, info.imageposition.y);

                for (let c of info.cuadrantes) {
                    self.drawRect(c.x, c.y, 0.4);
                }
            };

            // Cargar la imagen
            image.src = imagePath;
            console.log(imagePath);
        },

        drawRect: function(x, y, ga) {
            let size = this.odon.settings.recSize;
            this.ctx.beginPath();
            this.ctx.globalAlpha = ga;

            this.ctx.fillRect(x, y, size, size);

            this.ctx.globalAlpha = 1;
            this.ctx.restore();
        },
        add: function() {
            const self = this;
            const d = self.data;

            if (self.fill(d)) {
                self.odonto.datalist.push(self.data);
                this.clean();
            }
        },
        fill: function(d) {
            const self = this;
            const piece = self.odon.values.filter(
                item =>
                    item.name === d.tooth &&
                    item.prefix === d.position &&
                    item.type === d.patienttype
            );

            if (piece.length >= 1) {
                let arr = [];

                if (d.quadrant.o) arr.push("o");

                if (d.quadrant.v) arr.push("v");

                if (d.quadrant.d) arr.push("d");

                if (d.quadrant.m) arr.push("m");

                if (d.quadrant.p) arr.push("p");

                const diag = self.diagnostic.filter(x => x.value == d.diagnostic);
                self.ctx.fillStyle = defaultColor;
                if (diag.length >= 1) {
                    self.ctx.fillStyle = diag[0].color;
                }
                for (let p of arr) {
                    let q = piece[0].cuadrantes.filter(i => i.name == p)[0];
                    if (q) {
                        self.drawRect(q.x, q.y, 1);
                    }
                }
                return true;
            }
            return false;
        }
    },
    computed: {
        teeth: function() {
            let result = [];
            if (this.data.position === "" && this.data.patienttype === "") {
                result = this.odon.values.map(item => {
                    return {
                        name: item.name,
                        label: item.value
                    };
                });
                return result;
            }

            if (this.data.position !== "" && this.data.patienttype !== "") {
                result = this.odon.values
                    .filter(
                        x =>
                            x.prefix === this.data.position && x.type === this.data.patienttype
                    )
                    .map(item => {
                        return {
                            name: item.name,
                            label: item.value
                        };
                    });
                return result;
            }

            if (this.data.position !== "") {
                result = this.odon.values
                    .filter(x => x.prefix === this.data.position)
                    .map(item => {
                        return {
                            name: item.name,
                            label: item.value
                        };
                    });
                return result;
            }

            if (this.data.patienttype !== "") {
                result = this.odon.values
                    .filter(x => x.type === this.data.patienttype)
                    .map(item => {
                        return {
                            name: item.name,
                            label: item.value
                        };
                    });
                return result;
            }
            return result;
        }
    },
    mounted() {
        this.init();
    }
};
</script>
