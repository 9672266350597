<template>

</template>

<script>

//import API from "src/core/Api";
import {mapActions, mapGetters} from "vuex";
import {store} from "../../../store";
import API from "src/core/Api";

export default {
    components: {},
    props: ['usuarioId'],
    data() {
        return {
            previewStart: false,
            preview: {},
            previewErrors: {},
            dateStart: new Date().toISOString().slice(0,10),
            dateEnd: new Date().toISOString().slice(0,10),
        };
    },
    watch: {

    },
    mounted() {
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo'
        })
    },
    methods: {

    },
};
</script>
