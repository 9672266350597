<template>
    <layout :active="28" :title="true" :search="true">
        <div class="row">
            <div class="col-xl-9">
                <div class="row">
                    <div class="col-xl-12 col-lg-12 col-xxl-12">
                        <div class="card profile_chart transparent">
                            <div class="card-header p-0">
                                <div class="duration-option btn-group-sm">
                                    <a id="today" class="c-pointer mb-2" @click="active = 'today'; nombreTab = 'Hoy'" :class="active === 'today' && 'active'">Hoy</a>
                                    <a id="ytd" class="c-pointer mb-2" @click="active = 'ytd'; nombreTab = 'Ayer'" :class="active === 'ytd' && 'active'">Ayer</a>
                                    <a id="one_month_actual" class="c-pointer mb-2" @click="active = '1ma'; nombreTab = startUp.mesActual" :class="active === '1ma' && 'active'">{{ startUp.mesActual }}</a>
                                    <a id="one_month" class="c-pointer mb-2" @click="active = '1mp';  nombreTab = startUp.mesAnterior" :class="active === '1mp' && 'active'">{{ startUp.mesAnterior }}</a>
                                    <a id="six_months" class="c-pointer mb-2" @click="active = '6m'; nombreTab = '6 Meses'" :class="active === '6m' && 'active'">6 Meses</a>
                                    <a id="one_year" class="c-pointer mb-2" @click="active = '1y'; nombreTab = '1 año'" :class="active === '1y' && 'active'">1 año</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">

                    <div class="col-xl-4 col-lg-6 col-md-6 p-1">
                        <div class="widget-card">
                            <div class="widget-title">
                                <p class="text-secondary">
                                    <span><i class="las la-calendar-times la-2x"></i></span>
                                </p>
                                <h5>Citas generadas, {{ nombreTab }} <div class="linea-dashboard"></div> </h5>
                            </div>
                            <div class="widget-info">
                                <h3 class="text-secondary">{{ reportes.ordenes.pos + reportes.ordenes.tienda }}</h3>

                                <div class="float-right text-right">
                                    <ul>
                                        <li class="text-secondary">Clínica:
                                            <span class="text-dark-gray">{{ reportes.ordenes.pos }}</span></li>
                                        <li class="text-secondary">Página web:
                                            <span class="text-dark-gray">{{ reportes.ordenes.tienda }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-6 p-1">
                        <div class="widget-card">
                            <div class="widget-title">
                                <p class="text-primary">
                                    <span><i class="las la-cash-register la-2x"></i></span>
                                </p>
                                <h5>Total cobrado, {{ nombreTab }} <div class="linea-dashboard"></div> </h5>
                                <p class="text-success" v-if="reportes.estv.pos + reportes.estv.tienda > 0">
                                    {{ Numbro((reportes.ventas.pos + reportes.ventas.tienda + reportes.ventas.mv) * 100 / (reportes.estv.pos + reportes.estv.tienda + reportes.ventas.mv)).formatCurrency() }}%
                                    <span><i class="las la-arrow-up"></i></span>
                                </p>
                            </div>
                            <div class="widget-info">
                                <h5>
                                    <span class="text-muted">Total </span> {{ Numbro(reportes.ventas.pos + reportes.ventas.tienda + reportes.ventas.mv).formatCurrency() }}
                                </h5>

                                <div class="float-right text-right">
                                    <ul>
                                        <li class="text-info">Clínica:
                                            <span class="text-dark-gray">{{ Numbro((reportes.ventas.tienda) ? reportes.ventas.pos : 0).formatCurrency() }}</span>
                                        </li>
                                        <li class="text-info">Página web:
                                            <span class="text-dark-gray">{{ Numbro((reportes.ventas.tienda) ? reportes.ventas.tienda : 0).formatCurrency() }}</span>
                                        </li>
                                        <li class="text-info" v-if="enableMultiVendor">{{whiteLabelInfo.nombre}}:
                                            <span class="text-dark-gray">{{ Numbro((reportes.ventas.mv) ? reportes.ventas.mv : 0).formatCurrency() }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-xl-4 col-lg-6 col-md-6 p-1 ">
                        <div class="widget-card">
                            <div class="widget-title">
                                <p>
                                    <span><i style="color: var(--secondary-color);" class="las la-procedures la-2x"></i></span>
                                </p>
                                <h5>Citas en progreso, {{ nombreTab }} <div class="linea-dashboard"></div> </h5>
                                <p class="text-warning text-right" v-if="reportes.estv.pos + reportes.estv.tienda > 0">
                                    <small class="text-muted">Sin pago </small><br/>
                                    {{ Numbro(100 - ((reportes.ventas.pos + reportes.ventas.tienda) * 100 / (reportes.estv.pos + reportes.estv.tienda))).formatCurrency() }}%
                                    <span><i class="fas fa-warning"></i></span>
                                </p>
                            </div>
                            <div class="widget-info">
                                <h5>
                                    <span class="text-muted">Total </span> {{ Numbro(reportes.estv.pos + reportes.estv.tienda).formatCurrency() }}
                                </h5>
                                <h5 class="text-warning"></h5>

                                <div class="float-right text-right">
                                    <ul>
                                        <li class="text-success">Pagadas:
                                            <span class="text-dark-gray"> {{ Numbro(reportes.ventas.pos + reportes.ventas.tienda).formatCurrency() }}</span>
                                        </li>
                                        <li class="text-warning">Sin pago:
                                            <span class="text-dark-gray"> {{ Numbro((reportes.estv.pos + reportes.estv.tienda) - (reportes.ventas.pos + reportes.ventas.tienda)).formatCurrency() }}</span>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div class="row card my-1 pacientes-atendidos">
                    <div class="col-12 card-header">
                        <h4 class="font-weight-bold">Pacientes atendidos</h4>
                        <p class="h6 text-muted mb-0 mb-lg-0">{{nombreTab}}</p>
                    </div>
                    <div v-if="loadingTreated" class=" treatedLoader d-flex justify-content-center align-content-center p-4">
                        <div class="spinner-border text-primary" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                    <div v-if="treated.length && loadingTreated==false" class="col card-body">
                        <table class="table">
                            <thead>
                            <tr class=" font-weight-bolder ">
                                <th  class=" font-weight-bolder " scope="col">Nombre</th>
                                <th  class=" font-weight-bolder " scope="col">Fecha</th>
                                <th  class=" font-weight-bolder " scope="col">Género</th>
                                <th  class=" font-weight-bolder " scope="col">Edad</th>
                                <th  class=" font-weight-bolder " scope="col">Opciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="client in treated">
                                <td>{{ client.title.split('(')[0] }}</td>
                                <td>{{ client.time.start.slice(0, 10) }}</td>
                                <td>{{ client.genero }}</td>
                                <td>{{ client.edad }}</td>
                                <td class="d-flex gap-5 align-self-center text-center">
                                    <router-link title="Ver ficha" class="mx-1" :to="'/medic/ficha/'+client.id">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-file-earmark-fill" viewBox="0 0 16 16">
                                            <path d="M4 0h5.293A1 1 0 0 1 10 .293L13.707 4a1 1 0 0 1 .293.707V14a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm5.5 1.5v2a1 1 0 0 0 1 1h2l-3-3z"/>
                                        </svg>
                                    </router-link>
                                    <router-link title="Ver orden" class="mx-1" :to="'/medic/ficha/'+client.id">
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-eye" viewBox="0 0 16 16">
                                            <path d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"/>
                                            <path d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"/>
                                        </svg>
                                    </router-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                    <div v-else-if="loadingTreated==false" class="col card-body">
                        <p>No hay pacientes atendidos para {{ nombreTab }}</p>
                    </div>
                </div>
            </div>

            <div class="col-xl-3 card">
                <div class="row">
                    <div class="col-12">
                        <date-picker style="background-color: transparent"  v-model="dateCalendar" mode="date" is-expanded  :attributes="agendamientoCalendarAttr"  />
                        <div class="row">
                            <div class="col-12">
                                <h2 class="card-title pt-3 pb-2"> Eventos del día</h2>
                            </div>
                            <div class="col-12 scroll-area card-body">
                                <div id="content">
                                    <div v-if="loadingEventDay" class="d-flex justify-content-center">
                                        <div class="spinner-border text-primary" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                    <ul v-if="(toattend.length && dateCalendar != null && loadingEventDay===false)" class="timeline-1">
                                        <li v-for="client in toattend" class="event"  :data-date="client.time.start.split(' ')[1]">
                                            <h6 class="mb-3 pt-4 text-primary">{{ client.title.split('(')[0] }}</h6>
                                            <p>{{  client.title.split(' - ')[1] }}</p>
                                            <router-link :to="'/medic/ficha/'+client.id">
                                                <i title="Ver ficha" class="mdi mdi-file mdi-18px"></i>
                                            </router-link>

                                        </li>

                                    </ul>
                                    <div v-else-if="loadingEventDay===false">No hay eventos para el dia {{ ((dateCalendar=== '') || (dateCalendar ===  null)) ? new Date().toISOString().slice(0, 10) : dateCalendar.toISOString().slice(0, 10) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </layout>
</template>


<script>
//import TimelineChart from "../components/charts/TimelineChart.vue";
import Layout from "/src/layout/LayoutLoader.vue";
import API from "/src/core/Api";
import Numbro from "numbro";
import Tools from "src/core/Tools";
import AndroidBridge from "src/core/AndroidBridge";
import {mapGetters} from "vuex";
import {DatePicker} from 'v-calendar';
//import Toster from "../components/Toster.vue";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        Layout,
        DatePicker
        //Toster,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
        }),
    },
    data() {
        return {
            loadingEventDay:false,
            loadingTreated:false,
            enableMultiVendor: false,
            active: "today",
            nombreTab: 'Hoy',
            startUp: {},
            dateCalendar: '',
            agendamientoCalendarAttr: [
                {
                    dot: true,
                    key: 'today',
                    highlight: true,
                    dates: new Date(),

                }
            ],
            reportes: {
                "ventas": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                },
                "ordenes": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                },
                "estv": {
                    "pos": 0,
                    "tienda": 0
                },
            },
            treated:[],
            toattend:[],
            dateStart:new Date().toISOString().slice(0, 10),
            dateEnd:new Date().toISOString().slice(0, 10)
        };
    },
    methods: {
        Numbro,
        loadTreated(){
            const self = this;
            API.send('POST', 'orders/reservas/list', {
                dateStart: self.dateStart,
                dateEnd: self.dateEnd,
                personal:[]//Agregar selector de medicos 
            },
            function (response) {
                    if (response.status) {
                        const now = new Date();
                        self.dateStart = response.data.dateStart;
                        self.dateEnd = response.data.dateEnd;
                        self.treated=[];
                        const eventosListTmp = [];


                        if (typeof response.data.detalle !== 'undefined') {
                            Object.keys(response.data.detalle).map(function (a) {

                                const evento = response.data.detalle[a];
                                const motivo = (typeof evento.metadata !== 'undefined' && typeof evento.metadata.preclinica_motivo !== 'undefined' && typeof evento.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined') ? evento.metadata.preclinica_motivo.motivo_principal_consulta : 'Sin motivo de asistencia especificado';
                                const cancelada = (parseInt(evento.estado) === 1);

                                eventosListTmp.push({
                                    id: evento.id,
                                    title: evento.nombre,
                                    edad: evento.edad,
                                    genero: evento.genero,
                                    with: evento.personalNombre,
                                    time: { start: evento.fechaInicial, end: evento.fechaFinal },
                                    color: cancelada ? "green" : "red",
                                    isEditable: cancelada,
                                    id: evento.id,
                                    description: '<b>Motivo de cita:&nbsp;</b>' + motivo + (evento.entrecita ? '<br/> <b>Entre cita:&nbsp;</b> ' + evento.entrecitaMotivo : ''),
                                    disableDnD: [
                                        'month', 'week', 'day'
                                    ],
                                    disableResize: [
                                        'month', 'week', 'day'
                                    ],
                                })
                            })
                        }
                        eventosListTmp.forEach(event => {
                            const endTime = new Date(event.time.end);
                            if((now >= endTime)&& event.color==='green'){
                                self.treated.unshift(event);
                            }
                       })
                       self.loadingTreated=false;

                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        loadToAttend(){
            const self = this;
            let calendarSelection = new Date();
            if ((self.dateCalendar == '1970-01-01') || (self.dateCalendar == null) || (self.dateCalendar == 'undefined') || (self.dateCalendar == '')) {
                const now = new Date();
                calendarSelection = now.toISOString().slice(0, 10);
            }else{
                calendarSelection = new Date(self.dateCalendar).toISOString().slice(0, 10)
            }
            console.log(calendarSelection);
            API.send('POST', 'orders/reservas/list', {
                dateStart: calendarSelection,
                dateEnd: calendarSelection,
                personal:[]//Agregar selector de medicos 
            },
            function (response) {
                    if (response.status) {
                        self.toattend=[];
                        const eventosListTmp = [];

                        if (typeof response.data.detalle !== 'undefined') {
                            Object.keys(response.data.detalle).map(function (a) {

                                const evento = response.data.detalle[a];
                                const motivo = (typeof evento.metadata !== 'undefined' && typeof evento.metadata.preclinica_motivo !== 'undefined' && typeof evento.metadata.preclinica_motivo.motivo_principal_consulta !== 'undefined') ? evento.metadata.preclinica_motivo.motivo_principal_consulta : 'Sin motivo de asistencia especificado';
                                const cancelada = (parseInt(evento.estado) === 1);

                                eventosListTmp.push({
                                    id: evento.id,
                                    title: evento.nombre,
                                    edad: evento.edad,
                                    genero: evento.genero,
                                    with: evento.personalNombre,
                                    time: { start: evento.fechaInicial, end: evento.fechaFinal },
                                    color: cancelada ? "green" : "red",
                                    isEditable: cancelada,
                                    id: evento.id,
                                    description: '<b>Motivo de cita:&nbsp;</b>' + motivo + (evento.entrecita ? '<br/> <b>Entre cita:&nbsp;</b> ' + evento.entrecitaMotivo : ''),
                                    disableDnD: [
                                        'month', 'week', 'day'
                                    ],
                                    disableResize: [
                                        'month', 'week', 'day'
                                    ],
                                })
                            })
                        }
                    
                        eventosListTmp.forEach(event => {
                            if(event.color==='green'){
                                self.toattend.unshift(event);
                            }
                       })
                       self.loadingEventDay=false;

                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        }
    },
    watch: {

        active: function (val) {
            const self = this;
            self.loadingTreated=true;
            if (val==='today') {
                self.dateStart = new Date().toISOString().slice(0, 10);
                self.dateEnd = new Date().toISOString().slice(0, 10);
               
            }else if(val==='ytd'){
                const now = new Date();
                const yesterday = new Date();
                yesterday.setDate(now.getDate() - 1);

                self.dateStart= yesterday.toISOString().slice(0, 10);
                self.dateEnd= yesterday.toISOString().slice(0, 10);
              
            }else if (val==='1ma'){
                const now = new Date();
                // primer día del mes
                self.dateStart = new Date(now.getFullYear(), now.getMonth(), 1).toISOString().slice(0, 10);

                // último día del mes
                self.dateEnd = new Date(now.getFullYear(), now.getMonth() + 1, 0).toISOString().slice(0, 10);

            }else if (val==='1mp'){
                const now = new Date();      
                const primerDiaDelMesAnterior = new Date(now.getFullYear(), now.getMonth() - 1, 1);
                const ultimoDiaDelMesAnterior = new Date(now.getFullYear(), now.getMonth(), 0);
                self.dateStart = primerDiaDelMesAnterior.toISOString().slice(0, 10);
                self.dateEnd = ultimoDiaDelMesAnterior.toISOString().slice(0, 10);
            
            }else if (val==='6m'){
                const now = new Date();
                const semester = new Date()
                semester.setMonth(semester.getMonth() - 6);

                self.dateStart = semester.toISOString().slice(0, 10);
                self.dateEnd = now.toISOString().slice(0, 10);

            }else if (val==='1y'){
                const now = new Date();
                const year = new Date();
                year.setFullYear(year.getFullYear() - 1);
                self.dateStart = year.toISOString().slice(0, 10);
                self.dateEnd = now.toISOString().slice(0, 10);
                
            }


            if (val) {
                API.send('GET', 'reports/general/' + self.active, {},
                    function (response) {
                        self.reportes = Tools.parseObjectToFloat(response.data);
                       
                    },
                    function (response) {
                        //self.invalidLink = true;
                        API.showErrorNotify(response.msg);
                    });

                self.loadTreated();
            }


        },

        dateCalendar: function (val) {
            this.loadingEventDay=true;
            this.loadToAttend();
        }
        
    },
    mounted() {
        this.loadToAttend();
        this.loadTreated();

        const self = this;

        if (typeof this.whiteLabelInfo.multiVendor !== 'undefined' && this.whiteLabelInfo.multiVendor !== '') {
            this.enableMultiVendor = true;
        }

        //API.showErrorAlert('Prueba de notificación', 'Esta es un error');

        API.send('GET', 'reports/configuracion/', {},
            function (response) {
                self.startUp = response.data;
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })

        API.send('GET', 'reports/general/' + self.active, {},
            function (response) {
                self.reportes = Tools.parseObjectToFloat(response.data);
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })

        this.androidBridge = new AndroidBridge();

        // Salida en Clínica TOP WISE
        if (this.androidBridge.isOnTopWise()) {
            this.androidBridge.TopWiseCloseApp('http://localhost/dashboard');
        }
    },

};
</script>

<style>

.treatedLoader{
    min-height: 100px;
}

.pacientes-atendidos{
    padding-right: 10px;
}

.timeline-1 {
    border-left: 3px solid var(--primary-color);
    border-bottom-right-radius: 4px;
    border-top-right-radius: 4px;

    margin: 0 auto;
    position: relative;
    padding: 50px;
    list-style: none;
    text-align: left;
    max-width: 100%;

}

@media (max-width: 767px) {
    .timeline-1 {
        max-width: 100%;
        padding: 25px;
    }
}

.timeline-1 .event {
    border-bottom: 2px dashed var(--primary-color);
    padding-bottom: 15px;
    margin-bottom: 25px;
    padding-left: 15px;
    position: relative;
    background: rgba(var(--primary-color),var(--primary-color),var(--primary-color),0.5%);
    border-radius: 15px;
}
.linea-dashboard {
    padding-top: 10%;
    border-bottom: 2px solid var(--primary-color);
    width: 100%;
}

@media (max-width: 767px) {
    .timeline-1 .event {
        padding-top: 30px;
    }
}

.timeline-1 .event:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
}

.timeline-1 .event:before,
.timeline-1 .event:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline-1 .event:before {
    content: attr(data-date);
    text-align: right;
    font-weight: 100;
    font-size: 0.9em;
}

@media (max-width: 767px) {
    .timeline-1 .event:before {
        left: 0px;
        text-align: left;
    }
}

.timeline-1 .event:after {
    -webkit-box-shadow: 0 0 0 3px var(--primary-color);
    box-shadow: 0 0 0 3px var(--primary-color);
    left: -55.8px;
    background: #fff;
    border-radius: 50%;
    height: 9px;
    width: 9px;
    content: "";
    top: 0px;
}

@media (max-width: 767px) {
    .timeline-1 .event:after {
        left: -31.8px;

    }
}
</style>