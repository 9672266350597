<template>
    <layout :active="1" :title="true">
        <div class="content-body" >
            <div class="container" v-if="AuthGetUserInfo.layout === 'admin'">
                <div class="row" >
                    <div class="col-xl-12">
                        <div class="row">
                            <div class="col-xl-12 col-lg-12 col-xxl-12">
                                <div class="card profile_chart transparent">
                                    <div class="card-header p-0">
                                        <div class="duration-option btn-group-sm">
                                            <a id="today" class="c-pointer mb-2" @click="active = 'today'; nombreTab = 'Hoy'" :class="active === 'today' && 'active'">Hoy</a>
                                            <a id="ytd" class="c-pointer mb-2" @click="active = 'ytd'; nombreTab = 'Ayer'" :class="active === 'ytd' && 'active'">Ayer</a>
                                            <a id="one_month_actual" class="c-pointer mb-2" @click="active = '1ma'; nombreTab = startUp.mesActual" :class="active === '1ma' && 'active'">{{ startUp.mesActual }}</a>
                                            <a id="one_month" class="c-pointer mb-2" @click="active = '1mp';  nombreTab = startUp.mesAnterior" :class="active === '1mp' && 'active'">{{ startUp.mesAnterior }}</a>
                                            <a id="six_months" class="c-pointer mb-2" @click="active = '6m'; nombreTab = '6 Meses'" :class="active === '6m' && 'active'">6 Meses</a>
                                            <a id="one_year" class="c-pointer mb-2" @click="active = '1y'; nombreTab = '1 año'" :class="active === '1y' && 'active'">1 año</a>
                                        </div>
                                        <div class="chart_current_data pt-2 text-right">
                                            <span>Ordenes generadas</span>
                                            <h2 class="mb-1">{{ Numbro(reportes.estv.pos + reportes.estv.tienda + reportes.ventas.mv).formatCurrency() }}</h2>
                                            <span>Pagado</span><br/>
                                            <p class="text-success">
                                                {{ Numbro(reportes.ventas.pos + reportes.ventas.tienda + reportes.ventas.mv).formatCurrency() }}
                                                <span v-if="reportes.estv.pos + reportes.estv.tienda + reportes.ventas.mv > 0">({{ Numbro((reportes.ventas.pos + reportes.ventas.tienda + reportes.ventas.mv) * 100 / (reportes.estv.pos + reportes.estv.tienda + reportes.ventas.mv)).formatCurrency() }}%)</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="widget-card">
                                    <div class="widget-title">
                                        <h5>Ventas finalizadas, {{ nombreTab }}</h5>
                                        <p class="text-success" v-if="reportes.estv.pos + reportes.estv.tienda > 0">
                                            {{ Numbro((reportes.ventas.pos + reportes.ventas.tienda + reportes.ventas.mv) * 100 / (reportes.estv.pos + reportes.estv.tienda + reportes.ventas.mv)).formatCurrency() }}%
                                            <span><i class="las la-arrow-up"></i></span>
                                        </p>
                                    </div>
                                    <div class="widget-info">
                                        <h5>
                                            <span class="text-muted">Total </span> {{ Numbro(reportes.ventas.pos + reportes.ventas.tienda + reportes.ventas.mv).formatCurrency() }}
                                        </h5>

                                        <div class="float-right text-right">
                                            <ul>
                                                <li class="text-info">POS Virtual:
                                                    <span class="text-dark-gray">{{ Numbro((reportes.ventas.tienda) ? reportes.ventas.pos : 0).formatCurrency() }}</span>
                                                </li>
                                                <li class="text-info">Tienda:
                                                    <span class="text-dark-gray">{{ Numbro((reportes.ventas.tienda) ? reportes.ventas.tienda : 0).formatCurrency() }}</span>
                                                </li>
                                                <li class="text-info" v-if="enableMultiVendor">{{whiteLabelInfo.nombre}}:
                                                    <span class="text-dark-gray">{{ Numbro((reportes.ventas.mv) ? reportes.ventas.mv : 0).formatCurrency() }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6" >
                                <div class="widget-card">
                                    <div class="widget-title">
                                        <h5>Ventas en progreso, {{ nombreTab }}</h5>
                                        <p class="text-warning text-right" v-if="reportes.estv.pos + reportes.estv.tienda > 0">
                                            <small class="text-muted">Sin pago </small><br/>
                                            {{ Numbro(100 - ((reportes.ventas.pos + reportes.ventas.tienda) * 100 / (reportes.estv.pos + reportes.estv.tienda))).formatCurrency() }}%
                                            <span><i class="fas fa-warning"></i></span>
                                        </p>
                                    </div>
                                    <div class="widget-info">
                                        <h5>
                                            <span class="text-muted">Total </span> {{ Numbro(reportes.estv.pos + reportes.estv.tienda).formatCurrency() }}
                                        </h5>
                                        <h5 class="text-warning"></h5>

                                        <div class="float-right text-right">
                                            <ul>
                                                <li class="text-success">Pagadas:
                                                    <span class="text-dark-gray"> {{ Numbro(reportes.ventas.pos + reportes.ventas.tienda).formatCurrency() }}</span>
                                                </li>
                                                <li class="text-warning">Sin pago:
                                                    <span class="text-dark-gray"> {{ Numbro((reportes.estv.pos + reportes.estv.tienda) - (reportes.ventas.pos + reportes.ventas.tienda)).formatCurrency() }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-4 col-lg-6 col-md-6">
                                <div class="widget-card">
                                    <div class="widget-title">
                                        <h5>Ordenes, {{ nombreTab }} </h5>
                                        <p class="text-success">
                                            <span><i class="las la-file-invoice"></i></span>
                                        </p>
                                    </div>
                                    <div class="widget-info">
                                        <h3>{{ reportes.ordenes.pos + reportes.ordenes.tienda }}</h3>
                                        <div class="float-right text-right">
                                            <ul>
                                                <li class="text-info">POS:
                                                    <span class="text-dark-gray">{{ reportes.ordenes.pos }}</span></li>
                                                <li class="text-info">Tienda:
                                                    <span class="text-dark-gray">{{ reportes.ordenes.tienda }}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container" v-if="AuthGetUserInfo.layout === 'medico'">
                <dashboard-medico></dashboard-medico>
            </div>
            <div class="container" v-if="AuthGetUserInfo.layout === 'cotizador'">
                <div class="row">
                    <div v-for="(producto, index) in productos" :key="index" class="col-lg-3 col-md-4 col-sm-6 col-12 mb-4">
                        <div class="card h-100">
                            <img :src="producto.thumbnail" class="card-img-top" alt="Producto">
                            <div class="card-body">
                                <h5 class="card-title">{{ producto.nombre }}</h5>
                                <p class="card-text" v-html="textoRecortado(producto)"></p>
                            </div>
                            <div class="card-footer">
                                <div class="row align-items-center">
                                    <div class="col-6">
                                        <span class="text-info font-weight-bold">{{ producto.precio }}</span>
                                    </div>
                                    <div class="col-6">
                                        <button class="btn btn-info btn-block" style="border-radius: 8px;" @click="redirectToProduct(producto.id)">
                                            <i class="fa fa-shopping-cart"></i> Vender
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </layout>
</template>

<script>
//import TimelineChart from "../components/charts/TimelineChart.vue";
import Layout from "/src/layout/LayoutLoader.vue";
import dashboardMedico from "/src/modules/medico/views/Dashboard.vue";
import API from "/src/core/Api";
import Numbro from "numbro";
import Tools from "src/core/Tools";
import AndroidBridge from "src/core/AndroidBridge";
import {mapGetters} from "vuex";
//import Toster from "../components/Toster.vue";

export default {
    name: "Dashboard",
    components: {
        //TimelineChart,
        dashboardMedico,
        Layout,
        //Toster,
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        }),

    },
    data() {
        return {
            enableMultiVendor: false,
            active: "today",
            nombreTab: 'Hoy',
            startUp: {},
            filterByType: '',
            searchResults: {},
            pageSelected: 1,
            tipoOrden: 'recurrente',
            productos: {},
            limit: 10,
            filter: 0,
            numeroPalabras: 20,
            searchTerm: '',
            reportes: {
                "ventas": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                },
                "ordenes": {
                    "pos": 0,
                    "tienda": 0,
                    "mv": 0,
                },
                "estv": {
                    "pos": 0,
                    "tienda": 0
                },
            },
        };
    },
    methods: {
        Numbro,
        textoRecortado(producto) {
            const palabras = producto.descripcion.split(' ');

            if (palabras.length <= this.numeroPalabras) {
                return producto.descripcion;
            }

            const textoRecortado = palabras.slice(0, this.numeroPalabras).join(' ');
            return `${textoRecortado}...`;
        },
        search() {
            const self = this;

            let urlToSearch = '';

            if (this.tipoOrden === 'directo') {
                urlToSearch = 'products/all/1/'+self.pageSelected+'/'+self.limit+'/'+self.filter;

                if (this.searchTerm !== '') {
                    urlToSearch = 'products/search/'+this.searchTerm;
                }
            }
            else {
                urlToSearch = 'products/all/recurrente/1';

                if (this.searchTerm !== '') {
                    urlToSearch = 'products/search/recurrente/'+this.searchTerm;
                }
            }

            const dataSearch = {
                term: this.searchTerm,
                page: self.pageSelected,
                perPage: self.limit,
                filter: self.filter,
                filterByType: self.filterByType,
            }

            API.send('POST', 'products/do-search', dataSearch,
                function (response) {
                    if (response.status) {
                        if(Tools.isEmptyString(response.data.productos)){
                            self.productos = response.data;
                        }
                        else{
                            self.productos = response.data.productos;
                        }

                    }
                    else {
                        API.showErrorAlert(response.msg);
                    }
                },
                function (data) {
                    API.showErrorAlert(data.msg);
                })
        },
        redirectToProduct(id){
            this.$router.push('/orders/subscriptions/new/' + id)
        }
    },
    watch: {

        active: function (val) {
            const self = this;
            if (val) {
                API.send('GET', 'reports/general/' + self.active, {},
                    function (response) {
                        self.reportes = Tools.parseObjectToFloat(response.data);
                    },
                    function (response) {
                        //self.invalidLink = true;
                        API.showErrorNotify(response.msg);
                    });
            }
        }
    },
    mounted() {
        const self = this;

        /// prueba impresion
/*
        setTimeout(function () {
            const data = '{"emisor":{"nombreFiscal":"ABSTRAKT TECNOLOG\u00cdA Y SISTEMAS, S.A.","direccionFiscal":"","NIT":"108561968"},"receptor":{"nombreFiscal":"EDDY ORLANDO PEREZ VASQUEZ","direccionFiscal":"4 CALLE 33-60 1 COLONIA SAN JORGE A GUATEMALA ,","NIT":"80281540"},"documento":{"nombre":"Factura electr\u00f3nica","numero":1489584343,"serie":"02C478BD","CAE":"02C478BD-58C9-40D7-AC38-5E8F8AD28B79","link":"https:\\/\\/felpub.c.sat.gob.gt\\/verificador-web\\/publico\\/vistas\\/verificacionDte.jsf?tipo=autorizacion&numero=02C478BD-58C9-40D7-AC38-5E8F8AD28B79&emisor=108561968&receptor=80281540&monto=1.00","monto":"1.00","fechaEmision":"03\\/08\\/2022 13:28"},"detalle":[{"bienOServicio":"S","numeroLinea":1,"cantidad":1,"unidadMedida":"UN","descripcion":"Prueba","precioUnitario":1,"subTotal":1,"descuento":0,"impuestos":{"IVA":{"nombreCorto":"IVA","codigoUnidadGravable":1,"montoGravable":0.8929,"montoImpuesto":0.1071}},"total":1}],"impuestos":[{"NombreCorto":"IVA","TotalMontoImpuesto":0.1071}],"certificador":{"nombre":"Megaprint, S.A","NIT":"50510231"},"frases":{"ISR":"Sujeto a pagos trimestrales"},"footer":""}';
            const androidBridge = new AndroidBridge();
            androidBridge.PrintFactura(data);
        }, 2000);
*/
        if(this.AuthGetUserInfo.layout === 'cotizador'){
            this.search();
        }
        if (typeof this.whiteLabelInfo.multiVendor !== 'undefined' && this.whiteLabelInfo.multiVendor !== '') {
            this.enableMultiVendor = true;
        }

        //API.showErrorAlert('Prueba de notificación', 'Esta es un error');

        API.send('GET', 'reports/configuracion/', {},
            function (response) {
                self.startUp = response.data;
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })

        API.send('GET', 'reports/general/' + self.active, {},
            function (response) {
                self.reportes = Tools.parseObjectToFloat(response.data);
            },
            function (response) {
                API.showErrorNotify(response.msg);
            })

        this.androidBridge = new AndroidBridge();

        // Salida en POS TOP WISE
        if (this.androidBridge.isOnTopWise()) {
            this.androidBridge.TopWiseCloseApp('http://localhost/dashboard');
        }
    },

};
</script>
