<template>
    <setting-layout :active="27">
        <div class="card myPlanAndPackages">
            <div class="card-header">
                <h4 class="card-title">Mi saldo y consumibles</h4>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-sm-6 text-justify">
                        <h5>¿Qué es un consumible?</h5>
                        <div>
                            Las transacciones de venta, mensajes de texto hacia teléfonos móviles y las facturas electrónicas son elementos consumibles para tu cuenta, a modo de ejemplo, cada vez que se realiza una factura electrónica, se debita de tu cuenta 1 DTE o factura.
                        </div>
                        <br>
                        <h5>Saldo disponible</h5>
                        <div class="p-2">
                            <div class="row">
                                <div class="col-12 col-sm-12 consumibleItem">
                                    <div>
                                        <h5 class="text-dark-gray">
                                            Transacciones de venta
                                            <i class="infoQuestion fa fa-question-circle ml-3" v-tooltip="'Cada venta que realices a través de la plataforma (desde el POS, Tienda en línea o nuestra app) es considerada como una transacción de venta'"></i>
                                        </h5>
                                        <div class="packageItemAvailable mt-3">
                                            <div class="availableItems text-primary">
                                                {{ saldos.transaction.saldo }}
                                            </div>
                                            <div class="text-primary">
                                                Disponibles
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="text-center text-danger">
                                            No puedes desactivar esta opción
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <h5 class="text-dark-gray">
                                            DTE o Factura electrónica
                                            <i class="infoQuestion fa fa-question-circle ml-3" v-tooltip="'Puedes activar o desactivar el uso de facturas electrónicas, si desactivas esta opción, tus clientes NO recibirán facturas electrónicas y NO se emitirán facturas para tu negocio.'"></i>
                                        </h5>
                                        <div class="packageItemAvailable mt-3">
                                            <div class="availableItems text-primary">
                                                {{ saldos.dte.saldo }}
                                            </div>
                                            <div class="text-primary">
                                                Disponibles
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="text-center">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="enableDTE" :checked="enableDTE" @change="enableDisableConsumibles('dte')"/>
                                                <div class="toggle-switch"></div>
                                                    <span class="toggle-label">
                                                    Activar
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="mt-2">
                                        <h5 class="text-dark-gray">
                                            SMS o Mensajes de texto
                                            <i class="infoQuestion fa fa-question-circle ml-3" v-tooltip="'Cuando esta opción se encuentra activa, tus clientes recibirán un mensaje de texto a su teléfono celular (si está registrado), con la información de facturación, confirmación de compra y algunas otras operaciones.'"></i>
                                        </h5>
                                        <div class="packageItemAvailable mt-3">
                                            <div class="availableItems text-primary">
                                                {{ saldos.sms.saldo }}
                                            </div>
                                            <div class="text-primary">
                                                Disponibles
                                            </div>
                                        </div>
                                        <hr>
                                        <div class="text-center">
                                            <label class="toggle">
                                                <input class="toggle-checkbox" type="checkbox" v-model="enableSMS" :checked="enableSMS" @change="enableDisableConsumibles('sms')"/>
                                                <div class="toggle-switch"></div>
                                                    <span class="toggle-label">
                                                    Activar
                                                </span>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 text-justify">
                        <h5>¿Por qué recargar mis consumibles?</h5>
                        <div>
                            Tus consumibles disminuyen dependiendo del ritmo al cual realices ventas, puedes recargarlos en cualquier momento para continuar vendiendo y generando ingresos para tu negocio.
                        </div>
                        <br>
                        <h5>Selecciona la cantidad de consumibles a recargar</h5>
                        <div class="mt-4">
                            <div class="mb-2 pb-2">
                                <h6 class="mb-5 text-gray">Transacciones de venta</h6>
                                <div id="range_transacciones" class="range-slider">
                                    <div class="range-slider-ui"></div>
                                </div>
                            </div>
                            <div class="mb-2 pb-2">
                                <h6 class="mb-5 text-gray">SMS o mensajes de texto</h6>
                                <div id="range_sms" class="range-slider">
                                    <div class="range-slider-ui"></div>
                                </div>
                            </div>
                            <div class="mb-2 pb-2">
                                <h6 class="mb-5 text-gray">DTE o facturas electrónicas</h6>
                                <div id="range_dte" class="range-slider">
                                    <div class="range-slider-ui"></div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row">
                            <div class="col-5">
                                <h4 class="text-dark-gray text-left">Total a pagar:</h4>
                            </div>
                            <div class="col-7 text-right">
                                <h3 class="text-success mb-4">{{ Numbro(totalAmount).formatCurrency() }}</h3>
                            </div>
                        </div>
                        <div class="text-right">
                            <button class="btn btn-success btn-sm" @click="showPaymentForm = true">Recargar ahora</button>
                        </div>
                    </div>
                </div>
                <div>
                    <PaymentForm v-model:show-payment-form="showPaymentForm" @do-pay="doPay" :total-pago="totalAmount"></PaymentForm>
                </div>
            </div>
        </div>
    </setting-layout>
</template>

<script>
import settingLayout from "../components/settings/settingLayout.vue";
import PaymentForm from "src/modules/order/components/PaymentForm";
import {mapGetters} from "vuex";
import {store} from "src/store";
// import * as noUiSlider from "src/assets/publicStore/js/nouislider.min";
import API from "src/core/Api";
import Numbro from 'numbro';


export default {
    components: {
        settingLayout,
        PaymentForm,
    },
    data() {
        return {
            saldos: {
                transaction: {
                    saldo: 0
                },
                dte: {
                    saldo: 0
                },
                sms: {
                   saldo: 0
                },
            },
            showPaymentForm: false,
            enableSMS: false,
            enableDTE: false,
            showComprarSaldo: false,
            totalAmount: 0,
            rangeSliders: {},
        };
    },
    computed: {
        ...mapGetters({
            whiteLabelInfo: 'whiteLabelInfo',
            AuthGetUserInfo: 'AuthGetUserInfo',
        })
    },
    mounted() {
        this.getPlanInfo();
    },
    watch: {
        showComprarSaldo: function (val) {
            if (val) {
                document.body.classList.add("overflowLocked");
            }
            else {
                document.body.classList.remove("overflowLocked");
            }
        },
        recargarPaquetesSeleccionados: function () {
            this.calculateTotal();
        },
        rangeSliders: {
            deep: true,
            handler() {
                this.calculateTotal();
            }
        },
    },
    methods: {
        Numbro,
        enableRangeSlider(idWidget, options) {
            const self = this;
            const rangeSliderWidget = document.getElementById(idWidget);
            const rangeSlider = rangeSliderWidget.querySelector('.range-slider-ui');

            if (rangeSlider.noUiSlider) {
                rangeSlider.noUiSlider.destroy();
            }

            noUiSlider.create(rangeSlider, {
                start: [options.dataStartMin],
                //step: options.step,
                tooltips: {
                    to: function (value) {
                        return Numbro(parseInt(Math.round(value))).format({thousandSeparated: true})
                    }
                },
                range: options.range,
                format: {
                    to: function to(value) {
                        return Math.round(value);
                    },
                    from: function from(value) {
                        return Math.round(value);
                    }
                }
            });

            rangeSlider.noUiSlider.on('update', function (values, handle) {
                self.rangeSliders[idWidget] = values[handle];
            });
        },
        enableRangeConfig() {
            const transOptions = {
                dataStartMin: 10,
                range: {
                    'min': [10, 10],
                    '20%': [100, 50],
                    '50%': [2000, 500],
                    '80%': [5000, 1000],
                    'max': [10000],
                },
            };
            this.enableRangeSlider('range_transacciones', transOptions);

            const smsOptions = {
                dataStartMin: 10,
                range: {
                    'min': [0, 10],
                    '20%': [100, 50],
                    '50%': [2000, 500],
                    '80%': [5000, 1000],
                    'max': [10000],
                },
            };
            this.enableRangeSlider('range_sms', smsOptions);

            const dteOptions = {
                dataStartMin: 10,
                range: {
                    'min': [0, 10],
                    '20%': [100, 50],
                    '50%': [2000, 500],
                    '80%': [5000, 1000],
                    'max': [10000],
                },
            };
            this.enableRangeSlider('range_dte', dteOptions);
        },
        calculateTotal() {

            const self = this;

            const transPrice = (store.getters.whiteLabelInfo.tp) ? store.getters.whiteLabelInfo.tp : 0;
            const smsPrice = (store.getters.whiteLabelInfo.sp) ? store.getters.whiteLabelInfo.sp : 0;
            const dtePrice = (store.getters.whiteLabelInfo.dp) ? store.getters.whiteLabelInfo.dp : 0;

            // sumo los consumibles
            self.totalAmount = ((parseFloat(this.rangeSliders['range_transacciones']) * parseFloat(transPrice)) + (parseFloat(this.rangeSliders['range_sms']) * parseFloat(smsPrice)) + (parseFloat(this.rangeSliders['range_dte']) * parseFloat(dtePrice)));

            return self.totalAmount;
        },
        getPlanInfo() {
            const self = this;
            API.send('GET', 'users/plan-info/', {},
                function (response) {
                    if (response.status) {
                        console.log(response.data.saldos);
                        self.saldos = response.data.saldos;

                        if (typeof self.saldos.dte !== 'undefined') {
                            self.enableDTE = self.saldos.dte.enabled;
                        }
                        if (typeof self.saldos.sms !== 'undefined') {
                            self.enableSMS = self.saldos.sms.enabled;
                        }

                        self.enableRangeConfig();
                        self.SavedTotal = self.calculateTotal();
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorNotify(response.msg);
                })
        },
        enableDisableConsumibles(type) {

            let alert = "";
            if (type === 'dte') {
                if (!this.enableDTE) {
                    alert = 'Si desactivas esta opción, el sistema dejará de facturar automáticamente. Los DTE o facturas electrónicas dejarán de descontarse de tu saldo';
                }
                else {
                    alert = 'Si activas esta opción, el sistema emitirá DTE o facturas electrónicas automáticamente. Estos iniciarán a descontarse de tu saldo';
                }
            }
            else if (type === 'sms') {

                if (!this.enableSMS) {
                    alert = 'Si desactivas esta opción, el sistema dejará de enviar notificaciones por SMS o mensaje de texto. Los SMS dejarán de descontarse de tu saldo';
                }
                else {
                    alert = 'Si activas esta opción, el sistema enviará automáticamente notificaciones por SMS o mensaje de texto. Los SMS iniciarán a descontarse de tu saldo';
                }
            }

            const self = this;

            API.showConfirm('Antes de continuar', alert, function () {
                API.send('POST', 'users/consumibles/enable-disable', {
                        "dte": self.enableDTE,
                        "sms": self.enableSMS,
                    },
                    function (response) {
                        if (response.status) {
                            API.showSuccessNotify(response.msg);
                        }
                        else {
                            API.showErrorNotify(response.msg);
                        }
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    })
            }, function () {
                if (type === 'dte') {
                    self.enableDTE = !self.enableDTE;
                }
                else if (type === 'sms') {
                    self.enableSMS = !self.enableSMS;
                }
            })
        },
        doPay(data) {

            const self = this;

            const dataSave = {
                "card_name": data.cardName,
                "card_number": data.cardNumber,
                "card_exp_date": data.cardMonth + "/" + data.cardYear.toString().substr(2),
                "card_cvv": data.cardCvv,
                "modules": this.modulosEnabled,
                "consumibles": this.rangeSliders,
            }

            API.send('POST', 'payments/do_pay/recharge', dataSave,
                function (response) {
                    if (response.status) {
                        self.showPaymentForm = false;
                        API.showSuccessNotify(response.msg);
                    }
                    else {
                        API.showErrorNotify(response.msg);
                    }
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                })
        }
    },
};
</script>
