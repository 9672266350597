<template>
    <!--<box title="Preguntas Iniciales" icon="fas fa-spell-check">
        <div class="row">
            <div class="col-12 col-sm-12">
                <div class="form-group">
                    <label>Nombre del cónyuge</label>
                    <input type="text" class="form-control" placeholder="Escribe aquí" v-model="nombre_conyugue">
                </div>
            </div>
        </div>
    </box>-->
    <box title="Antecedentes Familiares" icon="fas fa-users">
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in familiares" :key="'familiares_' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveFamiliares"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ item.name }}
                    </span>
                </label>
                <!--<datos-complemento v-if="item.value" @save="item.complemento">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Miembro familiar</label>
                                <select class="form-control" name="miembro_familiar">
                                    <option value="madre">Madre</option>
                                    <option value="padre">Padre</option>
                                    <option value="hermano">Hermano</option>
                                    <option value="hermana">Hermana</option>
                                    <option value="abuelo">Abuelo</option>
                                    <option value="abuela">Abuela</option>
                                    <option value="tio">Tío</option>
                                    <option value="tia">Tía</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Padecimiento</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="padecimiento">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Estado del familiar</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="estado_familiar">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad de inicio (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_inicio">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad al morir (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_muerte">
                            </div>
                        </div>
                    </div>
                </datos-complemento>-->
            </div>
        </div>
    </box>
    <box title="Antecedentes Médicos" icon="fas fa-notes-medical">
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in medicos" :key="'medicos_' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveMedicos"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ item.name }}
                    </span>
                </label>
                <!--<datos-complemento v-if="item.value" @save="item.complemento">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Miembro familiar</label>
                                <select class="form-control" name="miembro_familiar">
                                    <option value="madre">Madre</option>
                                    <option value="padre">Padre</option>
                                    <option value="hermano">Hermano</option>
                                    <option value="hermana">Hermana</option>
                                    <option value="abuelo">Abuelo</option>
                                    <option value="abuela">Abuela</option>
                                    <option value="tio">Tío</option>
                                    <option value="tia">Tía</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Padecimiento</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="padecimiento">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Estado del familiar</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="estado_familiar">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad de inicio (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_inicio">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad al morir (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_muerte">
                            </div>
                        </div>
                    </div>
                </datos-complemento>-->
            </div>
        </div>
    </box>
    <box title="Antecedentes Quirúrgicos" icon="fas fa-bed-pulse">
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in quirurgicos" :key="'quirurgicos_' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveQuirurgicos"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ item.name }}
                    </span>
                </label>
                <!--<datos-complemento v-if="item.value" @save="item.complemento">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Miembro familiar</label>
                                <select class="form-control" name="miembro_familiar">
                                    <option value="madre">Madre</option>
                                    <option value="padre">Padre</option>
                                    <option value="hermano">Hermano</option>
                                    <option value="hermana">Hermana</option>
                                    <option value="abuelo">Abuelo</option>
                                    <option value="abuela">Abuela</option>
                                    <option value="tio">Tío</option>
                                    <option value="tia">Tía</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Padecimiento</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="padecimiento">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Estado del familiar</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="estado_familiar">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad de inicio (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_inicio">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad al morir (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_muerte">
                            </div>
                        </div>
                    </div>
                </datos-complemento>-->
            </div>
        </div>
    </box>
    <box title="Antecedentes Traumáticos" icon="fas fa-bone">
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in traumaticos" :key="'traumaticos_' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveTraumaticos"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ item.name }}
                    </span>
                </label>
                <!--<datos-complemento v-if="item.value" @save="item.complemento">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Miembro familiar</label>
                                <select class="form-control" name="miembro_familiar">
                                    <option value="madre">Madre</option>
                                    <option value="padre">Padre</option>
                                    <option value="hermano">Hermano</option>
                                    <option value="hermana">Hermana</option>
                                    <option value="abuelo">Abuelo</option>
                                    <option value="abuela">Abuela</option>
                                    <option value="tio">Tío</option>
                                    <option value="tia">Tía</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Padecimiento</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="padecimiento">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Estado del familiar</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="estado_familiar">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad de inicio (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_inicio">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad al morir (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_muerte">
                            </div>
                        </div>
                    </div>
                </datos-complemento>-->
            </div>
        </div>
    </box>
    <box title="Hábitos" icon="fas fa-smoking">
        <div class="row">
            <div class="col-12 col-sm-4 mb-3" v-for="(item, key) in habitos" :key="'habitos_' + key">
                <label class="toggle">
                    <input class="toggle-checkbox" type="checkbox" v-model="item.value" :checked="item.value" @change="saveHabitos"/>
                    <div class="toggle-switch"></div>
                    <span class="toggle-label">
                        {{ item.name }}
                    </span>
                </label>
                <!--<datos-complemento v-if="item.value" @save="item.complemento">
                    <div class="row">
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Miembro familiar</label>
                                <select class="form-control" name="miembro_familiar">
                                    <option value="madre">Madre</option>
                                    <option value="padre">Padre</option>
                                    <option value="hermano">Hermano</option>
                                    <option value="hermana">Hermana</option>
                                    <option value="abuelo">Abuelo</option>
                                    <option value="abuela">Abuela</option>
                                    <option value="tio">Tío</option>
                                    <option value="tia">Tía</option>
                                </select>
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Padecimiento</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="padecimiento">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Estado del familiar</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="estado_familiar">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad de inicio (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_inicio">
                            </div>
                        </div>
                        <div class="col-12 col-sm-6">
                            <div class="form-group">
                                <label>Edad al morir (años)</label>
                                <input type="text" class="form-control" placeholder="Escribe aquí" name="edad_muerte">
                            </div>
                        </div>
                    </div>
                </datos-complemento>-->
            </div>
        </div>
    </box>
    <hr>
    <box title="Alergias" icon="fas fa-allergies">
        <div v-if="typeof GetMedicoFichaActive.metadata.antecedentes_alergia === 'undefined'">
            <div class="text-center text-danger">No tiene alergias ingresadas</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Fecha identificada</th>
                    <th class="font-weight-bold">Alergia a</th>
                    <th class="font-weight-bold">Reacción</th>
                    <th class="font-weight-bold">Severidad</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.antecedentes_alergia" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.fechaIdentificada }}</td>
                        <td>{{ item.detail.alergiaA }}</td>
                        <td>{{ item.detail.reaccion }}</td>
                        <td>{{ item.detail.severidad }}/10</td>
                        <td>
                            <i @click="editAlergia(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deleteAlergia(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5" v-if="showEditionAlergias">
            <div>
                <h5>Agregar Alergias</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Fecha identificada</label>
                        <date-picker v-model="alergia.detail.fechaIdentificada" mode="dateTime">
                            <template #default="{ inputValue, inputEvents }">
                                <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                            </template>
                        </date-picker>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Alergia a</label>
                        <input type="text" class="form-control" v-model="alergia.detail.alergiaA"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Reacción</label>
                        <input type="text" class="form-control" v-model="alergia.detail.reaccion"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Severidad</label>
                        <Slider class="m-0 m-auto" v-model="alergia.detail.severidad" :min="0" :max="10" tooltipPosition="bottom"/>
                    </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditAlergia" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveAlergia" class="btn btn-primary mr-3">Guardar</button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addAlergia">
                <i class="fa fa-plus mr-2"></i>Agregar alergias
            </button>
        </div>
    </box>
    <hr>
    <box title="Medicamentos" icon="fas fa-pills">
        <div v-if="typeof GetMedicoFichaActive.metadata.antecedentes_medicamentos === 'undefined'">
            <div class="text-center text-danger">No tiene medicamentos ingresados</div>
        </div>
        <div v-else>
            <table class="table align-middle m-0">
                <thead class="small flex-sm-nowrap font-weight-bold">
                <tr class="font-weight-bold small">
                    <th class="font-weight-bold">Nombre y formulación del medicamento</th>
                    <th class="font-weight-bold">Fuente</th>
                    <th class="font-weight-bold">Fecha de inicio</th>
                    <th class="font-weight-bold">Observaciones</th>
                    <th class="font-weight-bold"></th>
                </tr>
                </thead>
                <tbody class="small">
                <tr v-for="item in GetMedicoFichaActive.metadata.antecedentes_medicamentos" :key="item.id">
                    <template v-if="typeof item.detail !== 'undefined'">
                        <td>{{ item.detail.nombreYFormulacion }}</td>
                        <td>{{ item.detail.fuente }}</td>
                        <td>{{ item.detail.fechaInicio }}</td>
                        <td>{{ item.detail.observaciones }}</td>
                        <td>
                            <i @click="editMedicamento(item)" class="fas fa-edit text-primary cursor-pointer mr-4"></i>
                            <i @click="deleteMedicamento(item)" class="fas fa-trash text-danger cursor-pointer"></i>
                        </td>
                    </template>
                </tr>
                </tbody>
            </table>
        </div>
        <div class="mt-5" v-if="showEditionMedicamento">
            <div>
                <h5>Agregar historia de medicamentos</h5>
            </div>
            <div class="row">
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Nombre y formulación del medicamento</label>
                        <input type="text" class="form-control" v-model="medicamento.detail.nombreYFormulacion"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Fuente</label>
                        <input type="text" class="form-control" v-model="medicamento.detail.fuente"/>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Fecha de inicio</label>
                        <date-picker v-model="medicamento.detail.fechaInicio" mode="dateTime">
                            <template #default="{ inputValue, inputEvents }">
                                <input :value="inputValue" v-on="inputEvents" class="form-control"/>
                            </template>
                        </date-picker>
                    </div>
                </div>
                <div class="col-12 col-sm-3">
                    <div class="form-group">
                        <label>Observaciones</label>
                        <input type="text" class="form-control" v-model="medicamento.detail.observaciones"/>
                    </div>
                </div>
            </div>
            <div class="text-right mt-5">
                <button @click="cancelEditMedicamento" class="btn btn-danger mr-3">Cancelar</button>
                <button @click="saveMedicamento" class="btn btn-primary mr-3">Guardar</button>
            </div>
        </div>
        <div v-else class="mt-4 text-right">
            <button class="btn btn-primary" @click="addMedicamento">
                <i class="fa fa-plus mr-2"></i>Agregar medicamento
            </button>
        </div>
    </box>
</template>
<script>
import API from "src/core/Api";
import Tools from "src/core/Tools";
import dayjs from "dayjs";
import Numbro from "numbro";
import {DatePicker} from 'v-calendar';
import {store} from "src/store";
import {mapActions, mapGetters} from "vuex";
import '@vueform/slider/themes/default.css';
import Slider from '@vueform/slider';

import Box from "src/modules/medico/views/fichas/Box.vue";
import DatosComplemento from "src/modules/medico/views/fichas/DatosComplemento.vue";

// Componentes

export default {
    components: {
        Box,
        DatosComplemento,
        Slider,
        DatePicker,
    },
    props: [
        'type'
    ],
    data() {
        return {
            nombre_conyugue: '',
            familiares: {
                alergias: {
                    name: 'Alergias',
                    complemento: {},
                    value: false,
                },
                asma: {
                    name: 'Asma',
                    complemento: {},
                    value: false,
                },
                cancer: {
                    name: 'Cancer',
                    complemento: {},
                    value: false,
                },
                cerebrovascular: {
                    name: 'Cerebrovascular',
                    complemento: {},
                    value: false,
                },
                colesterol: {
                    name: 'Colesterol',
                    complemento: {},
                    value: false,
                },
                depresion: {
                    name: 'Depresión',
                    complemento: {},
                    value: false,
                },
                diabetes: {
                    name: 'Diabetes',
                    complemento: {},
                    value: false,
                },
                dislipidemias: {
                    name: 'Dislipidemias',
                    complemento: {},
                    value: false,
                },
                enfermedades_coronarias: {
                    name: 'Enfermedades coronarias',
                    complemento: {},
                    value: false,
                },
                enfermedades_hereditarias: {
                    name: 'Enfermedades Hereditarias',
                    complemento: {},
                    value: false,
                },
                gemelares: {
                    name: 'Gemelares',
                    complemento: {},
                    value: false,
                },
                hemofilia: {
                    name: 'Hemofilia',
                    complemento: {},
                    value: false,
                },
                hipertension: {
                    name: 'Hipertensión',
                    complemento: {},
                    value: false,
                },
                hipertension_arterial: {
                    name: 'Hipertensión Arterial',
                    complemento: {},
                    value: false,
                },
                neoplasias: {
                    name: 'Neoplasias',
                    complemento: {},
                    value: false,
                },
                obesidad: {
                    name: 'Obesidad',
                    complemento: {},
                    value: false,
                },
                transtornos_psiquiatricos: {
                    name: 'Trastornos psiquiátricos',
                    complemento: {},
                    value: false,
                },
                otra_enfermedad: {
                    name: 'Otra enfermedad',
                    complemento: {},
                    value: false,
                },
            },
            medicos: {
                alalia: {
                    name: 'Alalia (mudo)',
                    complemento: {},
                    value: false,
                },
                alteraciones_de_la_coagulacion_sanguieo: {
                    name: 'Alteraciones de la coagulación sanguínea',
                    complemento: {},
                    value: false,
                },
                anemia: {
                    name: 'Anemia',
                    complemento: {},
                    value: false,
                },
                angina_de_pecho: {
                    name: 'Angina de pecho',
                    complemento: {},
                    value: false,
                },
                arritmia_cardiaca: {
                    name: 'Arritmia cardíaca',
                    complemento: {},
                    value: false,
                },
                asma: {
                    name: 'Asma',
                    complemento: {},
                    value: false,
                },
                calculos_renales: {
                    name: 'Cálculos renales',
                    complemento: {},
                    value: false,
                },
                ceguera: {
                    name: 'Ceguera (ciego)',
                    complemento: {},
                    value: false,
                },
                dano_cerebral: {
                    name: 'Daño cerebral',
                    complemento: {},
                    value: false,
                },
                dano_de_la_medula_espinal: {
                    name: 'Daño de la médula espinal',
                    complemento: {},
                    value: false,
                },
                descompensacion_cardiaca: {
                    name: 'Descompensación cardíaca',
                    complemento: {},
                    value: false,
                },
                diabetes: {
                    name: 'Diabetes',
                    complemento: {},
                    value: false,
                },
                enfermedad_cardiaca_congenita: {
                    name: 'Enfermedad cardíaca congénita',
                    complemento: {},
                    value: false,
                },
                enfermedad_de_whipple: {
                    name: 'Enfermedad de WHIPPLE',
                    complemento: {},
                    value: false,
                },
                enfermedad_valvular_cardiaca: {
                    name: 'Enfermedad valvular cardíaca',
                    complemento: {},
                    value: false,
                },
                enucleacion_de_globo_ocular: {
                    name: 'Enucleación de globo ocular (Tuerto)',
                    complemento: {},
                    value: false,
                },
                epilepsia: {
                    name: 'Epilepsia',
                    complemento: {},
                    value: false,
                },
                esclerosis_multiple: {
                    name: 'Esclerosis múltiple',
                    complemento: {},
                    value: false,
                },
                glaucoma: {
                    name: 'Glaucoma (hipertensión ocular)',
                    complemento: {},
                    value: false,
                },
                hepatitis_virica: {
                    name: 'Hepatitis vírica',
                    complemento: {},
                    value: false,
                },
                hipertension_arterial: {
                    name: 'Hipertensión arterial',
                    complemento: {},
                    value: false,
                },
                hipertiroidismo: {
                    name: 'Hipertiroidismo',
                    complemento: {},
                    value: false,
                },
                hipotiroidismo: {
                    name: 'Hipotiroidismo',
                    complemento: {},
                    value: false,
                },
                infarto_de_miocardio: {
                    name: 'Infarto de miocardio',
                    complemento: {},
                    value: false,
                },
                insuficiencia_hepatica: {
                    name: 'Insuficiencia hepática (hígado)',
                    complemento: {},
                    value: false,
                },
                insuficiencia_pulmonar_grave: {
                    name: 'Insuficiencia pulmonar grave',
                    complemento: {},
                    value: false,
                },
                insuficiencia_renal: {
                    name: 'Insuficiencia renal',
                    complemento: {},
                    value: false,
                },
                leucemia: {
                    name: 'Leucemia',
                    complemento: {},
                    value: false,
                },
                malaria: {
                    name: 'Malaria',
                    complemento: {},
                    value: false,
                },
                miastenia: {
                    name: 'Miastenia',
                    complemento: {},
                    value: false,
                },
                miopatia: {
                    name: 'Miopatía (músculos)',
                    complemento: {},
                    value: false,
                },
                mucoviscidosis: {
                    name: 'Mucoviscidosis',
                    complemento: {},
                    value: false,
                },
                poliomielitis: {
                    name: 'Poliomielitis',
                    complemento: {},
                    value: false,
                },
                prostatismo: {
                    name: 'Prostatismo',
                    complemento: {},
                    value: false,
                },
                sida: {
                    name: 'SIDA',
                    complemento: {},
                    value: false,
                },
                sordera: {
                    name: 'Sordera (sordo)',
                    complemento: {},
                    value: false,
                },
                tuberculosis: {
                    name: 'Tuberculosis',
                    complemento: {},
                    value: false,
                },
                tumor_laringeo: {
                    name: 'Tumor laríngeo',
                    complemento: {},
                    value: false,
                },
                tumor_o_malformacion_bucal: {
                    name: 'Tumor o malformación bucal',
                    complemento: {},
                    value: false,
                },
                ulcera_gastrica_o_duodenal: {
                    name: 'Úlcera gástrica o duodenal',
                    complemento: {},
                    value: false,
                },
                otra_enfermedad: {
                    name: 'Otra enfermedad',
                    complemento: {},
                    value: false,
                },
            },
            quirurgicos: {
                amputacion_de_miembro: {
                    name: 'Amputación de miembro',
                    complemento: {},
                    value: false,
                },
                aneurisma_cerebral: {
                    name: 'Aneurisma cerebral',
                    complemento: {},
                    value: false,
                },
                aneurisma_de_aorta_abdominal: {
                    name: 'Aneurisma de aorta abdominal',
                    complemento: {},
                    value: false,
                },
                aneurisma_de_aorta_toracica: {
                    name: 'Aneurisma de aorta torácica',
                    complemento: {},
                    value: false,
                },
                apendicectomia: {
                    name: 'Apendicectomía',
                    complemento: {},
                    value: false,
                },
                by_pass_coronario: {
                    name: 'By-Pass coronario',
                    complemento: {},
                    value: false,
                },
                carrefour_de_la_aorta_abdominal: {
                    name: 'Carrefour de la aorta abdominal',
                    complemento: {},
                    value: false,
                },
                cataratas: {
                    name: 'Cataratas',
                    complemento: {},
                    value: false,
                },
                cesarea: {
                    name: 'Cesárea',
                    complemento: {},
                    value: false,
                },
                cistectomia: {
                    name: 'Cistectomía (vesícula)',
                    complemento: {},
                    value: false,
                },
                colecistectomia: {
                    name: 'Colecistectomía',
                    complemento: {},
                    value: false,
                },
                colectomia: {
                    name: 'Colectomía (intestino grueso)',
                    complemento: {},
                    value: false,
                },
                derivacion_ventricular_cerebral: {
                    name: 'Derivación ventricular cerebral',
                    complemento: {},
                    value: false,
                },
                dilatacion_coronaria: {
                    name: 'Dilatación coronaria',
                    complemento: {},
                    value: false,
                },
                duodenopancreatectomia: {
                    name: 'Duodenopancreatectomía',
                    complemento: {},
                    value: false,
                },
                enfermedad_congenita_operada: {
                    name: 'Enfermedad congénita operada',
                    complemento: {},
                    value: false,
                },
                esplenectomia: {
                    name: 'Esplenectomía (bazo)',
                    complemento: {},
                    value: false,
                },
                fistula_arterio_venosa: {
                    name: 'Fístula arterio-venosa',
                    complemento: {},
                    value: false,
                },
                fractura_de_columna: {
                    name: 'Fractura de columna',
                    complemento: {},
                    value: false,
                },
                gastrectomia_parcial: {
                    name: 'Gastrectomía parcial',
                    complemento: {},
                    value: false,
                },
                gastrectomia_total: {
                    name: 'Gastrectomía total',
                    complemento: {},
                    value: false,
                },
                hemorragia_cerebral: {
                    name: 'Hemorragia cerebral',
                    complemento: {},
                    value: false,
                },
                hernia_discal: {
                    name: 'Hernia discal',
                    complemento: {},
                    value: false,
                },
                histerectomia: {
                    name: 'Histerectomía',
                    complemento: {},
                    value: false,
                },
                implante_de_morfina_a_bombeo: {
                    name: 'Implante de morfina a bombeo',
                    complemento: {},
                    value: false,
                },
                implante_estimulador_nervioso: {
                    name: 'Implante estimulador nervioso',
                    complemento: {},
                    value: false,
                },
                intervencion_cerebral: {
                    name: 'Intervención cerebral',
                    complemento: {},
                    value: false,
                },
                marcapasos_cardiaco: {
                    name: 'Marcapasos cardíaco',
                    complemento: {},
                    value: false,
                },
                nefrectomia_total: {
                    name: 'Nefrectomía total',
                    complemento: {},
                    value: false,
                },
                obstruccion_carotidea: {
                    name: 'Obstrucción carotídea',
                    complemento: {},
                    value: false,
                },
                pneumectomia_parcial: {
                    name: 'Pneumectomía parcial',
                    complemento: {},
                    value: false,
                },
                pneumectomia_total: {
                    name: 'Pneumectomía total',
                    complemento: {},
                    value: false,
                },
                stent_coronario: {
                    name: 'Stent coronario',
                    complemento: {},
                    value: false,
                },
                sustitucion_valvular_cardiaca: {
                    name: 'Sustitución valvular cardíaca',
                    complemento: {},
                    value: false,
                },
                tipo_de_marcapasos: {
                    name: 'Tipo de marcapasos',
                    complemento: {},
                    value: false,
                },
                trasplante_cardiaco: {
                    name: 'Trasplante cardíaco',
                    complemento: {},
                    value: false,
                },
                trasplante_de_corazon_y_pulmon: {
                    name: 'Trasplante de corazón y pulmón',
                    complemento: {},
                    value: false,
                },
                trasplante_de_higado: {
                    name: 'Trasplante de corazón y pulmón',
                    complemento: {},
                    value: false,
                },
                trasplante_renal: {
                    name: 'Trasplante renal',
                    complemento: {},
                    value: false,
                },
                traumatismo_cerebral: {
                    name: 'Traumatismo cerebral',
                    complemento: {},
                    value: false,
                },
                tumor_cerebral: {
                    name: 'Tumor cerebral',
                    complemento: {},
                    value: false,
                },
                tumor_esofagico: {
                    name: 'Tumor esofágico',
                    complemento: {},
                    value: false,
                },
                tumor_oseo: {
                    name: 'Tumor óseo',
                    complemento: {},
                    value: false,
                },
                valvuloplastia_cardiaca: {
                    name: 'Valvuloplastia cardíaca',
                    complemento: {},
                    value: false,
                },
                otros: {
                    name: 'Otros',
                    complemento: {},
                    value: false,
                }
            },
            traumaticos: {
                articulaciones: {
                    name: 'Articulaciones',
                    complemento: {},
                    value: false,
                },
                artritis_traumatica: {
                    name: 'Artritis traumática',
                    complemento: {},
                    value: false,
                },
                contusiones: {
                    name: 'Contusiones',
                    complemento: {},
                    value: false,
                },
                esguinces: {
                    name: 'Esguinces',
                    complemento: {},
                    value: false,
                },
                fractura_de_femur: {
                    name: 'Fractura de fémur',
                    complemento: {},
                    value: false,
                },
                fractura_de_humero: {
                    name: 'Fractura de húmero',
                    complemento: {},
                    value: false,
                },
                ligamentos: {
                    name: 'Ligamentos',
                    complemento: {},
                    value: false,
                },
                luxaciones: {
                    name: 'Luxaciones',
                    complemento: {},
                    value: false,
                },
                tendinitis: {
                    name: 'Tendinitis',
                    complemento: {},
                    value: false,
                },
                tendones: {
                    name: 'Tendones',
                    complemento: {},
                    value: false,
                },
                otros: {
                    name: 'Otros',
                    complemento: {},
                    value: false,
                },
            },
            habitos: {
                tabaquismo: {
                    name: 'Tabaquismo',
                    complemento: {},
                    value: false,
                },
                drogas_ilicitas: {
                    name: 'Drogas ilícitas',
                    complemento: {},
                    value: false,
                },
                alcoholismo: {
                    name: 'Alcoholismo',
                    complemento: {},
                    value: false,
                }
            },

            // alergias
            showEditionAlergias: false,
            alergia: {},

            // medicamentos
            showEditionMedicamento: false,
            medicamento: {},
        };
    },
    computed: {
        ...mapGetters({
            GetMedicoFichaActive: 'GetMedicoFichaActive',
        })
    },
    mounted() {
        const self = this;

        // Cargo familiares
        Object.keys(self.familiares).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.antecedentes_familiares) {
                if (self.GetMedicoFichaActive.metadata.antecedentes_familiares[value]) {
                    self.familiares[value].value = self.GetMedicoFichaActive.metadata.antecedentes_familiares[value];
                }
            }
        });

        // Cargo medicos
        Object.keys(self.medicos).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.antecedentes_medicos) {
                if (self.GetMedicoFichaActive.metadata.antecedentes_medicos[value]) {
                    self.medicos[value].value = self.GetMedicoFichaActive.metadata.antecedentes_medicos[value];
                }
            }
        });

        // Cargo medicos
        Object.keys(self.quirurgicos).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.antecedentes_quirurgicos) {
                if (self.GetMedicoFichaActive.metadata.antecedentes_quirurgicos[value]) {
                    self.quirurgicos[value].value = self.GetMedicoFichaActive.metadata.antecedentes_quirurgicos[value];
                }
            }
        });

        // Cargo medicos
        Object.keys(self.traumaticos).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.antecedentes_traumaticos) {
                if (self.GetMedicoFichaActive.metadata.antecedentes_traumaticos[value]) {
                    self.traumaticos[value].value = self.GetMedicoFichaActive.metadata.antecedentes_traumaticos[value];
                }
            }
        });

        // Cargo medicos
        Object.keys(self.habitos).map(function (value) {
            if (self.GetMedicoFichaActive.metadata.antecedentes_habitos) {
                if (self.GetMedicoFichaActive.metadata.antecedentes_habitos[value]) {
                    self.habitos[value].value = self.GetMedicoFichaActive.metadata.antecedentes_habitos[value];
                }
            }
        });
    },
    methods: {
        ...mapActions({}),
        dayjs,
        Numbro,

        // Antecedentes
        saveFamiliares() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.familiares).map(function (value) {
                arrToSave[value] = self.familiares[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'antecedentes_familiares',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        saveMedicos() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.medicos).map(function (value) {
                arrToSave[value] = self.medicos[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'antecedentes_medicos',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        saveQuirurgicos() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.quirurgicos).map(function (value) {
                arrToSave[value] = self.quirurgicos[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'antecedentes_quirurgicos',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        saveTraumaticos() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.traumaticos).map(function (value) {
                arrToSave[value] = self.traumaticos[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'antecedentes_traumaticos',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },
        saveHabitos() {
            const self = this;

            // transformo los keys a un array normal
            const arrToSave = {};
            Object.keys(self.habitos).map(function (value) {
                arrToSave[value] = self.habitos[value].value;
            });

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                orderId: self.GetMedicoFichaActive.orden.id,
                slug: 'antecedentes_habitos',
                detail: arrToSave,
                uniqueMeta: 1,
            }, function (response) {
                API.showSuccessAlert(response.msg);
            },
            function (response) {
                API.showErrorAlert(response.msg);
            });
        },

        // Alergias
        resetAlergia() {
            this.alergia = {
                id: 0,
                detail: {
                    fechaIdentificada: dayjs().format('DD-MM-YYYY HH:mm'),
                    alergiaA: '',
                    reaccion: '',
                    severidad: 0,
                }
            };
        },
        cancelEditAlergia() {
            this.showEditionAlergias = false;
            this.resetAlergia();
        },
        saveAlergia() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.alergia.id,
                    slug: 'antecedentes_alergia',
                    detail: self.alergia.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEditAlergia();
        },
        addAlergia() {
            this.resetAlergia();
            this.showEditionAlergias = true;
        },
        editAlergia(item) {
            this.resetAlergia();
            if (!item.detail.severidad) item.detail.severidad = 0; // arreglo el dolor por si viene null
            this.alergia = item;
            this.showEditionAlergias = true;
        },
        deleteAlergia(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },

        // Medicamentos
        resetMedicamento() {
            this.medicamento = {
                id: 0,
                detail: {
                    nombreYFormulacion: '',
                    fechaInicio: dayjs().format('DD-MM-YYYY HH:mm'),
                    fuente: '',
                    observaciones: '',
                }
            };
        },
        cancelEditMedicamento() {
            this.showEditionMedicamento = false;
            this.resetMedicamento();
        },
        saveMedicamento() {
            const self = this;

            // Guardo y luego emito el evento
            API.send('POST', 'orders/metadata/save/', {
                    orderId: self.GetMedicoFichaActive.orden.id,
                    metadataId: self.medicamento.id,
                    slug: 'antecedentes_medicamentos',
                    detail: self.medicamento.detail,
                }, function (response) {
                    API.showSuccessAlert(response.msg);
                    self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                },
                function (response) {
                    API.showErrorAlert(response.msg);
                });

            this.cancelEditMedicamento();
        },
        addMedicamento() {
            this.resetMedicamento();
            this.showEditionMedicamento = true;
        },
        editMedicamento(item) {
            this.resetMedicamento();
            this.medicamento = item;
            this.showEditionMedicamento = true;
        },
        deleteMedicamento(item) {
            const self = this;
            API.showConfirm('¿Está seguro de eliminar?', '', function (){
                API.send('POST', 'orders/metadata/delete', {
                        metadataId: item.id,
                    }, function (response) {
                        API.showSuccessAlert(response.msg);
                        self.emitter.emit("medico-ficha-refresh"); // emito un evento global para que se actualice la info de la ficha
                    },
                    function (response) {
                        API.showErrorAlert(response.msg);
                    });
            })
        },
    },
};

</script>
